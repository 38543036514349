import React from "react";
import ReactSVG from 'react-svg';
import axios from "axios";
import { API_URL } from '../../../config';
import {
  AnswerReviewContainer,
  HeaderContainer,
  Label,
  BodyContainer,
  AuthorName,
  LocationAddress,
  LocationContainer,
  Date,
  DateContainer,
  Text,
  Form,
  ActionContainer,
  Textarea,
  AnswerButton,
  CloseContainer
} from "./styled";
import { Row, Column } from "../../../Assets/Structure/Structure";
import Answer from './Answer/Answer';
import Rating from '../../../Components/Rating/Rating';
import Loading from '../../../Components/Loading/Loading';
import locationIcon from '../../../Assets/icons/location.svg';
import closeIcon from '../../../Assets/icons/close.svg';
import positive from '../../../Assets/icons/positive.svg';
import neutral from '../../../Assets/icons/neutral.svg';
import negative from '../../../Assets/icons/negative.svg';

export default class AnswerReview extends React.Component {

  state = {
    replyText: "",
    reviewAnswered: false,
    errorOnRequest: false,
    loading: false
  }

  componentDidMount(){
    const { review } = this.props;
    
    if (review.reply !== null){
      this.setState({ reviewAnswered: true, replyText: review.reply })
    } else {
      this.setState({ reviewAnswered: false })
    }
  }

    handleClose = () => {
        this.props.isModalOpen("", false);
    }

    handleChange = (e) => {
      this.setState({ replyText: e.target.value })
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const store = localStorage.getItem("store");
      const authToken = localStorage.getItem("token");
      const headers = { headers: { "x-sas-Auth" : authToken } };
      const payload = { comment: this.state.replyText };
      const { review } = this.props;
      this.setState({ loading: true })

      axios.post(`${API_URL}/admin/stores/${store}/locations/${review.location}/reviews/${review.id}/reply`, 
      payload, headers)
      .then(res => {
        axios.get(`${API_URL}/admin/stores/${store}/reviews`, headers)
        .then(res => {
          const reviews = res.data.reviews;
          const totalOfReviews = res.data.total;
          this.props.updateReviews(reviews, totalOfReviews);
        })

        this.setState({ reviewAnswered: true, loading: false })
      })
      .catch(() => {
        this.setState({ errorOnRequest: true, loading: false })
      })
    }

  render() {

    const review = this.props.review;
    
    let smile;

    if (review.rating > 3) smile = positive;
    if (review.rating === 3) smile = neutral;
    if (review.rating < 3) smile = negative;

    let firstName = review.author.split(" ");
    firstName = firstName[0];

    return (
      <AnswerReviewContainer>
        <HeaderContainer>
          <Row between>
            <Column>
              <Label>Answer review</Label>
              <p>Have confidence with your client. Be transparent</p>
            </Column>
            <Column>
              <CloseContainer onClick={this.handleClose}>
                <ReactSVG src={closeIcon} />
              </CloseContainer>
            </Column>
          </Row>
        </HeaderContainer>
        <BodyContainer>
          <Row className="top-answer">
            <Column>
              <ReactSVG src={smile} className="smile-answer" />
            </Column>
            <Column className="m0" justifyCenter ml2 fluid>
              <Row between>
                <AuthorName>{review.author}</AuthorName>
                <Rating totalOfStars={[1, 2, 3, 4, 5]} rating={review.rating} />
              </Row>
              <Row between mt025>
                <LocationContainer>
                  <ReactSVG src={locationIcon} />
                  <LocationAddress>{review.location_name}</LocationAddress>
                </LocationContainer>
                <DateContainer>
                  <Date>{review.time}</Date>
                </DateContainer>
              </Row>
              <Row>
                <Text>{review.text}</Text>
              </Row>
            </Column>
          </Row>
          <Row>
            {this.state.reviewAnswered ? (
              <Answer replyText={this.state.replyText} />
            ) : (
            this.state.loading ? (
              <Loading imageWidth="75px" />
            ) : (
            <Form onSubmit={this.handleSubmit}>
              <Textarea 
                type="text" 
                onChange={this.handleChange}
                placeholder={`Answer ${firstName}...`} 
                value={this.state.replyText} />
              <ActionContainer>
                <AnswerButton type="submit">Send</AnswerButton>
              </ActionContainer>
            </Form>
            )

            )}
          </Row>
        </BodyContainer>
      </AnswerReviewContainer>
    );
  }
}
