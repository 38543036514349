import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import TotalAverageRate from "./TotalAverageRate/TotalAverageRate";
import {
  OverviewContainer,
  ReviewsContainer,
  TotalAverageRateContainer
} from "./styled";
import { RowJustifyContainer } from "./styled";
import Review from "./Review/Review";
import OverviewSkeleton from "./OverviewSkeleton";

export default class Overview extends React.Component {
  state = {
    numberOfReviews: 0,
    positiveReviews: 0,
    neutralReviews: 0,
    negativeReviews: 0,
    totalAverageRate: 0,
    width: this.props.width
  };

  componentDidMount() {
    this.setState({ width: this.props.width })
  }

  componentWillReceiveProps(props) {
    this.setState({ width: props.width })
  }

  render() {

    const { 
      totalReviews, 
      positiveReviews, 
      neutralReviews, 
      negativeReviews, 
      locationRating 
    } = this.props;

    return (
      
      <OverviewContainer width={this.state.width}>
        {this.props.loading ? (
          <OverviewSkeleton />
        ) : (
          <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
          <React.Fragment>
            <RowJustifyContainer className="header">
          <h6>All reviews</h6>
          <label>{totalReviews}</label>
        </RowJustifyContainer>
        <ReviewsContainer>
          <Review type="positive" reviews={positiveReviews} />
          <Review type="neutral" reviews={neutralReviews} />
          <Review type="negative" reviews={negativeReviews} />
        </ReviewsContainer>
        <TotalAverageRateContainer>
          <TotalAverageRate totalAverageRate={locationRating} />
        </TotalAverageRateContainer>
          </React.Fragment>
          </CSSTransitionGroup>
        )}
      </OverviewContainer>
     
    );
  }
}
