import React from 'react';
import Skeleton from "react-skeleton-loader";
import { TableWrapper, TableRow } from '../TeamTableRow/styled';

export default class TeamTableRowSkeleton extends React.Component{
    render(){
        return (
            <TableWrapper>
                <TableRow style={{ flexDirection: "column" }}>
                    <Skeleton height="1em" />
                    <Skeleton height="1em" width="100%" count={2} />
                </TableRow>
            </TableWrapper>
        )
    }
}