import React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { MenuOptionsList, MenuOption } from "./styled";

export default class MenuOptions extends React.Component {

  state = {
    current: this.props.currentTab
  }

  handleCurrent = (e, current) => {
    this.props.handleCurrentTab(current)
    this.props.isMenuActive(false)
  } 

  componentWillReceiveProps(props){
    this.setState({ current: props.currentTab })
  }


  render() {
    return (
      <MenuOptionsList>
        {this.props.options.map((option) => {
          return (
            <Link to={`${option.path}`}>
              <MenuOption onClick={(e) => this.handleCurrent(e, option.path)} style={this.props.currentTab === option.path  ? { backgroundColor: "#F8F8F8", borderLeft: "5px solid #025f88" } : null}>
                <ReactSVG src={option.icon} />
                <span>{option.value}</span>
              </MenuOption>
            </Link>
          );
        })}
      </MenuOptionsList>
    );
  }
}
