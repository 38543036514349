import React from "react";
import SelectAlt from "../../../Components/SelectAlt/SelectAlt";
import {
  ReviewsFilterContainer,
  NoReply,
  ResetContainer,
  DesktopFilter,
  MobileFilter,
  ResetFilterButton
} from "./styled";
import HumorFilter from "../../../Components/HumorFilter/HumorFilter";
import { Column } from "../../../Assets/Structure/Structure";
import SideFilter from "../../../Components/SideFilter/SideFilter";

export default class ReviewsFilter extends React.Component {
  state = {
    isFilterOn: false,
    resetFilter: this.props.resetFilter,
    platformPlaceholder: "Platform",
    locationPlaceholder: "Location"
  };

  componentDidMount() {
    this.setState({ isFilterOn: this.props.isFilterOn });
  }

  componentWillReceiveProps(props) {
    this.setState({ 
      isFilterOn: props.isFilterOn,
      resetFilter: props.resetFilter
    });
  }

  handleResetFilter = () => {
    this.setState({ 
      isFilterOn: false, 
      platformPlaceholder: "Platform", 
      locationPlaceholder: "Location",
      noReplyActive: false
    });
    this.props.handleResetFilter();
  }

  handleSelectedLocation = location => {
    this.setState({ isFilterOn: false, locationPlaceholder: location.name });
    this.props.selectedLocation(location, "location");
  };

  handleSelectedPlatform = platform => {
    this.props.selectedPlatform(platform, "platform");
    this.setState({ platformPlaceholder: platform.value })
  };

  handleSelectedReviewType = reviewType => {
    this.props.selectedReviewType(reviewType, "review-type");
  };

  handleNoReply = () => {
    this.props.noReply("no-reply", "no-reply");
    this.setState({ noReplyActive: true })
  };

  handlePrivateReviews = () => {
    this.props.privateReviewsOn("", "private-reviews");
  };

  handleFilterType = filterType => this.props.filterType(filterType);

  handleStopBackground = () => {
    this.props.stopBackground();
  }

  render() {
    const { locations } = this.props;

    const platforms = [
      { value: "Facebook", name: "Facebook" },
      { value: "Google", name: "Google" }
    ];

    return (
      <Column>
        <ReviewsFilterContainer>
          <DesktopFilter
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <HumorFilter
              selectedReviewType={this.handleSelectedReviewType}
              resetFilter={this.state.resetFilter}
              filterType={this.handleFilterType}
            />
          </DesktopFilter>
          <DesktopFilter>
            <NoReply 
             onClick={this.handleNoReply} 
             noReplyActive={this.state.noReplyActive}>
              Unanswered reviews
            </NoReply>
          </DesktopFilter>
          <DesktopFilter>
            <SelectAlt
              options={locations}
              placeholder={this.state.locationPlaceholder}
              selectedOption={this.handleSelectedLocation}
              resetFilter={this.props.resetFilter}
              id="select-location"
            />
          </DesktopFilter>

          <DesktopFilter>
            <SelectAlt
              options={platforms}
              placeholder={this.state.platformPlaceholder}
              selectedOption={this.handleSelectedPlatform}
              resetFilter={this.props.resetFilter}
              id="select-platform"
            />
          </DesktopFilter>

          <MobileFilter>
            <SideFilter 
              page="reviews"
              locations={locations}
              platforms={platforms}
              resetFilter={this.state.resetFilter}
              noReply={this.handleNoReply}
              handleResetFilter={this.handleResetFilter}
              selectedPlatform={this.handleSelectedPlatform}
              selectedReviewType={this.handleSelectedReviewType}
              selectedLocation={this.handleSelectedLocation} />
          </MobileFilter>
        </ReviewsFilterContainer>
        {this.props.device !== "mobile" && this.props.device !== "tablet" && (
          <ResetContainer>
            {this.state.isFilterOn && <ResetFilterButton onClick={this.handleResetFilter}>
              Reset Filter
            </ ResetFilterButton>}
          </ResetContainer>
        )}
      </Column>
    );
  }
}
