import styled from 'styled-components';

export const MobileMenuContainer = styled.div`
    display: none;

    @media only screen and (max-width: 1024px){
        display: block;
        height: 91px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1.5em;
        border-right: 1px solid #e7e7e7;
    }
`;