
export const questions = [
    { 
        id: 1, 
        question: "1 - How do I change my password?",   
    },
    { 
        id: 2, 
        question: "2 - How do I integrate with Google and Facebook to load my reviews?",
    },
    { 
        id: 3, 
        question: "3 - How do I send review requests?",
    },
    { 
        id: 4, 
        question: "4 - How do I check my reviews?",
    },
    { 
        id: 5, 
        question: "5 - How do I answer reviews?",
    },
    { 
        id: 6, 
        question: "6 - How do I know who answered a review?",
    },
    { 
        id: 7, 
        question: "7 - Do I get a report from Review Alert?",
    },
    {
        id: 8,
        question: "8 - How do I access reports on Review Alert?"
    },
    { 
        id: 9, 
        question: "9 - Can I access the individual performance of each team member?",
    },
    { 
        id: 10, 
        question: "10 - How do I manage a store location on Review Alert?",
    },
    { 
        id: 11, 
        question: "11 - How do I check how well a specific location is doing?",
    },
    { 
        id: 12, 
        question: "12 - How do I see a comparison of the best-rated locations?", 
    },
    { 
        id: 13, 
        question: "13 - Where do I see statistics & data for my whole store?",
    },
    { 
        id: 14, 
        question: "14 - Do I get notified if someone posts a bad review?",
    },
    { 
        id: 15, 
        question: "15 - How do I manage my team on Review Alert? Add and delete members.",
    },
    {
        id: 16,
        question: "16 - Can I access Review Alert from different devices and platforms(PC, Mac, tablet or Smartphones)?"
    }
]
