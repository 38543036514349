import styled from 'styled-components';

export const BeaconPreviewContainer = styled.div`
    position: fixed;
    width: 100%;
    height: calc(100vh - 91px);
    margin-top: 91px;
    margin-left: 269px;
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 1500;
    max-width: calc(100% - 269px - 18em);
    pointer-events: ${props => (props.currentPreview === "" ? "none" : "all")};

    .image-container{
        width: 100%;
        max-width: 90%;
        padding: 2em;
        box-sizing: border-box;
        height: 36em;
        margin-left: 50%;
        transform: translate(-50%, -58%);
        margin-top: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img{
            width: 100%;
            position: fixed;
            transition: opacity .4s ease;
        }
    }

    @media only screen and (max-width: 1025px){
        margin: 0;
        width: 100%;
        height: 100vh;
        max-width: 100%;
    }
    
`;

export const Overlay = styled.div`
    top: 0;
    z-index: 1500;
    left:0;
    width: 100%;
    position: fixed;
    background-color: #00000090;
    height: 100vh;
`;