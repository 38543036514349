import React from "react";
import ReactSVG from 'react-svg';

import sendIcon from '../../../Assets/icons/send.svg';
import { SendCampaignButton } from './styled'
import ConfirmationSendCampaign from "../ConfirmationSendCampaign/ConfirmationSendCampaign";

export default class DeleteCampaign extends React.Component {
  state = {
    showConfirmation: false,
    campaign: ""
  };

  componentDidMount(){
    this.detectClick();
  }

  showConfirmation = (showConfirmation, campaign) => {
    this.setState({ showConfirmation, campaign });
  };

  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById(this.props.id);
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ showConfirmation: false })

    });
  };
  

  render() {

    return (
      <div id={this.props.id}>
        <SendCampaignButton>
          {this.state.showConfirmation && (
            <ConfirmationSendCampaign
              // locationId={this.props.locationId} 
              campaign={this.state.campaign} 
              handleClose={this.showConfirmation}
            />
          )}
          <button type="button"
            onClick={() => this.showConfirmation(true, this.props.campaign)}
          >
            <ReactSVG src={sendIcon} />
          </button>
        </SendCampaignButton>
      </div>
    );
  }
}