import styled from "styled-components";

export const ReviewTypeContainer = styled.div`
  .bar {
    margin-top: 0.5em;
  }
`;

export const TotalClicks = styled.label`
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  color: #333333;
`;

export const Label = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #666666;
`;
