import React from 'react';
import ReactSVG from 'react-svg';
import { SearchContainer } from './styled';
import searchIcon from '../../../Assets/icons/search.svg';

export default class Search extends React.Component{

    state = {
        searchValue: ""
    }

    handleChange = (e) => {
        this.setState({ searchValue: e.target.value });
        this.props.searchValue(e.target.value);
    }

    render(){
        return(
            <SearchContainer>
                <ReactSVG src={searchIcon} />
                <input type="text" placeholder="Search Store" className="search-input" onChange={this.handleChange} value={this.state.searchValue} />
            </SearchContainer>
        )
    }
}