import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from 'react-svg';
import {
    ListModuleContainer,
    LocationContainer,
    IntegratedPlatformsContainer,
    IntegratedPlatforms,
    Platform,
    LocationInfoContainer,
    IconContainer,
} from "./styled";
import Module from "../../Components/Module/Module";
import Button from "../../Components/Button/Button";
import locationIcon from '../../Assets/icons/location.svg';
import {blueDefault} from '../../Assets/colorPallet/colorPallet';
import ListStoresSkeleton from "./ListStoresSkeleton";

export default class ListStores extends React.Component {
    state = {
        locations: this.props.locations,
        loading: this.props.loading,
        device: ""
    };

    componentDidMount() {
        const screenWidth = window.innerWidth;
        this.setState({loading: this.props.loading})
        if (screenWidth < 760) {
            this.setState({device: "mobile"});
        }

        if (screenWidth > 760 && screenWidth < 1025) {
            this.setState({device: "tablet"});
        }

        if (screenWidth > 1025) {
            this.setState({device: "desktop"});
        }
    }

    componentWillReceiveProps(props) {
        this.setState({loading: props.loading, locations: props.locations})
    }

    handleModal = location => {
        this.props.handleModal(true, "integration-modal", location);
        localStorage.setItem("current_location_id", location.id);
        localStorage.setItem("current_location_name", location.name);
        localStorage.setItem("current_location_address", location.address);
    };

    render() {
        let locations = this.props.locations;

        return (
            <ListModuleContainer style={{
                marginTop: "2em"
            }}>
                {this.state.loading
                    ? (<ListStoresSkeleton/>)
                    : (locations.map((location, index) => {
                        return (
                            <CSSTransitionGroup
                            transitionName="mount-page"
                            transitionAppear={true}
                            transitionAppearTimeout={500}
                            transitionEnter={false}
                            transitionLeave={false}>
                            <Module
                                key={index}
                                style={{
                                display: "flex",
                                margin: "1em 0",
                                flexFlow: "row wrap",
                                justifyContent: "space-between"
                            }}>
                                <LocationContainer>
                                    {this.state.device === "mobile"
                                        ? (
                                            <div className="mobile-container">
                                                <div className="location-name-container">
                                                    <ReactSVG src={locationIcon}/>
                                                    <h2>{location.name}</h2>
                                                </div>
                                                <p>{location.address}</p>
                                            </div>
                                        )
                                        : (
                                            <React.Fragment>
                                                <IconContainer>
                                                    <ReactSVG src={locationIcon}/>
                                                </IconContainer>
                                                <LocationInfoContainer>
                                                    <h2>{location.name}</h2>
                                                    <p>{location.address}</p>
                                                </LocationInfoContainer>
                                            </React.Fragment>
                                        )}
                                </LocationContainer>
                                <IntegratedPlatformsContainer>
                                    {location.review_services.length > 0
                                        ? (
                                            <React.Fragment>
                                                <h3>
                                                    Integrated Platforms
                                                    <span>
                                                        <a className="tag-integration-modal" onClick={() => this.handleModal(location)}>Add more</a>
                                                    </span>
                                                </h3>
                                                <IntegratedPlatforms>
                                                    {location.review_services.length > 0
                                                        ? location
                                                            .review_services
                                                            .map(reviewService => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <Platform platform={reviewService}>
                                                                            {reviewService}
                                                                        </Platform>
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                        : null}
                                                </IntegratedPlatforms>
                                            </React.Fragment>
                                        )
                                        : (<Button
                                            className="btn-integrate-website tag-integration-modal"
                                            onClick={() => this.handleModal(location)}
                                            text="Load your reviews"
                                            borderRadius="20em"
                                            height="40px"
                                            width="236px"
                                            flex
                                            padding="0"
                                            bgColor={blueDefault}
                                            color="white"/>)}
                                </IntegratedPlatformsContainer>
                            </Module>
                            </CSSTransitionGroup>
                        );
                    }))}
            </ListModuleContainer>
        );
    }
}
