import React from "react";
import axios from "axios";
import NewMemberModal from "../NewMemberModal/NewMemberModal";
import { TopActionContainer, Wrapper, ResetContainer } from "./styled";
import Button from "../../../Components/Button/Button";
import TeamFilter from "../TeamFilter/TeamFilter";
import TeamTable from "../TeamTable/TeamTable";
import ResetFilter from "../TeamFilter/ResetFilter/ResetFilter";
import { API_URL } from "../../../config";
import addIcon from "../../../Assets/icons/add.svg";
import { blueDefault } from "../../../Assets/colorPallet/colorPallet";
import EmptyFeedback from "../../../Components/EmptyFeedback/EmptyFeedback";
import emptyMemberIcon from "../../../Assets/icons/empty-member.svg";
import Pagination from "../../../Components/Pagination/Pagination";

export default class TeamManagement extends React.Component {
  state = {
    employees: this.props.employees,
    searchValue: "",
    totalOfEmployees: "",
    loading: this.props.loading,
    resetFilter: false,
    allLocations: [],
    store: "",
    locations: [],
    allRoles: [{ name: "Manager" }, { name: "Seller" }],
    roles: [{ name: "Manager" }, { name: "Seller" }],
    isFilterOn: false,
    currentPage: 1,
    holdPropsBack: false,
    storeEmployees: "",
    endpoint: ""
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    const endpoint = `${API_URL}/admin/stores/${store}/employees?`

    axios.get(endpoint, headers)
    .then(res => {
      const storeEmployees = res.data.total;
      this.setState({ storeEmployees, endpoint })
    })

    axios.get(`${API_URL}/admin/stores/${store}/locations`, headers).then(res => {
      this.setState({
        locations: res.data,
        allLocations: res.data,
        store,
        authToken,
        headers
      });
    });

    this.setState({
      resetPagination: this.props.resetPagination,
      totalOfEmployees: this.props.totalOfEmployees
    });
  }

  componentWillReceiveProps(props) {
    if (!this.state.holdPropsBack){
      this.setState({
        employees: props.employees,
        resetPagination: props.resetPagination,
        totalOfEmployees: props.totalOfEmployees,
        loading: props.loading
      });
    }
  }

  handleSearchValue = searchValue => {
    this.setState({ searchValue });

    let employees = this.props.employees;
    let value = searchValue.trim().toLowerCase();

    if (value.length > 0) {
      employees = employees.filter(function(i) {
        return i.name.toLowerCase().match(value);
      });

      this.setState({ employees, isFilterOn: true });
    }

    if (value.length === 0) {
      employees = this.props.employees;
      this.setState({ employees });
    }
  };

  handleModal = isModalOpen => this.props.handleCloseModal(isModalOpen);

  handleSelectedLocation = location => {    
    this.setState({ loading: true });

    axios(`${API_URL}/admin/stores/${this.state.store}/locations/${location.id}/employees`, this.state.headers)
    .then(res => {
      const employees = res.data.employees;
      const totalOfEmployees = res.data.total;

      this.setState({ 
        employees,
        totalOfEmployees,
        loading: false,
        isFilterOn: true
      })
      this.props.updatedEmployees(employees, totalOfEmployees);
    })
  }

  handleMobileFilter = showMobileFilter => this.setState({ showMobileFilter });

  handleResetFilter = () => {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};
    this.setState({ loading: true })
    axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
    .then(res => {
      const employees = res.data.employees;
      const totalOfEmployees = res.data.total;
      this.setState({ employees, totalOfEmployees, loading: false}, () => {
        this.handleAfterReset();
      })
    })
  };

  handleAfterReset = () => this.setState({ 
    isFilterOn: false,
    endpoint: `${API_URL}/admin/stores/${this.state.store}/employees?`,
    currentPage: 1 
  });

  handleCurrentPage = currentPage => {
    this.setState({ loading: true });

    axios.get(`${this.state.endpoint}&&page=${currentPage}`, this.state.headers)
      .then(res => {
        this.setState({
          employees: res.data.employees,
          loading: false
        });
      });
  };

  handleUpdatedEmployees = (employees, totalOfEmployees, type, endpoint) => {
    if (type === "filter"){
      this.setState({ 
        employees, 
        totalOfEmployees, 
        holdPropsBack: true,
        isFilterOn: true,
        endpoint
      })
    } else {
      if (totalOfEmployees === 0 ){
        this.setState({ 
          storeEmployees: 0,
          employees, 
          totalOfEmployees, 
          holdPropsBack: true,
          endpoint: `${API_URL}/admin/stores/${this.state.store}/employees?`
        })
      } else {
        this.setState({ 
          storeEmployees: 1,
          employees, 
          totalOfEmployees, 
          holdPropsBack: true,
          endpoint: `${API_URL}/admin/stores/${this.state.store}/employees?`
        })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.storeEmployees === 0 ? (
          <React.Fragment>
            {this.props.isModalOpen && (
              <NewMemberModal
                isModalOpen={this.handleModal}
                updatedEmployees={this.handleUpdatedEmployees}
              />
            )}
            <EmptyFeedback
              iconPath={emptyMemberIcon}
              text="You do not have any members yet."
              handleNew={this.props.addMemberClick}
              buttonText="Add member"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.props.isModalOpen && (
              <NewMemberModal
                isModalOpen={this.handleModal}
                updatedEmployees={this.handleUpdatedEmployees}
              />
            )}
            <Wrapper>
              <div>
                <TopActionContainer searchIsActive={this.props.searchIsActive}>
                  <Button
                    onClick={this.props.addMemberClick}
                    bgColor="#fff"
                    color="#333"
                    text="Add member"
                    hasIcon
                    bgColorOnHover={false}
                    iconPath={addIcon}
                    iconColor={blueDefault}
                    borderRadius="20em"
                    className="add-member-btn tag-add-member"
                    childClass="tag-add-member"
                    hasShadow
                    flex
                    iconWidth="18px"
                    style={{ height: "40px", padding: "10px 20px" }}
                  >
                    {this.props.device !== "mobile" && "Add members"}
                  </Button>
                  <TeamFilter
                    isSearchActive={this.props.isSearchActive}
                    searchValue={this.handleSearchValue}
                    selectedOrderByOption={this.handleSelectedOrderByOption}
                    updateEmployees={this.handleUpdatedEmployees}
                    showMobileFilter={this.handleMobileFilter}
                    device={this.props.device}
                    className="team-filter"
                    handleResetFilter={this.handleResetFilter}
                    locations={this.state.locations}
                    roles={this.state.roles}
                    employees={this.state.employees}
                    isFilterOn={this.state.isFilterOn}
                  />
                </TopActionContainer>
                <ResetContainer>
                  {this.state.isFilterOn && <ResetFilter onClick={this.handleResetFilter} />}
                </ResetContainer>
              </div>

              {this.state.storeEmployees === 0 ? (
                <EmptyFeedback
                iconPath={emptyMemberIcon}
                text="This location does not have members yet."
                containerHeight="70vh"
                flex="1"
              />
              ) : this.state.totalOfEmployees === 0 ? (
                <EmptyFeedback
                iconPath={emptyMemberIcon}
                text="No team members with the selected criteria has been founded"
                containerHeight="70vh"
                flex="1"
              />
              ) : (
                <React.Fragment>
                  <TeamTable
                    employees={this.state.employees}
                    totalOfEmployees={this.state.totalOfEmployees}
                    device={this.props.device}
                    loading={this.state.loading}
                    updateEmployees={this.handleUpdatedEmployees}
                  />
                  <Pagination
                    totalOfItems={this.state.totalOfEmployees}
                    maxItemsPerPage={20}
                    currentPage={this.handleCurrentPage}
                    mt="2em"
                  />
                </React.Fragment>
              )}
            </Wrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
