import styled from "styled-components";

export const EmptyFeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${props =>
  props.containerHeight ? props.containerHeight : "calc(100vh - 91px - 4em)"};
  flex: ${props => props.flex};

  svg {
    width: ${props => props.svgWidth};
    height: ${props => props.svgHeight};
    fill: ${props => props.svgFill};

    path {
      fill: ${props => props.svgPathFill};
    }
  }

  p {
    text-align: center;
    color: #bbb;
    letter-spacing: 0.1em;
    margin-top: 2em;
    font-size: 18px;
    width: 100%;
    max-width: 34em;
    margin: 1em auto;
    line-height: 1.6em;
  }

  @media only screen and (max-width: 500px){
    height: auto;
    margin-top: 4em;
      p{
        max-width: 19em;
        font-size: 14px;
      }
    }
`;
