import React from 'react';
import { SideMenuContainer } from './styled';
import Profile from './Profile/Profile';
import Menu from './Menu/Menu';

export default class SideMenu extends React.Component{

    handleLoggedIn = loggedIn => this.props.loggedIn(loggedIn);
    
    handleMenuActive = () => {}

    render(){
        return (
            <SideMenuContainer>
                <Profile 
                    loggedIn={this.handleLoggedIn} 
                    userName={this.props.userName} 
                    userEmail={this.props.userEmail} />
                <Menu isMenuActive={this.handleMenuActive}/>
            </SideMenuContainer>
        )
    }
}