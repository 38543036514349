import styled from "styled-components";

export const IntegrateActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5em;
  width: 100%;
  max-width: 22em;
  margin: 2em auto;

  svg {
    width: 20px;
    height: 20px;
  }

  a:nth-child(2) {

    
    margin-top: 1em;

    svg {
      path:nth-child(1) {
        fill: #ea4335;
      }

      path:nth-child(2) {
        fill: #4285f4;
      }

      path:nth-child(3) {
        fill: #fbbc05;
      }

      path:nth-child(4) {
        fill: #34a853;
      }
    }
  }
`;
