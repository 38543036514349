import React from "react";
import ReactSVG from "react-svg";
import { SelectDateContainer, Overlay } from "./styled";
import { Column, Row } from "../../Assets/Structure/Structure";
import calendarIcon from "../../Assets/icons/calendar.svg";
import arrowIcon from "../../Assets/icons/arrow.svg";
import RenderCalendar from "./RenderCalendar/RenderCalendar";

export default class SelectDate extends React.Component {

  state = {
    isModalOn: false,
    placeholder: this.props.placeholder
  };

  componentWillReceiveProps(props){
    this.setState({ placeholder: props.placeholder })
  }

  handleModal = isModalOn => {
    this.setState({ isModalOn });

    const body = document.querySelector("body");
    if (isModalOn){
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }

   handleDateInterval = dateInterval => {
     this.props.selectedInterval(dateInterval)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isModalOn && (
          <React.Fragment>
            <Overlay onClick={() => this.handleModal(false)} /> 
            <RenderCalendar dateInterval={this.handleDateInterval} isModalOpen={this.handleModal}  />
          </React.Fragment>
        )}
        <SelectDateContainer onClick={() => this.handleModal(true)} isModalOn={this.state.isModalOn}>
          <Row between>
            <Column>
              <Row>
                <ReactSVG src={calendarIcon} />
                <label style={{ fontSize: "14px", margin: "0 1em" }}>{this.state.placeholder}</label>
              </Row>
            </Column>
            <Column className="arrow">
              <ReactSVG src={arrowIcon} />
            </Column>
          </Row>
        </SelectDateContainer>
      </React.Fragment>
    );
  }
}
