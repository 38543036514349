import React from "react";
import ReactSVG from "react-svg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { RowContainer, PagesContainer, Page } from "./styled";
import googleIcon from "../../../../Assets/icons/google.svg";
import facebookIcon from "../../../../Assets/icons/facebook.svg";
import { Label, Container } from '../../../../Assets/Structure/Structure';

export default class StepTwo extends React.Component {
  state = {
    isPageClicked: false,
    page: "",
    pageId: ""
  };

  handleCloseModal = isModalOpen => this.props.isModalOpen(isModalOpen);

  handlePage = page => this.setState(state => ({ isPageClicked: !state.isPageClicked, page, pageId: page.id }));

  handleCurrentStep = currentStep => this.props.updatedCurrentStep(currentStep);

  handleUpdatedLocations = updatedLocations => this.props.updatedLocations(updatedLocations);
  

  render() {
    const { currentLocationName, currentLocationAddress } = this.props;

    return (
      <React.Fragment>
        <Header
          currentLocationName={currentLocationName}
          currentLocationAddress={currentLocationAddress}
          isModalOpen={this.handleCloseModal}
        />
        <div className="modal-body">
          <RowContainer>
            {this.props.service === "google" ? (
              <div className="google">
                <ReactSVG src={googleIcon} />
              </div>
            ) : this.props.service === "facebook" ? (
              <div className="facebook">
                <ReactSVG src={facebookIcon} />
              </div>
            ) : null}
            <p>
              You have {this.props.pages.length} pages in your account.
              <br /> Select Which page you want to integrate with
            </p>
          </RowContainer>
          <PagesContainer>
            {this.props.pages.map(page => {
              return (
                <Page
                  onClick={() => this.handlePage(page)}
                  isPageClicked={this.state.isPageClicked}
                  style={
                    this.state.pageId === page.id
                      ? { border: "1px solid #00b1ff" }
                      : { border: "1px solid white" }
                  }
                >
                  <Label fontSize="16px">{page.name}</Label>
                  <Container mt=".25em" flex column>
                    <Label fontSize="14px" color="#888">{page.city}</Label>
                    <Label fontSize="12px" color="#888">{page.street}</Label>
                  </Container>
                </Page>
              );
            })}
          </PagesContainer>
        </div>
        <Footer
          currentStep={this.props.currentStep}
          selectedPage={this.state.page}
          service={this.props.service}
          updatedCurrentStep={this.handleCurrentStep}
          updatedLocations={this.handleUpdatedLocations}
        />
      </React.Fragment>
    );
  }
}
