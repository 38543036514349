import styled from "styled-components";

export const FooterContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    background-color: #eee;

    label{
        text-transform: uppercase;
        font-weight: normal;
        line-height: 42px;
        font-size: 12px;
        letter-spacing: 0.1em;
        color: #666666;
        padding: 2em;
        box-sizing: border-box;
    }

`;

export const TrustedContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    box-sizing: border-box;


    img{
        width: 100px;
    }

    @media only screen and (max-width: 500px){
        padding: 1em 2em;
        img{
            width: 50px;
        }
    }
`;