import React from 'react';
import { RatingBarContainer, FillingBar } from './styled';

export default class RatingBar extends React.Component{
    render(){
        return(
            <RatingBarContainer>
                <FillingBar ratePercentage={this.props.ratePercentage} />
            </RatingBarContainer>
        )
    }
}