import React from 'react';
import ReactSVG from 'react-svg';
import { MembersContainer } from './styled';
import teamIcon from '../../Assets/icons/team.svg';

export default class Members extends React.Component{
    render(){
        return(
            <MembersContainer>
                <ReactSVG src={teamIcon} />
                <label>{this.props.numberOfEmployees} <span>members</span></label>
            </MembersContainer>
        )
    }
}