import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../../config';
import { OverviewContainer, Span} from './styled';
import Data from './Data/Data';
import sentIcon from '../../../../Assets/icons/send.svg';
import viewIcon from '../../../../Assets/icons/view.svg';
import pointerIcon from '../../../../Assets/icons/pointer.svg';

export default class Overview extends React.Component{

    state = {
        totalOfReviewsSent: ""
    }

    componentDidMount(){
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {headers: { "x-sas-Auth" : authToken }};
        axios.get(`${API_URL}/admin/stores/${store}/stats/requests`, headers)
        .then(res => {
            const totalOfReviewsSent = res.data.sent;
            const totalOfReviewsViewed = res.data.viewed;
            const percentageOfReviewsViewed = parseInt((totalOfReviewsViewed * 100) / totalOfReviewsSent);
            this.setState({ totalOfReviewsSent, percentageOfReviewsViewed });
        })
    }

    render(){
        return(
            <OverviewContainer>
                <Data 
                iconPath={sentIcon}
                text={<React.Fragment><Span>{this.state.totalOfReviewsSent} REVIEWS SENT</Span></React.Fragment>}/>

                <Data 
                iconPath={viewIcon}
                text={<React.Fragment><Span>{this.state.percentageOfReviewsViewed}% VIEWS</Span> EMAIL 82% / MOBILE 18%</React.Fragment>}
                 />

                <Data 
                iconPath={pointerIcon}
                text={<React.Fragment><Span>23% CLICKED</Span> EMAIL 82% / MOBILE 18%</React.Fragment>}
                 />
            </OverviewContainer>
        )
    }
}