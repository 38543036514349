import React from 'react';
import ReactSVG from 'react-svg';
import { AddMoreMembersContainer } from './styled';
import addIcon from '../../../../Assets/icons/add.svg';

export default class AddMoreMembers extends React.Component{

    state = {
        enableMultipleRequests: this.props.enableMultipleRequests
    }

    componentWillReceiveProps(props){
        this.setState({ enableMultipleRequests: props.enableMultipleRequests })
    }



    render(){
        return(
            <AddMoreMembersContainer onClick={this.props.onClick} enableMultipleRequests={this.props.enableMultipleRequests}>
                <ReactSVG src={addIcon} />
                <label>Add more members</label>
            </AddMoreMembersContainer>
        )
    }
}