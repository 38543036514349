import React from "react";
import { RateRankinGraphicContainer } from "./styed";
import RateResult from "./RateResult/RateResult";

export default class RateRankingGraphic extends React.Component {
  render() {
    const ratings = [1, 2, 3, 4, 5];

    return (
      <RateRankinGraphicContainer>
        {ratings.map((rating, index) => {

          const starsRanking = this.props.starsRanking;
          const totalReviewsByRate = starsRanking.filter((el, i) => index === i);
          
          const share = (totalReviewsByRate * 100) / this.props.totalReviews;

          return (
            <RateResult
              rating={rating}
              totalReviewsByRate={totalReviewsByRate}
              ratePercentage={share}
            />
          );
        })}
      </RateRankinGraphicContainer>
    );
  }
}
