import styled from 'styled-components';

export const ForgotPasswordModalContainer = styled.div`

    .forgot-password-modal{
        max-width: 575px;

        svg{
            cursor: pointer;
        }

        @media only screen and (max-width: 600px){
            max-width: 90%;
        }
    }
`;