import styled from "styled-components";

export const PlatformContainer = styled.div`
  background-color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 2em;
  box-sizing: border-box;

    @media only screen and (max-width: 500px){
      padding: 1em;
    }
`;

export const TopContainer = styled.div`
  display: flex;
`;

export const BottomContainer = styled.div``;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightContainer = styled.div`

  @media only screen and (max-width: 800px){
    width: 100%;
  }
`;

export const PlatformLogoContainer = styled.div`
  position: relative;
  z-index: 25;
  margin-top: -7em;
  margin-left: 4em;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.15);

`;
