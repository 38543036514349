import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class AccessReport extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    On the upper left side of the menu, click on <strong style={{ color: blueDefault }}>Reports</strong> to
                    access a full report page, which includes the number of all reviews, total average rating, rating, conversion rate, 
                    and last reviews.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}