import React from "react";
import TrustedCompanies from "./TrustedCompanies/TrustedCompanies";
import { FooterContainer } from "./styled";
import StepTwoAction from "./StepTwoAction/StepTwoAction";

export default class Footer extends React.Component {
  handleUpdateCurrentStep = currentStep => {
    this.props.updatedCurrentStep(currentStep);
  };

  handleUpdatedLocations = updatedLocations => this.props.updatedLocations(updatedLocations);

  render() {
    return (
      <FooterContainer>
        {this.props.currentStep === 1 ? (
          <TrustedCompanies />
        ) : this.props.currentStep === 2 ? (
          <StepTwoAction
            service={this.props.service}
            selectedPage={this.props.selectedPage}
            updatedCurrentStep={this.handleUpdateCurrentStep}
            updatedLocations={this.handleUpdatedLocations}
            loading={this.props.loading}
          />
        ) : this.props.currentStep === 3 ? null : null}
      </FooterContainer>
    );
  }
}
