import React from "react";
import ReactSVG from "react-svg";
import Rating from '../../../Components/Rating/Rating';
import {
  LocationOverviewContainer,
  LocationName,
  LocationAddress,
} from "./styled";
import { Row, Text } from '../../../Assets/Structure/Structure';
import locationIcon from "../../../Assets/icons/location.svg";
import SentToday from "./SentToday/SentToday";
import LastDaysOverview from "./LastDaysOverview/LastDaysOverview";
import ConvertionRate from "./ConvertionRate/ConvertionRate";

export default class LocationOverview extends React.Component {
  render() {

    const { className, storeName, requests, convertionRate, locationRating, locationName } = this.props;

    return (
      <LocationOverviewContainer className={className}>
        <LocationName>{storeName}</LocationName>
        <Row alignCenter between padding="1em 0">
          <LocationAddress>
            <ReactSVG src={locationIcon} />
            {locationName}
          </LocationAddress>
          <Rating totalOfStars={[1]} rating={locationRating || 0} showReviewGrade textColor="white"  whiteStar/>
        </Row>
        <SentToday amount={requests.sentToday || 0} />
        <LastDaysOverview 
          yesterday={requests.sentYesterday || 0}
          last7Days={requests.sent7Days || 0}
          last30Days={requests.sent30Days || 0} 
        />
        {convertionRate > 0 && 
        <ConvertionRate convertionRate={convertionRate} />
        }
        <Text color="#44CA61">Help improve your store's online reputation by sending more review invitations!</Text>
      </LocationOverviewContainer>
    );
  }
}
