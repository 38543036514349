import React from 'react';
import { LastDaysOverviewContainer } from './styled';
import { Column, Label } from '../../../../Assets/Structure/Structure';

export default class LastDaysOverview extends React.Component{
    render(){
        return (
            <LastDaysOverviewContainer>
                <Column>
                    <Label color="white" fontSize="12px" uppercase>Yesterday</Label>
                    <Label color="white" fontSize="18px" mt=".5em">{this.props.yesterday}</Label>
                </Column>

                <Column>
                    <Label color="white" fontSize="12px" uppercase>Last 07 days</Label>
                    <Label color="white" fontSize="18px" mt=".5em">{this.props.last7Days}</Label>
                </Column>

                <Column>
                    <Label color="white" fontSize="12px" uppercase>Last 30 days</Label>
                    <Label color="white" fontSize="18px" mt=".5em">{this.props.last30Days}</Label>
                </Column>
            </LastDaysOverviewContainer>
        )
    }
}