import styled from 'styled-components'


export const WidgetPicture = styled.div`
    width: 150px;
    height: 150px;
    background-image: url(${props => props.imageUrl});
    background-position: center;
    background-size: cover;
`

export const WidgetPictureSkeletonContainer = styled.div`
    .react-skeleton-load{
        width: 150px !important;
        height: 150px !important;
    }
`   