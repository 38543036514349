import styled from "styled-components";

export const GraphicContainer = styled.div`
  width: 100%;
  flex: 1;

  .recharts-wrapper {

  }

  .bar-chart{
    margin-top:2em;

    svg{
      transform: translate(-2.9em, 0);
      width: 100%;
      max-width: 60em;

    }



  }

  @media only screen and (orientation: landscape) and (max-width: 1025px){
    max-width: 40em;
    #bar-chart-container-row{
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  @media only screen and (max-width: 800px){
    max-width: 24em;
    #bar-chart-container-row{
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  @media only screen and (max-width: 500px){
    margin-top: 2em;
  }
`;

export const LeftLabel = styled.label`
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const RightLabel = styled.label`
font-size: 14px;
letter-spacing: 0.1em;
color: #888888;
`;


export const RowJustifyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;