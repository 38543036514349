import styled from "styled-components";
import { blueDefault, red } from '../../../../../../Assets/colorPallet/colorPallet';

export const NewMemberContainer = styled.div`
  width: 100%;
  padding: 1em;
  max-height: 80vh;
  overflow-y: auto;
  margin-top: .5em;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const MemberInfoContainer = styled.div`
  h3 {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #333333;
    margin: 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    margin: .5em 0 0 0;
    letter-spacing: 0.05em;
    color: #999999;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-items: flex-end;
  align-items: center;

  svg{
      cursor: pointer;
      fill: #ccc;

      path{
        transition: fill .4s ease;
        fill: #ccc;
      }
  }

  .edit{
    svg{
      &:hover{
        fill: ${blueDefault};

        path{
          fill: ${blueDefault};
        }
      }
    }
  }

  .trash{
    margin-left: 1em;
    svg{
      &:hover{
        fill: ${red};

        path{
          fill: ${red};
        }
      }
    }
  }
`;