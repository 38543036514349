import React from "react";
import Header from "../Header/Header";
import IntegrateAction from "../IntegrateAction/IntregrateAction";
import Footer from "../Footer/Footer";

export default class StepOne extends React.Component {
  handleCloseModal = isModalOpen => {
    this.props.isModalOpen(isModalOpen);
  };

  handleService = service => {
    this.props.service(service);
  };

  render() {
    return (
      <React.Fragment>
        <Header
          currentLocationName={this.props.currentLocationName}
          currentLocationAddress={this.props.currentLocationAddress}
          isModalOpen={this.handleCloseModal}
        />
        <div className="modal-body">
          <p>Please connect with the platforms below to load your 
            reviews and get the embed codes to add the Review Beacon to your website</p>
          <IntegrateAction
            service={this.handleService}
            currentLocation={this.props.currentLocation}
          />
        </div>
        <Footer currentStep={this.props.currentStep} />
      </React.Fragment>
    );
  }
}
