import React from "react";
import { SelectOptionsContainer } from "./styled";
import Options from "./Options/Options";

export default class SelectOptions extends React.Component {
  
  handleSelectedOption = option => {
    this.props.selectedOption(option);
  };

  render() {
    return (
      <SelectOptionsContainer maxWidth={this.props.maxWidth} position={this.props.position} id="select-options-container">
        <Options
          options={this.props.options}
          selectedOption={this.handleSelectedOption}
        />
      </SelectOptionsContainer>
    );
  }
}
