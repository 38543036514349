import React from "react";
import axios from 'axios';
import ReactSVG from 'react-svg';
import { API_URL } from '../../../config';
import { UpdateEmployeeLocationContainer } from "../UpdateEmployeeLocation/styled";
import Options from "./Options/Options";
import arrowIcon from '../../../Assets/icons/arrow.svg';

export default class UpdateEmployeeRole extends React.Component {

    state = {
        role: this.props.role,
        showOptions: false,
        employeeLocationId: ""
    }

    componentDidMount(){
      this.detectClick();

      const store = localStorage.getItem("store");
      const authToken = localStorage.getItem("token");
      const headers = {headers: { "x-sas-Auth" : authToken }};
  
      axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
      .then(res => {
        const locations = res.data;
        const employeeLocationId = locations.filter(location => location.name === this.props.employee.location)[0].id;
        this.setState({ employeeLocationId })
      });
    }

    detectClick = () => {
      window.addEventListener("click", e => {
        const element = document.getElementById(this.props.id);
        let target = e.target;
  
        do {
          if (target == element) {
            return;
          }
  
          target = target.parentNode;
        } while (target);
  
        this.setState({ showOptions: false });
      });
    };

    componentWillReceiveProps(props){
      this.setState({ role: props.role })
    }

    handleShowOptions = (showOptions) => {
        this.setState({ showOptions })
    }

    handleSelectedOption = (selectedOption) => {
      this.setState({ role: selectedOption.label, showOptions: false })
      
      const authToken = localStorage.getItem("token");
      const store = localStorage.getItem("store");
      const headers = {headers: { "x-sas-Auth" : authToken }};
  
      const { employee } = this.props;
  
      const payload = {
        email: employee.email,
        name: employee.name,
        role: selectedOption.label.toLowerCase(),
      }

      axios.post(`${API_URL}/admin/stores/${store}/locations/${this.state.employeeLocationId}/employees`, payload, headers)
        .then(res => {
           axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
          .then(res => this.props.updateEmployees(res.data.employees, res.data.total));
      })
    }

  render() {
    return (
      <div id={this.props.id}>
        <UpdateEmployeeLocationContainer onClick={() => this.handleShowOptions(true)}>
          <ReactSVG src={arrowIcon} />
          <label>{this.state.role}</label>
        </UpdateEmployeeLocationContainer>
        {this.state.showOptions && <Options selectedOption={this.handleSelectedOption} />}
      </div>
    );
  }
}
