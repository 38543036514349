import styled from 'styled-components';

export const WebchatContainer = styled.div`

    .phone-icon, .location-icon{
        div:nth-child(1){
            display: flex;
        }
    }

    .arrow-icon {
        svg{
            width: 8px;
            height: 8px;
        }
        div:nth-child(1){
            display: flex;
        }
    }

    #select-fluid-action{
        padding: 0;
    }
    

    @media only screen and (max-width: 600px){
        #webchat-menu{
            transition: left .4s ease;
            left: ${props => props.showChatsOnMobile ? "0" : "-100%"};
            position: fixed;
            z-index: 1600;
        }
        
        .webchat-container{
            margin-top: 0;
        }

        #webchat-body{
            max-width: 100%;
        }
    }
`;

export const InactiveWebchatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 91px);
  .chat-icon {
    svg {
      width: 120px;
      height: 120px;
    }
  }
    `
