import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { OverlayContainer } from './styled'

export default class Overlay extends React.Component{
    render(){
        return (
            <CSSTransitionGroup
            transitionName="overlay"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
                <OverlayContainer onClick={() => this.props.onClick()} />
            </CSSTransitionGroup>
        )
    }
}