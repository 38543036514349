import React, {useEffect, useState} from "react"
import axios from "axios"
import {WEBCHAT_API_URL} from "../../../../config";
import {Container, Label} from "../../../../Assets/Structure/Structure";
import { StoreConversationItemContainer } from "./styled";
import StoresConversationRankingSkeleton from "./StoresConversationRankingSkeleton";

export default function StoresConversationRanking({ months }) {

    const [storesConversationRanking, setStoresConversationRanking] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchStoresConversationRanking()
    }, [])

    const fetchStoresConversationRanking = async () => {
        try {
            setLoading(true)
            const authToken = localStorage.getItem("token")
            const headers = { headers: { "ra-user-token": authToken } }
            const response = await axios.get(`${WEBCHAT_API_URL}/analytics/stores/conversationRanking?months=${months}`, headers)
                .then(res => res.data)

            setStoresConversationRanking(response)
        }
        catch(error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            {loading ? (
                <StoresConversationRankingSkeleton />
            ) : (
                storesConversationRanking.map(storeConversation => (
                    <StoreConversationItemContainer>
                        <Container fluid maxWidth="20em">
                            <Label>{storeConversation.storeName}</Label>
                        </Container>
                        <Container>
                            <label>{storeConversation.conversations}</label>
                        </Container>
                    </StoreConversationItemContainer>
                ))
            )}
        </Container>
    )
}
