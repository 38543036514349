import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { RaSnackbarContainer } from './styled'
import Slide from '@material-ui/core/Slide';


export default function RaSnackbar(props) {

    return (
        <RaSnackbarContainer>
            <Snackbar 
            {...props}
            TransitionComponent={(props) => <Slide {...props} direction="up" /> } />
        </RaSnackbarContainer>
    )
}
