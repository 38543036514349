import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class AccessIndividualPerformance extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    On the <strong style={{ color: blueDefault }}>Reports</strong> page, you can see the performance of each person including how many review invitations they have sent, if the client viewed and clicked on the review invitation link, and also total stats.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}