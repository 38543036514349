import styled from "styled-components";

export const ConvertionRateChartContainer = styled.div`
  width: 100%;
  background-color: ${props => props.loading ? "white" : "#eee"};
  border-radius: 5px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props =>
    props.flexEnd ? "flex-end" : props.between ? "space-between" : null};
  align-items: ${props => (props.alignCenter ? "center" : null)};
`;

export const TopContainer = styled.div`
  padding: 1em 2em;
  box-sizing: border-box;
  background-color: white;
`;

export const ConvertionRatePercentage = styled.label`
  font-size: 24px;
  text-align: right;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #999999;
`;

export const BottomContainer = styled.div`
  background: #eeeeee;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: ${props => props.noPerformance ? "0" : null};
  margin: auto auto 0 auto;

  svg {
    transform: translate(.5em, -2em);
    width: 200px;
  }

  .recharts-wrapper {
    width: 150px !important;
    height: 120px !important;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(100% - 11em);
  padding: 1em 0;
  flex-direction: column;

`;

export const Column = styled.div``;

export const Amount = styled.div`
  font-size: 18px;
  letter-spacing: 0.1em;
  color: ${props =>
    props.reviewsSent ? "#025f88" : props.totalReviews ? "#00B1FF" : null};
`;

export const ReviewsSentLabel = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #999999;
`;

export const TotalReviews = styled.label`
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #00b1ff;
`;

export const TextLabel = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #999999;
`;

export const ConvertionOverview = styled.div`
  background: #f8f8f8;
  width: 100%;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.15);
  padding: 1em;
  box-sizing: border-box;
  margin-top: 1em;

  p {
    font-size: 14px;
    text-align: center;
    margin: 0;
    color: #333333;

    @media only screen and (max-width: 1459px){
      font-size: 12px;
    }
  }
`;
