import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class TeamManagement extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    To setup your team go to <strong style={{ color: blueDefault }}>Team</strong> and click add member. 
                    Fill in name, email, location and what role the person will have. To delete members, mouse over the person's profile 
                    and then click on the trash icon to delete. You may choose to add several members at once and you can change the team
                    settings at any time.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}