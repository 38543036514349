import styled from 'styled-components';

export const MembersContainer = styled.div`
    display: flex;
    align-items: center;

    svg{
        margin-right: .5em;
    }

    label{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #999999;

        @media only screen and (max-width: 500px){
            span{
                display: none;
            }
        }
    }

    @media only screen and (max-width: 500px){
        padding: 0 1em;
    }
`;  