import React from "react";
import Loading from '../../../../Components/Loading/Loading';
import Search from "./Search/Search";
import { LocationsList, Location, LocationsContainer } from "./styled";

export default class Locations extends React.Component {
  state = {
    upContainer: false,
    locations: this.props.locations,
    loading: this.props.loading
  };

  componentDidMount() {
    const locationsList = document.getElementById("locations-list");
    this.getElementPosition(locationsList);
  }

  componentWillReceiveProps(props) {
    this.setState({ loading: props.loading });
  }

  getElementPosition = element => {
    let xPosition = 0;
    let yPosition = 0;
    const pageHeight = window.innerHeight;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    const diff = pageHeight - yPosition;

    if (diff < 250) {
      this.setState({ upContainer: true });
    } else {
      this.setState({ upContainer: false });
    }

    return { x: xPosition, y: yPosition };
  };

  handleSearchValue = searchValue => {
    let locations = this.props.locations;
    const value = searchValue.trim().toLowerCase();

    if (value.length > 0) {
      locations = locations.filter(function(i) {
             return i.name.toLowerCase().match(value);
      });

      this.setState({ locations });
    }

    if (value.length === 0) {
      locations = this.props.locations;
      this.setState({ locations });
    }
  };

  handleSelectedLocation = location => {
    this.props.selectedLocation(location);
  };

  render() {
    return (
      <LocationsContainer
        id="locations-list"
        upContainer={this.state.upContainer}
      >
        <Search searchValue={this.handleSearchValue} />
        {this.props.loading ? (
          <Loading />
        ) : (
          <LocationsList>
            {this.state.locations.map(location => {
              return (
                <Location onClick={() => this.handleSelectedLocation(location)}>
                  {location.name}
                </Location>
              );
            })}
          </LocationsList>
        )}
      </LocationsContainer>
    );
  }
}
