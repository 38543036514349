import styled from 'styled-components';

export const LatestReviewsContainer = styled.div`
    width: 100%;
    max-width: 575px;
    box-shadow: 0px 1px 0px rgba(0,0,0,0.15);
    margin: 0 1em;

    @media only screen and (max-width: 770px){
        max-width: 100%;
        margin-top: 2em;
    }

    @media only screen and  (max-width: 600px){
        margin: 2em 0 0;
    }
`;
