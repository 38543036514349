// LIBRARIES
import React from 'react'
import axios from 'axios'

// COMPONENTS
import { Container, ListContainer, ListItem, Label } from '../../../Assets/Structure/Structure'
import RaSnackbar from '../../../Components/RaSnackbar/RaSnackbar'
import RaSwitch from '../../../Components/RaSwitch/RaSwitch';

//CONFIG
import { WEBCHAT_API_URL } from '../../../config';

const NIGHT_NOTIFICATIONS = "night_notifications"

export default class NotificationSettings extends React.Component{

    state = {
        notificationSetting: false,
        showSnackbar: false,
        snackbarMessage: "Loading...",
        notificationSettings: [],
        storeHasSetting: false
    }

    componentDidMount() {
        this.fetchNotificationSettings()

    }

        fetchNotificationSettings = () => {
            const storeId = localStorage.getItem("store")

            axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/notificationSettings`)
            .then(res => {
                const notificationSettings = res.data
                let notificationSetting = false
                let storeHasSetting = false

                if (notificationSettings.length > 0){
                    storeHasSetting = true
                    notificationSetting = notificationSettings[0].settingValue
                }

                this.setState({
                    notificationSettings,
                    notificationSetting,
                    storeHasSetting
                })
            })
        }

        handleChangeNotificationSetting =  (e) => {
            const notificationSettingValue = e.target.checked

            this.setState({ notificationSetting: notificationSettingValue })

            if (!this.state.storeHasSetting){
                this.createNotificationSetting(notificationSettingValue)
            } else {
                this.updateNotificationSetting(notificationSettingValue)
            }
        }


        createNotificationSetting = (notificationSettingValue) => {
            const storeId = localStorage.getItem("store")

            this.setState({
                showSnackbar: true,
                snackbarMessage: "Loading..."
            })

            const payload = {
                settingKey: NIGHT_NOTIFICATIONS,
                settingValue: notificationSettingValue
            }

            axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/notificationSettings`, payload)
            .then(() => {
                setTimeout(() => {
                    this.setState({
                        showSnackbar: true,
                        snackbarMessage: "Notification settings created with success",
                        storeHasSetting: true
                    })
                }, 300)
            })
            .catch(() => {
                this.setState({ snackbarMessage: "Something went wrong." })
            })
        }


        updateNotificationSetting = (notificationSettingValue) => {
            const storeId = localStorage.getItem("store")
            this.setState({
                showSnackbar: true ,
                snackbarMessage: "Loading..."
            })

            const payload = {
                settingKey: NIGHT_NOTIFICATIONS,
                settingValue: notificationSettingValue
            }

            axios.put(`${WEBCHAT_API_URL}/stores/${storeId}/notificationSettings`, payload)
            .then(() => {
                setTimeout(() => {
                    this.setState({
                        showSnackbar: true,
                        snackbarMessage: "Notification settings updated with success"
                    })
                }, 300)

            })
            .catch((error) => {
                this.setState({ snackbarMessage: "Something went wrong." })
            })
        }

        handleCloseSnackbar = () => {
           this.setState({ showSnackbar: false })
        }

        render(){


            return (
                <>
            <ListContainer>
                <ListItem style={{ borderRadius: "8px" }}>
                    <Container flex alignCenter>
                        <Label>Turn off notifications during the night (06:00 pm - 08:00 am)</Label>
                        <Container ml="2em">
                            <RaSwitch
                            checked={this.state.notificationSetting}
                            onChange={this.handleChangeNotificationSetting}
                            name="notificationSetting"
                            color="primary" />
                        </Container>
                    </Container>
                </ListItem>
            </ListContainer>
            <RaSnackbar
            open={this.state.showSnackbar}
            message={this.state.snackbarMessage}
            onClose={this.handleCloseSnackbar} />
        </>
    )
}
}
