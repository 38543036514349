import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class HowDoIChangeMyPassword extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    Click on <strong style={{ color: blueDefault }}>Edit Profile</strong> in the upper left corner to open your profile page 
                    and click on <strong style={{ color: blueDefault }}>Change Password</strong>. 
                    Enter your current password , insert a new password, repeat the new password and click on ‘Save’.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}