import React from 'react';
import { OptionsContainer, OptionsList, Option } from './styled';

export default class Options extends React.Component{

    state = {
        upContainer: false
    }

    componentDidMount() {
        const locationsList = document.getElementById("role-list");
        this.getElementPosition(locationsList);
      }

      getElementPosition = element => {
        let xPosition = 0;
        let yPosition = 0;
        const pageHeight = window.innerHeight;
    
        while (element) {
          xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
          yPosition += element.offsetTop - element.scrollTop + element.clientTop;
          element = element.offsetParent;
        }
    
        const diff = pageHeight - yPosition;
    
        if (diff < 250) {
          this.setState({ upContainer: true });
        } else {
          this.setState({ upContainer: false });
        }
    
        return { x: xPosition, y: yPosition };
      };

      handleClick = (option) => {
          this.props.selectedOption(option)
      }
      

    render(){

        const options = [{ label: "Manager", value: "manager" }, { label: "Seller", value: "seller" }];

        return(
            <OptionsContainer id="role-list" upContainer={this.state.upContainer}>
                <OptionsList>
                    {options.map(option => {
                        return <Option onClick={() => this.handleClick(option)}>{option.label}</Option>
                    })}
                </OptionsList>
            </OptionsContainer>
        )
    }
}