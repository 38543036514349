import React from "react";
import Skeleton from "react-skeleton-loader";

export default class ConvertionRateChartSkeleton extends React.Component {
  render() {
    return (
      <div
        style={{
          padding: "2em",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridRowGap: "2em"
        }}
      >
        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>

        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>

        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>
      </div>
    );
  }
}
