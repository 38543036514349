import React from "react";
import { HOME_CHAT_S3_URL } from '../../config'
import { Code } from "./styled";
import Module from "../../Components/Module/Module";

export default class ChatWidgetGenerator extends React.Component {
  state = {
    copied: false,
    token: localStorage.getItem("store_key")
  };

  handleClick = () => {
    const el = document.createElement("textarea");

      el.value = `
          <script>
          (function() {
    
            var head = document.querySelector('head');
    
            var cdnGoogleFonts = document.createElement("link");
            cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
            cdnGoogleFonts.setAttribute("type", "text/css"),
            cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
    
            head.appendChild(cdnGoogleFonts);
    
            var e = document.createElement("div");
            e.setAttribute("id", "review-alert-webchat-widget-entrypoint"), e.setAttribute("data-review-alert-webchat-widget-store-key", "${this.state.token}"), document.body.appendChild(e);
            var t = document.createElement("script");
            t.type = "text/javascript", t.src = "${HOME_CHAT_S3_URL}", document.getElementsByTagName("head")[0].appendChild(t)
         })()
         </script>`;

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    this.setState({ copied: true }, () => {
      this.handleReset();
    });
  };

  handleReset = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  render() {

    const { widgetType, widget} = this.props;

    return (
      <React.Fragment>
        <Code onClick={() => this.handleClick(widgetType)}>
          <Module className="copy-code-module" >
            <div className="widget-info-container">
              <label>{widget}</label>
              <a>
                {this.state.copied ? "Copied" : "Copy Widget Code"}
              </a>
            </div>
          </Module>
        </Code>
      </React.Fragment>
    );
  }
}