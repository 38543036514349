import React from "react";
import { BeaconPreviewContainer } from "./styled";
import PreviewImage from "./PreviewImage/PreviewImage";
import { Overlay } from "./styled";
import homeBeacon from '../../Assets/images/home-beacon.png';
import homeHorizontal from '../../Assets/images/home-horizontal.png';
import productCatalogHorizontal from '../../Assets/images/product-catalog-horizontal.png';
import productCatalogBanner from '../../Assets/images/product-catalog-banner.png';

export default class BeaconPreview extends React.Component {
  render() {
    const { currentPreview, device } = this.props;

    return (
      <React.Fragment>
        {currentPreview !== "" && device !== "desktop" && <Overlay />}
        <BeaconPreviewContainer currentPreview={currentPreview}>
          <div className="image-container">
            <PreviewImage
              style={
                currentPreview === "home-beacon"
                  ? { opacity: "1" }
                  : { opacity: "0" }
              }
              imagePath={homeBeacon}
            />

            <PreviewImage
              style={
                currentPreview === "home-horizontal"
                  ? { opacity: "1" }
                  : { opacity: "0" }
              }
              imagePath={homeHorizontal}
            />

            <PreviewImage
              style={
                currentPreview === "product-catalog-horizontal"
                  ? { opacity: "1" }
                  : { opacity: "0" }
              }
              imagePath={productCatalogHorizontal}
            />

            <PreviewImage
              style={
                currentPreview === "product-catalog-banner"
                  ? { opacity: "1" }
                  : { opacity: "0" }
              }
              imagePath={productCatalogBanner}
            />
          </div>
        </BeaconPreviewContainer>
      </React.Fragment>
    );
  }
}