import styled from "styled-components";

export const SelectOptionsContainer = styled.div`
  position: ${props => props.absolute ? "absolute" : "relative"};
  width: 100%;
  background-color: white;
  max-width: ${props => props.absolute ? props.maxWidth : "100%"};
  z-index: 1600;
  border: ${props => (props.shadow && "1px solid #E6E6E6")};
  margin-top: 1em;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.15);
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;

  ul {
    margin: 1em 0 0 0;
    list-style: none; 
    padding: 0;
    overflow: auto;
    max-height: 8em;

    &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    li {
      margin: 0.5em;
      padding: 0.5em 1em;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.1em;
      color: #333333;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }

  @media only screen and (max-width: 500px){
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 4em);
    left: 50%;

    ul{
      max-height: 50vh;
    }
  }
`;

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 8em;
  margin-top: 1em;

  svg{
    width: 30px;
    height: 30px;

    path{
      fill: #ccc;
    }
  }

  p{
    color: #ccc;
    letter-spacing: .1em;
    font-size: 14px;
    margin-top: .5em;
  }
`;

export const Overlay = styled.div`
    top: 0;
    z-index: 1500;
    left:0;
    width: 100%;
    position: fixed;
    background-color: #00000090;
    height: 100vh;
    display: none;

    @media only screen and (max-width: 600px){
      display: block;
    }
`;