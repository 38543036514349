import styled from 'styled-components';

export const LatestStatsContainer = styled.div`
 width: 100%;
 max-width: 369px;
 margin: 0 1em;

 @media only screen and (max-width: 770px){
     max-width: 100%;
 }

 @media only screen and  (max-width: 600px){
        margin: 0;
    }
`;