import styled from "styled-components";

export const TotalAverageRateContainer = styled.div`
display: flex;
  label {
    display: flex;

    span {
      display: flex;
      align-items: center;
      margin-left: 0.5em;
    }
  }

  p {
    line-height: 23px;
    font-size: 12px;
    margin: 0 0 0 .5em;
    color: #666666;
  }
`;
