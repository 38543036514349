import styled from 'styled-components';

export const RatingContainer = styled.div`
    display: flex !important;
    align-items: center !important;

    h4{
        margin-right: .5em !important;
        font-size: 20px !important;
        margin: 0 .5em 0 0 !important;
        font-weight: 400 !important;
    }

    svg{
        width: ${props => (props.smallStars ? '10px' : '16px')} !important;
        margin-right: ${props => props.smallStars ? ".3em" : ".5em"}!important;

        polygon{
            fill: ${props => props.whiteStar ? "white !important" : null};
        }

        &:last-child{
            margin-right: 0 !important;
        }
    }
`;

export const RatingGrade = styled.h4`
    color: ${props => props.textColor ? props.textColor : "#333"};
`;