import styled from 'styled-components';

export const BarContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const BarPool = styled.div`
    width: 100%;
    height: .25em;
    background-color: #EEEEEE;
`;

export const BarFilling = styled.div`
    width: ${props => props.percentage > 0 ? props.percentage : 0}%;
    height: .25em;
    background-color: ${props => props.bgColor};
    transition: width 1s ease;
`;