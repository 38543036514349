// Libraries
import styled from "styled-components"


export const VideoRoomMessageChatContainer = styled.div`
  width: 375px;
  height: 100vh;
  position: fixed;
  right: 0;
  background-color: white;
  top: 0;
`
export const VideoRoomMessageHeaderContainer = styled.div`
  height: 70px;
  background-color: #F8F8F8;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.07);
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
`


export const VideoRoomMessageBodyContainer = styled.div`
  height: calc(100vh - 70px - 70px);
`

export const VideoRoomMessageFormContainer = styled.form`
  height: 70px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  
  input {
    border: none;
    height: 70px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f8f8;
  }
  
  button {
    margin-right: 20px;
  }
`
