import styled from 'styled-components';

export const OrderByOptionsContainer = styled.div`
    background-color: white;
    width: 100%;
    max-width: 14em;
    height: auto;
    box-sizing: border-box;
    z-index: 150;
    margin-left: -6.5em;
    margin-top: 1em;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: absolute;
`;