// Libraries
import React from "react"

// Components
import StyledTabs from "../../../Components/StyledTabs/StyledTabs"

// Styles
import { WebchatWidgetsContainer } from "./styled"
import ChatWidgetGenerator from "../ChatWidgetGenerator";
import {Container, Label} from "../../../Assets/Structure/Structure";
import ChatWidgetRepairGenerator from "../ChatWidgetRepairGenerator";


function WebchatWidgets() {
    const widgets = [
        {
            name: "Webchat Widget",
            content: (
                <Container>
                    <Label>Get the webchat widget for your website here:</Label>
                    <Container flex mt="10px">
                        <ChatWidgetGenerator />
                    </Container>
                </Container>
            )
        },
        {
            name: "Webchat Repair",
            content: (
                <Container>
                    <Label>Get the webchat repair widget for your website here:</Label>
                    <Container flex mt="10px">
                        <ChatWidgetRepairGenerator />
                    </Container>
                </Container>
            )
        }
    ]

    return (
        <WebchatWidgetsContainer>
            <StyledTabs tabs={widgets} />
        </WebchatWidgetsContainer>
    )
}

export default WebchatWidgets
