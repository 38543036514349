import React from "react";
import { AmountSentToday, SentTodayContainer } from "./styled";
import { Row, Label } from "../../../../Assets/Structure/Structure";

export default class SentToday extends React.Component {
  render() {
    return (
      <SentTodayContainer>
        <Row alignCenter between>
          <Label color="#FFA800" fontSize="18px">
            Sent today
          </Label>
          <AmountSentToday>{this.props.amount}</AmountSentToday>
        </Row>
      </SentTodayContainer>
    );
  }
}
