import React from "react";
import ReactSVG from "react-svg";
import { API_URL } from "../../../config";
import SelectFluid from "../../../Components/SelectFluid/SelectFluid";
import { SelectLocationContainer, RowContainer } from "./styled";
import axios from "axios";
import locationIcon from "../../../Assets/icons/location.svg";
import Skeleton from "react-skeleton-loader";

export default class SelectLocation extends React.Component {
  state = {
    locations: [],
    loading: true
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");

    axios
      .get(`${API_URL}/admin/stores/${store}/locations`, {
        headers: {
          "x-sas-Auth": authToken
        }
      })
      .then(res => {
        this.setState({ locations: res.data, loading: false });
      });
  }

  handleSelectedLocation = selectedLocation => this.props.selectedLocation(selectedLocation);
  

  render() {
    let locations = this.state.locations;
    locations = locations.map(location => {
      return { label: location.name, value: location.id, id: location.id };
    });

    return (
      <SelectLocationContainer>
        {this.state.loading ? (
          <Skeleton />
        ) : (
          <React.Fragment>
            <div className="location-icon">
              <ReactSVG src={locationIcon} />
            </div>

            <RowContainer>
              <SelectFluid
                options={locations}
                placeholder={this.props.initialLocation.name}
                absolute
                loading={this.state.loading}
                maxWidth="15em"
                invertOrder
                noBorder
                selectedOption={this.handleSelectedLocation}
              />
            </RowContainer>
          </React.Fragment>
        )}
      </SelectLocationContainer>
    );
  }
}
