import React from "react";
import ReactSVG from 'react-svg';
import { SideFilterContainer, Overlay } from "./styled";
import Filter from "./Filter/Filter";
import filterIcon from '../../Assets/icons/filter.svg';

export default class SideFilter extends React.Component {
  state = {
    showSideFilter: false
  };

  handleSideFilter = showSideFilter => {
    this.setState({ showSideFilter });
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  handleSelectedOption = (option, type) => {
    this.props.selectedOption(option, type);
  };

  handleResetFilter = (resetFilter) => {
    this.props.handleResetFilter(resetFilter);
  }

  handleSelectedReviewType = reviewType => {
    this.props.selectedReviewType(reviewType, "review-type");
  };

  handleSelectedLocation = location => {
    this.props.selectedLocation(location, "location");
  };

  handleSelectedPlatform = platform => {
    this.props.selectedPlatform(platform, "platform");
  };

  handleNoReply = (selectedOption, type) => {
    this.props.noReply(selectedOption, type)
  }

  handleSelectedSentBy = (selectedOption, type) => {
    this.props.selectedSentBy(selectedOption, type);
  }

  handleSelectedDateInterval = interval => {
    this.props.selectedInterval(interval, "date");
  }

  handleSelectedProfile = (selectedProfile, type) => {
    this.props.selectedProfile(selectedProfile, type)
  }

  render() {
    return (
      <React.Fragment>
        <Overlay
          onClick={() => this.handleSideFilter(false)}
          showSideFilter={this.state.showSideFilter}
        />
        <Filter
          showSideFilter={this.state.showSideFilter}
          closeSideFilter={this.handleSideFilter}
          locations={this.props.locations}
          platforms={this.props.platforms}
          employees={this.props.employees}
          roles={this.props.roles}
          page={this.props.page}
          noReply={this.handleNoReply}
          handleResetFilter={this.handleResetFilter}
          resetFilter={this.props.resetFilter}
          handleSideFilter={this.handleSideFilter}
          selectedReviewType={this.handleSelectedReviewType}
          selectedLocation={this.handleSelectedLocation}
          selectedPlatform={this.handleSelectedPlatform}
          selectedSentBy={this.handleSelectedSentBy}
          selectedInterval={this.handleSelectedDateInterval}
          selectedProfile={this.handleSelectedProfile}
        />
        <SideFilterContainer id="mob-side-filter" onClick={() => this.handleSideFilter(true)}>
          <ReactSVG src={filterIcon} />
          <label>Filters</label>
        </SideFilterContainer>
      </React.Fragment>
    );
  }
}
