import styled from 'styled-components';


export const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 80px 50px;

  @media only screen and (max-width: 1300px){
    padding: 0 20px;
  }

  @media only screen and (max-width: 1200px){
    padding: 0 10px;
  }

  @media only screen and (max-width: 1150){
    padding: 0;
  }

  @media only screen and (max-width: 500px){
    padding: 0;
  }
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const TopActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 500px){
    flex-direction: column;

    .btn-add-member{
      padding: 0;
      width: 50px;
      height: 50px;

      svg{
        transform: translate(.4em);
      }
    }

    .add-member-btn{
      width: 100%;
      order: 2;
      margin-top: 2em;
      max-width: calc(100% - 2em);
    }

    .team-filter{
      order: 1;
      padding: 0 1em;
      display: flex;
      box-sizing: border-box;
      justify-content: flex-end;
    }
  }
`;


export const ResetContainer = styled.div`
display: flex;
  width: 100%;
  justify-content: flex-end;

  @media only screen and (max-width: 500px){
    display: none;
  }
`;