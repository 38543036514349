import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from 'react-svg';
import {
  ReviewContainer,
  Row,
  Col,
  ClientLocation,
  ClientName,
  Date,
  ReviewText,
  SmileContainer
} from "./styled";
import Rating from "../../OvervieByPlatform/Platform/Rating/Rating";
import positive from '../../../Assets/icons/positive.svg';
import neutral from '../../../Assets/icons/neutral.svg';
import negative from '../../../Assets/icons/negative.svg';
import locationIcon from '../../../Assets/icons/location.svg';
import google from '../../../Assets/icons/google.svg';
import facebook from '../../../Assets/icons/facebook.svg';

export default class Review extends React.Component {
  render() {
    const { rating, location, clientName, text, date, platform } = this.props;
    let smile;

    if (rating > 3) smile = positive;
    if (rating === 3) smile = neutral;
    if (rating < 3) smile = negative;

    return (

      <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
      <ReviewContainer>
        <Row>
          <Col>
            <SmileContainer><ReactSVG src={smile} /></SmileContainer>
          </Col>
          <Col style={{ marginLeft: "1em" }}>
            <ClientName>{clientName}</ClientName>
            <ClientLocation>
              <ReactSVG src={locationIcon} />
              <label>{location}</label>
            </ClientLocation>
          </Col>
          <Col className="end">
            <Rating
              totalOfStars={[1, 2, 3, 4, 5]}
              rating={rating}
              showReviewGrade={false}
              smallStars
            />
            <Date>{date}</Date>
          </Col>
        </Row>
        <Row>
            <ReviewText>{text}</ReviewText>
        </Row>
        <Row className="flex-end">
            {platform === "Google" ? <ReactSVG src={google} />
            : platform === "Facebook" ? <ReactSVG src={facebook} />
            : null}
            
        </Row>
      </ReviewContainer>
      </CSSTransitionGroup>
    );
  }
}
