import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import CircleGraphic from "./CircleGraphic/CircleGraphic";
import {
  PlatformContainer,
  TopContainer,
  BottomContainer,
  LeftContainer,
  RightContainer
} from "./styled";
import Rating from "./Rating/Rating";
import RateRankingGraphic from "./RateRankingGraphic/RateRankingGraphic";
import OverviewByPlatformSkeleton from "../OverviewByPlatformSkeleton";

export default class Platform extends React.Component {

  render() {

    const { locationOverview, platform, loading, totalReviews, rating } = this.props;

    let share, data, starsRanking;
    if (locationOverview[0]){
      share = locationOverview[0].share
      data = [
        { name: platform, value: parseFloat(share) },
        { name: "", value: 100 - share }
      ]

      starsRanking = [
        locationOverview[0].star_1,
        locationOverview[0].star_2,
        locationOverview[0].star_3,
        locationOverview[0].star_4,
        locationOverview[0].star_5,
      ]


    } else {
      share = 0;
      data = [
        { name: "", value: 0 },
        { name: "", value: 0 }
      ];
      starsRanking = [];
    }

    return (
      <PlatformContainer>
        {loading ? (
          <OverviewByPlatformSkeleton />
        ) : (
          <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
          <React.Fragment>
            <TopContainer>
              <LeftContainer>
                <CircleGraphic
                  percentageOfReviewsByPlatform={share}
                  data={data}
                  platform={platform}
                  color={
                    platform === "Google"
                      ? ["#FE5943", "#eee"]
                      : this.props.platform === "Facebook"
                      ? ["#3B5998", "#eee"]
                      : null
                  }
                />
              </LeftContainer>
              <RightContainer>
                <Rating
                  totalOfStars={[1, 2, 3, 4, 5]}
                  rating={rating.toFixed(1) || 0.0}
                  showReviewGrade={true}
                />
                <RateRankingGraphic
                  totalReviews={totalReviews}
                  starsRanking={starsRanking}
                  reviews={this.props.reviews}
                  platform={this.props.platform}
                />
              </RightContainer>
            </TopContainer>
            <BottomContainer />
          </React.Fragment>
          </CSSTransitionGroup>
        )}
      </PlatformContainer>
    );
  }
}
