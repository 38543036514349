import React from "react";
import { Link } from "react-router-dom";
import { EditProfileButton } from "./styled";

export default class EditProfile extends React.Component {

  render() {

    const { userRole } = this.props;

    return (
      <React.Fragment>
        {userRole === "seller" ? (
          <a onClick={this.props.onClick}>
            <EditProfileButton>Edit profile</EditProfileButton>
          </a>
        ) : (
          <Link to="/edit-profile" style={{ textDecoration: "none" }} onClick={this.handleEditProfile}>
            <EditProfileButton>Edit profile</EditProfileButton>
          </Link>
        )}        
      </React.Fragment>
    );
  }
}
