import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 1em;
  box-sizing: border-box;

  .left-container {
    display: flex;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: auto;
        height: auto;
      }
    }
    .info-container {
      margin-left: 1em;
      
      h2 {
        margin: 0;
        font-weight: normal;
        font-size: 18px;
        letter-spacing: 0.1em;
        color: #333333;
      }

      p {
        margin: 0;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        color: #999999;
      }
    }
  }

  .right-container {
    display: flex;
    flex-direction: column;

    svg {
      cursor: pointer;
    }
  }
`;
