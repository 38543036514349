import React from 'react';
import { Redirect } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { LogoutContainer } from './styled';
import logoutIcon from '../../../../Assets/icons/logout.svg';
import axios from 'axios';
import { API_URL } from '../../../../config';


export default class Logout extends React.Component{

    handleLogout = () => {
        const authToken = localStorage.getItem("token");
        axios.delete(`${API_URL}/private/sessions`, {
            headers: {
                "x-sas-auth" : authToken
            }
        }).then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user_store");
            localStorage.removeItem("user_email");
            localStorage.removeItem("widget_only");
            localStorage.removeItem("store");
            localStorage.removeItem("role");
            localStorage.removeItem("is_admin");
            localStorage.removeItem("logged_in");

            this.props.loggedIn(false)
           
        })
    }

    render(){
        const userLoggedIn = localStorage.getItem("logged_in");

        if (!userLoggedIn) return <Redirect to="/login" />

        return(
            <LogoutContainer>
                <ReactSVG src={logoutIcon} onClick={this.handleLogout} />
            </LogoutContainer>
        )
    }
}