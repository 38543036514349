import React from 'react';
import { WidgetMobileButtonContainer } from './styled';

export default class WidgetMobileButton extends React.Component{

    state = {
        sideMenuOpen: false
    }

    handleOnClick = () => {
        this.setState({ sideMenuOpen: true })
        this.props.sideMenuOpen(true);
    }

    render(){

        const { onClick, sideMenuOpen } = this.props;

        return(
            <WidgetMobileButtonContainer onClick={onClick} sideMenuOpen={sideMenuOpen} >
                Embed Code
            </WidgetMobileButtonContainer>
        )
    }
}