import styled from "styled-components"

export const ParticipantContainer = styled.div`
  width: 100%;
  overflow: hidden;
  
  video {
    height: ${props => !props.isLocalParticipant ? "100vh" : null };
    transform: rotateY(180deg) scale(1.2);
    
    width: ${props => !props.isLocalParticipant ? "auto" : null };
      height: ${props => !props.isLocalParticipant ? "auto" : null };
    
      /* Center the video */
      position: ${props => !props.isLocalParticipant ? "fixed" : null };
      top: ${props => !props.isLocalParticipant ? "50%" : null };
      left: ${props => !props.isLocalParticipant ? "50%" : null };
      transform: ${props => !props.isLocalParticipant ? "translate(-50%,-50%) rotateY(180deg)" : null };
      object-fit: cover;
  }
    
  @media only screen and (max-width: 600px) {
    
     position: ${props => !props.isLocalParticipant ? "fixed" : null};
      top: ${props => !props.isLocalParticipant ? 0 : null};
      bottom: ${props => !props.isLocalParticipant ? 0 : null};
      width: ${props => !props.isLocalParticipant ? "100%" : null};
      height:${props => !props.isLocalParticipant ? "100%" : null};
      overflow: ${props => !props.isLocalParticipant ? "hidden" : null};
   
    
     video {
        min-width: ${props => !props.isLocalParticipant ? "100%" : null};
        min-height: ${props => !props.isLocalParticipant ? "100%" : null};
        width: ${props => props.isLocalParticipant ? "100px !important" : null};
        transform: ${props => !props.isLocalParticipant ? "translate(-50%,-50%) rotateY(180deg)" : null };
     }
  }
 
`

