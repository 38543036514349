import styled from 'styled-components';
import { blueDefault, blueDefaultHover } from '../../../../Assets/colorPallet/colorPallet';

export const NewMemberFormContainer = styled.form`
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 5px;
    padding: 2em;
    margin: 1em;
    box-sizing: border-box;
`;

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;

    &:not(:last-child){
        margin-bottom: 1em;
    }

    label{
        font-weight: normal;
        font-size: 14px;
        color: #333333;
    }

    .form-input{
        border: none;
        background-color: transparent;
        border-bottom: 1px solid #B5B5B5;
        padding: 1em 0;
        color: #333;
        font-size: 20px;
        outline: none;

        &::placeholder{
            color: #D0D0D0;
        }
    }

    .radio-button-new-member{
        margin-top: 1em;
    }
`;


export const FooterContainer = styled.div`
    width: 100%;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #333;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: auto auto 0 auto;
    
    button{
        width: 300px;
        border: none;
        background-color: ${blueDefault};
        transition: background-color .4s ease;
        text-transform: uppercase;
        letter-spacing: .2em;
        cursor: pointer;
        font-weight: 500;
        line-height: 48px;
        border-radius: 20em;
        color: white;
        outline: none;
        font-size: 14px;

        &:hover{
            background-color: ${blueDefaultHover};
        }
    }
`;

export const BodyContainer = styled.div`
    max-height: 70vh;
    overflow-y: auto;
`;