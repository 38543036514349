import { SET_MARKETING_CAMPAIGNS } from "./mutations";

const initialState = {
    marketingCampaigns: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MARKETING_CAMPAIGNS:
            return { ...state, marketingCampaigns: action.payload }

        default:
            return state
    }
}

export default reducer
