import styled from "styled-components";
import { red } from '../../Assets/colorPallet/colorPallet';

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  align-items: center;
  flex-direction: column;

  .login-module {
    width: 100%;
    max-width: 40em;
    display: flex;
    flex-direction: column;
    padding: 2.5em;
    margin-top: 2em;
  }

  .login-action-container{
    align-items: center;
    margin-top: 2em;
  }

  @media only screen and (max-width: 600px){
    box-sizing: border-box;
    padding: 0 2em;

    .login-action-container{
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: #333333;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 20px;
  outline: none;
  color: #333333;

  &::placeholder {
    color: #d0d0d0;
  }
`;

export const Anchor = styled.a`
  margin-left: ${props => (props.ml1 ? "1em" : null)};
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.color};

  @media only screen and (max-width: 600px){
    margin-top: 1em;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 40em;
`;

export const StoresLabel = styled.label`
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #333333;
`;


export const FeedbackText = styled.p`
  color: ${props => props.color};
  letter-spacing: .05em;
  font-size: 14px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 20.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
