import React from 'react'
import Skeleton from "react-skeleton-loader";
import { Container } from '../../../Assets/Structure/Structure'

export default class ChatInboxSkeleton extends React.Component{
    render(){
        return (
            <Container>
                <Container>
                    <Skeleton width="70%" />
                </Container>
                <Container mt=".25em">
                    <Skeleton width="100%" />
                </Container>
                <Container mt=".5em">
                    <Skeleton width="40%" />
                </Container>
            </Container>
        )
    }
}