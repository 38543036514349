import styled from "styled-components"


export const SMSMarketingContainer = styled.div`
  .MuiButton-root{
    text-transform: unset !important;
  }
  
  .create-marketing-campaign--button {
    width: 200px;
    height: 40px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
    &__icon{
        div:nth-child(1){
          display: flex;
        }
    }
  }
  
`

