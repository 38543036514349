import styled from "styled-components"

export const AddClientContainer = styled.form`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: baseline;
    
    .add-client--phone{
      margin: 20px 0 0 !important;
    }
  }
`

