export default class SMSMarketingCampaign {
    constructor({ name, locationId, locationName, storeId, createdAt, targets, message, staffName, storeName }) {
        this.name = name
        this.locationId = locationId
        this.locationName = locationName
        this.storeId = storeId
        this.createdAt = createdAt
        this.targets = targets
        this.message = message
        this.staffName = staffName
        this.storeName = storeName
    }

    get payload () {
        const payload = {}

        if (this.name) payload.name = this.name
        if (this.locationName) payload.locationName = this.locationName
        if (this.message) payload.message = this.message
        if (this.targets) payload.targets = this.targets
        if (this.staffName) payload.staffName = this.staffName
        if (this.storeName) payload.storeName = this.storeName

        return payload
    }
}
