import React from 'react'
import Skeleton from "react-skeleton-loader"
import {Container} from "../../../../Assets/Structure/Structure";

export default function StoresConversationRankingSkeleton() {
    return (
        <Container fluid>
           <Container fluid>
               <Skeleton width="100%" height="40px"/>
           </Container>
            <Container>
                <Skeleton width="100%" height="40px"/>
            </Container>
        </Container>
    )
}
