import styled from "styled-components"

export const StoreConversationItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #888;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
`

