import React, {useState} from "react"
import { AddClientContainer } from "./styled";
import {Input} from "@material-ui/core";
import {Container, Label} from "../../../../Assets/Structure/Structure";


const CLIENT_NAME = "clientName"
const CLIENT_NUMBER = "clientNumber"

export default function AddClient(props){
    const [clientName, setClientName] = useState("")
    const [clientNumber, setClientNumber] = useState("")

    const handleChange = (key, value) => {
        switch(key) {
            case CLIENT_NAME:
                setClientName(value)
                props.onChange("clientName", value)
                break
            case CLIENT_NUMBER:
                props.onChange("clientNumber", value)
                setClientNumber(value)
                break
            default:
                break
        }
    }

    const handleAddClient = (e) => {
        e.preventDefault()
        if (clientNumber === "" || clientName === "") {
            return
        }

        const client = { clientNumber, clientName }
        props.onChange(client)
        setClientNumber("")
        setClientName("")
    }


    return (
        <AddClientContainer onSubmit={handleAddClient}>
            <Container>
                <Input
                    placeholder="Client name"
                    value={clientName}
                    onChange={(e) => handleChange(CLIENT_NAME, e.target.value)} />
            </Container>
            <Container className="add-client--phone" flex alignCenter ml="20px" mr="30px">
                <Label>(+1)</Label>
                <Container ml="10px">
                <Input
                    type="number"
                    placeholder="Client phone number"
                    value={clientNumber}
                    onChange={(e) => handleChange(CLIENT_NUMBER, e.target.value)} />
                </Container>
            </Container>
        </AddClientContainer>
    )
}
