import styled from "styled-components";

export const MenuOptionsList = styled.ul`
  list-style: none;
  margin: 1em 0 0 0;
  padding: 0;
  width: 100%;

  a{
      text-decoration: none;
  }
`;

export const MenuOption = styled.li`
  width: 100%;
  padding: 10px 25px;
  box-sizing: border-box;
  border-left: 5px solid transparent;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #333333;

  div{
      width: 100%;
      max-width: 2em;
  }

  span{
      margin-left: 1em;
  }

  &:hover {
    border-left: 5px solid #025f88;
    background-color: #F8F8F8;
  }
`;
