import React from "react";
import { BestRatedLocationsContainer, Label } from "./styled";
import LocationRateOverview from "./LocationRateOverview/LocationRateOverview";
import BestRatedLocationSkeleton from "./BestRatedLocationSkeleton";

export default class BestRatedLocations extends React.Component {
  render() {

    const { bestLocations } = this.props;

    return (
      <div style={this.props.style}>
        <Label>Best rated locations</Label>
        <BestRatedLocationsContainer>
          {this.props.loading ? (
            <React.Fragment>
              <BestRatedLocationSkeleton  />
              <BestRatedLocationSkeleton />
              <BestRatedLocationSkeleton />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {bestLocations.map(bestLocation => {
                return <LocationRateOverview bestLocation={bestLocation} loading={this.props.loading} />
              })}
            </React.Fragment>
          )}
        </BestRatedLocationsContainer>
      </div>
    );
  }
}
