import styled from 'styled-components';

export const ChatMessageContainer = styled.div`
    background-color: ${props => 
        props.author !== "store" ? "#eee" 
        :  "rgba(0, 177, 255, 0.2)"};
    width: 100%;
    width: max-content;
    max-width: 80%;
    margin: ${props => 
    props.author !== "store" ? "1em auto auto 0"
    : "1em 0 auto auto"};
    padding: 1em;
    border-radius: 5px;
    box-sizing: border-box;
`;

export const OpenImage = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    background-color: #33333380;

    .open-image-icon{
        div:nth-child(1){
            &:first-child{
                display: flex;
            }
        }
    }
`;  