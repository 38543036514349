import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import PieChartSimple from "../PieChartSimple/PieChartSimple";
import {
  ConvertionRateChartContainer,
  Row,
  TopContainer,
  Label,
  ConvertionRatePercentage,
  BottomContainer,
  InfoContainer,
  Column,
  Amount,
  ReviewsSentLabel,
  TextLabel,
  ConvertionOverview
} from "./styled";
import ConvertionRateChartSkeleton from "./ConvertionRateChartSkeleton";


export default class ConvertionRate extends React.Component {

  render() {

    const { loading, ctr, totalRequestsSent, requestsForReview, noPerformance, totalReviews } = this.props

    return (
      <ConvertionRateChartContainer loading={loading}>
        {loading ? (
          <ConvertionRateChartSkeleton />
        ) : (
          <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
            <TopContainer>
              <Row between alignCenter>
                <Label>Conversion rate</Label>
                <ConvertionRatePercentage>{ctr || 0}%</ConvertionRatePercentage>
              </Row>
            </TopContainer>
            <BottomContainer noPerformance={noPerformance}>
              <Row style={{ flexFlow: "row nowrap" }}>
                <PieChartSimple />
                <InfoContainer>
                <div>
                  <p style={{
                    color: "#333",
                    fontSize: "12px",
                    letterSpacing: ".05em",
                    fontStyle: "italic"
                  }}
                  >Requests sent and reviews generated since joining Review Alert</p>
                </div>
                <Row> 
                  <Column>
                    <ReviewsSentLabel>Sent</ReviewsSentLabel>
                    <Amount reviewsSent>{totalRequestsSent || 0}</Amount>
                  </Column>
                  <Column style={{ marginLeft: "1em" }}>
                    <ReviewsSentLabel>Reviews</ReviewsSentLabel>
                    <Amount totalReviews>{totalReviews || 0}</Amount>
                  </Column>
                  </Row>
                </InfoContainer>
              </Row>
              {!noPerformance && (
              <Row style={{ padding: "0 2em 2em", boxSizing: "border-box" }}>
                <TextLabel>Performance</TextLabel>
                <ConvertionOverview>
                  <p>For every {requestsForReview || 0} sent, 1 is converted into a Review</p>
                </ConvertionOverview>
              </Row>
              )}
            </BottomContainer>
          </CSSTransitionGroup>
        )}
      </ConvertionRateChartContainer>
    );
  }
}
