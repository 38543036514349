import React from 'react';
import { Label } from '../../../../Assets/Structure/Structure';
import { FeedbackContainer, StoreName, StoreAddress } from './styled';
import Module from '../../../../Components/Module/Module';

export default class NewLocationFeedback extends React.Component{
    render(){

        const { newLocation } = this.props;
        return (
            <FeedbackContainer>
                <h2>Location successfully added</h2>
                <Module>
                    <StoreName>{newLocation.name || "Felipe Store"}</StoreName>
                    <StoreAddress>{newLocation.address || "Rio de Janeiro, Santa trerez"}</StoreAddress>
                </Module>
            </FeedbackContainer>
        )
    }
}