import styled from 'styled-components';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';

export const MultipleInvitationsContainer = styled.div`
    margin-top: 1em;

    .edit-btn, .delete-btn{
        cursor: pointer;

        svg{
            width: 16px;
            height: 17px;

            path{
                fill: #dadada;
                transition: fill 0.4s ease;
            }

            &:hover{
                path{
                    fill: ${blueDefault};
                }
            }
        }
    }

    .edit-btn{
        margin-right: 1em;
    }

    .invitation-module:not(:first-child){
        margin-top: .5em;
    }
`;

