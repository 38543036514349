import React from "react";
import ReactSVG from "react-svg";
import { HeaderContainer, TxtarLogo } from "./styled";
import txtarLogo from "../../Assets/images/txtar.png";
import Button from "../Button/Button";
import {
  blueDefault,
  blueDefaultHover
} from "../../Assets/colorPallet/colorPallet";
import ReviewInvitation from "./ReviewInvitation/ReviewInvitation";
import sendIcon from "../../Assets/icons/send.svg";
import MobileMenu from "../MobileMenu/MobileMenu";

export default class Header extends React.Component {
  state = {
    isModalOpen: false
  };

  handleReviewInvitationModal = isModalOpen => {
    this.setState({ isModalOpen });
  };

  handleUpdateReviewRequests = (reviewRequests, totalReviewRequests) => {
    this.props.updateReviewRequests(reviewRequests, totalReviewRequests);
  }

  handleLogout = (loggedIn) => {
    this.props.loggedIn(loggedIn)
  }

  render() {

    const chatOnly = !!+localStorage.getItem('chat_only')

    return (
      <React.Fragment>
        {this.state.isModalOpen && (
          <ReviewInvitation isModalOpen={this.handleReviewInvitationModal} updateReviewRequests={this.handleUpdateReviewRequests} />
        )}
        <HeaderContainer>
          <MobileMenu loggedIn={this.handleLogout} />
          <TxtarLogo src={txtarLogo} />
          {!chatOnly && <Button
            text="Send review request"
            borderRadius="20em"
            hasIcon
            iconPath={sendIcon}
            height="50px"
            flex
            hideTextOnMobile
            padding="0"
            mr="1em"
            width="349px"
            bgColor={blueDefault}
            bgColorOnHover={blueDefaultHover}
            color="white"
            uppercase
            device={this.state.device}
            kind="header"
            onClick={() => this.handleReviewInvitationModal(true)}
          />}
        </HeaderContainer>
      </React.Fragment>
    );
  }
}
