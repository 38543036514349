import React from 'react';
import ReactSVG from 'react-svg';
import Button from '../Button/Button';
import ImportedContactsList from './ImportedContactsList/ImportedContactsList';
import { InputFile, ImportContactContainer } from './styled';
import { Container, Label, Text } from '../../Assets/Structure/Structure';
import { blueDefault, red } from '../../Assets/colorPallet/colorPallet';
import uploadIcon from '../../Assets/icons/upload.svg';
import InlineLoading from '../InlineLoading/InlineLoading';
import { convertCSVToArrayOfObjects } from '../../utils/convertCSVToArrayOfObjects';
import DropFilesContainer from "../DropFilesContainer";

export default class ImportContacts extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fileReader: "",
            onUpload: false,
            loading: false,
            importedContacts: [],
            isFileColumnsWrong: false,
            fileTypeWrongFeedback: false
        }

        this.handleFileRead = this.handleFileRead.bind(this);
    }

    getUnique = (arr, comp) => {

        // store the comparison  values in array
        const unique =  arr.map(e => e[comp])

            // store the indexes of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the false indexes & return unique objects
            .filter((e) => arr[e]).map(e => arr[e]);

        return unique;
    }

    async handleFileRead (e) {
        let content = this.state.fileReader.result;
        content = content.replace(/;/g, ',');
        let importedContacts = await convertCSVToArrayOfObjects(content);
        importedContacts = importedContacts.map(importedContact => {
            return {
                ...importedContact,
                phone: `1${importedContact.phone}`
            }
        })
        const firstObject = importedContacts[0];

        if (firstObject === undefined){
            this.handleImportError()
        } else {
            if ((firstObject.name && firstObject.email) || (firstObject.name && firstObject.phone)){
                let uniqueImportedContacts = this.getUnique(importedContacts, "phone")
                if (!this.props.onlyList) {
                    uniqueImportedContacts = importedContacts
                }
                this.setState({
                    importedContacts: uniqueImportedContacts,
                    loading: false,
                    onUpload: true,
                    isFileColumnsWrong: false
                });
            } else {
                this.handleImportError();
            }
        }
    }

    handleImportError = () => {
        this.setState({ isFileColumnsWrong: true, loading: false, onUpload: false });
    }


    handleChange = (e, droppedFilesList) => {
        let file = droppedFilesList ? droppedFilesList[0] : null

        if (e) {
            this.setState({ loading: true })
            file = e.target.files[0];
        }

        const fileType = file.type

        if (fileType !== "text/csv") {
            this.setState({ fileTypeWrongFeedback: true })
            return
        }

        let fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsText(file);
        this.setState({ fileReader, fileTypeWrongFeedback: false})
    }


    handleUpdateContacts = importedContacts => {
        this.setState({ importedContacts })

        if (importedContacts.length === 0){
            this.props.cancel(false)
        }
    }

    cancelImportedContacts = (cancel) => {
        this.setState({
            onUpload: cancel,
            importedContacts: []
         })
    }

    render(){
        return (
            <ImportContactContainer>
                <Container uppercase flex alignCenter justifyCenter padding="1em 1em 0">
                    <ReactSVG src={uploadIcon} className="upload-icon" />
                    <Container className="import-contact-container--title-container" ml="10px" flex alignCenter >
                        <Label
                        color={blueDefault}
                        fontSize="16px">
                        Import Contacts
                        </Label>
                        <Container ml="10px">
                            <Label bold fontSize="12px">(CSV file only)</Label>
                        </Container>
                    </Container>
                </Container>
                {this.state.onUpload && this.state.loading ? (
                    <InlineLoading />
                ) : this.state.onUpload ? (
                    <ImportedContactsList
                    cancel={this.cancelImportedContacts}
                    locations={this.props.locations}
                    onlyList={this.props.onlyList}
                    sendInvitationToImportedContacts={(importedContacts, location) => {
                        this.props.onAddContacts(importedContacts)
                        if (this.props.onlyList) {
                            return
                        }

                        this.props.sendInvitationToImportedContacts(importedContacts, location)}
                    }
                    importedContacts={this.state.importedContacts}
                    updateContacts={this.handleUpdateContacts} />
                ) : (
                <React.Fragment>
                    <Container flex column alignCenter>
                        <Container maxWidth="22em">
                            <Text fontSize="12px" alignCenter lineHeight="20px">
                                Make sure to name your CSV file columns as
                                <br /><strong> name</strong>{!this.props.onlyList && (<strong>, email</strong>)} and <strong> phone </strong>.
                            </Text>
                        </Container>
                        {this.state.isFileColumnsWrong && (
                        <Container mt="1em" maxWidth="20em">
                            <Text alignCenter fontSize="12px" color={red}>
                                <strong></strong>Sorry, we have found an error in your file. <br />
                                Please make sure to name your file columns as <strong> name</strong>, <strong> email</strong> and <strong> phone </strong> in order to make it work.
                            </Text>
                        </Container>
                        )}
                        <Container mt="20px">
                            <DropFilesContainer onChange={this.handleChange} onDrop={(droppedFile) => this.handleChange(null, droppedFile)} />
                        </Container>
                        {this.state.fileTypeWrongFeedback && (
                            <Container mt="10px" maxWidth="20em">
                                <Text alignCenter fontSize="12px" color={red}>
                                    File file is wrong. Please add a CSV file.
                                </Text>
                            </Container>
                        )}
                    </Container>
                    <Container mt="2em" flex alignCenter justifyEnd>
                        <Button
                        bgColorOnHover="#eee"
                        height="40px"
                        padding="0"
                        width="150px"
                        borderRadius="20em"
                        onClick={() => this.props.cancel(false)}
                        transparent
                        text="Cancel" />
                    </Container>
                </React.Fragment>
                    )}
            </ImportContactContainer>
        )
    }
}
