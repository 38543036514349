import React, {useEffect, useState} from "react"
import { bindActionCreators } from 'redux';
import axios from "axios";
import InlineLoading from "../../InlineLoading/InlineLoading";
import { Overlay, ConfirmationContainer, ActionContainer } from "./styled";
import { API_URL } from "../../../config";
import { destroySMSMarketingCampaign } from "../../../store/modules/smsMarketing/actions";
import { connect } from "react-redux";

const ConfirmationDeleteCampaign = (props) => {
  const [state, setState] = useState({
    upContainer: false,
    loading: false
  })

  useEffect(() => {
    const confirmation = document.getElementById("confirmation-delete-modal");
    getElementPosition(confirmation);
  }, [])

  const getElementPosition = element => {
    let xPosition = 0;
    let yPosition = 0;
    const pageHeight = window.innerHeight;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    const diff = pageHeight - yPosition;

    if (diff < 250) {
      setState({ upContainer: true });
    } else {
      setState({ upContainer: false });
    }

    return { x: xPosition, y: yPosition };
  };

  const closeConfirmation = () => {
    props.handleClose(false);
  };


  const handleDeleteCampaign = async (campaign) => {
    setState({ loading: true })
    const store = localStorage.getItem("store");

    props.destroySMSMarketingCampaign(store, campaign.id)
    getAndUpdateCampaigns(store)
  }

  const getAndUpdateCampaigns = (store) => {
    setState({ loading: false })
    closeConfirmation()
  }

  return (
    <>
      <ConfirmationContainer
        id="confirmation-delete-modal"
        upContainer={state.upContainer}
      >
        <p>Are you sure about deleting <span>{props.campaign ? props.campaign.name : ''}</span> ?</p>
        <ActionContainer>
          <button className="btn-delete" onClick={() => handleDeleteCampaign(props.campaign)}>
            {state.loading ? <InlineLoading /> : "Delete"}
          </button>
          <button className="btn-cancel" onClick={closeConfirmation}>Cancel</button>
        </ActionContainer>
      </ConfirmationContainer>
    </>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  destroySMSMarketingCampaign: destroySMSMarketingCampaign,
}, dispatch);

export default connect(null, mapDispatchToProps)(ConfirmationDeleteCampaign)