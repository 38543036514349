import styled from 'styled-components';

export const WebchatHeaderContainer = styled.div`
    width: 100%;
    background-color: white;
    height: 5em;
    padding: 0 2em;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #E6E6E6;

    .chat-menu-icon, .phone-icon{
        div:nth-child(1){
            &:first-child{
                display: flex;
            }
        }
        
        svg{
            width: 14px;
            height: 14px;
        }
    }

    @media only screen and (max-width: 600px){
        height: 4em;
        padding: 0 1em;
        position: fixed;
        margin-top: 91px;
        top: 0;
    }
`;

export const Status = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.color};
`;

export const ListContainer = styled.ul`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: ${props => props.fluid ? "100%" : null};

    @media only screen and (max-width: 600px){
        display: ${props => props.hideOnMobile ? "none" : null};
        width: ${props => props.fluidOnMobile ? "100%" : null};
    }
`;

export const ListItem = styled.li`
    list-style: none;
    letter-spacing: .1em;
    cursor: pointer;
    font-size: 16px;
    display: ${props => props.showOnMobile ? "none" : null};
    pointer-events: ${props => props.clickDisabled ? "none" : null};

    &:not(:first-child){
        margin-left: 2em;
    }

    .animated-line{
        width: ${props => props.active ? "100%" : "0"};
        height: 1px;
        background-color: #333;
        transition: width .2s ease-in-out;
    }

    &:hover{
        .animated-line{
            width: 100%;
        }
    }   

    @media only screen and (max-width: 600px){
        display: ${props => props.hideOnMobile ? "none" : props.showOnMobile ? "block" : null};


        .filter-icon{
            div:nth-child(1){
                display: flex;
            }
        }
    }
`;

export const SettingsContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    div{
        display: flex;
    }
`;