import React from 'react';
import ReactSVG from 'react-svg';
import Modal from '../../../Components/Modal/Modal';
import EditProfile from '../../EditProfile/EditProfile';
import { CloseContainer } from './styled';
import closeIcon from '../../../Assets/icons/close.svg';

export default class EditSellerProfile extends React.Component{

    state = {
        currentUserEmail: "",
        currentUserName: ""
    }

    componentDidMount(){
        const currentUserName = localStorage.getItem("user_name");
        const currentUserEmail = localStorage.getItem("user_email");
        this.setState({ currentUserEmail, currentUserName });
    }

    updateUserName = userName => {
        this.props.userName(userName)
    }

    handleCloseModal = isModalOpen => {
        this.props.isModalOpen(isModalOpen)
    }

    render(){

        return (
            <Modal isModalOpen={this.props.isModalOpen} className="edit-seller-profile-modal">
                <EditProfile 
                    type="seller"
                    isModalOpen={this.handleCloseModal}
                    userName={this.updateUserName}
                    currentUserEmail={this.state.currentUserEmail} 
                    currentUserName={this.state.currentUserName}  />
            </Modal>
        )
    }
}