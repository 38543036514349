import styled from 'styled-components';

export const CopyCodeContainer = styled.div`
`;

export const Code = styled.div`
    .copy-code-module{
        display: flex;
        align-items: center;    
        width: auto;
        cursor: pointer;
        justify-content: center;
        border: 1px solid #ccc !important;
        box-shadow: none !important;

        .widget-info-container{
            display: flex;
            flex-direction: column;

            label{
                font-weight: 400;
                line-height: 25px;
                font-size: 14px;
                letter-spacing: 0.05em;
                color: #333333;
            }

            a{
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #449AC1;
                cursor: pointer;
            }
        }
    }
`;


export const DeleteNumberContainer = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: #d6d6d6;
    }
`
