import styled from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20em;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px;

    input{
        border: none;
        outline: none;
        border-radius: 20em;
        padding: 0 1em;
        color: #333;
    }

    svg{
        margin-right: 4px;
    }
`;