import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class CheckBestLocations extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    On the upper left side click on <strong style={{ color: blueDefault }}>Dashboard</strong>. 
                    On the bottom of the page, you can see a comparison of best-rated locations.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}