import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 269px);
  margin-left: 269px;
  height: 91px;
  background-color: #f8f8f8;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.07);
  position: fixed;
  padding: 0 2em;
  box-sizing: border-box;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: space-between;

  .temp-logout{
    display: none;
  }

  @media only screen and (max-width: 1050px) {
    margin-left: 0;
    max-width: 100%;
    padding: 0;
    
    .temp-logout{
      display: flex;
    }
  }

  @media only screen and (max-width: 500px){
    .ra-logo{
      position: absolute;
      margin-left: 50%;
      transform: translate(-50%);
    }
  }
`;

export const TxtarLogo = styled.img`
    
`
