import styled from "styled-components";

export const AmountSentToday = styled.label`
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 36px;
  color: #ffa800;
`;


export const SentTodayContainer = styled.div`
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: .5em 0;
`;