import React from "react"
import {Container} from "../../../Assets/Structure/Structure";
import Skeleton from "react-skeleton-loader"


export default function MarketingCampaignListSkeleton() {
    return (
        <Container>
            <Container className="full-skeleton">
                <Skeleton width="100%" height="80px" />
            </Container>
            <Container className="full-skeleton" mt="5px">
                <Skeleton width="100%" height="80px" />
            </Container>
            <Container className="full-skeleton" mt="5px">
                <Skeleton width="100%" height="80px" />
            </Container>
            <Container className="full-skeleton" mt="5px">
                <Skeleton width="100%" height="80px" />
            </Container>
            <Container className="full-skeleton" mt="5px">
                <Skeleton width="100%" height="80px" />
            </Container>
            <Container className="full-skeleton"mt="5px">
                <Skeleton width="100%" height="80px" />
            </Container>
        </Container>
    )
}
