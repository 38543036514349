import React from "react";
import axios from 'axios';
import Button from "../../../Components/Button/Button";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import {SendReviewInvitationContainer, FormContainer, ActionContainer, ScrollContainer} from "./styled";
import sendIcon from "../../../Assets/icons/send.svg";
import {Label, Row, Column, Input, Container} from "../../../Assets/Structure/Structure";
import Module from "../../../Components/Module/Module";
import SendMoreInvitations from "./SendMoreInvitations/SendMoreInvitatons";
import {API_URL} from "../../../config";
import MultipleInvitations from "./MultipleInvitations/MultipleInvitations";
import Feedback from "../../EditProfile/Feedback/Feedback";

export default class SendReviewInvitation extends React.Component {
    state = {
        wayOfSending: "",
        isModalOpen: false,
        customerName: "",
        email: "",
        phone: "",
        loading: false,
        invitations: [],
        multipleInvitations: false,
        required: true,
        requestHasFailed: false

    };

    handleMultipleInvitations = () => {
        let invitations = this.state.invitations;

        const invitation = {
            name: this.state.customerName,
            email: this.state.email,
            phone: parseInt(this.state.phone)
        }

        if (this.state.name !== "" && (this.state.phone || this.state.email !== "")){
            invitations.push(invitation);
            this.setState({
                multipleInvitations: true, 
                invitations, 
                customerName: "", 
                email: "", 
                phone: "", 
                required: false
            })
        }
    }

    handleWayOfSendingInvitation = way => this.setState({wayOfSending: way.value});

    handleChange = (e, type) => this.setState({[type]: e.target.value});

    handleSubmit = (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem("token");
        const store = localStorage.getItem("store");
        const headers = {headers: { "x-sas-Auth": authToken }};

        const {wayOfSending, invitations, customerName, email, phone} = this.state;

        this.setState({ loading: true })
        // MULTIPLE REQUESTS
        if (this.state.multipleInvitations) {
            let multipleInvitations = invitations;
            let onFormInvitation;
            if (wayOfSending === "email" && customerName !== "" ) {
                onFormInvitation = { 
                    name: customerName, 
                    email: email 
                }
                multipleInvitations.push(onFormInvitation);
            }

            if (wayOfSending === "phone" && customerName !== "") {
                onFormInvitation = { 
                name: customerName, 
                phone: +parseInt(phone)
                }
                multipleInvitations.push(onFormInvitation);
            }

            multipleInvitations.map((invitation) => {
                let payload;

                if (invitation.email) {
                    payload = { name: invitation.name, email: invitation.email };
                }

                if (invitation.phone) {
                    payload = { name: invitation.name, phone: parseInt(`${1}${invitation.phone}`) };
                }
                
                axios.post(`${API_URL}/private/stores/${store}/locations/${this.props.locationId}/review_requests`, payload, headers)
                .then(async () => {
                    await this.props.handleFeedback(multipleInvitations, "new-invitations-feedback")
                    this.props.updateOverview();
                    this.setState({ invitations: [], loading: false});
                    this.resetForm();
                }).catch(() => {
                    this.setState({ requestHasFailed: true, loading: false })
                })
            })

        } else {
            // SINGLE REQUEST
            let payload;
            if (wayOfSending === "email") payload = { name: customerName, email: email }
            if (wayOfSending === "phone") payload = { name: customerName, phone: parseInt(`${1}${phone}`)}

            axios.post(`${API_URL}/private/stores/${store}/locations/${this.props.locationId}/review_requests`, payload, headers)
            .then(async () => {
                await this.props.handleFeedback([payload], "new-invitations-feedback")
                this.props.updateOverview();
                this.setState({ loading: false })
                this.resetForm()
            }).catch(() => {
                this.setState({ requestHasFailed: true, loading: false })
            })
            
        }

    }

    resetForm = () => this.setState({customerName: "", email: "", phone: ""});

    handleInvitationUpdate = (invitation) => {
        if (invitation.phone) {
            this.setState({wayOfSending: "phone", customerName: invitation.name, phone: parseInt(invitation.phone)})
        }

        if (invitation.email) {
            this.setState({wayOfSending: "email", customerName: invitation.name, email: invitation.email});
        }
    }

    render() {
        const { 
            requestHasFailed, 
            customerName, 
            email, 
            required,
            wayOfSending,
            loading,
            phone,
            invitations 
        } = this.state;

        let formIsFilled;
        if (customerName && (email || phone) !== ""){
            formIsFilled = true;
        } else {
            formIsFilled = false;
        }

        return (
            <React.Fragment>
                <SendReviewInvitationContainer onSubmit={this.handleSubmit} className={this.props.className}>
                    <FormContainer>
                        <Label
                            color="#666"
                            fontSize="18px"
                            style={{
                            margin: "1em 1em 0"
                        }}>
                            Send Review Invitation
                        </Label>
                        <ScrollContainer invitations={invitations}>
                            <MultipleInvitations
                                invitations={invitations}
                                invitationToBeUpdated={this.handleInvitationUpdate}/>
                            <Module className="send-review-module">
                                <Column>
                                    <Label>Customer Name</Label>
                                    <Row borderBottom="1px solid #B5B5B5">
                                        <Input
                                            onChange={(e) => this.handleChange(e, "customerName")}
                                            value={customerName}
                                            type="text"
                                            required={required}
                                            placeholder="Add customer name"
                                            noBorder/>
                                    </Row>
                                </Column>

                                <Column mt="2em">
                                    <Row>
                                        <RadioButton
                                            selectedOption={this.handleWayOfSendingInvitation}
                                            options={[
                                            {
                                                label: "Email",
                                                value: "email"
                                            }, {
                                                label: "SMS",
                                                value: "phone"
                                            }
                                        ]}/>
                                    </Row>
                                </Column>
                                <Column mt="1em">
                                    {wayOfSending === "email"
                                        ? (
                                            <React.Fragment>
                                                <Label>Customer E-mail</Label>
                                                <Input
                                                    onChange={(e) => this.handleChange(e, "email")}
                                                    value={email}
                                                    required={required}
                                                    type="email"
                                                    placeholder="Add e-mail"/>
                                            </React.Fragment>
                                        )
                                        : wayOfSending === "phone"
                                            ? (
                                                <React.Fragment>
                                                    <Label>Customer Phone</Label>
                                                    <Row>
                                                    <Container flex alignCenter mr=".5em">
                                                      <Label fontSize="20px">(+1)</Label>
                                                    </Container>
                                                    <Input
                                                        onChange={(e) => this.handleChange(e, "phone")}
                                                        value={phone}
                                                        required={required}
                                                        type="number"
                                                        placeholder="Add phone"/>
                                                        </Row>
                                                </React.Fragment>
                                            )
                                            : null}
                                </Column>
                                <Column>
                                    <SendMoreInvitations onClick={this.handleMultipleInvitations} formIsFilled={formIsFilled}/>
                                </Column>
                            </Module>
                        </ScrollContainer>
                        {requestHasFailed && <Feedback type="fail" text="Something went wrong. Please try again." />}
                    </FormContainer>
                    <ActionContainer>
                        <Button
                            text="Send"
                            hasIcon
                            loading={loading}
                            type="submit"
                            iconPath={sendIcon}
                            borderRadius="20em"
                            color="white"
                            width="300px"
                            padding="0"
                            uppercase
                            height="50px"
                            flex/>
                    </ActionContainer>
                </SendReviewInvitationContainer>
            </React.Fragment>
        );
    }
}
