import React, {useEffect, useState} from "react"
import axios from "axios"
import { connect } from "react-redux"
import ReactSVG from "react-svg"
import { Dialog } from "@material-ui/core"

// STYLE
import { ComposeMessageDialogContainer, CloseButtonContainer } from "./styled"

// ASSETS
import closeIcon from "../../../Assets/icons/close.svg"
import {Container, Label} from "../../../Assets/Structure/Structure"
import {Textarea} from "../../../Pages/Reviews/AnswerReview/styled";
import Button from "../../../Components/Button/Button";
import AddClient from "./AddClient/AddClient";
import ClientsList from "../../MarketingCampaign/ClientsList/ClientsList";
import ChooseLocation from "./ChooseLocation/ChooseLocation";
import {WEBCHAT_API_URL} from "../../../config";
import { updateChatGroupsMenu, setCurrentChat } from "../../../store/modules/webchat/actions"


function ComposeMessageDialog(props) {
    const [clients, setClients] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [enableSendMessages, setEnableSendMessages] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [showImportCSVDialog, setShowImportCSVDialog] = useState(false)
    const [state, setState] = useState({
        clientName: "",
        clientNumber: ""
    })


    useEffect(() => {
        if (!props.active) resetLocalState()
    }, [props.active])


    const resetLocalState = () => {
        setClients([])
        setMessage("")
        setLoading(false)
        setEnableSendMessages(false)
        setSelectedLocation(null)
    }

    const handleAddClientsOnChange = (clientKey, value) => {
        switch(clientKey) {
            case "clientName":
                setState({ ...state, clientName: value });
                break;
            case "clientNumber":
                setState({ ...state, clientNumber: value });
                break;
            default:
                break;
        }
    }

    const handleDeleteClient = (clientToBeDeleted) => {
        setClients(clients.filter(client => client !== clientToBeDeleted))
    }

    const handleSendMessages = async () => {
        try {
            setLoading(true)
            await sendMessages()
            await fetchChatGroupsMenu()
            setLoading(false)
            props.onClose()
        }
        catch(error) {
            setLoading(false)
            props.onClose()
        }
    }

    const fetchChatGroupsMenu = () => {
        const storeId = localStorage.getItem('store');

        const headers = { headers: {"x-auth-token": props.chatCredentials.twilioAuthToken , "x-account-sid": props.chatCredentials.accountSid} }

        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/messages/menu`, headers)
            .then(res => {
                const { chatGroups } = res.data
                props.updateChatGroupsMenu(true, chatGroups)
            })
    }

    const handleMessage = (message) => {
        setMessage(message)
    }

    useEffect(() => {
       if (state.clientName !== "" && state.clientNumber !== "" && message !== "" && selectedLocation) {
           setEnableSendMessages(true)
       } else {
           setEnableSendMessages(false)
       }
    }, [clients, message, selectedLocation])


    const sendMessages = () => {
        return new Promise(async(resolve, reject) => {
            try {
                const storeId = localStorage.getItem("store")
                const endpoint = `${WEBCHAT_API_URL}/stores/${storeId}/locations/${selectedLocation.id}/messages`
                const headers = {
                    headers: {
                        "x-auth-token": props.chatCredentials.twilioAuthToken,
                        "x-account-sid": props.chatCredentials.accountSid
                    }
                }

                const payload = {
                    sentTo: `1${state.clientNumber}`,
                    text: message,
                    author: "store",
                    clientNumber: `1${state.clientNumber}`,
                    createdAt: new Date().toISOString(),
                    storeName: localStorage.getItem('store_name'),
                    locationName: selectedLocation.name,
                    clientName: state.clientName,
                    staffName: localStorage.getItem("user_name")
                }

                const response = await axios.post(endpoint, payload, headers)
                resolve(response)
            }
            catch(error){
                reject(error)
            }

        })
    }

    const handleSelectedLocation = (selectedLocation) => {
        setSelectedLocation(selectedLocation)
    }
    //
    // const handleShowImportCSVDialog = (showDialog) => {
    //     setShowImportCSVDialog(showDialog)
    // }
    //
    // const handleImportedContacts = (importedContacts) => {
    //     setClients(importedContacts)
    // }


    return (
        <>
        <Dialog
            className="compose-message-dialog"
            style={{ borderRadius: "20em" }}
            open={props.active}
            onClose={() => props.onClose()}
            maxWidth="lg"
            fullWidth>
            <ComposeMessageDialogContainer>
                <Container flex fluid justifyEnd alignCenter>
                    <CloseButtonContainer onClick={() => props.onClose()}>
                        <ReactSVG src={closeIcon} />
                    </CloseButtonContainer>
                </Container>
                <Container auto>
                    <Label fontSize="18px" bold color="#333">Compose a message</Label>
                </Container>
                <Container flex wrap>
                    <Container className="compose-message-dialog--left-column" style={{ flex: "0 1 36em" }} mr="40px" fluid>
                        <Container mt="40px">
                            <Label semiBold>Choose location</Label>
                            <Container fluid maxWidth="16em" mt="20px">
                                <ChooseLocation
                                    onChange={handleSelectedLocation}
                                    onInitialSelectedLocation={handleSelectedLocation}/>
                            </Container>
                        </Container>
                        <Container mt="40px">
                            <Container flex alignCenter>
                                <Label semiBold>Add clients</Label>
                            </Container>
                            <Container mt="20px">
                                <AddClient onChange={handleAddClientsOnChange} />
                            </Container>
                        </Container>
                        {clients.length > 0 && <Container mt="20px">
                            <ClientsList clients={clients} onDelete={handleDeleteClient} />
                        </Container>}
                    </Container>
                    <Container style={{ flex: "1 1 26em" }} fluid>
                        <Container mt="40px">
                            <Label semiBold>Message</Label>
                            <Container fluid mt="20px">
                                <Textarea
                                    maxheight="30vh"
                                    value={message}
                                    minHeight="30vh"
                                    placeholder="Write a message"
                                    onChange={(e) => handleMessage(e.target.value)}>
                                </Textarea>
                            </Container>
                        </Container>
                    </Container>
                </Container>

                <Container mt="60px" flex fluid alignCenter justifyEnd>
                    <Button
                        disabled={!enableSendMessages}
                        loading={loading}
                        primary
                        padding="0"
                        flex
                        width="14em"
                        height="40px"
                        color="white"
                        borderRadius="20em"
                        text="Send message"
                        onClick={handleSendMessages}/>
                </Container>
            </ComposeMessageDialogContainer>
        </Dialog>
            {/*<ImportContactsDialog*/}
            {/*    active={showImportCSVDialog}*/}
            {/*    onChange={handleImportedContacts}*/}
            {/*    onClose={() => handleShowImportCSVDialog(false)}*/}
            {/*    onCancel={handleShowImportCSVDialog}/>*/}
        </>
    )
}

const mapStateToProps = state => ({
    chatCredentials: state.webchat.chatCredentials
})

const mapDispatchToProps = dispatch => ({
    updateChatGroupsMenu(updateChatGroups, chatGroups){
        dispatch(updateChatGroupsMenu(updateChatGroups, chatGroups))
    },

    setCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessageDialog)
