// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import {Redirect} from "react-router-dom";


// Styles
import {CreateVideoRoomDialogForm} from "./styled";
import {createVideoRoomName, sendClientVideoRoomLink} from "../../../store/modules/videoChat/actions";
import {Container, Input, Label, Span} from "../../../Assets/Structure/Structure";
import {Dialog} from "@material-ui/core";
import ReactSVG from "react-svg";

// Icons
import videoIcon from "../../../Assets/icons/video.svg"
import Button from "../../../Components/Button/Button";

// Images
import usFlag from "../../../Assets/images/use-flag.svg"


function CreateVideoRoomDialog({
    active,
    onClose,
    dispatchCreateVideoRoom,
    dispatchSendClientVideoLink,
    currentVideoRoom
}) {
    const [clientPhoneNumber, setClientPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [redirectToVideoRoom, setRedirectToVideoRoom] = useState(false)
    const [showError, setShowError] = useState(false)
    const [activateVideoRoom, setActivateVideoRoom] = useState(false)

    const handleClientPhoneNumberOnChange = (e) => {
        setClientPhoneNumber(e.target.value)
    }

    const handleCreateVideoRoom = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const videoRoomName = clientPhoneNumber

            const videoRoom = await dispatchCreateVideoRoom(videoRoomName)
            const videoRoomId = videoRoom.id
            const storeId = localStorage.getItem("store")

            const rootPath = window.location.origin
            const clientVideoRoomLink = `${rootPath}/video-chat?participantName=Client&videoRoomName=${clientPhoneNumber}&videoRoomId=${videoRoom.id}`

            await dispatchSendClientVideoLink({ storeId, videoRoomId, clientPhoneNumber, clientVideoRoomLink })
            setShowError(false)
            setActivateVideoRoom(true)
        } catch (error) {
            setShowError(true)
            setActivateVideoRoom(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (activateVideoRoom) {
            setRedirectToVideoRoom(true)
        }
    }, [activateVideoRoom])


    if (redirectToVideoRoom) {
        const userName = localStorage.getItem("store_name")
        const storeKey = localStorage.getItem("store_key")

        return <Redirect to={`/video-chat?participantName=${userName}&videoRoomName=${clientPhoneNumber}&storeKey=${storeKey}`} />
    }

    return (
            <Dialog
                style={{ borderRadius: "20em" }}
                open={active}
                onClose={() => onClose(false)}
                maxWidth="xs"
                fullWidth>
                    <CreateVideoRoomDialogForm onSubmit={handleCreateVideoRoom}>
                        <Container padding="40px">
                            <Container fluid flex alignCenter justifyCenter column>
                                <Container
                                    flex
                                    alignCenter
                                    justifyCenter
                                    width="100px"
                                    height="100px"
                                    backgroundColor="#eee"
                                    borderRadius="50%">
                                    <ReactSVG src={videoIcon} className="create-video-room-dialog--video-icon"/>
                                </Container>
                                <Container mt="20px" fluid maxWidth="15em" textCenter>
                                    <Label fontSize="18px" lineHeight="30px">
                                        Invite your customer to a video conference
                                    </Label>
                                </Container>
                            </Container>
                            <Container mt="40px">
                                <Label>Add client phone</Label>
                                <Container mt="5px" flex alignCenter>
                                    <Container flex alignCenter>
                                        <img src={usFlag} style={{ height: "14px" }} />
                                        <Container ml="10px">
                                            <Label>(+1)</Label>
                                        </Container>
                                    </Container>
                                    <Container ml="10px">
                                        <Input
                                        placeholder="Add phone number"
                                        value={clientPhoneNumber}
                                        onChange={handleClientPhoneNumberOnChange}
                                        required />
                                    </Container>
                                </Container>
                            </Container>
                            <Container mt="40px" fluid>
                                <Button
                                    loading={loading}
                                    type="submit"
                                    text="Create room"
                                    color="white"
                                    height="40px"
                                    padding="0"
                                    width="100%"
                                    borderRadius="20em"
                                    uppercase />
                            </Container>
                            {showError && (
                                <Container mt="10px">
                                    <Label fontSize="12px" color="red" >Invalid phone number</Label>
                                </Container>
                            )}
                        </Container>
                    </CreateVideoRoomDialogForm>
            </Dialog>
    )
}

const mapStateToProps = state => ({
    currentVideoRoom: state.videoChat.currentVideoRoom
})

const mapDispatchToProps = dispatch => ({
    async dispatchCreateVideoRoom(videoRoomName) {
        const videoRoom = await dispatch(createVideoRoomName(videoRoomName))
        return videoRoom
    },

    async dispatchSendClientVideoLink({ storeId, videoRoomId, clientPhoneNumber, clientVideoRoomLink }) {
        await dispatch(sendClientVideoRoomLink({ storeId, videoRoomId, clientPhoneNumber, clientVideoRoomLink }))
        return
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateVideoRoomDialog)

