import styled from "styled-components";

export const AllReviewsChartContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
  padding: 2em;
  box-sizing: border-box;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props =>
    props.flexEnd ? "flex-end" : props.between ? "space-between" : null};
  align-items: ${props => (props.alignCenter ? "center" : null)};
`;

export const Column = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #999999;
`;

export const TotalReviews = styled.label`
  font-size: 24px;
  text-align: right;
  letter-spacing: 0.1em;
  color: #888888;
`;

export const LeftContainer = styled.div`
  align-items: center;
  display: flex;

  label {
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #999999;
  }

  svg {
    margin-right: 0.5em;
  }
`;

export const RightContainer = styled.div`
  font-size: 16px;
  text-align: right;
  letter-spacing: 0.1em;
  color: ${props => props.bgColor};
`;

export const ReviewsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3em;
  margin-top: 2em;
`;
