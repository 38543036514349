import React from 'react';

export default class PreviewImage extends React.Component{
    render(){

        const { style, imagePath } = this.props;
        
        return(
            <React.Fragment>
                <img style={style} src={imagePath} />
            </React.Fragment>
        )
    }
}