import React, { useState, useEffect } from 'react'
import { Container, Label, ListContainer, ListItem } from '../../../Assets/Structure/Structure'
import Button from '../../../Components/Button/Button'
import editIcon from '../../../Assets/icons/edit-secundary.svg'
import addIcon from '../../../Assets/icons/plus.svg'
import deleteIcon from '../../../Assets/icons/bin.svg'


import UpdatePhoneNumberDialog from './UpdatePhoneNumberDialog'
import AddPhoneNumberDialog from './AddPhoneNumberDialog'
import DeleteChatAgentDialog from './DeleteChatAgentDialog'

export default function UpdatePhoneNumbers(props){

    const [showDialog, setShowDialog] = useState(false)
    const [showAddPhoneNumberDialog, setShowAddPhoneNumberDialog] = useState(false)
    const [showDeleteChatAgentDialog, setShowDeleteChatAgentDialog] = useState(false)
    const [selectedChatAgent, setSelectedChatAgent] = useState(null)
    const [phoneNumberToBeUpdated, setPhoneNumberToBeUpdated] = useState(null)
    const [currentPhoneIsManager, setCurrentPhoneIsManager] = useState(false)
    const [dialogTitle, setDialogTitle] = useState("Update Phone Number")

    useEffect(() => {
        setTimeout(() => {
            if (Object.getOwnPropertyNames(props.phoneNumbers.manager).length === 0){
                checkIfStoreHasManagerPhoneNumber()
            }
        }, 300)
    }, [props])

    const checkIfStoreHasManagerPhoneNumber = () => {
        if (Object.getOwnPropertyNames(props.phoneNumbers.manager).length === 0) {
            setShowAddPhoneNumberDialog(true)
            setCurrentPhoneIsManager(true)
            setDialogTitle("Add Manager Phone Number")
        }
    }

    const handleShowUpdateDialog = (showDialog, phone) => {
        setShowDialog(showDialog)
        if (phone){
            setPhoneNumberToBeUpdated(phone)
            setCurrentPhoneIsManager(false)
            setDialogTitle("Update Phone Number")
        } else {
            setPhoneNumberToBeUpdated(props.phoneNumbers.manager)
            setCurrentPhoneIsManager(true)
            setDialogTitle("Update Manager Phone Number")
        }
    }

    const handleShowAddPhoneNumberDialog = (showDialog, type) => {
        if (type === "manager") {
            setCurrentPhoneIsManager(true)   
            setDialogTitle("Add Manager Phone Number")
        }     
        setShowAddPhoneNumberDialog(showDialog)
        setCurrentPhoneIsManager(false)
        setDialogTitle("Add Phone Number")
    }

    const handleSuccess = (data) => {
        props.onSuccess(data)
        setCurrentPhoneIsManager(false)
    }

    const handleShowDeleteChatAgentDialog = (showDialog, chatAgent) => {
        setShowDeleteChatAgentDialog(showDialog)
        setSelectedChatAgent(chatAgent)
    }

    return (
        <Container>
            <Container>
                <Container flex alignCenter between>
                    <Label>Manager phone number</Label>
                    <Container>
                        {!props.phoneNumbers.manager && <Button 
                        onClick={() => handleShowAddPhoneNumberDialog(true, "manager")}
                        kind="icon-button"
                        bgColorOnHover="#ddd"
                        bgColor="transparent"
                        hasIcon
                        iconColor="#333"
                        iconPath={addIcon} />}
                    </Container>
                </Container>
                <ListContainer>
                {props.phoneNumbers.manager && (
                    <ListItem style={{ borderRadius: "8px" }}>
                        <Container flex between alignCenter>
                            <Container flex alignCenter>
                                <Container width="10em">
                                    <Label>{props.phoneNumbers.manager.name}</Label>
                                </Container>
                                <Container ml="20px">
                                    <Label>{props.phoneNumbers.manager.number}</Label>
                                </Container>
                            </Container>
                            <Container flex alignCenter>
                                <Container>
                                    <Button 
                                    onClick={() => handleShowUpdateDialog(true)}
                                    kind="icon-button"
                                    bgColorOnHover="#ddd"
                                    bgColor="transparent"
                                    hasIcon
                                    iconColor="#333"
                                    iconPath={editIcon} />
                                </Container>
                            </Container>
                        </Container>
                    </ListItem>
                    )}

                </ListContainer>
            </Container>
            <Container mt="40px">
                <Container flex fluid alignCenter between>
                    <Label>Other phone numbers</Label>
                    <Container>
                        <Button 
                        onClick={() => handleShowAddPhoneNumberDialog(true)}
                        kind="icon-button"
                        bgColorOnHover="#ddd"
                        bgColor="transparent"
                        hasIcon
                        iconColor="#333"
                        iconPath={addIcon} />
                    </Container>
                </Container>
                <ListContainer>
                    {props.phoneNumbers.others.length > 0 && props.phoneNumbers.others.map((phone, index) => (
                        <ListItem style={{ borderRadius: "8px" }} key={index}>
                            <Container flex between alignCenter>
                                <Container flex alignCenter>
                                    <Container width="10em">
                                        <Label>{phone.name}</Label>
                                    </Container>
                                    <Container ml="20px">
                                        <Label>{phone.number}</Label>
                                    </Container>
                                </Container>
                                <Container flex alignCenter>
                                    <Container>
                                        <Button 
                                        onClick={() => handleShowUpdateDialog(true, phone)}
                                        kind="icon-button"
                                        bgColorOnHover="#ddd"
                                        bgColor="transparent"
                                        hasIcon
                                        iconColor="#333"
                                        iconPath={editIcon} />
                                    </Container>
                                    <Container>
                                        <Button 
                                        onClick={() => handleShowDeleteChatAgentDialog(true, phone)}
                                        kind="icon-button"
                                        bgColorOnHover="#ddd"
                                        bgColor="transparent"
                                        hasIcon
                                        iconColor="#333"
                                        iconPath={deleteIcon} />
                                    </Container>
                                </Container>
                            </Container>
                        </ListItem>
                    ))}
                </ListContainer>
            </Container>

            {showDialog && 
            <UpdatePhoneNumberDialog 
            onSuccess={(data) => props.onSuccess(data)}
            title={dialogTitle}
            currentPhoneIsManager={currentPhoneIsManager}
            twilioNumber={props.twilioNumber}
            phoneNumber={phoneNumberToBeUpdated} 
            onClose={handleShowUpdateDialog}/>}

            {showAddPhoneNumberDialog && 
            <AddPhoneNumberDialog 
            currentPhoneIsManager={currentPhoneIsManager}
            title={dialogTitle}
            storeDoesNotHasManagerPhoneNumber={Object.getOwnPropertyNames(props.phoneNumbers.manager).length === 0}
            onSuccess={(data) => handleSuccess(data)}
            onClose={handleShowAddPhoneNumberDialog}/>}

            {showDeleteChatAgentDialog && 
            <DeleteChatAgentDialog 
            onSuccess={() => props.onSuccess()}
            selectedChatAgent={selectedChatAgent}
            onClose={handleShowDeleteChatAgentDialog} />}
        </Container>
    )
}