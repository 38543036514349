import React from 'react';
import ReactSVG from 'react-svg';
import editIcon from '../../../../Assets/icons/edit.svg';
import {AnswerContainer} from './styled';
import {Label, Row, Column} from '../../../../Assets/Structure/Structure'
import Avatar from '../../../../Components/Avatar/Avatar';
import { AuthorName, Text } from '../styled';

export default class Answer extends React.Component {

    render() {

      const userName = localStorage.getItem("user_name");

        return (
            <AnswerContainer>
                <Row between>
                    <Label fontSize="12px" uppercase>Answer</Label>
                    <ReactSVG src={editIcon}/>
                </Row>
                <Row mt="1em">
                    <Column>
                        <Avatar name={userName}/>
                    </Column>
                    <Column justifyCenter ml2 fluid>
                        <Row>
                            <AuthorName>{userName}</AuthorName>
                        </Row>
                        <Row between mt025>
                            <Label fontSize="12px" uppercase>Manager</Label>
                        </Row>
                        <Row>
                            <Text>{this.props.replyText}</Text>
                        </Row>
                    </Column>
                </Row>
            </AnswerContainer>
        )
    }
}