import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  @media only screen and (max-width: 500px){
    padding: 0;
    
    .store-location--widget-menu{
      display: none;
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;  
`;

export const BodyContainer = styled.div`
  width: ${props => {
    if (props.chatOnly == 1) return "100%"
    else return "100%"
  }};

  @media only screen and (max-width: 1025px){
    width: 100% !important;
  }

  .btn-new-location{
    justify-content: center;

    @media only screen and (max-width: 500px){
      margin-left: 1em;
    }
  }  
`;
