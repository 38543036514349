import React from 'react';
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import Accordion from '../../Components/Accordion/Accordion';
import { questions } from './questions';
import HowDoIChangeMyPassword from './Content/HowDoIChangeMyPassword/HowDoIChangeMyPassword';
import HowToIntegrate from './Content/HowToIntegrate/HowToIntegrate';
import HowToSendReviewRequests from './Content/HowToSendReviewRequests/HowToSendReviewRequests';
import HowToCheckMyReviews from './Content/HowToCheckMyReviews/HowToCheckMyReviews';
import HowToAnswerReview from './Content/HowToAnswerReview/HowToAnswerReview';
import HowToKnowWhoAnswered from './Content/HowToKnowWhoAnswered/HowToKnowWhoAnswered';
import DoIGetAReport from './Content/DoIGetAReport/DoIGetAReport';
import AccessReport from './Content/AccessReport/AccessReport';
import AccessIndividualPerformance from './Content/AccessIndividualPerformance/AccessIndividualPerformance';
import HowToManageStoreLocation from './Content/HowToManageStoreLocation/HowToManageStoreLocation';
import CheckHowWellSpecificLocation from './Content/CheckHowWellSpecificLocation/CheckHowWellSpecificLocation';
import CheckBestLocations from './Content/CheckBestLocations/CheckBestLocations';
import StatsAndDataStore from './Content/StatsAndDataStore/StatsAndDataStore';
import BadReviewNotification from './Content/BadReviewNotification/BadReviewNotification';
import TeamManagement from './Content/TeamManagement/TeamManagement';
import Devices from './Content/Devices/Devices';
import { Container } from './styled';
import { PageWrapper, Text } from '../../Assets/Structure/Structure';
import { blueDefault } from '../../Assets/colorPallet/colorPallet';

export default class Faq extends React.Component{

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render(){
        return(
            <ValidateWidgetOnly>
                <PageWrapper padding="2em 12em">
                    <Accordion 
                    contents={[
                        { id: 1, component: <HowDoIChangeMyPassword /> },
                        { id: 2, component: <HowToIntegrate /> },
                        { id: 3, component: <HowToSendReviewRequests /> },
                        { id: 4, component: <HowToCheckMyReviews /> },
                        { id: 5, component: <HowToAnswerReview /> },
                        { id: 6, component: <HowToKnowWhoAnswered /> },
                        { id: 7, component: <DoIGetAReport /> },
                        { id: 8, component: <AccessReport /> },
                        { id: 9, component: <AccessIndividualPerformance /> },
                        { id: 10, component: <HowToManageStoreLocation /> },
                        { id: 11, component: <CheckHowWellSpecificLocation /> },
                        { id: 12, component: <CheckBestLocations /> },
                        { id: 13, component: <StatsAndDataStore /> },
                        { id: 14, component: <BadReviewNotification /> },
                        { id: 15, component: <TeamManagement /> },
                        { id: 16, component: <Devices /> }
                    ]}
                    questions={questions} />
                    <Container pl="2em" bl="2px solid #d0d0d0" mt="2em" className="faq-bottom-info">
                        <Text mb="0">Have more questions?</Text>
                        <Text mt="0">Reach out to <a href="mailto:support@reviewalert.com" 
                        style={{ color: blueDefault, cursor: "pointer", textDecoration: "none" }}>
                        support@reviewalert.com</a> at anytime</Text>
                    </Container>
                </PageWrapper>
            </ValidateWidgetOnly>
        )
    }
}