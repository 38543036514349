import React from 'react';
import {InvitationFeedbackContainer, LocationContainer, CloseContainer} from './styled';
import {Row, Label, Container, Text, Column, GridContainer} from '../../../../Assets/Structure/Structure';
import ReactSVG from 'react-svg';
import Module from '../../../Module/Module';
import locationIcon from '../../../../Assets/icons/location.svg';
import closeIcon from '../../../../Assets/icons/close.svg';
import successCheckIcon from '../../../../Assets/icons/success-check.svg';

export default class InvitationFeedback extends React.Component {

    handleClose = () => this.props.isModalOpen(false)

    render() {
        return (
            <InvitationFeedbackContainer>
                <Row flexEnd alignCenter backgroundColor="#f8f8f8"> 
                    <CloseContainer>
                        <ReactSVG src={closeIcon} onClick={this.handleClose}/>
                    </CloseContainer>
                </Row>
                <Container backgroundColor="#F8F8F8" padding="2em" className="mob-container">
                    <Row alignCenter justifyCenter>
                        <ReactSVG src={successCheckIcon}/>
                        <Label color="#44CA61" fontSize="24px" ml=".75em">
                        {this.props.invitations.length > 1 ? "Invitations " : "Invitation "} sent successfully</Label>
                    </Row>
                    <GridContainer templateColumns="1fr" mt="1em" padding="1em" overflowY="auto" maxHeight="50vh" mt="1em">
                        {this.props.invitations.map(invitation => {
                            return (
                                <Module>
                                    <Row between>
                                        <Column>
                                            <Label fontSize="16px;">{invitation.customerName || invitation.name}</Label>
                                            <Label color="#999" ls=".05em">{invitation.email}</Label>
                                        </Column>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <ReactSVG src={successCheckIcon} />
                                            <Label uppercase color="#44CA61" fontSize="12px" ml=".5em">Sent</Label>
                                        </div>
                                    </Row>
                                </Module>
                            )
                        })}
                    </GridContainer>
           
                </Container>
            </InvitationFeedbackContainer>
        )
    }
}