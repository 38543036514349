import React from 'react';
import { BeaconGuideNavContainer, NavItem } from './styled';

export default class BeaconGuideNav extends React.Component{

    state = {
        currentNavItem: "home-beacon"
    }

    handleClick = currentNavItem => {
        this.setState({ currentNavItem })
        this.props.currentNavItem(currentNavItem);
    }

    render(){

        const options = [
            { name: "Home Beacon", value: "home-beacon" },
            { name: "Home Horizontal", value: "home-horizontal" },
            { name: "Product Catalog Horizontal", value: "product-catalog-horizontal" },
            { name: "Product Catalog Banner", value: "product-catalog-banner" }
        ]

        return(
            <BeaconGuideNavContainer>
                {options.map(option => {
                    return (
                    <NavItem 
                    active={option.value === this.state.currentNavItem ? true : false}
                    onClick={() => this.handleClick(option.value)}>
                    {option.name}
                    </NavItem>
                    )
                })}
            </BeaconGuideNavContainer>
        )
    }
}