import React from "react";
import {TeamTableContainer, SkeletonContainer} from "./styled";
import TeamTableRow from "./TeamTableRow/TeamTableRow";
import TeamTableRowSkeleton from "./TeamTableRowSkeleton/TeamTableRowSkeleton";

export default class TeamTable extends React.Component {
    state = {
        activePage: 1,
        paginationActive: false,
        employees: []
    };

    componentWillMount() {
        this.setState({employees: this.props.employees, resetPagination: this.props.resetPagination, totalOfEmployees: this.props.totalOfEmployees});
    }

    componentWillReceiveProps(props) {
        const employees = props.employees;
        this.setState({
            employees,
            activePage: 1,
            minIndex: 0,
            maxIndex: 9,
            resetPagination: props.resetPagination,
            totalOfEmployees: props.totalOfEmployees
        });
    }

    handlePageChange = (currentPage, itemsCountPerPage,) => {
        let itemsPerPage = itemsCountPerPage;
        let maxIndex = currentPage * itemsPerPage - 1;
        let minIndex = maxIndex - (itemsCountPerPage - 1);
        this.setState({activePage: currentPage, minIndex, maxIndex, paginationActive: true});
    };

    handleUpdateEmployees = (employees, totalOfEmployees) => {
      this.props.updateEmployees(employees, totalOfEmployees)
    }

    render() {
        return (
            <TeamTableContainer>
                <React.Fragment>
                    {this.props.loading
                        ? (
                            <SkeletonContainer>
                                <TeamTableRowSkeleton/>
                                <TeamTableRowSkeleton/>
                                <TeamTableRowSkeleton/>
                                <TeamTableRowSkeleton/>
                            </SkeletonContainer>
                        )
                        : (this.props.employees.map((employee, index) => {
                            return <TeamTableRow
                                key={index}
                                employee={employee}
                                rowId={index}
                                updateEmployees={this.handleUpdateEmployees}/>
                        }))}
                </React.Fragment>
            </TeamTableContainer>
        );
    }
}
