//WEBCHAT
import {
    SET_CHAT_CREDENTIALS,
    SET_CHAT_GROUPS,
    SET_CHAT_GROUPS_AMOUNT,
    SET_CONVERSATION_BEFORE_REFRESH,
    SET_CURRENT_CHAT,
    SET_NEEDS_RESPONSE_AMOUNT,
    SET_NEW_MESSAGES_AMOUNT,
    SET_SEND_REVIEW_REQUEST_ON_CHAT,
    SET_SHOW_CHATS_ON_MOBILE,
    UPDATE_CHAT_GROUPS_MENU,
    SET_FILTER,
} from "./mutations";
import axios from "axios";
import {WEBCHAT_API_URL} from "../../../config";


export const fetchChatCredentials = () => async (dispatch) => {
    try {
        const storeKey = localStorage.getItem("store_key")
        const response = await axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)

        const { accountSid } = response.data[0]
        const twilioAuthToken = response.data[0].token
        const twilioNumber = response.data[0].number

        const chatCredentials = {
            accountSid,
            twilioAuthToken,
            twilioNumber
        }

        dispatch({ type: SET_CHAT_CREDENTIALS, payload: chatCredentials })

        return chatCredentials
    } catch (error) {
        throw new Error(error)
    }
}

export const setCurrentChat = (chat) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_CHAT,
        payload: chat
    })
}

export const setChatCredentials = (chatCredentials) => (dispatch) => {
    dispatch({
        type: SET_CHAT_CREDENTIALS,
        payload: chatCredentials
    })
}

export const updateChatGroupsMenu = (update, chatGroups) => (dispatch) => {
    dispatch({
        type: UPDATE_CHAT_GROUPS_MENU,
        payload: update
    })

    dispatch({ type: SET_CHAT_GROUPS, payload: chatGroups })
}

export const setNeedsResponseAmount = (amount) => dispatch => {
    dispatch({
        type: SET_NEEDS_RESPONSE_AMOUNT,
        payload: amount
    })
}

export const setNewMessagesAmount = amount => dispatch => {
    dispatch({
        type: SET_NEW_MESSAGES_AMOUNT,
        payload: amount
    })
}

export const setShowChatsOnMobile = showChatsOnMobile => dispatch => {
    dispatch({
        type: SET_SHOW_CHATS_ON_MOBILE,
        payload: showChatsOnMobile
    })
}

export const setChatGroupsAmount = chatGroupsAmount => dispatch => {
    dispatch({
        type: SET_CHAT_GROUPS_AMOUNT,
        payload: chatGroupsAmount
    })
}

export const setConversationBeforeRefresh = chatId => dispatch => {
    dispatch({
        type: SET_CONVERSATION_BEFORE_REFRESH,
        payload: chatId
    })
}

export const setSendReviewRequestOnChat = sendReviewRequestOnChat => dispatch => {
    dispatch({
        type: SET_SEND_REVIEW_REQUEST_ON_CHAT,
        payload: sendReviewRequestOnChat
    })

    setTimeout(() => {
        dispatch({
            type: SET_SEND_REVIEW_REQUEST_ON_CHAT,
            payload: false
        })
    }, 1000)
}

export const setFilter = filter => dispatch => {
    dispatch({
        type: SET_FILTER,
        payload: filter
    })
}
