// LIBRARIES
import React, {useEffect} from "react"

// STYLE
import { MarketingCampaignsListContainer } from "./styled"

// PARTIALS
import MarketingCampaign from "./MarketingCampaign/MarketingCampaign";


export default function MarketingCampaignsList(props) {
    return (
        <MarketingCampaignsListContainer>
            {props.marketingCampaigns.map((marketingCampaign) => (
                <MarketingCampaign
                    marketingCampaign={marketingCampaign}
                />
            ))}
        </MarketingCampaignsListContainer>
    )
}
