import styled from "styled-components";

export const ReviewContainer = styled.div`
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  margin-top: ${props => props.marginTop};
`;

export const BodyContainer = styled.div`
  display: flex;
  padding: 2em;
  box-sizing: border-box;
  flex-direction: column;

  @media only screen and (max-width: 500px){
    padding: 1em;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 1em;
`;

export const Col = styled.div`

`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: ${props =>
    props.between ? "space-between" : props.end ? "flex-end" : null};
  align-items: center;
`;

export const AuthorName = styled.label`
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const ContentContainer = styled.div`
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const SmileContainer = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`;

export const LocationAddress = styled.p`
  margin: 0 0 0 0.5em;
  color: #999999;
  font-size: 14px;
  letter-spacing: 0.1em;
`;

export const LocationIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: .25em;
  svg {
    width: 13px;
  }
`;

export const ReviewText = styled.p`
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #333333;
  margin-left: calc(40px + 1em);

  @media only screen and (max-width: 500px){
    margin-left: 0;
  }
`;

export const DateContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: .25em;
`;

export const Date = styled.label`
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #666666;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 15px;
  }

  label {
    margin-left: 0.5em;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #333333;
    cursor: pointer;
  }
`;

export const PlatformContainer = styled.div`
  svg {
    width: 18px;
  }
`;

export const AlertContainer = styled.div`
    margin: .5em .5em .5em 0;

  label {
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
  }
`;


export const Module = styled.div`
    width: 100%;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  height: 10em;
  padding: 1em;
  box-sizing: border-box;
`;