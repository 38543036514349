// Libraries
import styled from "styled-components"


export const CreateVideoRoomDialogForm = styled.form`
  .create-video-room-dialog--video-icon{    
    &:first-child {
      
      div:nth-child(1) {
        display: flex;
        
        svg {
            width: 40px !important;
            height: 40px !important;
        }
      }
    }
  }
`

