import styled from 'styled-components'
import { blueDefault } from '../../Assets/colorPallet/colorPallet'


export const RaSwitchContainer = styled.div`
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: ${blueDefault};
    }

    .MuiSwitch-colorPrimary.Mui-checked {
        color: ${blueDefault};
    }

`