import styled from "styled-components";

export const ReviewContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
  flex-flow: row wrap;

  p {
    margin: 0;
  }

 
 svg{
   width: 40px;
   height: 40px;
 }

  @media only screen and (max-width: 500px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div{
      margin-left: 0;
    }
  }
`;

export const InfoContainer = styled.div`
  margin-left: 2em;

  p {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #999999;
  }

  label {
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.1em;
    color: ${props =>
      props.type === "positive"
        ? "#44CA61"
        : props.type === "neutral"
        ? "#449BC1"
        : props.type === "negative"
        ? "#E97373"
        : null};
  }
`;
