import React from "react";
import { Link } from 'react-router-dom';
import ReactSVG from "react-svg";
import {
  Form,
  Label,
  FormItem,
  Input,
  AddMultipleInvitationsContainer,
  Alert
} from "./styled";
import SelectFluid from "../../../SelectFluid/SelectFluid";
import axios from "axios";
import { API_URL } from "../../../../config";
import RadioButton from "../../../RadioButton/RadioButton";
import addIcon from ".././../../../Assets/icons/add.svg";

export default class InvitationForm extends React.Component {
  state = {
    locations: [],
    selectedLocation: "Select the location",
    location: "",
    contactType: "email",
    email: "",
    phone: "",
    customerName: "",
    multipleInvitations: false,
    invitations: [],
    locationNeedsIntegration: false,
    loading: true
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");

    axios
      .get(`${API_URL}/admin/stores/${store}/locations`, {
        headers: {
          "x-sas-Auth": authToken
        }
      })
      .then(res => {
        const locations = res.data.map(location => {
          return {
            id: location.id,
            name: location.name,
            value: location.name,
            services: location.review_services
          };
        });

        this.setState({ 
          locations, 
          loading: false 
        });
      });
  }

  componentWillReceiveProps(props){
    const invitationToBeUpdated = props.invitationToBeUpdated;
    if (invitationToBeUpdated !== "" && invitationToBeUpdated.email){
      this.setState({ 
        customerName: props.invitationToBeUpdated.customerName,
        email: props.invitationToBeUpdated.email,
        location: props.invitationToBeUpdated.location
       })
    }
  }

  handleSelectedLocation = selectedLocation => {
    if (selectedLocation.services.length === 0){
      this.setState({ locationNeedsIntegration: true })
    } else {
      this.setState({ 
        selectedLocation: selectedLocation.name, 
        location: selectedLocation.id, 
        locationNeedsIntegration: false 

      }, () => {
      this.props.locationId("locationId", selectedLocation.id);
      this.handleShowMultipleInviationsAction();
    });
  }
  };

  handleContactType = contactType => {
    this.setState({ contactType });
    this.props.contactType("contactType", contactType.value);
  };

  handleChange = (e, type) => {
    this.setState({ [type]: e.target.value });
    
    let newInvitation;
    if (type === "phone") {
      this.props.phone("phone", e.target.value);
      newInvitation = {
        customerName: this.state.customerName,
        phone: parseInt(this.state.phone),
        locationId: this.state.location
      }
      this.props.invitationOnForm(newInvitation);
    }

    if (type === "email") {
      this.props.email("email", e.target.value);
      newInvitation = {
        customerName: this.state.customerName,
        email: this.state.email,
        locationId: this.state.location
      }
      this.props.invitationOnForm(newInvitation);
    }

    if (type === "customerName") {
      this.props.customerName("customerName", e.target.value);
      newInvitation = {
        customerName: this.state.customerName,
        email: this.state.email,
        locationId: this.state.location
      }
      this.props.invitationOnForm(newInvitation);
    }
    
    this.handleShowMultipleInviationsAction();
  };

  handleShowMultipleInviationsAction = () => {
    if (this.state.customerName && (this.state.email || this.state.phone) && this.state.location !== ""){
      this.setState({ showMultipleInvitationsAction: true })
    } else {
      this.setState({ showMultipleInvitationsAction: false })
    }
  }

  handleMultipleInvitations = () => {  
    let invitations = this.state.invitations;

    let newInvitation;
    if (this.state.contactType.value === "email"){
      newInvitation = {
        customerName: this.state.customerName,
        email: this.state.email,
        locationId: this.state.location
      }
    }

    if (this.state.contactType.value === "phone"){
      newInvitation = {
        customerName: this.state.customerName,
        phone: parseInt(this.state.phone),
        locationId: this.state.location
      }
    }

    if (newInvitation.customerName && newInvitation.email && newInvitation.location !== "" 
    || newInvitation.customerName && newInvitation.phone && newInvitation.location !== "") {
      invitations.push(newInvitation);
      this.setState({
          invitations,
          customerName: "",
          email: "",
          phone: "",
          selectedLocation: "Select the location"
        },() => this.props.invitations(invitations)
      );
    }
  };

  handleModal = () => {
    this.props.isModalOpen(false)
  }

  render() {

    const {
      loading,
      locations,
      locationNeedsIntegration
    } = this.state;

    return (
      <Form>
        <FormItem>
          <Label>Location</Label>
          {locationNeedsIntegration &&
          <Alert onClick={this.handleModal}>You must connect your location(s) with Google or Facebook in order to send 
          a review request. Please  <strong>
            <Link to="/store-location" style={{ textDecoration: "none", cursor: "pointer", color: "#E97373"}}> 
            click here </Link></strong> to connect.
          </Alert>}
          <SelectFluid
            options={locations}
            justify="space-between"
            loading={loading}
            selectedOption={this.handleSelectedLocation}
            placeholder={this.state.selectedLocation}
          />
        </FormItem>

        <FormItem mt2>
          <Label>Customer Name</Label>
          <Input
            placeholder="Add customer name"
            value={this.state.customerName}
            onChange={e => this.handleChange(e, "customerName")}
          />
        </FormItem>

        <FormItem mt2>
          <RadioButton
            className="radio-button-new-member"
            options={[
              { label: "Email", value: "email" },
              { label: "SMS", value: "phone" }
            ]}
            selectedOption={this.handleContactType}
          />
        </FormItem>

        <FormItem mt1>
          {this.state.contactType.value === "email" ? (
            <React.Fragment>
              <Label>Customer email</Label>
              <Input
                placeholder="Add e-mail"
                value={this.state.email}
                onChange={e => this.handleChange(e, "email")}
              />
            </React.Fragment>
          ) : this.state.contactType.value === "phone" ? (
            <React.Fragment>
              <Label>Customer phone</Label>
              <div style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #b5b5b5"
               }}>
                <label>(+1)</label>
              
              <Input
                placeholder="Add phone"
                type="number"
                style={{ 
                  marginTop: "0", 
                  marginLeft: ".5em",
                  borderBottom: "none" 
                }}
                value={this.state.phone}
                onChange={e => this.handleChange(e, "phone")}
              />
              </div>
            </React.Fragment>
          ) : null}
        </FormItem>

        <FormItem mt2>
          <AddMultipleInvitationsContainer
            active={this.state.showMultipleInvitationsAction}
            onClick={this.handleMultipleInvitations}
          >
            <ReactSVG src={addIcon} />
            <label>Send invitation to more people</label>
          </AddMultipleInvitationsContainer>
        </FormItem>
      </Form>
    );
  }
}
