import styled from 'styled-components';

export const LastDaysOverviewContainer = styled.div`
    width: ${props => props.fluid ? "100%" : "calc(100% - 21em)"};
    margin-left: ${props => props.fluid ? "0" : "2em"};

    @media only screen and (max-width: 500px){
      width: 100%;
      margin-left: 0;
      margin-top: 2em;

      p{
        padding: 0 1em;
      }
    }
`;

export const BarChartContainer = styled.div`
    width: 100%;
    background-color: #eee;
    padding: ${props => props.fluid ? "2em" : null};
    margin-top: ${props => props.fluid ? "1em" : null};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .recharts-wrapper{
     

      @media only screen and (max-width: 500px){
        height: 270px !important;
        margin-top: 0;
  
      }
    }

    label{
      margin: auto 0 auto auto;
    }

    svg{
        width: 100%;
        margin-top: 2em;
        transform: translate(-3em, -1em);
    }
`;
