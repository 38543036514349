import styled from "styled-components";

export const SelectAltContainer = styled.div`
  background-color: ${props => props.active ? "white" : "#e7e7e7"};
  border: ${props => props.active ? "1px solid #333" : "1px solid #e7e7e7"};
  border-radius: 20em;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1em;
  justify-content: space-between;
  border: 1px solid #e7e7e7;
  width: 100%;
  max-width: 31em;
  cursor: pointer;
  box-sizing: border-box;

  label {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #333333;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 90%;
  }

  svg {
    width: 7px;
    transform: ${props => props.active ? "rotate(180deg)" : null};
    margin-left: 1em;
  }
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: transparent;
    left: 0;
    height: 100vh;
`;
