import styled from 'styled-components';

export const WidgetMobileButtonContainer = styled.div`
    right: ${props => (props.sideMenuOpen ? "35em" : "-2.7em")};
    white-space: nowrap;
    position: fixed;
    transform: rotate(-90deg);
    letter-spacing: .1em;
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #449BC1;
    top: 12em;
    z-index: 90;
    color: white;
    transition: right .4s ease;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-family: "Roboto",sans-serif;

    @media only screen and (min-width: 800px){
        display: none;
    }
    @media only screen and (orientation: landscape) and (min-width: 1024px) and (max-width: 1025px){
        right: ${props => (props.sideMenuOpen ? "33.7em" : "-2.7em")};
    }
`;