import React from "react";
import { RatingContainer, RatingGrade } from "./styled";

export default class Rating extends React.Component {
  render() {
    const { totalOfStars, rating, smallStars, textColor, whiteStar } = this.props;
    let star;
    
    return (
      <RatingContainer smallStars={smallStars} whiteStar={whiteStar}>
        {this.props.showReviewGrade ? (
          <RatingGrade textColor={textColor}>{rating}</RatingGrade>
        ) : null}
        {totalOfStars.map(index => {
          const splittedRating = rating.toString().split('');
          let firstNumber = splittedRating[0];
          const decimalNumber = splittedRating[splittedRating.length - 1];
          
          if (decimalNumber >= 5){
            firstNumber = firstNumber + 1;
          }

          if (decimalNumber < 5){
            firstNumber = firstNumber;
          }

          let isFilled = index <= firstNumber ? true : false;
          isFilled
            ? (star =  (
              <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 426.667 426.667"
                >
                  <polygon
                    style={{ fill: "#FFA800" }}
                    points="213.333,10.441 279.249,144.017 426.667,165.436 320,269.41 345.173,416.226 213.333,346.91 
                       81.485,416.226 106.667,269.41 0,165.436 147.409,144.017 "
                  />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                </svg>
            ))
            : (star = (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 426.667 426.667"
                >
                  <polygon
                    style={{ fill: "#D0D0D0" }}
                    points="213.333,10.441 279.249,144.017 426.667,165.436 320,269.41 345.173,416.226 213.333,346.91 
	81.485,416.226 106.667,269.41 0,165.436 147.409,144.017 "
                  />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                </svg>
              ));
          return star;
        })}
      </RatingContainer>
    );
  }
}
