import React from "react";
import ReactSVG from 'react-svg';
import { Code, IconContainer } from "../styled";
import Module from "../../../Module/Module";
import viewIcon from '../../../../Assets/icons/view.svg';
import { HOME_BEACON_S3_URL } from "../../../../config";

export default class CopyCodeButton extends React.Component {
  state = {
    copied: false,
    token: localStorage.getItem("store_key")
  };

  handleClick = widgetType => {
    const el = document.createElement("textarea");
    const { token } = this.state;

    if (widgetType === "home-beacon") {
      el.value = `
          <script>
          (function() {
    
            var head = document.querySelector('head');
    
            var cdnGoogleFonts = document.createElement("link");
            cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
            cdnGoogleFonts.setAttribute("type", "text/css"),
            cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
    
            head.appendChild(cdnGoogleFonts);
    
            var e = document.createElement("div");
            e.setAttribute("id", "review-alert-home-beacon-entrypoint"), e.setAttribute("data-review-alert-home-beacon-token", "${token}"), document.body.appendChild(e);
            var t = document.createElement("script");
            t.type = "text/javascript", t.src = "${HOME_BEACON_S3_URL}", document.getElementsByTagName("head")[0].appendChild(t)
         })()
         </script>`;
    }

    if (widgetType === "home-horizontal") {
      el.value = `
      <script>
          (function() {
            var head = document.querySelector('head');
           
             var cdn1 = document.createElement("link");
             cdn1.setAttribute("rel", "stylesheet"), 
             cdn1.setAttribute("type", "text/css"),
             cdn1.setAttribute("charset", "UTF-8")
             cdn1.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"),
     
             head.appendChild(cdn1);
     
             var cdn2 = document.createElement("link");
             cdn2.setAttribute("rel", "stylesheet"), 
             cdn2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"),
     
             head.appendChild(cdn2);
     
             var cdnGoogleFonts = document.createElement("link");
             cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
             cdnGoogleFonts.setAttribute("type", "text/css"),
             cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
     
             head.appendChild(cdnGoogleFonts);
     
             var e = document.createElement("div");
             e.setAttribute("id", "review-alert-home-horizontal-entrypoint"), e.setAttribute("data-review-alert-home-horizontal-token", "${token}");
             var raContainer = document.getElementById("ra_beacon_container");
             if (raContainer) 
                     raContainer.appendChild(e);
             else
                    document.body.appendChild(e);
             var t = document.createElement("script");
             t.type = "text/javascript", t.src = "https://reviewalert-widget.s3.amazonaws.com/home-horizontal/static/js/main.js", document.getElementsByTagName("head")[0].appendChild(t)
          })()
          </script>`;
    }

    if (widgetType === "product-catalog-horizontal") {
      el.value = `
        <script>
          (function() {
            var head = document.querySelector('head');
    
            var cdn1 = document.createElement("link");
            cdn1.setAttribute("rel", "stylesheet"), 
            cdn1.setAttribute("type", "text/css"),
            cdn1.setAttribute("charset", "UTF-8")
            cdn1.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"),
    
            head.appendChild(cdn1);
    
            var cdn2 = document.createElement("link");
            cdn2.setAttribute("rel", "stylesheet"), 
            cdn2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"),
    
            head.appendChild(cdn2);
    
            var cdnGoogleFonts = document.createElement("link");
            cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
            cdnGoogleFonts.setAttribute("type", "text/css"),
            cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
    
            head.appendChild(cdnGoogleFonts);
    
            var e = document.createElement("div");
            e.setAttribute("id", "review-alert-product-catalog-horizontal-entrypoint"), e.setAttribute("data-review-alert-product-catalog-horizontal-token", "${token}");
            var raContainer = document.getElementById("ra_beacon_container");
            if (raContainer)
                 raContainer.appendChild(e);
            else
                 document.body.appendChild(e);
    
            var t = document.createElement("script");
            t.type = "text/javascript", t.src = "https://reviewalert-widget.s3.amazonaws.com/product-catalog-horizontal/static/js/main.js", document.getElementsByTagName("head")[0].appendChild(t)
         })()
         </script>`;
    }

    if (widgetType === "product-catalog-banner") {
      el.value = `
      <script>
          (function() {
            var head = document.querySelector('head');
    
            var cdn1 = document.createElement("link");
            cdn1.setAttribute("rel", "stylesheet"), 
            cdn1.setAttribute("type", "text/css"),
            cdn1.setAttribute("charset", "UTF-8")
            cdn1.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"),
    
            head.appendChild(cdn1);
    
            var cdn2 = document.createElement("link");
            cdn2.setAttribute("rel", "stylesheet"), 
            cdn2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"),
    
            head.appendChild(cdn2);
    
            var cdnGoogleFonts = document.createElement("link");
            cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
            cdnGoogleFonts.setAttribute("type", "text/css"),
            cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
    
            head.appendChild(cdnGoogleFonts);
    
            var e = document.createElement("div");
            e.setAttribute("id", "review-alert-product-catalog-banner-entrypoint"), e.setAttribute("data-review-alert-product-catalog-banner-token", "${token}");
            var raContainer = document.getElementById("ra_beacon_container_banner");
            if (raContainer)
                      raContainer.appendChild(e);
            else
                     document.body.appendChild(e);
            var t = document.createElement("script");
            t.type = "text/javascript", t.src = "https://reviewalert-widget.s3.amazonaws.com/product-catalog-banner/static/js/main.js", document.getElementsByTagName("head")[0].appendChild(t)
         })()
         </script>`;
    }

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    this.setState({ copied: true }, () => {
      this.handleReset();
    });
  };

  handleReset = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  render() {

    const { widgetType, widget, onMouseOver, onMouseOut} = this.props;

    return (
      <React.Fragment>
        <Code style={this.props.style}>
          <Module className="copy-code-module" >
            <IconContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
             <ReactSVG src={viewIcon} />
            </IconContainer>
            <div className="widget-info-container">
              <label>{widget}</label>
              <a onClick={() => this.handleClick(widgetType)}>
                {this.state.copied ? "Copied" : "Copy Code"}
              </a>
            </div>
          </Module>
        </Code>
      </React.Fragment>
    );
  }
}
