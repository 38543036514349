import React from "react";
import ReactSVG from "react-svg";
import { ButtonContainer, Text } from "./styled";
import { magentaBright } from "ansi-colors";
import loadingGif from '../../Assets/images/loading.gif';
import InlineLoading from "../InlineLoading/InlineLoading";

export default class Button extends React.Component {

  render() {
    const {
      kind,
      text,
      iconPath,
      hasIcon,
      borderRadius,
      bgColor,
      color,
      onlyIcon,
      onClick,
      hideTextOnMobile,
      bgColorOnHover,
      uppercase,
      className,
      iconColor,
      hasShadow,
      transparent,
      style,
      loading,
      width,
      height,
      flex,
      mr,
      mt,
      iconWidth,
      customBorder,
      iconHeight,
      colorOnHover,
      disabled,
      padding,
      widthOnMobile,
      fontSize,
      integrated,
      childClass,
    } = this.props;

    return (
      <ButtonContainer
        kind={kind}
        onlyIcon={onlyIcon}
        borderRadius={borderRadius}
        bgColor={bgColor}
        color={color}
        onClick={onClick}
        bgColorOnHover={bgColorOnHover}
        uppercase={uppercase}
        className={className}
        transparent={transparent}
        colorOnHover={colorOnHover}
        childClass={childClass}
        iconColor={iconColor}
        hideTextOnMobile={hideTextOnMobile}
        mt={mt}
        mr={mr}
        hasShadow={hasShadow}
        customBorder={customBorder}
        style={style}
        flex={flex}
        iconWidth={iconWidth}
        width={width}
        widthOnMobile={widthOnMobile}
        height={height}
        iconHeight={iconHeight}
        disabled={disabled}
        fontSize={fontSize}
        padding={padding}
        integrated={integrated}
      >
      {integrated ? (
        <React.Fragment>
          {hasIcon && <ReactSVG className={childClass} src={iconPath} />}
          <Text 
          text={text}
          hasIcon={hasIcon} 
          className={childClass} 
          hideTextOnMobile={hideTextOnMobile}>
            Integrated successfully
          </Text>
        </React.Fragment>
      ) : loading ? (
        <InlineLoading />
      ) : (
        <React.Fragment>
        {hasIcon && <ReactSVG className={childClass} src={iconPath} />}
        <Text 
        hasIcon={hasIcon} 
        text={text}
        className={childClass} 
        hideTextOnMobile={hideTextOnMobile}>
          {text}
        </Text>
        </React.Fragment>
      )}
        
      </ButtonContainer>
    );
  }
}
