import styled from 'styled-components';

export const UpdateEmployeeLocationContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    svg{
        width: 7px;
        margin-right:1em;
    }

    label{
        font-size:16px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        max-width: 70%;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #00000090;
    top: 0;
    left: 0;
    z-index: 100;
`;