import React from "react";
import ReactSVG from 'react-svg';
import {
  LocationRateOverviewContainer,
  HeroContainer,
  Row,
  Column,
  TotalReviews,
  Wrapper,
  TeamMembers,
  RatingsContainer,
  AverageRating,
  LocationName,
  LocationAddress
} from "./styled";
import Rating from "../../Rating/Rating"
import RatingOverview from "./RatingOverview/RatingOverview";
import ratingIcon from '../../../Assets/icons/rating.svg';
import teamIcon from '../../../Assets/icons/team.svg';
import mask from '../../../Assets/images/mask.png';

export default class LocationRateOverview extends React.Component {
  render() {

    const { bestLocation } = this.props;
    return (
      <LocationRateOverviewContainer>
        <Wrapper>
          <HeroContainer backgroundImage={mask}>
            <AverageRating>
              <span>{bestLocation.rating.toFixed(1)}</span>
              <ReactSVG src={ratingIcon} />
            </AverageRating>
            <Row>
              <LocationName>{bestLocation.name || ""}</LocationName>
            </Row>
            {/* <Row>
              <LocationAddress>8320 Crystal View Rd, Eden Praire,<br/>Eden Praire, 55344</LocationAddress>
            </Row> */}
          </HeroContainer>
        </Wrapper>
        <Row style={{ padding: "1em 2em", boxSizing: "border-box" }} between>
          <Column>
            <Rating
              totalOfStars={[1, 2, 3, 4, 5]}
              rating={bestLocation.rating.toFixed(1) || 0.0}
              showReviewGrade={false}
            />
            <TotalReviews>
              <span>{bestLocation.reviews || 0.0}</span> reviews
            </TotalReviews>
          </Column>
          <Row>
            <ReactSVG src={teamIcon} />
            <TeamMembers>{bestLocation.total_employees}</TeamMembers>
          </Row>
        </Row>
        <RatingsContainer>
          <RatingOverview type="positive" amount={bestLocation.positive} />  
          <RatingOverview type="neutral" amount={bestLocation.neutral} />
          <RatingOverview type="negative" amount={bestLocation.negative} />
        </RatingsContainer>
      </LocationRateOverviewContainer>
    );
  }
}
