import React from 'react';
import Item from './Item/Item';
import { AccordionContainer } from './styled';

export default class Accordion extends React.Component{

    state = {
        active: false,
        currentQuestion: ""
    }

    handleClick = (questionId) => {
        this.setState({currentQuestion: questionId })

        if (this.state.currentQuestion === questionId){
            this.setState({ currentQuestion: "" })
        }
    }
    
    render(){

        const { currentQuestion } = this.state;
        const { questions, contents } = this.props;

        return(
            <AccordionContainer>
                {questions.map(question => {                   
                    return (
                    <Item 
                     onClick={() => this.handleClick(question.id)}
                     active={currentQuestion === question.id ? true : false}
                     question={question.question}>
                      {contents.filter(content => content.id === question.id).map(content => {
                          return content.component
                      })}
                    </Item>
                    )
                })}
            </AccordionContainer>
        )
    }
}