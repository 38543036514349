import React from "react";
import { Redirect } from 'react-router-dom';
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import { PageWrapper } from "../../Assets/Structure/Structure";
import RenderContent from "./RenderContent/RenderContent";
import { ReviewsWrapper } from "./styled";

export default class Reviews extends React.Component {

  state = {
    device: "",
    redirectUrl: "/store-location",
    loading: true
  }
  

  componentDidMount(){
    window.scrollTo(0,0);
    let screenWidth = window.innerWidth;

    if (screenWidth < 500){
      this.setState({ device: "mobile" })
    }

    if (screenWidth < 1050 && screenWidth > 500){
      this.setState({ device: "tablet" });
    }

    if (screenWidth > 1050){
      this.setState({ device: "desktop" })
    }
  }

  handleRouteProtection = (redirectUrl) => {
    this.setState({ routeNotAllowed: true, redirectUrl })
  }

  handleValidateWidgetOnlyLoading = loading => this.setState({ loading })


  render() {
    if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl} />

    return (
      <ValidateWidgetOnly protectRoute={this.handleRouteProtection} onLoading={this.handleValidateWidgetOnlyLoading}>
      <PageWrapper>
        <ReviewsWrapper>
          <RenderContent device={this.state.device} loading={this.state.loading} />
        </ReviewsWrapper>
      </PageWrapper>
      </ValidateWidgetOnly>
    );
  }
}
