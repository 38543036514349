import React from 'react';
import { OrderByOptionsContainer } from './styled';
import Options from './Options/Options';

export default class OrderByOptions extends React.Component{

    handleSelectedOption = (option) => {
        this.props.selectedOrderByOption(option)
    }

    render(){
        return (
            <OrderByOptionsContainer>
                <Options
                options={[
                    { label: "Highest CTR", value: "ctr" }
                ]}
                selectedOrderByOption={this.handleSelectedOption}
                 />
            </OrderByOptionsContainer>
        )
    }
}