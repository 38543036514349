import React from 'react';
import { MultipleInvitationsContainer } from './styled';
import Invitation from './Invitation/Invitation';

export default class MultipleInvitation extends React.Component{

    handleDeleteInvitation = (index) => this.props.invitationToBeDeleted(index);

    handleUpdateInvitation = (index) => this.props.invitationToBeUpdated(index);

    render(){
        return(
            <MultipleInvitationsContainer>
                {this.props.invitations.map((invitation, index ) => {
                    return (
                    <Invitation 
                    key={index}
                        invitation={invitation}
                        editInvitation={() => this.handleUpdateInvitation(index)} 
                        deleteInvitation={() => this.handleDeleteInvitation(index)} />
                    )
                })}
            </MultipleInvitationsContainer>
        )
    }
}