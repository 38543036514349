import styled from "styled-components"

import { blueDefault } from "../../../Assets/colorPallet/colorPallet" 

export const EditCampaignWrapper = styled.div`  
  .edit-icon {
    background: transparent;
    border: 0;
    padding: 0;

    svg {
      cursor: pointer;
      transition: opacity 0.4s ease;
      opacity: ${props =>
        props.device === "desktop" && props.showDeleteAction
          ? "1"
          : props.device === "desktop" && !props.showDeleteAction
          ? "0"
          : "1"};
  
      path {
        fill: #333;
        transition: fill 0.4s ease;
      }
  
      &:hover {
        path {
          fill: ${blueDefault};
        }
      }
    }
  }
`