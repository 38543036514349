import React from 'react'
import ReactSVG from 'react-svg'
import { Link } from "react-router-dom"
import axios from 'axios'
import { connect } from 'react-redux'
import {
    setCurrentChat,
    updateChatGroupsMenu,
} from '../../store/modules/webchat/actions'
import { ChatInputContainer, InputForm, Input } from './styled'
import { Container, Label } from '../../Assets/Structure/Structure'
import Button from '../Button/Button'
import { WEBCHAT_API_URL, API_URL } from '../../config'
import sendReviewRequestIcon from '../../Assets/icons/send-review-request.svg'

//Icons
import videoIcon from "../../Assets/icons/video.svg"
import CreateVideoRoomDialog from "../../Containers/Webchat/VideoRoom/VideoRoom";
import VideoRoom from "../../Containers/Webchat/VideoRoom/VideoRoom";

// Store action
import { createVideoRoomName } from "../../store/modules/videoChat/actions"


class ChatInput extends React.Component{

    state = {
        message: "",
        currentChat: {},
        loading: false,
        expandTextarea: false,
        showCreateVideoRoomDialog: false
    }

    componentWillReceiveProps(props){
        if (props.sendReviewRequestOnChat) {
            this.sendReviewRequest(new Event("click"))
        }
    }

    handleSubmitOnEnter = e => {
        if (e.key === "Enter") this.submitMessage(e)
    }

    submitMessage = (e) => {
        e.preventDefault()
        const storeId = localStorage.getItem('store');
        const { message } = this.state;
        const { chatCredentials, currentChat} = this.props;

        const headers = {
            headers: {
                "x-auth-token": chatCredentials.twilioAuthToken ,
                "x-account-sid": chatCredentials.accountSid,
                "x-sas-auth": localStorage.getItem("token")
            }
        }

        const payload = {
            sentTo: currentChat.clientNumber ? currentChat.clientNumber : currentChat.sentFrom,
            text: message,
            author: "store",
            clientNumber: currentChat.clientNumber ? currentChat.clientNumber : currentChat.sentFrom,
            clientName: currentChat.clientName ? currentChat.clientName : currentChat.author,
            createdAt: new Date().toISOString(),
            storeName: localStorage.getItem('store_name'),
            locationName: currentChat.locationName,
            staffName: localStorage.getItem("user_name")
        }

        this.props.newMessageOptmistic(payload)

        let optmisticChatGroupUpdate = currentChat
        optmisticChatGroupUpdate.lastMessage = message
        optmisticChatGroupUpdate.createdAt = payload.createdAt

        this.props.updateCurrentChat(optmisticChatGroupUpdate)

        this.props.setChatGroupsMenuUpdate(true)
        this.resetChatGroupMenuUpdateState()

        this.setState({ message: "", expandTextarea: false })

        axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/locations/${currentChat.locationId}/messages`, payload, headers)
    }


    handleChange = e => {
        this.setState({ message: e.target.value })
    }

    resetChatGroupMenuUpdateState = () => {
        setTimeout(() => {
            this.props.setChatGroupsMenuUpdate(false)
        }, 1000)
    }

    sendReviewRequest = (e) => {
            const storeId = localStorage.getItem("store")
            const authToken = localStorage.getItem("token")
            const headers = { headers: { "x-sas-Auth": authToken } }

            const { currentChat } = this.props

            const payload = { phone: parseInt(currentChat.clientNumber), name: currentChat.author }

            axios.post(`${API_URL}/private/stores/${storeId}/locations/${currentChat.locationId}/review_requests`, payload, headers)
            .then(() => {
                this.setState({ message: `Hey ${currentChat.author}, we have just sent you a review request.` }, () => {
                    this.submitMessage(e)
                })
            })
    }

    handleSendVideoChatLinkToClient = async (e) => {
        const { currentChat } = this.props
        const { clientName } = currentChat
        const storeName = localStorage.getItem('store_name')

        const videoRoom = await this.props.createVideoRoom(storeName)

        const rootPath = window.location.origin
        let videoChatClientLink = `${rootPath}/video-chat?participantName=${clientName}&videoRoomName=${storeName}&videoRoomId=${videoRoom.id}`
        videoChatClientLink = videoChatClientLink.split(/\s+/).join('%20')

        this.setState({
            message: `Please click in the following video chat link: ${videoChatClientLink} and enter the access code "${videoRoom.password}" to join the room.`
        }, () => {
            this.submitMessage(e)

        })
    }


    render(){
        const { expandTextarea } = this.state

        const storeName = localStorage.getItem('store_name')
        const userName = localStorage.getItem("user_name") + ' - ' + storeName
        const storeKey = localStorage.getItem(("store_key"))


        return(
            <Container>
                <ChatInputContainer expand={this.state.expandTextarea}>
                    <InputForm expand={this.state.expandTextarea} onKeyPress={this.handleSubmitOnEnter}>
                        <Input
                            expand={this.state.expandTextarea}
                        maxLength={1600}
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={`Answer ${this.props.currentChat.author}...`} />
                        <Container flex alignCenter>
                            {/*<Container>*/}
                            {/*    <Link to={`/video-chat?participantName=${userName}&videoRoomName=${storeName}&storeKey=${storeKey}`} target="_blank"><Button*/}
                            {/*        bgColor="white"*/}
                            {/*        bgColorOnHover="#f8f8f8"*/}
                            {/*        borderRadius="50%"*/}
                            {/*        width="38px"*/}
                            {/*        height="38px"*/}
                            {/*        customBorder="1px solid #D0D0D0;"*/}
                            {/*        padding="0"*/}
                            {/*        hasIcon*/}
                            {/*        onlyIcon*/}
                            {/*        iconPath={videoIcon}*/}
                            {/*        onClick={this.handleSendVideoChatLinkToClient}/></Link>*/}
                            {/*</Container>*/}
                            <Container ml="10px">
                                <Button
                                type="submit"
                                style={{
                                    position: expandTextarea ? "absolute" : null,
                                    right: expandTextarea ? 0 : null,
                                    bottom: expandTextarea ? 0 : null,
                                    margin: expandTextarea ? "0 26px 20px" : null
                                }}
                                onClick={this.submitMessage}
                                height="38px"
                                width="100px"
                                flex
                                padding="0"
                                borderRadius="20em"
                                color="white"
                                text="Send"/>
                            </Container>
                        </Container>
                    </InputForm>
                </ChatInputContainer>
                <Container
                hideOnMobile
                pl="10px"
                height="50px"
                flex
                cursor="pointer"
                alignCenter
                onClick={this.sendReviewRequest}>
                    <ReactSVG src={sendReviewRequestIcon} />
                    <Container pl="10px" flex alignCenter>
                        <Label bold pointer color="#666">Send review invitation</Label>
                    </Container>
                    <Container pl="5px" flex alignCenter>
                        <Label pointer color="#666">to {this.props.currentChat.author} after ending the chat conversation</Label>
                    </Container>
                </Container>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    chatCredentials: state.webchat.chatCredentials,
    currentChat: state.webchat.currentChat,
    sendReviewRequestOnChat: state.webchat.sendReviewRequestOnChat,
    currentVideoRoom: state.videoChat.currentVideoRoom
})


const mapDispatchToProps = dispatch => ({
    updateCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },

    setChatGroupsMenuUpdate(update){
        dispatch(updateChatGroupsMenu(update))
    },

    setSendReviewRequestOnChat(sendReviewRequestOnChat){
        dispatch(this.setSendReviewRequestOnChat(sendReviewRequestOnChat))
    },

    async createVideoRoom(videoRoomName) {
        const videoRoom = await dispatch(createVideoRoomName(videoRoomName))
        return videoRoom
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput);
