import styled from "styled-components";

export const MenuContainer = styled.div`
  padding: 2em 0;
  overflow: auto;
  box-sizing: border-box;
  max-height: calc(100vh - 91px);

  &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }
`;

export const Label = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #999999;
  padding: 0 30px;
`;

export const DataAnalysisContainer = styled.div``;

export const ManagementContainer = styled.div`
  margin-top: 1em;
`;

export const FaqContainer = styled.div`
  margin: 1em 0;
  box-sizing: border-box;

  hr {
    border-top: 1px solid #e7e7e7;
    border-bottom: none;
    width: 100%;
    max-width: calc(100% - 60px);
  }
`;
