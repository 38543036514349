import React from 'react';
import { ChatMessagesContainer } from './styled';
import ChatMessage from '../../../Components/ChatMessage/ChatMessage';

export default class ChatMessages extends React.Component{

    componentDidMount(){
        const chatBody = document.getElementById("chat-messages-body");
        chatBody.scrollTop = chatBody.clientHeight;
    }


    componentDidUpdate(){
        const chatBody = document.getElementById("chat-messages-body");
        chatBody.scrollTop = chatBody.scrollHeight;
    }

    render(){
        
        return(
            <ChatMessagesContainer id="chat-messages-body">
                 {this.props.messages.map((message, index) => {
                    return (
                        <ChatMessage 
                         key={index}
                         message={message} />
                    )
                })} 
            </ChatMessagesContainer>
        )
    }
}