// MUTATIONS
import { SET_INTEGRATION_ISSUE_REPORT, SET_INTEGRATION_ISSUE_STATUS } from "./mutations"

const initialState = {
    integrationIssueStatus: false,
    integrationIssueReport: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_INTEGRATION_ISSUE_REPORT:
            return { ...state, integrationIssueReport: action.payload }
            break

        case SET_INTEGRATION_ISSUE_STATUS:
            return { ...state, integrationIssueStatus: action.payload }
            break

        default:
            return state
    }
}

export default reducer
