import styled from "styled-components";

export const FilterHeaderContainer = styled.div`
    padding: 1em;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;

  .row-top-txt {
    display: flex;

    svg {
      margin-right: 0.5em;
    }

    label {
      font-size: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #333333;
    }
  }

`;


export const ResetFilterBtn = styled.button`
   background-color: transparent;
    border: none;
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #025F88;
`;