import styled from 'styled-components';

export const Text = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100% !important;

    span{
        margin-left: 1em;
    }
`;

export const RowContainer = styled.div`
    display: flex;
`;