import React from "react";
import ReactSVG from "react-svg";
import { Row, LeftContainer, RightContainer } from "../styled";
import Bar from "../Bar/Bar";
import { ReviewTypeContainer, TotalClicks, Label } from "./styled";
import Rating from "../../../Components/Rating/Rating";
import google from "../../../Assets/icons/google.svg";
import facebook from "../../../Assets/icons/facebook.svg";

export default class ReviewType extends React.Component {

  render() {
    const { bgColor, percentage, type, rating, redirectsToFacebook, redirectsToGoogle } = this.props;


    return (
      <ReviewTypeContainer>
        <Row between alignCenter>
          <LeftContainer>
            {type === "Google" ? (
              <ReactSVG src={google} />
            ) : type === "Facebook" ? (
              <ReactSVG src={facebook} />
            ) : null}
          </LeftContainer>
          <RightContainer>
            <Rating
              totalOfStars={[1, 2, 3, 4, 5]}
              rating={rating}
              showReviewGrade
            />
          </RightContainer>
        </Row>
        <Row between style={{ marginTop: ".5em" }}>
          <Label>Clicked</Label>
          <TotalClicks>
            {type === "Google"
           
              ? redirectsToGoogle 
              : type === "Facebook"
              ? redirectsToFacebook 
              : null}
          </TotalClicks>
        </Row>
        <Row className="bar">
          <Bar bgColor={bgColor} percentage={percentage} />
        </Row>
      </ReviewTypeContainer>
    );
  }
}
