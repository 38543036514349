import React from "react";
import ReactSVG from 'react-svg';
import { PieChart, Pie, Cell } from "recharts";
import { CircleGraphicContainer, BackgroundCircle } from "./styled";
import { PlatformLogoContainer } from "../styled";
import google from '../../../../Assets/icons/google.svg';
import facebook from '../../../../Assets/icons/facebook.svg';

export default class CircleGraphic extends React.Component {
  render() {
    return (
      <CircleGraphicContainer platform={this.props.platform}>
        <BackgroundCircle>
          <label>{this.props.percentageOfReviewsByPlatform}%</label>
        </BackgroundCircle>
        <PieChart width={400} height={400} onMouseEnter={this.onPieEnter}>
          <Pie
            data={this.props.data}
            cx={120}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill={this.props.color}
            paddingAngle={5}
          >
            {this.props.data.map((entry, index) => (
              <Cell
                stroke={false}
                fill={this.props.color[index % this.props.color.length]}
              />
            ))}
          </Pie>
        </PieChart>
        <PlatformLogoContainer>
          {this.props.platform === "Google" ? <ReactSVG src={google} />
          : this.props.platform === "Facebook" ? <ReactSVG src={facebook} />
          : null}
        </PlatformLogoContainer>
      </CircleGraphicContainer>
    );
  }
}
