import styled from "styled-components"

export const CreateMarketingCampaignDialogContainer = styled.div`  
  @media only screen and (max-width: 600px) {
    padding: 24px 0 0;

    .compose-message-dialog--left-column{
      margin-right: 0 !important;
    }
    
    .create-marketing-campaign-dialog--close-container{
      box-sizing: border-box;
      left:0;
      top: 0;
    }
    
    .create-marketing-campaign-dialog--body-container{
      flex-direction: column;
      
      &__left-container{
        max-width: 100%;
      }
      
      &__right-container{
        max-width: 100%;
        &--message-container{
          margin-top: 0;
        }
      }
    }
  }
`;


export const CloseButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  
  &:hover{
    background-color: #eee;
  }
`;
