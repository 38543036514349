import styled from "styled-components";

export const LastReviewsContainer = styled.div`
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #eeeeee;
  margin-top: ${props => (props.noLabel ? "0" : "1em")};
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
`;

export const Label = styled.p`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;

  @media only screen and (max-width: 500px){
    padding: 0 1em;
  }
`;

export const Container = styled.div`
  margin-top: 2em;
`;

export const SkeletonContainer = styled.div`
  display: grid;
  grid-row-gap: 1em;
`;
