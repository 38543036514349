import {
    SET_CURRENT_VIDEO_MESSAGES,
    SET_CURRENT_VIDEO_ROOM
} from "./mutations";
import axios from "axios";
import {WEBCHAT_API_URL} from "../../../config";
import store from "../../index";
import VideoMessage from "../../../models/VideoMessage";


// VIDEO CHAT
export const createVideoRoomName = (videoRoomName) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const payload = { videoRoomName }
        const response = await axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/videoChat/videoRooms`, payload)

        const videoRoom = response.data
        dispatch({ type: SET_CURRENT_VIDEO_ROOM, payload: videoRoom })
        return videoRoom
    } catch (error) {
        throw new Error(error)
    }
}

export const generateVideoChatAccessToken = (videoRoomName, participantName) => async (dispatch) => {
    try {
        const payload = { videoRoomName, participantName }
        const response = await axios.post(`${WEBCHAT_API_URL}/videoChat/accessToken`, payload)

        const accessToken = response.data.accessToken

        return accessToken
    } catch (error) {
        throw new Error(error)
    }
}

export const validateAccessCode = (videoRoomId, accessCode) => async (dispatch) => {
    try {
        const payload = { accessCode }
        const response = await axios.post(`${WEBCHAT_API_URL}/videoChat/videoRooms/${videoRoomId}/accessCode`, payload)
        const accessToken = response.data

        if (accessToken) {
            return true
        }

        return false
    } catch (error) {
        if (error.response.status === 404) {
            return false
        }
    }
}

export const fetchVideoMessagesByVideoRoomId = ({ videoRoomId }) => async (dispatch) => {
    try {
        const response = await axios.get(`${WEBCHAT_API_URL}/videoChat/videoRooms/${videoRoomId}/videoMessages`)
        const currentVideoMessages = response.data.map((videoMessage) => {
            return new VideoMessage({
                id: videoMessage.id,
                videoRoomId: videoMessage.videoRoomId,
                message: videoMessage.message,
                author: videoMessage.author,
                createdAt: videoMessage.createdAt
            })
        })

        dispatch({ type: SET_CURRENT_VIDEO_MESSAGES, payload: currentVideoMessages })

        return currentVideoMessages
    } catch (error) {
        throw new Error(error)
    }
}

export const sendVideoMessage = ({ videoRoomId, message, author }) => async (dispatch) => {
    try {
        const payload = { message, author }
        const response = await axios.post(
            `${WEBCHAT_API_URL}/videoChat/videoRooms/${videoRoomId}/videoMessages`,
            payload
        )

        const videoMessage = new VideoMessage({
            id: response.data.id,
            videoRoomId: response.data.videoRoomId,
            message: response.data.message,
            author: response.data.author,
            createdAt: response.data.createdAt
        })

        const { currentVideoMessages } = store.getState().webchat
        currentVideoMessages.push(videoMessage)

        dispatch({ type: SET_CURRENT_VIDEO_MESSAGES, payload: currentVideoMessages })

        return videoMessage
    } catch (error) {
        throw new Error(error)
    }
}

export const sendClientVideoRoomLink = ({ storeId, videoRoomId, clientPhoneNumber, clientVideoRoomLink }) => async (dispatch) => {
    try {
        const chatCredentials = store.getState().webchat.chatCredentials

        const headers = {
            headers: {
                "x-auth-token": chatCredentials.twilioAuthToken ,
                "x-account-sid": chatCredentials.accountSid
            }
        }

        const payload = {
            clientVideoRoomLink,
            clientPhoneNumber: `1${clientPhoneNumber}`,
            storeName: localStorage.getItem("store_name")
        }

        const response = await axios.post(
            `${WEBCHAT_API_URL}/stores/${storeId}/videoChat/videoRooms/${videoRoomId}/sendClientVideoLink`,
            payload,
            headers
        )

        return response.data
    } catch (error) {
        throw new Error(error)
    }
}
