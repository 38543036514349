import React from "react";
import { RadioButtonContainer } from "./styled";
import RadioItem from "./RadioItem/RadioItem";

export default class RadioButton extends React.Component {

  state = {
    selectedOption: ""
  }

  handleClick = (selectedOption) => {
    this.setState({ selectedOption})
    this.props.selectedOption(selectedOption);
  }

  render() {

    const { options, className } = this.props;

    return (
      <RadioButtonContainer className={className}>
        {options.map(option => {
          return <RadioItem 
          option={option} 
          onClick={() => this.handleClick(option)}
          isActive={this.state.selectedOption.value === option.value}
          />;
        })}
      </RadioButtonContainer>
    );
  }
}
