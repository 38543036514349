import styled from "styled-components";
import { blueDefault } from '../../../../../Assets/colorPallet/colorPallet';

export const InvitationContainer = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1em;
  box-sizing: border-box;

  svg {
    cursor: pointer;
    path {
      fill: #dadada;
      transition: fill 0.4s ease;
    }
    &:hover {
      path {
        fill: ${blueDefault};
      }
    }
  }
`;

export const CustomerName = styled.h4`
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #333333;
  margin: 0;
  font-weight: 400;
`;

export const CustomerEmail = styled.h5`
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #999999;
  margin: 0.5em 0 0 0;
  font-weight: 400;
`;
