import React from 'react'
import Skeleton from 'react-skeleton-loader';
import { WidgetPictureSkeletonContainer } from './styled'


export default function WidgetPictureSkeleton() {
    return (
        <WidgetPictureSkeletonContainer>
            <Skeleton />
        </WidgetPictureSkeletonContainer>
    )
}