import styled from "styled-components";
import { blueDefault, red } from '../../../../Assets/colorPallet/colorPallet';

export const Form = styled.form`
  width: 100%;
  margin: 1em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  max-width: calc(100% - 2em);
  background-color: white;
  padding: 2em;
  box-sizing: border-box;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props =>
    props.mt05 ? ".5em" : props.mt1 ? "1em" : props.mt2 ? "2em" : null};
`;

export const Label = styled.label``;

export const Input = styled.input`
  margin-top: 0.5em;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #b5b5b5;
  padding: 1em 0;
  color: #333;
  outline: none;
  font-size: 20px;

  &::placeholder {
    color: #d0d0d0;
  }
`;

export const AddMultipleInvitationsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 0.5em;
    fill: ${props => props.active ? blueDefault : "rgba(153, 153, 153, 0.5)"};

    path{
      fill: ${props => props.active ? blueDefault : "rgba(153, 153, 153, 0.5)"};
    }
  }

  label {
    font-size: 16px;
    letter-spacing: 0.1em;
    color: ${props => props.active ? blueDefault : "rgba(153, 153, 153, 0.5)"};
    cursor: pointer;
  }
`;

export const Alert = styled.label`
    margin: .5em 0;
    font-size: 12px;
    letter-spacing: .05em;
    color: ${red}; 
`;