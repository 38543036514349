import React from "react";
import ReactSVG from "react-svg";
import { SelectFluidContainer, SelectAction, Label, ArrowContainer } from "./styled";
import SelectOptions from "./SelectOptions/SelectOptions";
import arrowIcon from "../../Assets/icons/arrow.svg";

export default class SelectFluid extends React.Component {
  state = {
    isSelectOn: false,
    option: [],
    width: "",
    loading: this.props.loading
  };

  componentDidMount(){
    const selectFluidElement = document.getElementById("select-fluid-action");
    const width = selectFluidElement.clientWidth;
    this.setState({ width, loading: this.props.loading, options: this.props.options })
  }

  componentWillReceiveProps(props){
    this.setState({ loading: props.loading, options: props.options })
  }

  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById("select-fluid");
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ isSelectOn: false });
    });
  };

  handleClick = isSelectOn => {
    if (isSelectOn === "toggle"){
      this.setState(state => ({
        isSelectOn: !state.isSelectOn
      }))
    } else {
      this.setState({ isSelectOn })
    }

    this.detectClick();
  };

  handleSelect = option => {
    this.setState({ option });
    this.props.selectedOption(option);
  };

  render() {
    const {
      placeholder,
      className,
      shadow,
      absolute,
      invertOrder,
      noBorder,
      maxWidth,
      justify,
      showFullName,
      avatar
    } = this.props;


    return (

      <SelectFluidContainer
       maxWidth={maxWidth}
       absolute={absolute}
       className={className} id="select-fluid">
        <SelectAction
          onClick={() => this.handleClick("toggle")}
          noBorder={noBorder}
          justify={justify}
          id="select-fluid-action"
        >

            <React.Fragment>
              <Label showFullName maxWidth={showFullName ? "100%" : "8em"} invertOrder={invertOrder}>{placeholder}</Label>
              <ArrowContainer isSelectOn={this.state.isSelectOn}>
                <ReactSVG src={arrowIcon} />
              </ArrowContainer>
            </React.Fragment>

        </SelectAction>
        {this.state.isSelectOn && (
          <SelectOptions
            options={this.state.options}
            loading={this.state.loading}
            maxWidth={maxWidth}
            avatar={avatar}
            handleClose={this.handleClick}
            optionSelected={this.handleSelect}
            shadow={shadow}
            width={this.state.width}
            absolute={absolute}
          />
        )}
      </SelectFluidContainer>
    );
  }
}
