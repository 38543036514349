import React from "react";
import axios from "axios";
import InlineLoading from '../../../../Components/InlineLoading/InlineLoading';
import { Overlay, ConfirmationContainer, ActionContainer } from "./styled";
import { API_URL } from "../../../../config";

export default class Confirmation extends React.Component {
  state = {
    upContainer: false,
    loading: false
  };

  componentDidMount() {
    const confirmation = document.getElementById("confirmation-delete-modal");
    this.getElementPosition(confirmation);
  }

  getElementPosition = element => {
    let xPosition = 0;
    let yPosition = 0;
    const pageHeight = window.innerHeight;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    const diff = pageHeight - yPosition;

    if (diff < 250) {
      this.setState({ upContainer: true });
    } else {
      this.setState({ upContainer: false });
    }

    return { x: xPosition, y: yPosition };
  };

  closeConfirmation = () => {
    this.props.handleClose(false);
  };


  handleDeleteEmployee = (employee) => {
    this.setState({ loading: true })
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};

    axios.delete(`${API_URL}/admin/stores/${store}/locations/${this.props.locationId}/employees/${employee.user}`, headers)
    .then(res => this.getAndUpdateEmployees(store, headers))
  }

  getAndUpdateEmployees = (store, headers) => {
    axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
    .then(res => {
      const employees = res.data.employees;
      const totalOfEmployees = res.data.total;
      this.setState({ loading: false })
      this.props.updateEmployees(employees, totalOfEmployees);
      this.closeConfirmation()
    })
  }


  render() {
    const { employee } = this.props;

    let name;
    if (employee !== undefined) {
      name = employee.name;
    } else {
      name = "";
    }

    return (
        <ConfirmationContainer
          id="confirmation-delete-modal"
          upContainer={this.state.upContainer}
        >
          <p>Are you sure about deleting <span>{name}</span> ?</p>
          <ActionContainer>
            <button className="btn-delete" onClick={() => this.handleDeleteEmployee(employee)}>
              {this.state.loading ? <InlineLoading /> : "Delete"}
            </button>
            <button className="btn-cancel" onClick={this.closeConfirmation}>Cancel</button>
          </ActionContainer>
        </ConfirmationContainer>
    );
  }
}
