import React from "react";
import ReactSVG from "react-svg";
import { CSSTransitionGroup } from 'react-transition-group';
import Button from "../Button/Button";
import { EmptyFeedbackContainer } from "./styled";
import {
  blueDefault,
  blueDefaultHover
} from "../../Assets/colorPallet/colorPallet";
import memberIcon from '../../Assets/icons/add.svg';

export default class EmptyFeedback extends React.Component {
  render() {
    const {
      iconPath,
      text,
      handleNew,
      buttonIconPath,
      buttonText,
      svgWidth,
      svgHeight,
      svgFill,
      svgPathFill,
      containerHeight,
      flex,
      textSize,
      textButton
    } = this.props;

    return (
      <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
      <EmptyFeedbackContainer
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        svgFill={svgFill}
        svgPathFill={svgPathFill}
        containerHeight={containerHeight}
        flex={flex}
      >
        <ReactSVG src={iconPath} />
        <p style={{ fontSize: textSize ? textSize : "24px" }}>{text}</p>
        {buttonIconPath ||
          (buttonText && (
            <Button
              text={buttonText}
              uppercase
              hasIcon={textButton ? false : true}
              iconPath={memberIcon}
              borderRadius="20em"
              bgColor={blueDefault}
              color="white"
              iconColor="white"
              iconWidth="17px"
              iconHeight="17px"
              bgColorOnHover={blueDefaultHover}
              onClick={handleNew ? handleNew : () => this.props.onClick()}
            />
          ))}
      </EmptyFeedbackContainer>
      </CSSTransitionGroup>
    );
  }
}
