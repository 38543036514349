import {SET_INTEGRATION_ISSUE_REPORT, SET_INTEGRATION_ISSUE_STATUS} from "./mutations"

export const setIntegrationIssueStatus = (status) => (dispatch) => {
    dispatch({
        type: SET_INTEGRATION_ISSUE_STATUS,
        payload: status
    })
}

export const setIntegrationIssueReport = (report) => (dispatch) => {
    dispatch({
        type: SET_INTEGRATION_ISSUE_REPORT,
        payload: report
    })
}
