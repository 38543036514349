import styled from 'styled-components';

export const WebchatBodyContainer = styled.div`
    height: ${props => !props.active ? "calc(100vh - 5em - 10em - 80px)" : "calc(100vh - 5em - 10em - 80px);"};
    background-color: white;
    width: 100%;
    border-left: 1px solid #E6E6E6;
    display: ${props => !props.active ? "flex" : null};
    align-items: ${props => !props.active ? "center" : null};
    justify-content: ${props => !props.active ? "center" : null};

    @media only screen and (max-width: 600px){
        height: ${props => !props.active ? "calc(100vh - 91px)" : "calc(100vh - 91px - 4em);"};
        margin-top: 4em;
    }
`;

export const Overlay = styled.div`
    top: calc(91px + 4em);
    z-index: 1000;
    left: 0;
    width: 100%;
    position: fixed;
    background-color: #00000090;
    height: calc(100vh - 91px + 4em);
`;