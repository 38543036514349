import React from "react";
import moment from 'moment';
import axios from "axios";
import { API_URL } from "../../../../config";
import SelectAlt from "../../../../Components/SelectAlt/SelectAlt";
import { 
  ReportFilterContainer, 
  SelectContainer,
  DesktopFilter,
  MobileFilter,
  ResetFilterContainer
} from "./styled";
import SelectDate from "../../../../Components/SelectDate/SelectDate";
import SideFilter from '../../../../Components/SideFilter/SideFilter';
import ResetFilter from '../../../../Components/ResetFilter/ResetFilter';

export default class ReportFilter extends React.Component {

  constructor(props){
    super(props);
    this.handleResetFilter = this.handleResetFilter.bind(this);

    this.state = {
      allSellers: [],
      sentByPlaceholder: "Sent by",
      performancePlaceholder: "Performance",
      locationsPlaceholder: "Locations",
      datePlaceholder: "Date",
      store: "",
      authToken: "",
      headers: {},
      isFilterOn: false,
      filterBySeller: false,
      filterByLocation: false,
      filterByPerformance: false,
      filterByDate: false,
      selectedSeller: "",
      selectedPerformance: "",
      selectedLocation: ""
    };
  }
  
  componentDidMount() {
    const authToken = localStorage.getItem("token");
    const store = localStorage.getItem("store");
    const headers = { headers: { "x-sas-Auth": authToken } };

    axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
    .then(res => {
      const totalOfPages = Math.ceil(res.data.total / 20);

     
      for (let page = 1; page <= totalOfPages; page++){
        axios.get(`${API_URL}/admin/stores/${store}/employees?page=${page}`, headers)
        .then(res => {
          let employees = [];
          res.data.employees.map((employee, index) => {
            employees.push(employee);
            if (index === totalOfPages){
              this.setState({ employees })
            }
          })
        })
      }
    })

      axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
      .then(res => {
        const locations = res.data.map(location => {
          return { name: location.name, value: location.id }
        })
        this.setState({ locations, store})
      })
  }

  uniqueArray = arr => {
    let uniqueArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (uniqueArray.indexOf(arr[i]) == -1) {
        uniqueArray.push(arr[i]);
      }
    }

    return uniqueArray;
  };


  handleSelectedSentBy = sentBy => {
    this.filterData(sentBy, "sent-by");
    this.setState({ 
      filterBySeller: true,
      selectedSeller: sentBy.user,
      sentByPlaceholder: sentBy.name,
      isFilterOn: true
    })
  }

  handleSelectedPerformance = performance => {
    this.filterData(performance, "performance");
    this.setState({ 
      filterByPerformance: true,
      selectedPerformance: performance.value,
      isFilterOn: true
    })
  }

  handleSelectedLocation = location => {
    this.filterData(location, "location");
    this.setState({ 
      filterByLocation: true,
      selectedLocation: location.value,
      locationsPlaceholder: location.name,
      isFilterOn: true
    })
  }

  handleSelectedDateInterval = dateInterval => {
    const interval = {
      startDate: moment(dateInterval.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateInterval.endDate).format("YYYY-MM-DD")
    }
    this.filterData(interval, "date");
    this.setState({ 
      filterByDate: true, 
      selectedDate: dateInterval,
      datePlaceholder: `${interval.startDate} - ${interval.endDate}`,
      isFilterOn: true
    })
  }

  filterData = (obj, filterType) => {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};

    const { 
      filterBySeller,
      filterByLocation,
      filterByPerformance,
      filterByDate,
      selectedLocation,
      selectedPerformance,
      selectedDate,
      selectedSeller
    } = this.state;

    let endpoint;
    if (filterType === "sent-by"){
      if (filterByLocation && !filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${obj.user}&&sort_order=desc`;
      } else if (filterByLocation && filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${obj.user}&&performance=${selectedPerformance}&&sort_order=desc`;
      } else if (filterByLocation && filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${obj.user}&&performance=${selectedPerformance}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else if (filterByLocation && !filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${obj.user}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`
      } else if (!filterByLocation && filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?sales_person=${obj.user}&&performance=${selectedPerformance}&&sort_order=desc`;
      } else if (!filterByLocation && !filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?sales_person=${obj.user}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else {
        endpoint = `${API_URL}/private/stores/${store}/review_requests?sales_person=${obj.user}&&sort_order=desc`
      } 
    } else if (filterType === "location"){
      if (filterBySeller && !filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?sales_person=${selectedSeller}&&sort_order=desc`;
      } else if (filterBySeller && filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?sales_person=${selectedSeller}&&performance=${selectedPerformance}&&sort_order=desc`;
      } else if (filterBySeller && filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?sales_person=${selectedSeller}&&performance=${selectedPerformance}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else if (filterBySeller && !filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?sales_person=${selectedSeller}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`
      } else if (!filterBySeller && filterByPerformance && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?performance=${selectedPerformance}&&sort_order=desc`;
      } else if (!filterBySeller && !filterByPerformance && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else {
        endpoint = `${API_URL}/private/stores/${store}/locations/${obj.value}/review_requests?sort_order=desc`;
      }
    } else if (filterType === "performance"){
      if (filterByLocation && !filterBySeller && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?performance=${obj.value}&&sort_order=desc`;
      } else if (filterByLocation && filterBySeller && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${selectedSeller}&&performance=${obj.value}&&sort_order=desc`;
      } else if (filterByLocation && filterBySeller && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${selectedSeller}&&performance=${obj.value}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else if (filterByLocation && !filterBySeller && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?performance=${obj.value}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`
      } else if (!filterByLocation && filterBySeller && !filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?sales_person${selectedSeller}&&performance=${obj.value}&&sort_order=desc`;
      } else if (!filterByLocation && !filterBySeller && filterByDate){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?performance=${obj.value}&&start_date=${selectedDate.startDate}&&end_date=${selectedDate.endDate}&&sort_order=desc`;
      } else {
        endpoint = `${API_URL}/private/stores/${store}/review_requests?performance=${obj.value}&&sort_order=desc`;
      }
    } else if (filterType === "date"){
      if (filterByLocation && !filterBySeller && !filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?performance=${selectedPerformance}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`;
      } else if (filterByLocation && filterBySeller && !filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${selectedSeller}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`;
      } else if (filterByLocation && filterBySeller && filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?sales_person=${selectedSeller}&&performance=${selectedPerformance}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`;
      } else if (filterByLocation && !filterBySeller && filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/review_requests?performance=${selectedPerformance}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`
      } else if (!filterByLocation && filterBySeller && !filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?sales_person${selectedSeller}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`;
      } else if (!filterByLocation && !filterBySeller && filterByPerformance){
        endpoint = `${API_URL}/private/stores/${store}/review_requests?performance=${selectedPerformance}&&start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`;
      } else {
        endpoint = `${API_URL}/private/stores/${store}/review_requests?start_date=${obj.startDate}&&end_date=${obj.endDate}&&sort_order=desc`
      }
    } else {
      endpoint = `${API_URL}/private/stores/${store}/review_requests?sort_order=desc`;
    }

    this.setState({ endpoint, headers, store }, () => {
      this.getData();
    })
  }

  getData = () => {
    axios.get(this.state.endpoint, this.state.headers)
    .then(res => {
      const totalOfRequests = res.data.total;
      const requests = res.data.requests;
      this.props.updateRequests(requests, totalOfRequests)
      this.props.endpoint(this.state.endpoint)
    })
  }

  async handleResetFilter(){
    const endpoint = `${API_URL}/private/stores/${this.state.store}/review_requests?sort_order=desc`;
    this.setState({ 
      selectedSeller: "",
      selectedLocation: "",
      selectedPerformance: "",
      filterByLocation: false,
      filterByPerformance: false,
      filterBySeller: false,
      locationsPlaceholder: "Locations",
      datePlaceholder: "Date",
      sentByPlaceholder: "Sent by",
      endpoint
     })
     await this.props.endpoint(endpoint)
     this.props.handleResetFilter();

  }
  

  render() {
    return (
      <div style={{ width: "100%" }}>
      <ReportFilterContainer>
        <DesktopFilter>
          <SelectContainer className="select">
            <SelectAlt
              options={this.state.employees}
              selectedOption={this.handleSelectedSentBy}
              placeholder={this.state.sentByPlaceholder}
              id="sent-by"
            />
          </SelectContainer>
        </DesktopFilter>
        
        <DesktopFilter>
          <SelectContainer className="select">
            <SelectAlt
              options={this.state.locations}
              selectedOption={this.handleSelectedLocation}
              placeholder={this.state.locationsPlaceholder}
              id="locations"
            />
          </SelectContainer>
        </DesktopFilter>

        <DesktopFilter>
          <SelectContainer className="select">
            <SelectDate 
              placeholder={this.state.datePlaceholder} 
              selectedInterval={this.handleSelectedDateInterval} />
          </SelectContainer>
        </DesktopFilter>

        <MobileFilter>
        <SideFilter 
          page="reports"
          locations={this.state.locations}
          employees={this.state.employees}
          handleResetFilter={this.handleResetFilter}
          selectedInterval={this.handleSelectedDateInterval}
          selectedSentBy={this.handleSelectedSentBy}
          selectedLocation={this.handleSelectedLocation} />
        </MobileFilter>
      </ReportFilterContainer>
      <ResetFilterContainer>
          {this.state.isFilterOn && <ResetFilter onClick={this.handleResetFilter} />}
        </ResetFilterContainer>
      </div>
    );
  }
}
