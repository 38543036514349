import styled from 'styled-components';

export const ConvertionRateContainer = styled.div`
    width: 100%;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 369px;
    margin-top: 1em;
    height: 50px;

    @media only screen and (max-width: 770px){
        max-width: 100%;
    }

`;