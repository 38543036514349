import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Container, Label} from "../../../../Assets/Structure/Structure"
import {Dialog} from "@material-ui/core";

const SEND_REVIEW_REQUEST = "SEND_REVIEW_REQUEST"
const WEBCHAT_NUMBER = "WEBCHAT_NUMBER"

const options = [
    { value: SEND_REVIEW_REQUEST, label: "Send Review Request" },
    { value: WEBCHAT_NUMBER, label: "Webchat Number" }
];


export default function SubMenu(props) {
    const [state, setState] = useState({
        showSubMenuDialog: false
    })

    const handleShowSubMenuDialog = (showSubMenuDialog) => {
        setState({ ...state, showSubMenuDialog })
    };

    const handleSubMenuOptionOnClick = (option) => {
        if (option.value === SEND_REVIEW_REQUEST) {
            props.onSendReviewRequest()
        } else if (option.value === WEBCHAT_NUMBER) {
            props.onShowWebchatNumber()
        }

        setState({ ...state, showSubMenuDialog: false })
    }


    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => handleShowSubMenuDialog(true)}
            >
                <MoreVertIcon />
            </IconButton>
            <Dialog
                className="compose-message-dialog"
                style={{ borderRadius: "20em", padding: "20px" }}
                open={state.showSubMenuDialog}
                onClose={() => handleShowSubMenuDialog(false)}
                maxWidth="lg"
                fullWidth
            >
                {options.map((option) => (
                    <Container onClick={() => handleSubMenuOptionOnClick(option)} fluid padding="20px" bgColorOnHover="#eee">
                        <Label>{option.label}</Label>
                    </Container>
                ))}
            </Dialog>
        </div>
    );
}
