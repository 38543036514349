import React from "react"
import { Dialog } from "@material-ui/core"

// STYLE
import ImportContacts from "../../../Components/ImportContacts/ImportContacts";
import {Container} from "../../../Assets/Structure/Structure";

// ASSETS


export default function ImportContactsDialog(props) {

    // Methods
    const handleCancel = () => props.onCancel(false)

    const getUnique = (arr, comp) => {

        // store the comparison  values in array
        const unique =  arr.map(e => e[comp])

            // store the indexes of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the false indexes & return unique objects
            .filter((e) => arr[e]).map(e => arr[e]);

        return unique;
    }



    const handleImportedContacts = (importedContacts) => {
        const uniqueImportedContacts = getUnique(importedContacts, "phone")
        const formattedImportedContacts = uniqueImportedContacts.map(contact => {
            return {
                clientNumber: contact.phone,
                clientName: contact.name
            }
        })

        props.onChange(formattedImportedContacts)
        props.onClose()
    }

    return (
        <Dialog
            style={{ borderRadius: "20em" }}
            open={props.active}
            onClose={() => props.onClose()}
            maxWidth="sm"
            fullWidth>
            <Container padding="40px">
              <ImportContacts
                  onlyList
                  onAddContacts={handleImportedContacts}
                  cancel={handleCancel}
              />
            </Container>
        </Dialog>
    )
}
