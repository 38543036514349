import React from 'react';
import { LoadingContainer } from './styled';
import loading from '../../Assets/images/loading.gif';

export default class Loading extends React.Component{
    render(){
        return(
            <LoadingContainer height={this.props.height} imageWidth={this.props.imageWidth}>
                <img src={loading}/>
            </LoadingContainer>
        )
    }
}