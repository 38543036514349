import React from "react";
import ReactSVG from 'react-svg';
import {
  PageWrapper,
  Form,
  Label,
  Input,
  FormItem,
  Row
} from "../../Assets/Structure/Structure";
import {
  EditProfileContainer,
  LoadRequestContainer,
  ChangePasswordContainer,
  ActionContainer,
  OpenFlexContainer
} from "./styled";
import Module from "../../Components/Module/Module";
import Button from "../../Components/Button/Button";
import editIcon from "../../Assets/icons/edit.svg";
import {
  blueDefault,
  blueDefaultHover
} from "../../Assets/colorPallet/colorPallet";
import axios from "axios";
import { API_URL } from "../../config";
import Loading from "../../Components/Loading/Loading";
import lockIcon from '../../Assets/icons/lock.svg';
import closeIcon from '../../Assets/icons/close.svg';
import Feedback from "./Feedback/Feedback";

export default class EditProfile extends React.Component {
  state = {
    name: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    newPasswordClone: "",
    onChangingName: false,
    passwordDismatch: false,
    missingCurrentPassword: false,
    passwordCannotBeBlank: false,
    profileMutatedWithSuccess: "",
    loading: false,
    resetValues: false,
    currentUserName: this.props.currentUserName,
    currentUserEmail: this.props.currentUserEmail,
    showContent: false,
    isFormDisabled: true
  };

  componentDidMount() {
    this.setState({
      currentUserName: this.props.currentUserName,
      currentUserEmail: this.props.currentUserEmail
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      currentUserName: props.currentUserName,
      currentUserEmail: props.currentUserEmail
    });
  }

  handleChange = (e, type) => {
    this.setState({ [type]: e.target.value });

    if (type === "currentPassword" || type === "newPassword" || type === "newPasswordClone"){
      if (e.target.value !== ""){
        this.handleUpdateType("onChangingPassword", true);
        this.handleFormActivation(false);
      } else {
        this.handleUpdateType("onChangingPassword", false);
        this.handleFormActivation(true);
      }
    }

    if (type === "name"){
      if (e.target.value !== ""){
        this.handleUpdateType("onChangingName", true);
        this.handleFormActivation(false);
      }else {
        this.handleUpdateType("onChangingName", false);
        this.handleFormActivation(true);
      }

    }
  };

  handleFormActivation = (isFormDisabled) => this.setState({ isFormDisabled })

  handleUpdateType = (type, data) => this.setState({ [type]: data });

  handleProfileMutation = e => {
    e.preventDefault();
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };
    const currentPassword = this.state.currentPassword;
    const newPassword = this.state.newPassword;
    const newPasswordClone = this.state.newPasswordClone;

    let onChangingPassword;
    if (currentPassword || newPassword || newPasswordClone !== "") {
      onChangingPassword = true;
      if (currentPassword === "") {
        this.setState({
          missingCurrentPassword: true,
          profileMutatedWithSuccess: ""
        });
      } else {
        this.setState({ missingCurrentPassword: false });
      }

      if (newPassword !== newPasswordClone) {
        this.setState({
          passwordDismatch: true,
          profileMutatedWithSuccess: ""
        });
      } else {
        this.setState({ passwordDismatch: false });
      }

      if (newPassword === "" && newPasswordClone === "") {
        this.setState({
          passwordCannotBeBlank: true,
          profileMutatedWithSuccess: ""
        });
      } else {
        this.setState({ passwordCannotBeBlank: false });
      }
    } else {
      onChangingPassword = false;
    }

    if (
      currentPassword !== "" &&
      newPassword !== "" &&
      newPasswordClone !== "" &&
      newPassword === newPasswordClone
    ) {
      this.handlePasswordMutation(authToken, headers);
    }

    if (this.state.onChangingName) this.handleNameMutation(authToken, headers);
    if (this.state.onChangingEmail)
      this.handleEmailMutation(authToken, headers);
  };

  handlePasswordMutation = (authToken, headers) => {
    this.setState({ loading: true });
    const payload = {
      current_password: this.state.currentPassword,
      new_password: this.state.newPassword
    };

    axios
      .put(`${API_URL}/private/users/${authToken}/credential`, payload, headers)
      .then(res => {
        this.setState({
            profileMutatedWithSuccess: true,
            resetValues: true,
            loading: false,
            newPassword: "",
            currentPassword: "",
            passwordDismatch: false,
            passwordCannotBeBlank: false,
            newPasswordClone: "",
            onChangingPassword: false
          },() => this.retoreResetValueState()
        );
      })
      .catch(() => {
        this.setState({
            profileMutatedWithSuccess: false,
            loading: false,
            newPassword: "",
            currentPassword: "",
            newPasswordClone: "",
            resetValues: true,
            onChangingPassword: false
          },() => this.retoreResetValueState()
        );
      });
  };

  retoreResetValueState = () => {
    this.setState({ resetValues: false });
  };

  handleNameMutation = (authToken, headers) => {
    const payload = { name: this.state.name };
    axios
      .patch(`${API_URL}/private/users/${authToken}`, payload, headers)
      .then(res => {
        localStorage.setItem("user_name", this.state.name)
        this.props.userName(this.state.name);
        this.setState({ 
          profileMutatedWithSuccess: true,
          name: "", 
          onChangingName: false,
          newPassword: "",
          newPasswordClone: "",
          currentPassword: "",
          passwordCannotBeBlank: false,
          passwordDismatch: false
        });
      })
      .catch(() => {
        this.setState({ 
          profileMutatedWithSuccess: false, 
          name: "", 
          onChangingName: false 
        });
      });
  };

  handleShow = () =>
    this.setState(state => ({ showContent: !state.showContent }));

  handleCloseModal = () => {
    this.props.isModalOpen(false)
  }

  render() {

    const { type } = this.props;

    return (
      <PageWrapper>
        <EditProfileContainer>
          <Row ml="20px" between alignCenter style={type === "seller" ? {padding: "0 1em", boxSizing: "border-box"} : null}>
            <h2>Edit profile</h2>
            {type === "seller" && 
            <ReactSVG src={closeIcon} onClick={this.handleCloseModal} className="close-icon" />}
          </Row>
          
          <Module style={{ padding: "3em" }} noShadow={type === "seller" && true}>
            <Form onSubmit={this.handleProfileMutation}>
              <FormItem>
                <Label>Name</Label>
                <Input
                  placeholder={this.state.currentUserName}
                  value={this.state.name}
                  autoComplete="Name"
                  onChange={e => this.handleChange(e, "name")}
                />
              </FormItem>
              <ChangePasswordContainer>
                <ActionContainer
                  onClick={this.handleShow}
                  showContent={this.state.showContent}>
                  <ReactSVG src={lockIcon} />
                  <label>Change password</label>
                </ActionContainer>
                <OpenFlexContainer showContent={this.state.showContent}>
                  <FormItem mt2>
                    <Label>Current password*</Label>
                    <Input
                      type="password"
                      placeholder="**********"
                      value={this.state.currentPassword}
                      onChange={e => this.handleChange(e, "currentPassword")}
                      className="password-field"
                    />
                  </FormItem>

                  <FormItem mt2>
                    <Label autoComplete="new-password">New password*</Label>
                    <Input
                      type="password"
                      placeholder="**********"
                      value={this.state.newPassword}
                      onChange={e => this.handleChange(e, "newPassword")}
                      className="password-field"
                    />
                  </FormItem>

                  <FormItem mt2>
                    <Label autoComplete="repeat-new-password">Repeat new password*</Label>
                    <Input
                      type="password"
                      placeholder="**********"
                      value={this.state.newPasswordClone}
                      onChange={e => this.handleChange(e, "newPasswordClone")}
                      className="password-field"
                    />
                  </FormItem>
                </OpenFlexContainer>
              </ChangePasswordContainer>
              <LoadRequestContainer>
                {this.state.loading ? (
                  <Loading imageWidth="50px" />
                ) : (
                  <React.Fragment>
                    {this.state.passwordDismatch && <Feedback type="fail" text="Password dismatch" />}
                    {this.state.missingCurrentPassword && <Feedback type="fail" text="Missing current password" />}
                    {this.state.passwordCannotBeBlank && <Feedback type="fail" text="Password cannot be blank" />}
                    {this.state.profileMutatedWithSuccess && <Feedback type="success" text="Profile updated successfully" />}
                  </React.Fragment>
                )}
              </LoadRequestContainer>
              <FormItem>
                <Button
                  text="save editions"
                  uppercase
                  type="submit"
                  hasIcon
                  iconPath={editIcon}
                  borderRadius="20em"
                  bgColor={blueDefault}
                  bgColorOnHover={blueDefaultHover}
                  color="white"
                  iconColor="white"
                  disabled={this.state.isFormDisabled}
                />
              </FormItem>
            </Form>
          </Module>
        </EditProfileContainer>
      </PageWrapper>
    );
  }
}
