import React from 'react';
import ReactSVG from 'react-svg';
import { DataContainer, Text } from './styled';
import { Column } from '../../../../../Assets/Structure/Structure';
import questionIcon from '../../../../../Assets/icons/question.svg';

export default class Data extends React.Component{
    render(){

        const { iconPath, text } = this.props;

        return(
            <DataContainer>
                <Column>
                    <ReactSVG src={iconPath} className="sent-icon" />
                </Column>
                <Column ml05>
                    <Text>{text}</Text>
                </Column>
                <Column ml05>
                    <ReactSVG src={questionIcon} className="question-icon" />
                </Column>
            </DataContainer>
        )
    }
}