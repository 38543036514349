import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';
import img2 from './images/img-2.png';

export default class HowToSendReviewRequests extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                   In the upper right corner of the page click on the button <strong style={{ color: blueDefault }}>Send Review Invitation</strong>.
                   You can send review invitation by sms or email. It is possible to send 
                   review invitations to multiple customers at once.
                </Text>
                <Image fluid src={img1} />
                <Image src={img2} style={{ margin: "0 auto", display: "flex" }} />
            </DescriptionContainer>
        )
    }
}