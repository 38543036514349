import styled from "styled-components";

export const BestRatedLocationsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1em;
  margin-top: 1em;
  grid-row-gap: 1em;

  @media only screen and (max-width: 800px){
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px){
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.h3`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;
  font-weight: 400;
  margin-top: 4em;

  @media only screen and (max-width: 500px){
    padding: 0 1em;
  }
`;
