import styled from "styled-components";

export const ResetFilterButton = styled.a`
  text-decoration: none;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #025f88;
  transition: opacity .4s ease;
  margin-top: ${props => props.mt};
  cursor: pointer;
`;
