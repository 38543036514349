import React from 'react';
import ReactSVG from 'react-svg';
import { NewMemberContainer, MemberInfoContainer, ActionContainer } from './styled';
import editIcon from '../../../../../../Assets/icons/edit.svg';
import trashIcon from '../../../../../../Assets/icons/trash.svg';

export default class NewMember extends React.Component{

    handleDeleteEmployee = (index) => {
        this.props.indexToBeDeleted(index);
    }


    render(){

        const { name, email, index } = this.props;

        return(
            <NewMemberContainer>
                <MemberInfoContainer>
                    <h3>{email}</h3>
                    <p>{name}</p>
                </MemberInfoContainer>
                <ActionContainer>
                    <div className="edit"><ReactSVG src={editIcon} /></div>
                    <div className="trash" onClick={() => this.handleDeleteEmployee(index)}><ReactSVG src={trashIcon} /></div>
                </ActionContainer>
            </NewMemberContainer>
        )
    }
}