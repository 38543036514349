import React from 'react';
import ReactSVG from 'react-svg';
import { SearchContainer } from './styled';
import searchIcon from '../../../../../Assets/icons/search.svg';

export default class Search extends React.Component{

    state = {
        searchValue: "",
    }

    handleChange = (e) => {
        const searchValue = e.target.value;
        this.setState({ searchValue });
        this.props.searchValue(searchValue);
    }

    render(){
        return(
            <SearchContainer>
                <input type="text"  onChange={this.handleChange} value={this.state.searchValue}/>
                <ReactSVG src={searchIcon} />
            </SearchContainer>
        )
    }
}