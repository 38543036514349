import styled from 'styled-components';

export const StoresContainer = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f8f8;
    font-family: "Roboto";
    overflow-y: auto;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 40em;

  .stores-module{
    padding: 2.5em;
    margin-top: 1.5em;
  }

  @media only screen and (max-width: 500px){
    padding: 2em;
    box-sizing: border-box;
  }
`;