import styled from "styled-components";

export const ReviewsWrapper = styled.div`
  min-height: calc(100vh - 91px - 4em);
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;


  @media only screen and (max-width: 500px){
    max-width: 100%;

    .answer-review-modal{
      border-radius: 0;
      .m0{
        margin: 1em 0;
      }
    }

    .no-reply-feedback{
      margin-top: 2em;
    }
  }
`;
