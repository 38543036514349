import styled from "styled-components";

export const RateResultContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.25em;
  }
`;

export const Rate = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;
`;

export const TotalReviews = styled.label`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;
  margin-left: 1em;
`;
