import styled from "styled-components";
import { red } from '../../Assets/colorPallet/colorPallet';

export const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: ${props => props.padding};
  padding: 0 0 2em;   
  flex-direction: ${props => props.direction ? props.direction : null};

  svg {
    width: ${props => props.svgSize ? props.svgSize : "30px"};
    height: ${props => props.svgSize ? props.svgSize : "30px"};
    fill: ${props => props.type === "negative" ? red : props.svgColor ? props.svgColor : null};

    path{
      fill: ${props => props.svgColor ? props.svgColor : null};
    }
  }
`;

export const Text = styled.p`
  letter-spacing: 0.1em;
  font-size: ${props => props.fontSize ? props.fontSize : "14px"};
  color: ${props => props.color ? props.color : "#333"};
  text-align: ${props => props.center ? "center" : null};
  margin-left: ${props => props.ml ? props.ml : "2em"};
`;
