import styled from 'styled-components';
import { blueDefault } from '../../../Assets/colorPallet/colorPallet';

export const CopyCodeContainer = styled.div`
`;

export const Code = styled.div`
    .copy-code-module{
        display: flex;
        align-items: center;    
        justify-content: flex-start;

        .widget-info-container{
            display: flex;
            flex-direction: column;
            margin-left: 1em;

            label{
                font-weight: 400;
                line-height: 25px;
                font-size: 14px;
                letter-spacing: 0.05em;
                color: #333333;
            }

            a{
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #449AC1;
                cursor: pointer;
            }
        }
    }
`;

export const IconContainer = styled.div`
    cursor: pointer;

    svg{
        path{
            transition: fill .4s ease;
        }
    }

    &:hover{
        svg{
            path{
                fill: ${blueDefault};
            }
        }
    }
`;