import styled from "styled-components";

export const TeamFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;  
  float: right;
  margin: auto 0 auto auto;
  flex: 1;
  align-items: center;
  padding-left: 1em;
  
  .select-location{
      width: 100%;
      max-width: 20em;
  }

  .dd-header {
    margin-left: 1em;
  }

  @media only screen and (max-width: 1025px){

      .locations, .profiles{
          display: none;
      }

      #search-container{
          margin-left:0;
      }

      #mob-side-filter{
          margin-right: 1em;
      }
  }

`;

export const SearchContainer = styled.div`
    width: ${props => (props.searchIsActive ? "100%" : "40px")};
    transition: width 1.5s ease;
    display: flex;
    cursor: pointer;
    height: 40px;
    margin-left: 1em;
    background-color: #e7e7e7;
    border-radius: 20em;

    svg{
      margin: auto 0 auto auto !important;
      right: 40px;
      position: absolute;
    }

    @media only screen and (orientation: portrait) and (max-width: 800px){
        svg{
            right: 10px;
        }
    }

    @media only screen and (orientation: landscape) and (max-width: 1025px){
        svg{
            right: 20px;
        }
    }

    @media only screen and (max-width: 500px){
        width: ${props => props.searchIsActive ? "100%" : "40px"};
    }
`;