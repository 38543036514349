import React from "react";
import Skeleton from "react-skeleton-loader";
import Module from "../Module/Module";

export default class OverviewByPlatformSkeleton extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridRowGap: "2em"
        }}
      >
      
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" count={2} />
          </div>
    
      </div>    
    );
  }
}
