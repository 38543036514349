import styled from "styled-components"

export const ComposeMessageDialogContainer = styled.div`
  padding: 40px;
  
  @media only screen and (max-width: 600px) {
    padding: 24px;

    .compose-message-dialog--left-column{
      margin-right: 0 !important;
    }
  }
`;


export const CloseButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  
  &:hover{
    background-color: #eee;
  }
`;
