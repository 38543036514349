import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: ${props => props.padding ? props.padding : "2em"};
  box-sizing: border-box;
  min-height: calc(100vh - 91px);
  display: ${props => props.alignContentCenter ? "flex" :  null};
  justify-content: ${props => props.alignContentCenter ? "center" : null};
  align-items: ${props => props.alignContentCenter ? "center" : null};

  @media only screen and (max-width: 500px) {
    padding: ${props => props.mobPadding ? props.mobPadding : "2em 0 0.5em"};
    max-width: 100% !important;

    .new-member-modal{
      height: 100vh;
      display: flex;
      flex-direction: column;
      border-radius: 0;

      .new-member-body-modal{
        max-height: 100vh;
      }
    }



    .mob-modal{
      height: auto;
      max-height: 90vh;
      max-width: 90%;
      border-radius: 0;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FluidPageWrapper = styled.div`
  width: 100%;
  max-width: 68em;
  margin: 0 auto;
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  justify-content: ${props => (props.between ? "space-between" : null)};
`;

export const AppContainer = styled.div`
  display: flex;
  background-color: #f8f8f8;
  width: 100%;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;

  .mount-page-enter {
  opacity: 0.01;
}

.mount-page-enter.mount-page-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mount-page-leave {
  opacity: 1;
}

.mount-page-leave.mount-page-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.mount-page-appear {
  opacity: 0.01;
}

.mount-page-appear.mount-page-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}


  @media only screen and (max-width: 600px){
    .review-invitation-modal{
      height: 100vh;
      display: flex;
      flex-direction: column;
      border-radius: 0;   
    }
  }
`;

export const Column = styled.div`
  width: ${props => props.fluid && "100%"};
  display: flex;
  flex-direction: column;
  margin-left: ${props =>
    props.ml05 ? ".5em" : props.ml1 ? "1em" : props.ml2 ? "2em" : null};
  width: ${props => (props.fluid ? "100%" : null)};
  justify-content: ${props =>
    props.justifyCenter ? "center" : props.flexEnd ? "flex-end" : null};
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin-top: ${props => props.mt};
`;

export const AppBodyContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 269px);
  margin-left: 269px;
  margin-top: 91px;
  min-height: calc(100vh - 91px);

  @media only screen and (max-width: 1050px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: ${props => props.flexFlow};
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${props => (props.between ? "space-between" : props.flexEnd ? "flex-end" : props.justifyCenter ? "center" : null)};
  width: ${props => props.auto ? "auto" : "100%"};
  flex-flow: ${props => props.flexFlow};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin-top: ${props => (props.mt025 ? ".25em" : props.mt ? props.mt : null)};
  margin-left: ${props => props.ml ? props.ml : null};
  align-items: ${props => (props.alignCenter ? "center" : null)};
  border-bottom: ${props => props.borderBottom ? props.borderBottom : null};
`;

export const Container = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.padding};
  box-sizing: border-box;
  display: ${props => props.flex ? "flex" : props.showOnMobile ? "none" : null};
  flex-direction: ${props => props.column ? "column" : props.row ? "row" : null};
  align-items: ${props => props.alignCenter ? "center" : null};
  justify-content: ${props => props.justifyCenter ? "center" : props.between ? "space-between" : props.justifyEnd ? "flex-end" : null};
  margin-top: ${props => props.mt};
  padding-left: ${props => props.pl ? props.pl : null};
  margin-left: ${props => props.ml ? props.ml : null};
  margin-right: ${props => props.mr};
  position: ${props => props.fixed ? "fixed" : props.absolute ? "absolute" : null};
  margin-bottom: ${props => props.mb};
  flex-wrap: ${props => props.wrap ? "wrap" : null};
  cursor: ${props => props.cursor};
  margin: ${props => props.m};
  max-width: ${props => props.maxWidth ? props.maxWidth : null};
  max-height: ${props => props.maxHeight};
  overflow: ${props => props.maxHeight ? "auto" : null};
  bottom: ${props => props.forceBottom ? "0" : props.bottom ? props.bottom : null};
  top: ${props => props.top ? props.top : null};
  right: ${props => props.right ? props.right : null};
  width: ${props => props.fluid ? "100%" : props.width ? props.width : null};
  height: ${props => props.height ? props.height : null};
  opacity: ${props => props.disabled ? ".4" : "1"};
  pointer-events: ${props => props.disabled ? "none" : "all"};
  border-radius: ${props => props.borderRadius ? props.borderRadius : null};
  box-shadow: ${props => props.boxShadowDefault ? "0px 1px 0px rgba(0,0,0,0.15)" : null};
  z-index: ${props => props.zIndex ? props.zIndex : null};
  text-align: ${props => props.textCenter ? "center" : null};
  
  a{
    text-decoration: none;
  }
  
  &:hover {
    background-color: ${props => props.bgColorOnHover ? props.bgColorOnHover : null};
  }

  
  &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    @media only screen and (max-width: 600px){
      position: ${props => props.fixedOnMobile ? "fixed" : null};
      display: ${props => props.hideOnMobile ? "none" : props.showOnMobile ? "flex" : null};
    }
  width: ${props => props.fluid ? "100%" : props.auto ? "auto" : null};
  border-radius: ${props => props.radius};
  box-shadow: ${props => props.shadowOn ? "0px 1px 0px rgba(0, 0, 0, 0.15)" : null};
  max-width: ${props => props.maxWidth};
  border-bottom: ${props => props.borderBottom};
`;

export default styled.span`
  font-family: "Roboto", sans-serif;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
`;

export const GridContainer = styled.div`
  margin-top: ${props => props.mt};
  display: grid;
  grid-template-columns: ${props => props.templateColums};
  grid-row-gap: 1em;
  overflow-y: ${props => props.overflowY};
  max-height: ${props => props.maxHeight};
  padding: ${props => props.padding};
  box-sizing: border-box;

  @media only screen and (max-width: 500px){
    padding: 0;
  }
`;

export const FluidContainer = styled.div`
  width: 100%;
`;

//Form

export const Form = styled.form`
  padding: ${props => props.padding};
  box-sizing: border-box;
`;

export const Label = styled.label`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  color: ${props => (props.color ? props.color : "#333")};
  letter-spacing: ${props => props.ls ? props.ls : ".1em"};
  text-transform: ${props => (props.uppercase ? "uppercase" : null)};
  text-align: ${props => props.textCenter ? "center" : props.textRight ? "right" : null};
  margin-top: ${props => props.mt};
  font-weight: ${props => props.fontWeight ? props.fontWeight : props.semiBold ? "500" : null};
  margin-left: ${props => props.ml};
  font-weight: ${props => props.bold ? "600" : null};
  max-width: ${props => props.maxWidth};
  line-height: ${props => props.lineHeight ? props.lineHeight : null};
  cursor: ${props => props.pointer ? "pointer" : null};
  display: ${props => props.ellipsis ? "block !important" : null};
  white-space: ${props => props.ellipsis ? "nowrap" : null};
  text-overflow: ${props => props.ellipsis ? "ellipsis" : null};
  overflow: ${props => props.ellipsis ? "hidden" : null};


  @media only screen and (max-width: 600px){
    display: ${props => props.hideOnMobile ? "none" : null};
  }
`;

export const Input = styled.input`
  font-size: 20px;
  color: #333;
  outline: none;
  border: none;
  width: 100%;
  border-bottom: ${props => (props.noBorder ? "none" : "1px solid #ccc")};
  padding: 10px 0;

  &::placeholder {
    color: #999999;
  }

  @media only screen and (max-width: 600px){
    font-size: 16px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props =>
    props.mt05 ? ".5em" : props.mt1 ? "1em" : props.mt2 ? "2em" : null};
`;


export const Text = styled.p`
  color: ${props => props.color ? props.color : "#333"};
  font-size: ${props => props.fontSize ? props.fontSize : "14px"};
  line-height: ${props => props.lineHeight ? props.lineHeight  : "26px"};
  text-align: ${props => props.alignCenter ? "center" : null};
  width: 100%;
  max-width: ${props => props.maxWidth};
  letter-spacing: ${props => props.ls ? props.ls : ".1em"};
  width: 100%;
  overflow-wrap: break-word;
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  margin-bottom: ${props => props.mb};
  text-decoration: ${props => props.underline ? "underline" : null};
  text-overflow: ${props => props.ellipsis ? "ellipsis" : null};
  white-space: ${props => props.ellipsis ? "nowrap" : null};
  max-width: ${props => props.maxWidth};
  overflow: ${props => props.ellipsis ? "hidden" : null};
`;

export const LoadingContainer = styled.div`
  height: calc(100vh - 91px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListContainer = styled.ul`
  padding: ${props => props.padding ? props.padding : "0"};
  margin: ${props => props.margin ? props.margin : "0"};
  max-height: ${props => props.maxHeight};
  overflow: ${props => props.maxHeight ? "auto" : null};

  
  &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 1em;
  background-color: #eee;
  margin: .5em 0;
`;

export const Title = styled.h2`
  letter-spacing: .1em;
  color: #333;
  font-size: 24px;
  padding: 0;
  margin: 0;
`

export const ActionContainer = styled.div`

`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

export const Span = styled.span`
  color: ${props => props.color};
  font-weight: ${props => props.fw ? props.fw : props.bold ? 600 : null};
  font-size: ${props => props.fs};
  text-transform: ${props => props.uppercase ? "uppercase" : null};
`;


export const Image = styled.div`
    width: 300px;
    height: 200px;
    background-image: url(${props => props.src});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px){
      width: 200px;
    }
`;
