import React from 'react';
import { OverviewByPlatformContainer, Label, PlatformsContainer } from './styled';
import Platform from './Platform/Platform';

export default class OverviewByPlatform extends React.Component{
    render(){

        const { overview, ratings, type } = this.props;

        let locationGoogleOverview, locationFacebookOverview, totalReviews;
        if (overview.review_service) {
            locationGoogleOverview = overview.review_service.filter(review => review.service === "Google") || "";
            locationFacebookOverview = overview.review_service.filter(review => review.service === "Facebook") || "";
            totalReviews = overview.reviews.total;
         } else { 
            locationFacebookOverview = "";
            locationGoogleOverview = "";
            totalReviews = 0;
         }

         let googleRating, facebookRating;

         let condition;
         if (type === "store") condition = ratings;
         if (type === "location") condition = ratings.services;

         if (condition){
             const google = ratings.services.filter(loc => loc.review_service === "Google");
             const facebook = ratings.services.filter(loc => loc.review_service === "Facebook")
             
             googleRating = google.length === 1 ? google[0].rating : 0.0;
             facebookRating =  facebook.length === 1 ? facebook[0].rating : 0.0
           
         } else {
             googleRating = 0.0;
             facebookRating = 0.0;
         }


        return(
            <OverviewByPlatformContainer style={this.props.style}>
                <Label>Percentage of reviews and rating by platform</Label>
                <PlatformsContainer>
                    <Platform 
                        totalReviews={totalReviews} 
                        locationOverview={locationGoogleOverview} 
                        rating={parseFloat(googleRating)}
                        reviews={this.props.reviews} 
                        platform="Google" 
                        loading={this.props.loading} />
                    <Platform 
                        totalReviews={totalReviews} 
                        rating={parseFloat(facebookRating)}
                        locationOverview={locationFacebookOverview} 
                        reviews={this.props.reviews} 
                        platform="Facebook" 
                        loading={this.props.loading} />
                </PlatformsContainer>
            </OverviewByPlatformContainer>
        )
    }
}