import styled from 'styled-components';

export const BeaconGuideNavContainer = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
`;

export const NavItem = styled.li`
    list-style: none;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    padding: 0 .5em;
    transition: color .4s ease;
    color: ${props => props.active ? "#333" : "#888"};
    font-weight: ${props => props.active ? "500" : null};

    &:hover{
        color: #333;
    }
`;