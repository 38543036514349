import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Container, PageWrapper, Title, ListContainer, ListItem, Label } from '../../../Assets/Structure/Structure'
import { WEBCHAT_API_URL, API_URL } from '../../../config'
import AddTwilioNumberDialog from './AddTwilioNumberDialog'
import Button from '../../../Components/Button/Button'

    
export default class TwilioNumber extends React.Component{

    state = {
        store: "",
        number: "",
        accountSid: "",
        token: "",
        storeKey: "",
        showError: false,
        storeHasTwilioNumber: false,
        loading: true,
        newStorePhoneNumber: "",
        credentials: null,
        showAddTwilioNumberDialog: false
    }

    componentDidMount(){
        const store = localStorage.getItem("store_name")
        this.setState({ store })

        this.getStoreTwilioNumber()
    }

    getStoreTwilioNumber = () => {
        const storeKey = localStorage.getItem('store_key')
        axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
        .then((response) => {
            this.setState({ storeHasTwilioNumber: true })

            const credentials = response.data[0]
            this.setState({ 
                storeHasTwilioNumber: true, 
                credentials
            })
            
        })
        .catch(() => {
            this.setState({ storeHasTwilioNumber: false })
        })
        .finally(() => {
            this.setState({ loading: false })
        })
    }


    handleChange = (e, type) => { this.setState({ [type]: e.target.value }) }


    handleSuccessUpdate = (credentials) => {
        this.setState({ 
            credentials,
            storeHasTwilioNumber: true
        })
    }


    handleShowAddTwilioNumberDialog = (showAddTwilioNumberDialog) => {
        this.setState({ showAddTwilioNumberDialog })
    }


    render(){
        if (this.state.redirectToWebChat) return <Redirect to="/webchat" />
        
        const { loading, storeHasTwilioNumber, credentials } = this.state

        const storeName = localStorage.getItem("store_name")

        return (
            <Container flex>
                <PageWrapper style={{ width: "100%" }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                            <Container fluid>
                                <Container>
                                    <Title>Twilio Number Settings</Title>
                                </Container>
                                <Container mt="20px">
                                <ListContainer>
                                    <ListItem>
                                        <Container flex between alignCenter>
                                            <Container flex alignCenter>
                                                <Container width="10em" flex column>
                                                    <Label bold>Store Name</Label>
                                                    <Container mt="15px">
                                                        <Label>{storeName}</Label>
                                                    </Container>
                                                </Container>
                                                <Container ml="20px" flex column>
                                                    <Label bold>Twilio Number</Label>
                                                    <Container mt="15px">
                                                        <Label>{storeHasTwilioNumber ? credentials.number : "-"}</Label>
                                                    </Container>
                                                </Container>
                                            </Container>
                                            <Container flex alignCenter>
                                                {!storeHasTwilioNumber && (
                                                <Container mt="15px">
                                                    <Button 
                                                    onClick={this.handleShowAddTwilioNumberDialog}
                                                    text="Add twilio number"
                                                    color="white"
                                                    borderRadius="20em"
                                                    iconColor="#333" />
                                                </Container>
                                                )}
                                            </Container>
                                        </Container>
                                    </ListItem>
                                </ListContainer>
                                </Container>
                            </Container>)}
                </PageWrapper>
                {this.state.showAddTwilioNumberDialog &&
                <AddTwilioNumberDialog
                onSuccess={this.handleSuccessUpdate}
                onClose={this.handleShowAddTwilioNumberDialog} />}
            </Container>
        )
    }
}