import React from 'react';
import { OptionsList } from './styled';

export default class Options extends React.Component{



    handleSelectedOption = (option) => {
        this.props.selectedOption(option)
    }

    render(){
        return(
            <OptionsList>
                {this.props.options.map((option, index) => {
                    return <li key={index} onClick={() => this.handleSelectedOption(option)}>{option.name}</li>
                })}
            </OptionsList>
        )
    }
}