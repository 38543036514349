import styled from 'styled-components';

export const ModuleContainer = styled.div`
    width: 100%;
    padding: ${props => props.noPadding ? "0" : "1em"};
    max-width: ${props => props.maxWidth};
    background-color: white;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: ${props => props.noShadow ? "0" : "0px 1px 0px rgba(0, 0, 0, 0.15)"};
`;