import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { LastReviewsSkeletonContainer } from './styled';
import { ReviewContainer } from '../Review/styled';

export default class LastReviewsSkeleton extends React.Component{
    render(){
        return (
            <LastReviewsSkeletonContainer>
                <ReviewContainer style={{ marginTop: "2em" }}> 
                    <Skeleton />
                    <Skeleton count={4} />
                </ReviewContainer>

                <ReviewContainer style={{ marginTop: "1em" }}>
                    <Skeleton />
                    <Skeleton count={4} />
                </ReviewContainer>

                <ReviewContainer style={{ marginTop: "1em" }}>
                    <Skeleton />
                    <Skeleton count={4} />
                </ReviewContainer>

                <ReviewContainer style={{ marginTop: "1em" }}>
                    <Skeleton />
                    <Skeleton count={4} />
                </ReviewContainer>

                <ReviewContainer style={{ marginTop: "1em" }}>
                    <Skeleton />
                    <Skeleton count={4} />
                </ReviewContainer>
            </LastReviewsSkeletonContainer>
        )
    }
}