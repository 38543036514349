import styled from 'styled-components';

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${props => props.mt};

    @media only screen and (max-width: 500px){
        padding: 0 1em;
    }
`;

export const Page = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
    background-color: white;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    cursor: pointer;
`;

export const Back = styled.div`
    margin-right: 1em;
    cursor: pointer;

    svg{
        width: 8px;
        height: 8px;
    }
`;

export const Next = styled.div`
    margin-left: 1em;
    cursor: pointer;

    svg{
        width: 8px;
        height: 8px;
    }
`;