import styled from "styled-components";

export const FeedbackContainer = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${props => (props.type === "success" ? "green" : props.type === "fail" ? "red" : null)};
  letter-spacing: 0.1em;
  text-align: center;
`;
