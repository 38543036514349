import styled from 'styled-components';

export const TotalOfReviews = styled.p`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
  margin-top: 2em;
  
  @media only screen and (max-width: 500px){
    padding: 0 1em;
  }
`;

export const SkeletonContainer = styled.div`
  display: grid;
  grid-row-gap: 1em;
  margin-top: 1em;
`;

export const UnansweredReviewsFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 500px){
    padding: 0 1em;
  }
`;