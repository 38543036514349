import React from "react";
import { TrustedContainer } from "../styled";
import yelp from '../../../../../Assets/images/yelp.png';
import tripadvisor from '../../../../../Assets/images/tripadvisor.png';
import foursquare from '../../../../../Assets/images/foursquare.png';
import opentable from '../../../../../Assets/images/opentable.png';
import yahoo from '../../../../../Assets/images/yahoo.png';

export default class TrustedCompanies extends React.Component {
  render() {
    return (
      <React.Fragment>
        <label>Coming soon:</label>
        <TrustedContainer>
          <div>
            <img src={yelp} />
          </div>

          <div>
            <img src={tripadvisor} />
          </div>

          <div>
            <img src={foursquare} />
          </div>

          <div>
            <img src={opentable} />
          </div>

          <div>
            <img src={yahoo} />
          </div>
        </TrustedContainer>
      </React.Fragment>
    );
  }
}
