import styled from 'styled-components';
import { blueDefault } from '../../Assets/colorPallet/colorPallet';

export const InputFile = styled.input`
    padding: 2em;
    border: 1px solid #ccc;
    outline: none;
    color: #888;
    margin-top: 1em;
`;

export const ImportContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2em; */
    box-sizing: border-box;

    .upload-icon{
        div:nth-child(1){
            &:first-child{
                display: flex;
                svg{
                    width: 20px;
                    path{
                        fill: ${blueDefault};
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 600px) {
      .import-contact-container--title-container{
        flex-direction: column;
      }
    }
`;
