import React from 'react';
import axios from 'axios';
import { LatestStatsContainer } from './styled';
import { Label, Column } from '../../../Assets/Structure/Structure';
import AverageRatingChart from '../../../Components/AverageRatingChart/AverageRatingChart';
import ConvertionRateChart from '../../../Components/ConvertionRateChart/ConvertionRateChart';
import { API_URL } from '../../../config';

export default class LatestStats extends React.Component{

    state = {
        reviews: [],
        reviewsSinceStart: 0
    }

    componentDidMount(){
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const location = localStorage.getItem("location");
        const headers = {headers: { "x-sas-Auth" : authToken }};

        axios.get(`${API_URL}/private/stores/${store}/locations/${location}/reviews`, headers)
        .then(res => {
            const reviews = res.data.reviews;
            this.setState({ reviews });
        })

        axios.get(`${API_URL}/private/stores/${store}/locations/${location}/stats/reviews`, headers)
        .then(res => {
            const reviewsSinceStart = res.data.reviews.since_start;
            this.setState({ reviewsSinceStart })
        })
    }

    render(){

        const { 
            locationRating, 
            totalReviews, 
            totalRequestsSent, 
            googleRating, 
            facebookRating,
            redirectsToFacebook,
            ctr,
            redirectsToGoogle
        } = this.props;

        return (

            <LatestStatsContainer>
                <Label uppercase fontSize="12px" color="#666">Latest stats from your location</Label>
                <Column mt="2em">
                    <AverageRatingChart 
                        locationRating={locationRating || 0}
                        googleRating={googleRating || 0} 
                        redirectsToGoogle={redirectsToGoogle || 0}
                        redirectsToFacebook={redirectsToFacebook || 0}
                        facebookRating={facebookRating || 0}
                        reviews={this.state.reviews} 
                    />
                </Column>
                
                <Column mt="2em">
                    <ConvertionRateChart 
                        totalReviews={this.state.reviewsSinceStart} 
                        ctr={ctr}
                        totalRequestsSent={totalRequestsSent} 
                        noPerformance
                    />
                </Column>
            </LatestStatsContainer>
        )
    }
}