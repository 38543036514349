import styled from 'styled-components';

export const MenuContainer = styled.div`
    display: none;

    @media only screen and (max-width: 835px){
        display: block;
        position: fixed;
        left: ${props => props.isMenuActive ? "0" : "-100%"};
        opacity: ${props => props.isMenuActive ? "1" : "0"};
        height: 100vh;
        background-color: white;
        top: 0;
        z-index: 160;
        width: 80%;
        transition: left .4s ease, opacity .2s ease;
    }
`;

export const Overlay = styled.div`
    display: none;

    @media only screen and (max-width: 835px){
        background-color: #00000090;
        position: fixed;
        top: 0;
        justify-content: flex-end;
        align-items: baseline;
        display: flex;
        width: 100%;
        left: 0;
        transition: left .4s ease;
        z-index: 160;
        pointer-events: ${props => props.isMenuActive ? "all" : "none"};
        height: 100vh;
        opacity: ${props => props.isMenuActive ? "1" : "0"};
        transition: all .4s ease, opacity .4s ease;

        svg{
            width: 20%;
            cursor: pointer;
            position: absolute;
            padding: 1em 0;
            right: 0;
        }
    }
`;