import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import SelectAlt from "../../../../Components/SelectAlt/SelectAlt";

function ChooseLocation(props) {
    const [placeholder, setPlaceholder] = useState("Select location")

    useEffect(() => {
        setPlaceholder(props.locations[0].name)
        props.onInitialSelectedLocation(props.locations ? props.locations[0] : null)
    }, [props.locations])

    const handleSelectedLocation = (selectedLocation) => {
        setPlaceholder(selectedLocation.name)
        props.onChange(selectedLocation)
    }

    return (
        <SelectAlt
            options={props.locations}
            selectedOption={handleSelectedLocation}
            placeholder={placeholder}
            id="sent-by"
        />
    )
}

const mapStateToProps = state => ({
    locations: state.locations.locations
})

export default connect(mapStateToProps)(ChooseLocation)
