import React from "react";
import ReactSVG from 'react-svg';
import EllipsisText from "react-ellipsis-text";
import { SelectAltContainer } from "./styled";
import SelectOptions from "./SelectOptions/SelectOptions";
import arrowIcon from '../../Assets/icons/arrow.svg';
import { FluidContainer } from "../../Assets/Structure/Structure";

export default class SelectAlt extends React.Component {
  state = {
    active: false,
  };

  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById(this.props.id);
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ active: false });
    });
  };

  showSelectOptions = active => {
    this.setState({ active });
    this.detectClick();
  };

  handleSelectedOption = (option, type) => {
    this.props.selectedOption(option, this.props.type);
    this.setState({ active: false, placeholder: option.label || option.name });
  };

  render() {
    const { style, options, position, maxWidth } = this.props;

    return (
      <FluidContainer id={this.props.id}>
        <SelectAltContainer
          onClick={() => this.showSelectOptions(true)}
          style={style}
          active={this.state.active}
        >
          <label>
            {this.props.placeholder}
          </label>
          <ReactSVG src={arrowIcon} />
        </SelectAltContainer>
        {this.state.active && (
          <SelectOptions
            selectedOption={this.handleSelectedOption}
            options={options}
            position={position}
            maxWidth={maxWidth}
          />
        )}
      </FluidContainer>
    );
  }
}
