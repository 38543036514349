import styled from 'styled-components';

export const WidgetMenuContainer = styled.div`
    background-color: #eee;
    height: calc(100vh - 91px);
    position: fixed;
    width: 100%;
    max-width: 18em;
    top: 0;
    right: 0;
    margin-top: 91px;
    padding: 1em;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    transition: left .4s ease;
    overflow-y: auto;

    &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    label{
        font-weight: 400;
        line-height: 30px;
        font-size: 18px;
        letter-spacing: 0.1em;
        color: #449BC1;
    }

    p{
        font-weight: 400;
        line-height: 25px;
        font-size: 14px;
        letter-spacing: 0.05em;
        color: #000000;
    }


    @media only screen and (max-width: 1025px){
        left: ${props => (
            props.sideMenuOpen && props.device === "mobile" ? "0" 
            : props.sideMenuOpen && props.device === "tablet" ? "50%"  : "100%")};
        max-width: ${props => (
            props.device === "mobile" ? "100%" 
            : props.device === "tablet" ? "50%" 
            : null)};
        margin-top: 0;
        z-index: 100;
        height: 100vh;

        .close-container{
            display: flex;
            justify-content: flex-end;

            svg{
                @media only screen and (max-width: 500px){
                    overflow: unset;
                }
                path{
                    transform: translate(50%, 25%); 
                }
            }
        }

    }
`;

export const WidgetContainer = styled.div`
  
`;

export const Overlay = styled.div`
    position: fixed;
    left: 0;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000090;
`;

export const BeaconGuideButton = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0;
    cursor: pointer;

    div:nth-child(1){
        &:first-child{
            display: flex;
        }
    }

    span{
        margin-left: .5em;
    }
`;
