import React from 'react';
import ReactSVG from 'react-svg';
import { FeedbackContainer, Text } from './styled';

export default class Feedback extends React.Component{
    render(){

        const { 
            iconPath, 
            text, 
            padding, 
            color, 
            center, 
            fontSize, 
            direction, 
            type, 
            svgSize, 
            svgColor ,
            ml
        } = this.props;

        return (
            <FeedbackContainer 
             svgColor={svgColor} 
             svgSize={svgSize} 
             type={type} 
             padding={padding} 
             direction={direction}>
                <ReactSVG src={iconPath} />
                <Text 
                ml={ml}
                color={color} 
                fontSize={fontSize}
                center={center}>{text}</Text>
            </FeedbackContainer>
        )
    }
}