import styled from "styled-components";

export const TeamTableContainer = styled.div`
  margin-top: 2em;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  max-width: 40em;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 2em 0;

  .pagination {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      width: 20px;
      height: 20px;
      border: 2px solid #03a9f4;
      display: flex;
      margin: 0 .2em;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
  }
`;


export const SkeletonContainer = styled.div`

`;