import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class HowToCheckMyReviews extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                   On the left side of the page click on <strong style={{ color: blueDefault }}>Reviews</strong>.
                   You can filter your reviews based on: Unanswered reviews, Good, Bad, Neutral, By Location and By Platform.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}