import styled from "styled-components";

export const SelectFluidContainer = styled.div`
  width: 100%;
  max-width: ${props => props.absolute ? props.maxWidth : "100%"};
`;

export const SelectAction = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${props => (props.noBorder ? "none" : "1px solid #B5B5B5")};
  padding: 1em 0;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: ${props => props.justify ? props.justify : null};


  svg {
    width: 7px;
    order: ${props => props.invertOrder && "1"};
    margin: 0 .5em 0 1em;
  }
  @media only screen and (max-width: 500px) {
    border: none;
    svg {
      margin-left: 0.5em;
    }
  }
`;

export const Label = styled.label`
  font-weight: ${props => props.bold ? "600" : "normal" };
  font-size: ${props => props.fs ? props.fs : "16px"};
  text-transform: ${props => props.uppercase ? "uppercase" : null};
  letter-spacing: 0.1em;
  display: flex;
  color: ${props => props.color ? props.color : "#666"};
  cursor: pointer;
  order: ${props => props.invertOrder && "2"};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => props.showFullName ? "100%" : null};
  max-width:${props => props.maxWidth ? props.maxWidth : null};


  span{
      display: flex;
      align-items: center;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;


export const ArrowContainer = styled.div`
  svg{
    transform: ${props => props.isSelectOn ? "rotate(180deg)" : null};
  }
`;