import styled from 'styled-components';

export const OverviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1em;
    box-sizing: border-box;
    border-bottom: 1px solid #E6E6E6;

    @media only screen and (max-width: 500px){
        white-space: nowrap;
        display: flex;
    }
`;

export const Span = styled.span`
    font-weight: 600;
`;