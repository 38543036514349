import styled from "styled-components";
import { blueDefault, blueDefaultHover } from "../../../Assets/colorPallet/colorPallet";

export const AnswerReviewContainer = styled.div`

  @media only screen and (max-width: 500px){
    height: 100vh;
    background-color: white;
  }
`;

export const HeaderContainer = styled.div`
  padding: 2em;
  box-sizing: border-box;
  background-color: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  p {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #666666;
    margin: 0.5em 0 0 0;  
  }
`;

export const Label = styled.label`
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const BodyContainer = styled.div`
  background-color: white;
  padding: 2em;
  box-sizing: border-box;
  max-height: 70vh;
    overflow: auto;

    .smile-answer{
      svg{
        width: 50px;
        height: 50px;
      }
    }

    @media only screen and (max-width: 500px){
    height: 80vh;
    max-height: 80vh;

    .top-answer{
      flex-direction: column;
    }
  }
`;

export const AuthorName = styled.label`
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const LocationContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 13px;
    fill: #999;
  }
`;

export const LocationAddress = styled.p`
  margin: 0 0 0 0.5em;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #999999;
`;

export const DateContainer = styled.div``;

export const Date = styled.label`
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #666666;
`;

export const Text = styled.p`
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #333333;
`;

export const Form = styled.form`
  background-color: #f8f8f8;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  min-height: 10em;
  justify-content: space-between;
  display: flex;
  margin-top: 1em;
  flex-direction: column;

  @media only screen and (max-width: 500px){
    height: auto;
  }
`;

export const Textarea = styled.textarea`
  background-color: #f8f8f8;
  border: none;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  outline: none;
  min-height: ${props => props.minHeight ? props.minHeight : "8em"};;
  max-height: ${props => props.maxHeight ? props.maxheight : "8em"};
  letter-spacing: .1em;

  color: #666;

  &::placeholder {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #999999;
  }

  @media only screen and (max-width: 500px){
    max-height: 38vh;
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
`;

export const AnswerButton = styled.button`
  background-color: ${blueDefault};
  border-radius: 40px;
  border: none;
  color: white;
  letter-spacing: 0.1em;
  transition: background-color .4s ease;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px; 
  cursor: pointer;
  outline: none;

  &:hover{
      background-color: ${blueDefaultHover};
  }
`;


export const CloseContainer = styled.div`
    cursor: pointer;

    svg{
      position: absolute;
      top: 1em;
      right: 1em;
    }
`;
