import React from "react";
import { ResetFilterAction } from "./styled";

export default class ResetFilter extends React.Component {

  render() {
    return (
      <ResetFilterAction
        onClick={this.props.onClick}
      >
        Reset Filter
      </ResetFilterAction>
    );
  }
}
