import styled from 'styled-components';

export const InvitationFeedbackContainer = styled.div`

    @media only screen and (max-width: 500px){
        .mob-container{
            padding: 1em;
        }
    }
`;

export const LocationContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    align-items: center;

    svg{
        margin-right: .5em;
    }
`;

export const CloseContainer = styled.div`
    padding: 1em;   
    box-sizing: border-box;

    svg{
        cursor: pointer;
    }
`;

