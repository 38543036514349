import styled from 'styled-components';
import { blueDefaultHover, blueDefault, notAllowed } from '../../../../../Assets/colorPallet/colorPallet';

export const StepTwoActionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #333333;
    padding: 2em;
    box-sizing: border-box;
`;

export const ContinueButton = styled.button`
    border: none;
    height: 50px;
    width: 100%;
    outline: none;
    max-width: 367px;
    background-color: ${props => props.active ? blueDefault : "#3a3a3a"};
    color: ${props => props.active ? "white" : "#4d4c4c"};
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .2em;
    border-radius: 20em;
    transition: background-color .4s ease;
    cursor: ${props => props.active ? "pointer" : "none"};
    pointer-events: ${props => props.active ? "all" : "none"};

    &:hover{
        background-color: ${props => props.active ? blueDefaultHover : notAllowed};
    }
`;