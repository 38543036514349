import styled from "styled-components";

export const LocationOverviewContainer = styled.div`  
  width: 100%;
  margin: 0 1em;
  max-width: 369px;

  @media only screen and (max-width: 770px){
    max-width: 100%;
  }

  @media only screen and  (max-width: 600px){
        margin: 0;
    }
`;

export const LocationName = styled.h2`
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin: 0;
`;

export const LocationAddress = styled.p`
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin: 0;

  svg {
    margin-right: 0.5em;
    fill: white;

    path {
      fill: white;
    }
  }
`;
