import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactSVG from 'react-svg';
import queryString from "query-string";
import { API_URL } from '../../config';
import Module from '../../Components/Module/Module';
import Button from '../../Components/Button/Button';
import {ChangePasswordContainer, Form, LoadingContainer, Input, Label, Anchor, FeedbackText} from './styled';
import { Row, Column } from '../../Assets/Structure/Structure';
import raLogo from "../../Assets/icons/ra-logo-color.svg";
import { red, blueDefault, blueDefaultHover, green } from '../../Assets/colorPallet/colorPallet';


export default class ChangePassword extends React.Component {

    state = {
        newPassword: "",
        repeatPassword: "",
        passwordDoesNotMatch: false,
        passwordUpdatedWithSuccess: false,
        failedRequest: false,
        code: ""
    }

    componentDidMount(){
        let parsed = queryString.parse(window.location.search);

        if (parsed.code) {
            this.setState({ 
                code: parsed.code 
            })
        } 
    }

    handleChange = (e, type) => {
        this.setState({ [type]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const payload = { password: this.state.newPassword };
        const headers = { headers:{"x-sas-Auth" : this.state.code} }
        
        if (this.state.newPassord === this.state.repeatPassowrd){
            axios.put(`${API_URL}/public/user/credential/forgot`, payload, headers)
            .then(res => {
                this.setState({ passwordUpdatedWithSuccess: true, loading: false })
            }).catch(() => {
                this.setState({ loading: false, failedRequest: true })
            })
        } else {
            this.setState({ 
                passwordDoesNotMatch: true,
                loading: false
             })
        }
    }

    handleClose = () => {
        this.props.isModalOpen(false, "")
    }


    render() {

        const { loading } = this.state;

        return (
            <ChangePasswordContainer>
                <Row maxWidth="40em">
                    <ReactSVG src={raLogo}/>
                </Row>
                <Form onSubmit={this.handleSubmit}>
                    <Module className="login-module">
                        {this.state.loading
                            ? (
                                <LoadingContainer>
                                    <img src={loading}/>
                                </LoadingContainer>
                            )
                            : (
                                <React.Fragment>
                                    <Column>
                                        <Label>Enter your new password</Label>
                                        <Input
                                            type="password"
                                            placeholder="Enter password"
                                            value={this.state.newPassword}
                                            onChange={(e) => this.handleChange(e, "newPassword")}
                                            required/>
                                    </Column>

                                    <Column>
                                        <Label>Confirm new password</Label>
                                        <Input
                                            type="password"
                                            placeholder="Confirm your new password"
                                            value={this.state.repeatPassword}
                                            onChange={(e) => this.handleChange(e, "repeatPassword")}
                                            required/>
                                    </Column>
                                    {this.state.passwordDoesNotMatch && 
                                    <FeedbackText color={red}>Password does not match. Please try again.</FeedbackText>}
                                    {this.state.passwordUpdatedWithSuccess &&
                                    <FeedbackText color={green}>Password updated with success</FeedbackText>}
                                    {this.state.failedRequest &&
                                    <FeedbackText color={red}>Something went wrong. Please try again.</FeedbackText>}
                                    <Row alignCenter>
                                        <Button
                                            text="Reset your password"
                                            uppercase
                                            width="310px"
                                            type="submit"
                                            bgColor={blueDefault}
                                            bgColorOnHover={blueDefaultHover}
                                            color="white"
                                            borderRadius="20em"/>
                                        <Link to="/login" style={{ textDecoration: "none" }}>
                                            <Anchor
                                                color="#999"
                                                ml1>
                                                Back to Sign in
                                            </Anchor>
                                        </Link>
                                    </Row>
                                </React.Fragment>
                            )}

                    </Module>
                </Form>
            </ChangePasswordContainer>
        )
    }
}