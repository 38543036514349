import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import {
  AllReviewsChartContainer,
  Container,
  Row,
  Label,
  TotalReviews,
  ReviewsList
} from "./styled";
import ReviewType from "./ReviewType/ReviewType";
import AllReviewsChartSkeleton from "./AllReviewsChartSkeleton";

export default class AllReviewsChart extends React.Component {
  render() {

    const { totalReviews, loading, positiveReviews, neutralReviews, negativeReviews } = this.props;

    const positivePercentage = (positiveReviews * 100) / totalReviews;
    const neutralPercentage = (neutralReviews * 100) / totalReviews;
    const negativePercentage = (negativeReviews * 100) / totalReviews;

    return (
      <AllReviewsChartContainer>
        {loading ? (
          <AllReviewsChartSkeleton />
        ) : (
          <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
          <Container>
          <Row between>
            <Label>All reviews</Label>
            <TotalReviews>{totalReviews}</TotalReviews>
          </Row>
          <ReviewsList>
            <ReviewType
              type="Positive"
              amount={positiveReviews}
              bgColor="#44CA61"
              percentage={positivePercentage}
              loading={this.props.loading}
              />
            <ReviewType
              type="Neutral"
              amount={neutralReviews}
              bgColor="#449BC1"
              percentage={neutralPercentage}
              loading={this.props.loading}
            />
            <ReviewType
              type="Negative"
              amount={negativeReviews}
              bgColor="#E97373"
              percentage={negativePercentage}
              loading={this.props.loading}
              />
          </ReviewsList>
        </Container>
        </CSSTransitionGroup>
          )}
      </AllReviewsChartContainer>
    );
  }
}
