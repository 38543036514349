import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { SkeletonPageAssociationContainer, SkeletonHeader, SkeletonBody, SkeletonActionContainer } from './styled';
import { PagesContainer, Page } from '../NewIntegration/StepTwo/styled';
import Footer from '../NewIntegration/Footer/Footer';
import Header from '../NewIntegration/Header/Header';

export default class SkeletonPageAssociation extends React.Component{
    render(){
        return (
            <SkeletonPageAssociationContainer>
                <Header loading={true} />
                <SkeletonBody>
                    <Skeleton width="100%" count={2} />
                    <PagesContainer>
                        <Page>
                            <Skeleton count={2} />
                        </Page>

                        <Page>
                            <Skeleton count={2} />
                        </Page>
                    </PagesContainer>
                </SkeletonBody>
                <Footer currentStep={2} loading={true} />
            </SkeletonPageAssociationContainer>
        )
    }
}