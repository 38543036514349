import { DateTime } from 'luxon';


export const formatTimeLabel = (time) => {
    const timeInMiliseconds = DateTime.fromISO(time).toMillis()
    const today = DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toMillis()
    const yesterday = DateTime.fromMillis(today).minus({ day: 1 }).set({ hour: 0, minute: 0, second: 0 }).toMillis()
    const createdAtInMilliseconds = DateTime.fromISO(time).toMillis()
    const currentMinuteInMiliseconds = DateTime.local().minus({ minute: 1 }).toMillis()

    
    if (timeInMiliseconds >= currentMinuteInMiliseconds) {
        return "now"
    } else if ((createdAtInMilliseconds < today) && (createdAtInMilliseconds >= yesterday)) {
        return `yesterday`
    } else if ((createdAtInMilliseconds >= today)) {
        return DateTime.fromMillis(timeInMiliseconds).toFormat("t")
    } else {
        return DateTime.fromMillis(timeInMiliseconds).toLocaleString() 
    }

}
