import styled from 'styled-components';
import { blueDefault, blueDefaultHover, red } from '../../../Assets/colorPallet/colorPallet';

export const NewLocationForm = styled.form`
    width: 100%;
    font-family: "Roboto", sans-serif;
    padding: 0 2em 2em;
    box-sizing: border-box;
    max-height: 86vh;
    
    
    label{
        font-weight: normal;
        font-size: 14px;
        color: #333333;
    }
    .form-item{
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;

        input{
            border: none;
            border-bottom: 1px solid #B5B5B5;
            padding: 10px 0;
            outline: none;
            font-size: 20px;

            &::placeholder{
                color: #B5B5B5;
            }
        }

        @media only screen and (max-width: 500px){
            margin-bottom: 0;

            &:not(:first-child){
                margin-top: 1em;
            }
        }
    }

    .form-row-item{
        display: flex;

        @media only screen and (max-width: 500px){
            flex-direction: column;

            .form-item{
                margin-top: 1em;
            }
        }
    }

    .btn-save-location{
        margin-top: 2em;
        color: white;
        transition: background-color .4s ease;
        
        label {
          color: white;
        }

        &:hover{
            background-color: ${blueDefaultHover};
        }
        
        @media only screen and (max-width: 600px) {
          width: 100% !important;
        }
    }

    @media only screen and (max-width: 500px){
        max-height: 90vh;
    }

`;

export const ScrollContainer = styled.div`
    max-height: 67vh;
    overflow-y: auto;

    @media only screen and (max-width: 500px){
        overflow-y: unset;
    }
`;

export const AlertText = styled.p`
    color: ${red};
    font-size: 12px;
    text-align: center;
    line-height: 24px;

    a{
        text-decoration: none;
        color: ${red};
    }

    span{
        font-size: 14px;
        font-weight: 600;
    }
`;
