import styled from "styled-components";
import { blueDefault } from "../../../Assets/colorPallet/colorPallet";

export const SendCampaignButton = styled.div`
  button {
    border: none;
    padding: 0;
    background: transparent;

    svg {
      cursor: pointer;
      transition: opacity 0.4s ease;
      opacity: ${props =>
        props.device === "desktop" && props.showSendAction
          ? "1"
          : props.device === "desktop" && !props.showSendAction
          ? "0"
          : "1"};
  
      path {
        fill: #333;
        transition: fill 0.4s ease;
      }
  
      &:hover {
        path {
          fill: ${blueDefault};
        }
      }
    }
  }
`