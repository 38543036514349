import styled from "styled-components";

export const OverviewByPlatformContainer = styled.div`
  margin-top: 2em;
`;

export const Label = styled.p`
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;

  @media only screen and (max-width: 500px){
    padding: 0 1em;
  }
`;

export const PlatformsContainer = styled.div`
    display: grid;
    margin-top: 2em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;

    @media only screen and (max-width: 800px){
      grid-template-columns: 1fr;
    }

    @media only screen and (max-width: 500px){
      grid-template-columns: 1fr;
    }

    @media only screen and (min-width: 2000px){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 2500px){
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
`;