import React from "react";
import { CSSTransitionGroup } from 'react-transition-group'
import ReactSVG from "react-svg";
import {
  ReviewContainer,
  BodyContainer,
  FooterContainer,
  SmileContainer,
  Row,
  AuthorName,
  LocationAddress,
  LocationIconContainer,
  ReviewText,
  DateContainer,
  Date,
  AnswerContainer,
  PlatformContainer,
  Col
} from "./styled";
import Rating from "../Rating/Rating";
import positive from "../../Assets/icons/positive.svg";
import neutral from "../../Assets/icons/neutral.svg";
import negative from "../../Assets/icons/negative.svg";
import google from "../../Assets/icons/google.svg";
import facebook from "../../Assets/icons/facebook.svg";
import locationIcon from "../../Assets/icons/location.svg";
import answerIcon from "../../Assets/icons/answer.svg";

export default class Review extends React.Component {

  render() {
    const { review, marginTop, locations } = this.props;

    let smile;
    let showAnswerReview = false;

    if (review.rating > 3) smile = positive;
    if (review.rating === 3) smile = neutral;
    if (review.rating < 3) smile = negative;

    const reviewLocation = review.location;

    locations.map(location => {
      if (location.id === reviewLocation){
        const reviewServices = location.review_services;
        if (reviewServices.includes("google")){
         showAnswerReview = true;
        } else {
          showAnswerReview = false;
        }
      }
    })


    return (
      <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
      <ReviewContainer marginTop={marginTop}>
        <BodyContainer>
          <Row>
            <SmileContainer >
              <ReactSVG src={smile} />
            </SmileContainer>
            <Col style={{ marginLeft: "1em" }}>
              <AuthorName>{review.author}</AuthorName>
              <LocationIconContainer>
                <ReactSVG src={locationIcon} />
                <LocationAddress>{review.location_name}</LocationAddress>
              </LocationIconContainer>
            </Col>
            <Col style={{ margin: "auto 0 auto auto" }}>
              <Rating totalOfStars={[1, 2, 3, 4, 5]} rating={review.rating} />
              <DateContainer>
                <Date>{review.time}</Date>
              </DateContainer>
            </Col>
          </Row>

          <Row>
            <ReviewText>{review.text}</ReviewText>
          </Row>
        </BodyContainer>
        <FooterContainer>
          <Row between>
            {showAnswerReview && review.review_service === "Google" ? (
                <AnswerContainer onClick={this.props.answerReview}>
                 {review.reply === null ? (
                   <React.Fragment>
                    <ReactSVG src={answerIcon} />
                    <label>Answer review</label>
                   </React.Fragment>
                  ) : (
                      <label>See answer</label>
                  )}
                </AnswerContainer> 
            ) : <AnswerContainer></AnswerContainer>}
            <PlatformContainer>
              {review.review_service === "Google" ? (
                <ReactSVG src={google} />
              ) : review.review_service === "Facebook" ? (
                <ReactSVG src={facebook} />
              ) : null}
            </PlatformContainer>
          </Row>
        </FooterContainer>
      </ReviewContainer>
      </CSSTransitionGroup>
    );
  }
}
