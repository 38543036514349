import React from 'react';
import ReactSVG from 'react-svg';
import axios from 'axios';
import { API_URL } from '../../config';
import Modal from '../Modal/Modal';
import { Label, FormItem } from '../../Assets/Structure/Structure';
import { Input, Form, FeedbackText } from '../../Pages/Login/styled';
import Button from '../Button/Button';
import { ChangePasswordModalContainer, CloseContainer } from './styled';
import closeIcon from '../../Assets/icons/close.svg';
import { green, red } from '../../Assets/colorPallet/colorPallet';

export default class ChangePasswordModal extends React.Component{

    state = {
        newPassord: "",
        repeatPassowrd: "",
        passwordDoesNotMatch: false,
        passwordUpdatedWithSuccess: false
    }

    handleChange = (e, type) => {
        this.setState({ [type]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // const payload = { password: this.state.newPassord };
        // const headers = { headers:{"x-sas-Auth" : this.props.code} }
        
        // if (this.state.newPassord === this.state.repeatPassowrd){
        //     axios.put(`${API_URL}/public/user/credential/forgot`, payload, headers)
        //     .then(res => {
        //         this.setState({ passwordUpdatedWithSuccess: true })
        //     })
        // } else {
        //     this.setState({ 
        //         passwordDoesNotMatch: true
        //      })
        // }
    }

    handleClose = () => {
        this.props.isModalOpen(false, "")
    }

    render(){

        const { newPassord, repeatPassowrd } = this.state;

        return(
            <Modal maxWidth="20em" isModalOpen={this.handleClose}>
                <ChangePasswordModalContainer>
                    <CloseContainer>
                        <ReactSVG src={closeIcon} onClick={this.handleClose} />
                    </CloseContainer>
                    <Form onSubmit={this.handleSubmit}>  
                        <FormItem>
                            <Label>New Password</Label>
                            <Input 
                                type="password" 
                                value={newPassord} 
                                onChange={(e) => this.handleChange(e, "newPassword")} required />
                        </FormItem>
                        <FormItem mt2>
                            <Label>Repeat password</Label>
                            <Input 
                                type="password" 
                                value={repeatPassowrd} 
                                onChange={(e) => this.handleChange(e, "repeatPassword")} required />
                        </FormItem>
                        {this.state.passwordDoesNotMatch && 
                        <FeedbackText color={red}>Password does not match, please try again.</FeedbackText>}
                        {this.state.passwordUpdatedWithSuccess &&
                        <FeedbackText color={green}>Password updated with success</FeedbackText>}
                        <Button 
                            color="white"
                            text="Change password" 
                            type="submit"
                            mt="2em"
                            borderRadius="20em" />
                    </Form>
                </ChangePasswordModalContainer>
            </Modal>
        )
    }
}