import styled from "styled-components";

export const LocationsContainer = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 1em;
  background-color: white;
  max-width: 16em;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 1em;
  height: 15em;
  box-sizing: border-box;
  z-index: 10;
  margin-top: ${props => (props.upContainer && "-14em")};
`;

export const LocationsList = styled.ul`
    margin: 1em 0 0 0;
    list-style: none;
    padding: 0;
    max-height: 9em;
    overflow: auto;
`;

export const Location = styled.li`
      margin: 0.5em;
    padding: 0.5em 1em;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #333333;

  &:hover{
    background-color: #f4f4f4;
  }
`;


export const SearchContainer = styled.div`

`;