import styled from 'styled-components';

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: .25em 0;
    box-sizing: border-box;
    cursor: pointer;

    svg{
        width: 6px;
        transform: ${props => props.active ? "rotate(0deg)" : "rotate(-90deg)" };
    }
`;

export const Question = styled.p`
    font-weight: 600;
`;

export const DescriptionContainer = styled.div`
    height: ${props => props.active ? "auto" : "0"};
    transition: height .4s ease;
    overflow: hidden;
`;