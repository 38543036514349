import React from "react";
import ReactSVG from "react-svg";
import { PaginationContainer, Page, Back, Next } from "./styled";
import arrowLeftIcon from "../../Assets/icons/arrow-left.svg";
import arrowRightIcon from "../../Assets/icons/arrow-right.svg";

export default class Pagination extends React.Component {
  state = {
    currentPage: 1,
    minLimit: 1,
    maxLimit: 10,
    paginationSize: 10
  };

  componentDidMount(){
    const screenWidth = window.innerWidth;
    if (screenWidth <= 500){
      this.setState({
        maxLimit: 5,
        paginationSize: 5
      })
    } else {
      this.setState({
        maxLimit: 10,
        paginationSize: 10
      })
    }
  }

  handleClick = currentPage => {
    this.setState({ currentPage });
    this.props.currentPage(currentPage);
  };

  handleBack = () => {
    let maxLimit = this.state.maxLimit;
    let minLimit = this.state.minLimit;
    
    if (minLimit > 1){
        maxLimit = maxLimit - this.state.paginationSize;
        minLimit = minLimit - this.state.paginationSize;
        this.setState({ maxLimit, minLimit })
    }
  };

  handleNext = () => {
    let maxLimit = this.state.maxLimit;
    let minLimit = this.state.minLimit;

    const totalOfPages = Math.ceil(this.props.totalOfItems / this.props.maxItemsPerPage);
    if (maxLimit < totalOfPages){
    maxLimit = maxLimit + this.state.paginationSize;
    minLimit = minLimit + this.state.paginationSize;
    this.setState({ maxLimit, minLimit })
    }
  };

  render() {
    const { totalOfItems, maxItemsPerPage, mt, isMobileActive } = this.props;
    const totalOfPages = Math.ceil(totalOfItems / maxItemsPerPage);

    let pages = [];
    for (let i = 0; i < totalOfPages; i++) pages.push(i + 1);

    return (
      <PaginationContainer mt={mt}>
        {pages.length > 1 && (
          <Back onClick={this.handleBack}>
          <ReactSVG src={arrowLeftIcon} />
        </Back>
        )}
        {pages.map((page, index) => {
          if (
            index + 1 >= this.state.minLimit &&
            index + 1 <= this.state.maxLimit
          ) {
            return (
              <Page
                onClick={() => this.handleClick(index + 1)}
                style={
                  this.state.currentPage === index + 1
                    ? { backgroundColor: "transparent", boxShadow: "none" }
                    : { backgroundColor: "white" }
                }
              >
                {page}
              </Page>
            );
          }
        })}
        {pages.length > 1 && (
        <Next onClick={this.handleNext}>
          <ReactSVG src={arrowRightIcon} />
        </Next>
        )}
      </PaginationContainer>
    );
  }
}
