import React from "react";
import { NewIntegrationContainer } from "./styled";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";

export default class NewIntegration extends React.Component {

  state = {
    currentLocation: []
  }

  componentDidMount(){
    const currentLocationId = localStorage.getItem("current_location_id");
    const currentLocationName = localStorage.getItem("current_location_name");
    const currentLocationAddress = localStorage.getItem("current_location_address");
    this.setState({ currentLocationId, currentLocationName, currentLocationAddress });
  }

  handleCloseModal = isModalOpen => {
    this.props.isModalOpen(isModalOpen);
  };

  handleService = service => {
    this.setState({ service, currentStep: 2 });
  };

  handleCurrentStep = (currentStep) => this.props.updateCurrentStep(currentStep)

  handleUpdatedLocations = updatedLocations => this.props.updatedLocations(updatedLocations);

  render() {
    
    return (
      <NewIntegrationContainer>
        {this.props.currentStep === 1 ? (
          <StepOne
            service={this.handleService}
            isModalOpen={this.handleCloseModal}
            currentLocationId={this.state.currentLocationId}
            currentLocationName={this.state.currentLocationName}
            currentLocationAddress={this.state.currentLocationAddress}
            currentStep={this.props.currentStep}
            currentLocation={this.props.currentLocation}
          />
        ) : this.props.currentStep === 2 ? (
          <StepTwo
            service={this.props.service}
            isModalOpen={this.handleCloseModal}
            currentStep={this.props.currentStep}
            currentLocationId={this.state.currentLocationId}
            currentLocationName={this.state.currentLocationName}
            currentLocationAddress={this.state.currentLocationAddress}
            updatedCurrentStep={this.handleCurrentStep}
            pages={this.props.pages}
            updatedLocations={this.handleUpdatedLocations}
          />
        ) : this.props.currentStep === 3 ? (
          <StepThree
            isModalOpen={this.handleCloseModal}
            currentLocationId={this.state.currentLocationId}
            currentLocationName={this.state.currentLocationName}
            currentLocationAddress={this.state.currentLocationAddress}
            currentStep={this.props.currentStep}
          />
        ) : null}
      </NewIntegrationContainer>
    );
  }
}
