import React from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { setIntegrationIssueStatus } from '../../store/modules/integration/actions';
import { Link } from 'react-router-dom';
import Modal from '../../Components/Modal/Modal';
import { Text, Container, Row } from '../../Assets/Structure/Structure';
import { blueDefault } from '../../Assets/colorPallet/colorPallet';
import { CloseContainer, LocationsContainer, Location, Services, Service } from './styled';
import closeIcon from '../../Assets/icons/close.svg';
import googleIcon from '../../Assets/icons/google.svg';
import facebookIcon from '../../Assets/icons/facebook.svg';

class IntegrationIssueAlert extends React.Component{

    handleClose = (isModalOpen) => {
        this.props.isModalOpen(isModalOpen)
        this.props.setIssueStatus(false)
    }

    componentWillUnmount(){
        this.props.setIssueStatus(false)
    }

    render(){
        return (
            <Modal isModalOpen={this.handleClose} className="integration-issue-alert-modal">
                <CloseContainer >
                    <ReactSVG src={closeIcon} onClick={() => this.handleClose(false)} />
                </CloseContainer>
                <Container padding="0 2em 2em">
                    <Text>
                        It seems like the following locations had their Facebook or Google token expired.
                        Please <Link
                        style={{ textDecoration: "none", color: blueDefault, fontWeight: "600", cursor: "pointer"}}
                        to="/store-location">
                            click here
                        </Link> to reconnect and load your reviews
                    </Text>
                </Container>
                <Container>
                    <LocationsContainer>
                        {this.props.report.map(loc => {
                            if ((loc.services.google !== "Unavailable" && loc.services.google !== "Succeeded") ||
                            (loc.services.facebook !== "Unavailable" && loc.services.facebook !== "Succeeded")){
                                return (
                                    <Location>
                                        <Text>{loc.location_name}</Text>
                                        <Services>
                                            <Service>
                                            {loc.services.google !== "Unavailable" && loc.services.google !== "Succeeded" && (
                                                <ReactSVG src={googleIcon} />
                                            )}
                                            </Service>
                                            <Service>
                                            {loc.services.facebook !== "Unavailable" &&  loc.services.facebook !== "Succeeded" && (
                                                <ReactSVG src={facebookIcon} />
                                            )}
                                            </Service>
                                        </Services>
                                    </Location>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </LocationsContainer>
                </Container>
            </Modal>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    setIssueStatus(status){
        dispatch(setIntegrationIssueStatus(status))
    },
})

export default connect(null, mapDispatchToProps)(IntegrationIssueAlert)
