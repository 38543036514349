import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class HowToManageStoreLocation extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0" mb="0">
                    On the lower left of the menu click on <strong style={{ color: blueDefault }}>Store Location</strong>..
                    There you can add or delete a location.
                </Text>
                <Text mt="0" ><i>* Talk to our sales team to verify the pricing by location.</i></Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}