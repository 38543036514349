import React from 'react';
import Skeleton from 'react-skeleton-loader';

export default class ReportDataSkeleton extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
                <Skeleton width="5em" />
            </React.Fragment>
        )
    }
}