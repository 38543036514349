import styled from "styled-components"

export const ClientsListContainer = styled.div`
  border: ${props => props.clients.length === 0 ? "none" : "1px solid #eee"};
  padding: 0 10px;
`

export const ClientContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  justify-content: space-between;
  
  &:not(:first-child) {
    border-top: 1px solid #eee;
  }
`
