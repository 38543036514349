// MUTATIONS
import { SET_LOCATIONS } from "./mutations"

const initialState = {
    locations: [],
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LOCATIONS:
            return { ...state, locations: action.payload }
            break
        
        default:
            return state
    }
}

export default reducer
