import React from 'react';
import { MultipleInvitationsContainer } from './styled';
import Module from '../../../../Components/Module/Module';
import { Row, Column, Label } from '../../../../Assets/Structure/Structure';
import ReactSVG from 'react-svg';
import editIcon from '../../../../Assets/icons/edit.svg';
import deleteIcon from '../../../../Assets/icons/trash.svg';

export default class MultipleInvitation extends React.Component{

    state = {
        invitations: this.props.invitations
    }

    componentWillReceiveProps(props){
        this.setState({ invitations: props.invitations });
    }

    handleDelete = (index) => {
        let invitations = this.props.invitations;
        invitations.splice(index, 1);
        this.setState({ invitations });
    }

    async handleEdit(invitation, index){
        let invitations = this.state.invitations;
        await this.props.invitationToBeUpdated(invitation);
        invitations.splice(index, 1);
        this.setState({ invitations });
    }

    render(){
        return (
            <MultipleInvitationsContainer>
                {this.state.invitations.map((invitation, index) => {
                    return (
                        <Module className="invitation-module">
                            <Row alignCenter between>
                                <Column>
                                    <Label fontSize="16px">{invitation.name}</Label>
                                    <Label color="#999">{invitation.email || invitation.phone}</Label>
                                </Column>
                                <Row auto>
                                    <ReactSVG src={editIcon} className="edit-btn" onClick={() => this.handleEdit(invitation, index)} />
                                    <ReactSVG src={deleteIcon} className="delete-btn" onClick={() => this.handleDelete(index)} />
                                </Row>
                            </Row>
                        </Module>
                    )
                })}
            </MultipleInvitationsContainer>
        )
    }
}