import styled from 'styled-components';
import { red } from '../../Assets/colorPallet/colorPallet';

export const ChangePasswordContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  align-items: center;
  flex-direction: column;

  .login-module {
    width: 100%;
    max-width: 40em;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5em;
    padding: 2.5em;
    margin-top: 2em;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: #333333;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 20px;
  outline: none;
  color: #333333;

  &::placeholder {
    color: #d0d0d0;
  }
`;

export const Anchor = styled.a`
  margin-left: ${props => (props.ml1 ? "1em" : null)};
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.color};
`;

export const Form = styled.form`
  width: 100%;
  max-width: 40em;
`;

export const StoresLabel = styled.label`
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #333333;
`;


export const FeedbackText = styled.p`
  color: ${props => props.color};
  letter-spacing: .05em;
  font-size: 14px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 20.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;