import styled from 'styled-components';

export const DescriptionContainer = styled.div`
    padding: 0 0 2em;
`;

export const Image = styled.img`
    width: ${props => props.fluid ? "100%" : null};
    display: ${props => props.center ? "flex" : null};
    margin: ${props => props.center ? "0 auto" : null};
`;

export const Container = styled.div`
    padding-left: ${props => props.pl};
    border-left: ${props => props.bl};
    margin-top: ${props => props.mt};
`;