import React from "react";
import Skeleton from "react-skeleton-loader";
import { Module } from "./styled";

export default class ReviewSkeleton extends React.Component {
  render() {
    return (
      <Module>
        <Skeleton height="1em" />
        <div style={{ marginTop: "1em" }}>
          <Skeleton width="100%" count={4} />
        </div>
      </Module>
    );
  }
}
