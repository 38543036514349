import styled from "styled-components";

export const ReviewsFilterContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;

  @media only screen and (max-width: 500px){
    display: flex;
    justify-content: flex-end;
    
  }
`;

export const PrivateReviews = styled.button`
  outline: none;
  border: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20em;
  height: 40px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
`;

export const NoReply = styled.button`
  outline: none;
  border: none;
  border-radius: 20em;
  height: 40px;
  background-color: ${props => props.noReplyActive ? "transparent" : "#e7e7e7"};
  border: ${props => props.noReplyActive ? "1px solid #025F88" : "1px solid transparent"};
  cursor: pointer;
  font-size: 14px;
  width: 200px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
`;

export const ResetContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: .5em;
`;

export const  DesktopFilter = styled.div`
display: flex;

#select-container{
  width: 100%;
}
  @media only screen and (max-width: 800px){
    display: none;
  }
`;  

export const MobileFilter = styled.div`
  padding: 0 1em;
  @media only screen and (min-width: 801px){
    display: none;
  }
`;  

export const ResetFilterButton = styled.a`
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #025f88;
    cursor: pointer;
    transition: opacity .4s ease;

    @media only screen and (max-width: 500px){
      display: none;
    }
`;