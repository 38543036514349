import styled from 'styled-components';

export const ImportedContactsListContainer = styled.div`
    width: 100%;
    margin-top: 2em;

    .close-icon{
        svg{
            cursor: pointer;
            path{
                fill: #888;
            }

            &:hover{
                path{
                    fill: #333;
                }
            }
        }
    }
`;