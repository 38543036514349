import styled from "styled-components";
import { red, blueDefault } from '../../Assets/colorPallet/colorPallet';

export const EditProfileContainer = styled.div`
  width: 100%;
  max-width: 575px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.15em;
    font-weight: 500;
    color: #999999;
  }

  .close-icon{
    cursor: pointer;
    svg{
      path{
        fill: #888;
        transition: fill .4s ease;
      }
    }

    &:hover{
      svg{
        path{
          fill: white;
        }
      }
    }
  }
`;


export const LoadRequestContainer = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChangePasswordContainer = styled.div``;

export const ActionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 2em;
  cursor: pointer;
  width: auto;

  svg {
    margin-right: 1em;

    path{
      fill: ${props => props.showContent ? "#ccc" : blueDefault};
    }
  }

  label {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #333333;
    cursor: pointer;
  }
`;

export const OpenFlexContainer = styled.div`
  height: ${props => props.showContent ? "18em" : "0"};
  transition: height .4s ease;
  overflow-y: hidden;
  pointer-events: ${props => props.showContent ? "all" : "none"};
`;
