import React from "react";
import { WEBCHAT_REPAIR_S3_URL } from '../../config'
import { Code } from "./styled";
import Module from "../../Components/Module/Module";

export default class ChatWidgetRepairGenerator extends React.Component {
  state = {
    copied: false,
    token: localStorage.getItem("store_key"),
    storeName: localStorage.getItem("store_name")
  };

  handleClick = () => {
    const el = document.createElement("textarea");
    el.value = `
      <script>
          (function() {
            var head = document.querySelector('head');
           
             var cdn1 = document.createElement("link");
             cdn1.setAttribute("rel", "stylesheet"), 
             cdn1.setAttribute("type", "text/css"),
             cdn1.setAttribute("charset", "UTF-8")
             cdn1.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"),
     
             head.appendChild(cdn1);
     
             var cdn2 = document.createElement("link");
             cdn2.setAttribute("rel", "stylesheet"), 
             cdn2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"),
     
             head.appendChild(cdn2);
     
             var cdnGoogleFonts = document.createElement("link");
             cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
             cdnGoogleFonts.setAttribute("type", "text/css"),
             cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
     
             head.appendChild(cdnGoogleFonts);
     
             var e = document.createElement("div");
            e.setAttribute("id", "txtar-repair-widget-entrypoint"), e.setAttribute("data-txtar-repair-widget-store-key", "${this.state.token}"), document.body.appendChild(e), e.setAttribute("data-store-name", "${this.state.storeName}");
             var raContainer = document.getElementById("txtar-repair-widget--container");
             if (raContainer) {
                raContainer.appendChild(e);
             } else {
               document.body.appendChild(e);
             }
             var t = document.createElement("script");
             t.type = "text/javascript", t.src = "${WEBCHAT_REPAIR_S3_URL}", document.getElementsByTagName("head")[0].appendChild(t)
          })()
          </script>
    `

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    this.setState({ copied: true }, () => {
      this.handleReset();
    });
  };

  handleReset = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  render() {

    const { widgetType, widget} = this.props;

    return (
      <React.Fragment>
        <Code onClick={() => this.handleClick(widgetType)}>
          <Module className="copy-code-module" >
            <div className="widget-info-container">
              <label>{widget}</label>
              <a>
                {this.state.copied ? "Copied" : "Copy Widget Code"}
              </a>
            </div>
          </Module>
        </Code>
      </React.Fragment>
    );
  }
}
