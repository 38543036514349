import React from "react";
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../config';
import { Row } from '../../Assets/Structure/Structure';
import { LastDaysOverviewContainer } from './styled';
import { BarChartContainer } from "./styled";
import BarChartSimple from "../BarChartSimple/BarChartSimple";

export default class LastDaysOverview extends React.Component {

    state = {
        barChartFluidWidth: "",
        loading: true,
        last7Days: []
    }

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    this.getChartData(store, headers);
    
    setTimeout(() => {
       this.getClientWidth();
       this.setState({ loading: false })
      }, 1000);
    window.addEventListener("resize", this.handleFluidWidth);
  }

  componentWillReceiveProps(props){
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    this.getChartData(store, headers);
  }

  getChartData = (store, headers) => {
    this.setState({ loading: true })
    let endpoint;
    if (this.props.type === "location"){
      endpoint = `${API_URL}/private/stores/${store}/locations/${this.props.location}/stats/reviews`;
    } else {
      endpoint = `${API_URL}/admin/stores/${store}/stats/reviews`;
    }
    axios.get(endpoint, headers)
    .then(res => {
      let reviewsPerDay = res.data.reviews_per_day;
      
      reviewsPerDay = reviewsPerDay.sort((a,b) => {
          if (a.day < b.day) return -1;
          if (a.day > b.day) return 1;
      });

      let last7Days = this.getLast7Days();
      
    //CHECK AVAILABLE REVIEWS FROM LAST 7 DAYS
      for (let i = 0; i < reviewsPerDay.length; i++){
        const date = reviewsPerDay[i].day;
        last7Days.map(review => {
          if (review.day === date){
            review.positive = reviewsPerDay[i].positive;
            review.neutral = reviewsPerDay[i].neutral;
            review.negative = reviewsPerDay[i].negative;
          }

          return review;
        })
      }

      //FORMAT 
     last7Days = last7Days.map(review => {
        return {
          day: moment(review.day).format("MM/DD"),
          positive: review.positive,
          neutral: review.neutral,
          negative: review.negative
        }
      })

      last7Days.reverse();
      this.setState({ last7Days, loading: false })

    })
  }

  getLast7Days = () => {
    let result = [];
    for (let i = 0; i < 7; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);
        date = moment(date).format("YYYY-MM-DD")

        const review = {
          day: date,
          positive: 0,
          neutral: 0,
          negative: 0
        }
        result.push(review)
    }

    return result
  }

  getClientWidth = () => {
    const barChartContainer = document.getElementById("bar-chart-container");
    let barChartFluidWidth;
    if (barChartContainer === null){
      return;
    } else {
      barChartFluidWidth = barChartContainer.clientWidth;
    }
    this.setState({ barChartFluidWidth });
  };

  handleFluidWidth = e => {
    const barChartContainer = document.getElementById("bar-chart-container");
    let barChartFluidWidth;
    if(barChartContainer === null){
      return;
    } else {
      barChartFluidWidth = barChartContainer.clientWidth;
    }
    this.setState({ barChartFluidWidth });
  };

  render() {

    return (
      <LastDaysOverviewContainer 
       fluid={this.props.fluid} 
       className={this.props.className} 
       style={this.props.style}>
        <Row between>
          <p>Last 7 days</p>
        </Row>
        <BarChartContainer 
         fluid={this.props.fluid} 
         onResize={this.handleFluidWidth} 
         id="bar-chart-container">
          <BarChartSimple 
           last7Days={this.state.last7Days} 
           width={this.state.barChartFluidWidth} />
        </BarChartContainer>
      </LastDaysOverviewContainer>
      
    );
  }
}
