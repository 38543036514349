import styled from "styled-components";

export const ReportFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .select{
   margin-left: 1em;
   min-width: 10em;
  }

  @media only screen and (max-width: 500px){
    padding: 0;
  .select:not(:first-child){
    margin-left: 1em;
  }
  }
`;


export const SelectContainer = styled.div`
  #select-container {
    width: 100%;
  }
`;

export const  DesktopFilter = styled.div`
display: flex;

#select-container{
  width: 100%;
}
  @media only screen and (max-width: 800px){
    display: none;
  }
`;  

export const MobileFilter = styled.div`
  @media only screen and (min-width: 801px){
    display: none;
  }
`;  

export const ResetFilterContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1em;
`;