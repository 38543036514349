import styled from 'styled-components';

export const SelectLocationContainer = styled.div`
    display: flex;
    align-items: center;

    .location-icon{
        svg{
            path{
                fill: #999;
            }
        }
    }

    @media only screen and (max-width: 500px){
        padding: 0 1em;
    }

`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;