import styled from "styled-components"

export const AddClientContainer = styled.form`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: baseline;
    
    .add-client--phone{
      margin: 20px 0 0 !important;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 1338px) {
    .add-client--icon-action-container{
      display: none;
    }
    
    .add-client--button-action-container{
      display: flex;
      margin-top: 20px;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 1210px) {
    .add-client--name-field-container{
      width: 100%;
      .MuiInputBase-root {
         width: 100%;
      }
    }
    
    .add-client--phone{
      width: 100%;
      margin: 20px 0 0;
    }
    
    .add-client--phone-field-container{
      width: 100%;
      .MuiInputBase-root {
         width: 100%;
      }
    }
  }
`

