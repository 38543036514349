import styled from 'styled-components';

export const Button = styled.button`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    border: none;
`;