import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .sent-icon{
      svg{
          width: 16px;
          path{
              fill: #449BC1;    
          }
      }
  }

  @media only screen and (max-width: 500px){
      &:not(:first-child){
          margin-left: 2em;
      }
  }
`;

export const Text = styled.p`
    font-size: 14px;
`;