import React from "react";
import ReactSVG from 'react-svg';
import Calendar from "react-calendar";
import Button from "../../Button/Button";
import {
  CalendarContainer,
  StartContainer,
  EndContainer,
  DatesContainer,
  ActionContainer,
  Label
} from "./styled";
import { blueDefault } from "../../../Assets/colorPallet/colorPallet";
import { Row } from "../../../Assets/Structure/Structure";
import closeIcon from '../../../Assets/icons/close.svg';

export default class RenderCalendar extends React.Component {
  state = {
    month: 0,
    year: 2018,
    startDate: "",
    endDate: ""
  };

  handleSelectedMonthYear = (month, year) => this.setState({ month, year });

  handleStartDate = startDate => this.setState({ startDate });

  handleEndDate = endDate => this.setState({ endDate })

  handleApplyDate = () => {
    this.props.dateInterval({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });

    this.props.isModalOpen(false)
};

  handleClearDate = () => {
    this.setState({
      startDate: "",
      endDate: ""
    })
  }

  render() {
    return (
      <CalendarContainer>
        <Row flexEnd padding="20px 20px 0;">
            <ReactSVG onClick={() => this.props.isModalOpen(false)} src={closeIcon} className="close-icon" />
          </Row>
        <DatesContainer>
          <StartContainer>
            <Row>
              <Label>Start</Label>
            </Row>
            <Calendar className="ra-calendar" locale="en-EN" onChange={this.handleStartDate} value={this.state.startDate} />
          </StartContainer>
          <EndContainer>
            <Row>
              <Label>End</Label>
            </Row>
            <Calendar className="ra-calendar" locale="en-EN" onChange={this.handleEndDate} value={this.state.endDate} />
          </EndContainer>
        </DatesContainer>
        <ActionContainer>
          <Button
            text="Clear date"
            bgColor="transparent"
            customBorder="1px solid #333"
            color="#333"
            bgColorOnHover="transparent"
            onClick={this.handleClearDate}
            borderRadius="20em"
            style={{ marginRight: "2em" }}
          />
          <Button
            text="Apply date"
            onClick={this.handleApplyDate}
            bgColor={blueDefault}
            customBorder={`1px solid ${blueDefault}`}
            color="white"
            borderRadius="20em"
          />
        </ActionContainer>
      </CalendarContainer>
    );
  }
}
