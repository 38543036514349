import React, {useEffect, useState} from "react"
import { bindActionCreators } from 'redux';
import InlineLoading from "../../InlineLoading/InlineLoading";
import { Overlay, ConfirmationContainer, ActionContainer } from "./styled";
import { sendSMSMarketingCampaign } from "../../../store/modules/smsMarketing/actions";
import { connect } from "react-redux";

const ConfirmationSendCampaign = (props) => {
  const [state, setState] = useState({
    upContainer: false,
    loading: false
  })

  useEffect(() => {
    const confirmation = document.getElementById("confirmation-send-modal");
    getElementPosition(confirmation);
  }, [])

  const getElementPosition = element => {
    let xPosition = 0;
    let yPosition = 0;
    const pageHeight = window.innerHeight;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    const diff = pageHeight - yPosition;

    if (diff < 250) {
      setState({ upContainer: true });
    } else {
      setState({ upContainer: false });
    }

    return { x: xPosition, y: yPosition };
  };

  const closeConfirmation = () => {
    props.handleClose(false);
  };


  const handleSendCampaign = async (campaign) => {
    setState({ loading: true })
    const store = localStorage.getItem("store")

    const staffName = localStorage.getItem("user_name")
    const storeName = localStorage.getItem("store_name")

    props.sendSMSMarketingCampaign({
      storeId: store, 
      campaignId: campaign.id, 
      staffName: staffName, 
      storeName: storeName,
      twilioAuthToken: props.chatCredentials.twilioAuthToken,
      twilioAccountSid: props.chatCredentials.accountSid
    })
    setState({ loading: false })
    closeConfirmation()

    alert("Campaign sent! We'll send the messages in the background, thank you!")
  }

  return (
    <>
      <ConfirmationContainer
        id="confirmation-send-modal"
        upContainer={state.upContainer}
      >
        <p>Are you sure about sending <span>{props.campaign ? props.campaign.name : ''}</span> ?</p>
        <ActionContainer>
          <button className="btn-send" onClick={() => handleSendCampaign(props.campaign)}>
            {state.loading ? <InlineLoading /> : "Send"}
          </button>
          <button className="btn-cancel" onClick={closeConfirmation}>Cancel</button>
        </ActionContainer>
      </ConfirmationContainer>
    </>
  )
}

const mapStateToProps = state => ({
  chatCredentials: state.webchat.chatCredentials
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendSMSMarketingCampaign: sendSMSMarketingCampaign,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationSendCampaign)