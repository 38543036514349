import React from 'react';
import { OptionsList } from './styled';

export default class Options extends React.Component{

    handleSelectedOption = (option) => {
        this.props.selectedOrderByOption(option);
    }

    render(){
        return (
            <OptionsList>
                {this.props.options.map(option => {
                    return <li onClick={() => this.handleSelectedOption(option)}>{option.label}</li>
                })}
            </OptionsList>
        )
    }
}