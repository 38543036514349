import React from "react";
import Skeleton from 'react-skeleton-loader';
import ReactSVG from "react-svg";
import { HeaderContainer } from "./styled";
import locationIcon from "../../../../Assets/icons/location.svg";
import closeIcon from "../../../../Assets/icons/close.svg";

export default class Header extends React.Component {
  handleCloseModal = () => {
    this.props.isModalOpen(false);
  };

  render() {
    const { currentLocationAddress, currentLocationName } = this.props;

    return (
      <HeaderContainer>
        {this.props.loading ? (
          <Skeleton />
        ) : (
          <React.Fragment>
            <div className="left-container">
              <div className="icon-container">
                <ReactSVG src={locationIcon} />
              </div>
              <div className="info-container">
                <h2>{currentLocationName}</h2>
                <p>{currentLocationAddress}</p>
              </div>
            </div>
            <div className="right-container">
              <ReactSVG src={closeIcon} onClick={this.handleCloseModal} />
            </div>
          </React.Fragment>
        )}
      </HeaderContainer>
    );
  }
}
