// Libraries
import React, {useEffect, useRef, useState} from "react"
import { ParticipantContainer } from "./styled"
import {Container} from "../../../../Assets/Structure/Structure";


const Participant = ({ participant, width, height, cameraOn, audioOn, isLocalParticipant }) => {
    const [videoTracks, setVideoTracks] = useState([])
    const [audioTracks, setAudioTracks] = useState([])

    const videoRef = useRef()
    const audioRef = useRef()

    useEffect(() => {
        const videoPublications = [...participant.videoTracks].map(([name, value]) => ({ name, value }));
        setVideoTracks([videoPublications[0].value])

        const audioPublications = [...participant.audioTracks].map(([name, value]) => ({ name, value }));
        setAudioTracks([audioPublications[0].value])

    }, [participant])

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            setTimeout(() => {
                const track = videoTrack.track
                if (track) {
                    track.attach(videoRef.current);
                    return () => {
                        track.detach();
                    };
                }

            }, 1000)

        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];

        if (audioTrack) {
            setTimeout(() => {
                const track = audioTrack.track
                if (track) {
                    track.attach(audioRef.current);
                    return () => {
                        track.detach();
                    };
                }

            }, 1000)

        }
    }, [audioTracks]);

    useEffect(() => {
        if (isLocalParticipant && !cameraOn && videoTracks) {
            videoTracks.forEach(videoTrack => videoTrack.track.disable())
        } else {
            if (isLocalParticipant && videoTracks.length > 0) {
               videoTracks.forEach((videoTrack) => videoTrack.track.enable())
            }
        }
    }, [cameraOn])

    useEffect(() => {
        if (isLocalParticipant && !audioOn && audioTracks) {
            audioTracks.forEach(audioTrack => audioTrack.track.disable())
        } else {
            if (isLocalParticipant && audioTracks.length > 0) {
                audioTracks.forEach(audioTrack => audioTrack.track.enable())
            }
        }
    }, [audioOn])

    return (
        <ParticipantContainer isLocalParticipant={isLocalParticipant} className="participant">
            <video style={{ width, height }} ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true}  />
        </ParticipantContainer>
    );

}


export default Participant
