import styled from "styled-components";

export const StepTwoContainer = styled.div`
  padding: 2em;
  box-sizing: border-box;
  background-color: #f8f8f8;

  p {
    text-align: center;
    width: 100%;
    max-width: 30em !important;
    margin: 0 auto;
    font-weight: normal;
    line-height: 30px;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #333333;
  }
`;

export const RowContainer = styled.div`
  display: flex;

  p{
    max-width: 30em !important;
  }

  .google {
    svg {
      path:nth-child(1) {
        fill: #ea4335;
      }

      path:nth-child(2) {
        fill: #4285f4;
      }

      path:nth-child(3) {
        fill: #fbbc05;
      }

      path:nth-child(4) {
        fill: #34a853;
      }
    }
  }
`;

export const PagesContainer = styled.div`
  margin-top: 2em;
  height: 16em;
  overflow-y: auto;
  padding: 1em;
  box-sizing: border-box;
`;

export const Page = styled.div`
  padding: 20px;
  background-color: white;
  margin: 1em 0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: border .4s ease;
  border: 1px solid white;
  display: flex;
  flex-direction: column;

  &:hover{
    border: 1px solid #00b1ff !important;
  }
`;
