import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 100%;
    max-width: ${props => props.maxWidth ? props.maxWidth : "44em"};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "white"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: fixed;
    z-index: 150;
    border-radius: 5px;
    left: 0;
    margin-left: 50%;
    top: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    transition: all .4s ease;

    .close-icon{
        div:nth-child(1){
            &:first-child{
                display: flex;

                svg{
                    cursor: pointer;
                    path{
                        fill: #888;
                    }

                    &:hover{
                        path{
                            fill: #333;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px){
        height: ${props => props.sent ? "auto !important" : null};
        width: ${props => props.sent ? "90%" : null};
    }
`;

export const OverlayContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.56);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 150;
    height: 100vh;
    left:0;
    transition: all .4s ease;
`;