import styled from 'styled-components';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';

export const SendMoreInvitationsButton = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 2em;
    cursor: pointer;

    svg{
        margin-right: .5em;
        path{
            transition: fill .4s ease;
            fill: ${props => props.formIsFilled ? blueDefault : "rgba(153, 153, 153, 0.5)"};
        }
    }

    label{
        cursor: pointer;
        transition: color .4s ease;
        color: ${props => props.formIsFilled ? blueDefault : null};
    }


`;