import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../config';
import queryString from "query-string";
import PlatformButton from '../../Components/PlatformButton/PlatformButton';
import { ActionContainer, ChoosePlatformContainer } from './styled';
import { Label } from '../../Assets/Structure/Structure';
import facebookIcon from '../../Assets/icons/facebook-choose.svg';
import googleIcon from '../../Assets/icons/google-choose.svg';


export default class ChoosePlatform extends React.Component{

    state = {
        googleUrl: "",
        facebookUrl: "",
        storeName: "",
        code: "",
        services: [{service: "Facebook", review_url: ""}]
    }

    componentDidMount(){
        let parsed = queryString.parse(window.location.search);

        if (parsed.code) {
            axios.get(`${API_URL}/public/review_requests/${parsed.code}`)
            .then(res => {
                const storeName = res.data.store;
                let googleUrl, facebookUrl;
                res.data.services.map(service => {
                    if (service.service === "Google"){
                        googleUrl = service.review_url;
                    } 

                    if (service.service === "Facebook"){
                        facebookUrl = service.review_url;
                    }
                });
           
                this.setState({ googleUrl, facebookUrl, storeName, code: parsed.code, services: res.data.services });
            })
        }
    }

    handleClickCount = (service) => {
        const payload = { redirecting_to: service };
        axios.patch(`${API_URL}/public/review_requests/${this.state.code}`, payload);
    }

    render(){

        return(
            <ChoosePlatformContainer>
                <Label fontSize="16px" color="#888">{this.state.storeName}</Label>
                <Label textCenter maxWidth="12em" fontSize="24px" color="#333">
                    Where do you prefer to leave your review?
                </Label>
                <ActionContainer>
                    {this.state.services.map(service => {
                        if (service.service === "Facebook"){
                            return (
                                <a href={this.state.facebookUrl} onClick={() => this.handleClickCount("facebook")}>
                                    <PlatformButton 
                                    platform="facebook" 
                                    className="tag-request-facebook"
                                    iconPath={facebookIcon} />  
                                </a>
                            )
                        }
                    })}

                    {this.state.services.map(service => {   
                        if (service.service === "Google"){
                            return (
                                <a href={this.state.googleUrl} onClick={() => this.handleClickCount("google")}>
                                <PlatformButton 
                                    platform="google" 
                                    className="tag-request-google"
                                    iconPath={googleIcon} />
                                </a>
                            )
                        }
                    })}
                </ActionContainer>
            </ChoosePlatformContainer>
        )
    }
}