import React from 'react';
import ReactSVG from 'react-svg';
import { MenuContainer, Overlay } from './styled';
import closeIcon from '../../../Assets/icons/close-outline.svg';
import Profile from '../../SideMenu/Profile/Profile';
import Menu from '../../SideMenu/Menu/Menu';


export default class MobMenu extends React.Component{

    handleClose = (isMenuActive) => {
        this.props.handleCloseMenu(isMenuActive)
    }

    handleLoggedIn = (loggedIn) => {
        this.props.loggedIn(loggedIn)
    }

    render(){
        const { isMenuActive } = this.props;
        const userName = localStorage.getItem("user_name");
        const userEmail = localStorage.getItem("user_email");

        return(
            <React.Fragment>
                <Overlay isMenuActive={isMenuActive} onClick={() => this.handleClose(false)} >
                    <ReactSVG src={closeIcon} onClick={() => this.handleClose(false)} />
                </Overlay>
                <MenuContainer isMenuActive={isMenuActive}>
                    <Profile loggedIn={this.handleLoggedIn} userName={userName} userEmail={userEmail} />
                    <Menu isMenuActive={this.handleClose} />
                </MenuContainer>
            </React.Fragment>
        )
    }
}