import styled from 'styled-components';

export const OptionsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li{
        padding: 1em;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 5px;

        &:hover{
            background-color: #E6E6E6;
        }
    }
`;