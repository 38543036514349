import React from 'react';

export default class AvatarBgColor extends React.Component{

    componentDidMount(){
        const employeeFirstInitial = this.props.employeeFirstInitial;

        let avatarBgColor;
        if (employeeFirstInitial === "A") avatarBgColor = "#A088FF";
        if (employeeFirstInitial === "B") avatarBgColor = "#FF9487";
        if (employeeFirstInitial === "C") avatarBgColor = "#6A8D73";
        if (employeeFirstInitial === "D") avatarBgColor = "#F0A868";
        if (employeeFirstInitial === "E") avatarBgColor = "#03B5AA";
        if (employeeFirstInitial === "F") avatarBgColor = "#006DAA";
        if (employeeFirstInitial === "G") avatarBgColor = "#7A306C";
        if (employeeFirstInitial === "G") avatarBgColor = "#0C1B33";
        if (employeeFirstInitial === "I") avatarBgColor = "#B2AA8E";
        if (employeeFirstInitial === "J") avatarBgColor = "#B9D6F2";
        if (employeeFirstInitial === "K") avatarBgColor = "#607B7D";
        if (employeeFirstInitial === "L") avatarBgColor = "#3A606E";
        if (employeeFirstInitial === "M") avatarBgColor = "#00B1FF";
        if (employeeFirstInitial === "N") avatarBgColor = "#DB8A74";
        if (employeeFirstInitial === "O") avatarBgColor = "rgb(158, 231, 73);";
        if (employeeFirstInitial === "P") avatarBgColor = "#f1a4e8";
        if (employeeFirstInitial === "Q") avatarBgColor = "#ede368";
        if (employeeFirstInitial === "R") avatarBgColor = "#ed688d";
        if (employeeFirstInitial === "S") avatarBgColor = "#a39599";
        if (employeeFirstInitial === "T") avatarBgColor = "#d598ba";
        if (employeeFirstInitial === "U") avatarBgColor = "#d560a1";
        if (employeeFirstInitial === "V") avatarBgColor = "#60d57f";
        if (employeeFirstInitial === "W") avatarBgColor = "#7eac8a";
        if (employeeFirstInitial === "X") avatarBgColor = "#00bcd4";
        if (employeeFirstInitial === "Y") avatarBgColor = "#009688";
        if (employeeFirstInitial === "Z") avatarBgColor = "#62d7b1";

        this.props.avatarBgColor(avatarBgColor);
    }

    render(){
        return <React.Fragment>{this.props.children}</React.Fragment>
        
    }
}