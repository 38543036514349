import React from "react";
import {Redirect} from "react-router-dom";
import ReactSVG from "react-svg";
import { setIntegrationIssueStatus, setIntegrationIssueReport } from '../../store/modules/integration/actions';
import { connect } from 'react-redux';
import {
    LoginContainer,
    Label,
    Input,
    Anchor,
    Form,
    FeedbackText,
    LoadingContainer
} from "./styled";
import {Column, Row} from "../../Assets/Structure/Structure";
import {blueDefault, blueDefaultHover} from "../../Assets/colorPallet/colorPallet";
import Module from "../../Components/Module/Module";
import {API_URL} from "../../config";
import Button from "../../Components/Button/Button";
import txtarLogo from "../../Assets/icons/txtar.svg";
import axios from "axios";
import ForgotPasswordModal from "../../Components/ForgotPasswordModal/ForgotPasswordModal";
import ChangePasswordModal from "../../Components/ChangePasswordModal/ChangePasswordModal";
import { red } from '../../Assets/colorPallet/colorPallet';
import InlineLoading from "../../Components/InlineLoading/InlineLoading";

export class Login extends React.Component {
    state = {
        email: "",
        password: "",
        userLoggedIn: this.props.userLoggedIn,
        loginHasFailed: "",
        isModalOpen: false,
        currentModal: "",
        loading: false,
        showChangePasswordModal: false,
        code: "",
        userRole: "",
        widgetOnly: "",
        chatOnly: "",
        storeHasIntegrated: false
    };

    componentDidMount(){
        const userLoggedIn = localStorage.getItem("logged_in");
        const userRole = localStorage.getItem("role");
        this.setState({ userLoggedIn, userRole })
    }

    componentWillReceiveProps(props) {
        const userLoggedIn = props.userLoggedIn || localStorage.getItem("logged_in")
        this.setState({userLoggedIn})
    }

    //GET INPUT VALUE
    handleChange = (e, type) => this.setState({[type]: e.target.value});

    handleModal = (isModalOpen, currentModal) => {
        this.setState({isModalOpen, currentModal});
    }

    // SUBMIT AUTHENTICATION
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const payload = {
            username: this.state.email,
            password: this.state.password
        };

        axios.post(`${API_URL}/public/sessions`, payload)
        .then(res => {
            const authToken = res.data.auth_token;
            const firstLogin = res.data.first_login;
            localStorage.setItem("token", authToken);
            localStorage.setItem("first_login", firstLogin);

            const headers = {
                headers: {
                    "x-sas-Auth": authToken
                }
            };

            axios.get(`${API_URL}/private/sessions`, headers)
            .then(res => {

                    const userDetails = {
                        userName: res.data.user_name,
                        store: res.data.store,
                        widgetOnly: res.data.widget_only,
                        chatOnly: res.data.chat_only,
                        role: res.data.role,
                        isAdmin: res.data.is_admin,
                        storeName: res.data.user_store,
                        location: res.data.location,
                        userEmail: res.data.user_email,
                    }

                    localStorage.setItem("user_name", userDetails.userName);
                    localStorage.setItem("store", userDetails.store);
                    localStorage.setItem("widget_only", userDetails.widgetOnly);
                    localStorage.setItem("chat_only", userDetails.chatOnly)
                    localStorage.setItem("role", userDetails.role);
                    localStorage.setItem("is_admin", userDetails.isAdmin);
                    localStorage.setItem("store_name", userDetails.storeName);
                    localStorage.setItem("location", userDetails.location)
                    localStorage.setItem("user_email", userDetails.userEmail);
                    localStorage.setItem("logged_in", true);


                    if (res.data.role === "sas-team"){
                        this.setState({ userLoggedIn: true, loading: false, userRole: res.data.role })
                    } else {


                        if (res.data.role !== "seller") {

                            axios.get(`${API_URL}/admin/stores/${res.data.store}`, headers)
                                .then(response => {
                                    localStorage.setItem("store_key", response.data.store_key);

                                    const locations = response.data.locations;
                                    let locationsServiceStatus = [];
                                    let storeHasIntegrated = this.state.storeHasIntegrated;
                                    locations.map(location => {
                                        if (location.review_services.length > 0) {
                                            locationsServiceStatus.push(true);
                                        }
                                        ;
                                    })

                                    if (locationsServiceStatus.length > 0) {
                                        storeHasIntegrated = true;
                                    }

                                    this.setState({
                                        userLoggedIn: true,
                                        email: "", password: "",
                                        loading: false,
                                        userRole: userDetails.role,
                                        widgetOnly: userDetails.widgetOnly,
                                        chatOnly: userDetails.chatOnly,
                                        storeHasIntegrated
                                    });
                                })

                        } else {
                            this.setState({
                                userLoggedIn: true,
                                email: "", password: "",
                                loading: false,
                                userRole: userDetails.role,
                                widgetOnly: userDetails.widgetOnly,
                                chatOnly: userDetails.chatOnly,
                            });
                        }


                        this.checkingIntegrationIssues(userDetails.role, userDetails.store, headers );
                    }


                })
            })
            .catch(() => {
                this.setState({
                    loginHasFailed: true,
                    email: "",
                    password: "",
                    loading: false
                })
            })
    }

    checkingIntegrationIssues = (role, store, headers) => {
        if (role === "manager")
            axios.get(`${API_URL}/admin/stores/${store}/stats/reviews`, headers)
            .then(res => {
                const locations = Object.getOwnPropertyNames(res.data.monitoring);
                let monitoring = res.data.monitoring;
                let locationReport;
                let report = [];
                monitoring = Object.values(monitoring);
                locations.map((location, index) => {
                    const obj = monitoring[index];
                    locationReport = {
                        location_name: location,
                        services: {
                            google: obj.google.status,
                            facebook: obj.facebook.status
                        }
                    }

                    report.push(locationReport);

                    if (index === locations.length - 1){
                        report.map(loc => {
                            if ((loc.services.google !== "Unavailable" && loc.services.google !== "Succeeded") ||
                            (loc.services.facebook !== "Unavailable" && loc.services.facebook !== "Succeeded")){
                                this.props.setIssueStatus(true);
                            }
                        })

                        this.props.setIssueReport(report);

                    }
                })
            })
    }

    render() {

        const {
            currentModal,
            userRole,
            userLoggedIn,
            widgetOnly,
            chatOnly,
            storeHasIntegrated,
            loading
        } = this.state;

        if (userLoggedIn) {
            if (widgetOnly == 1){
                return <Redirect to="/store-location" />
            }

            if (chatOnly == 1){
                return <Redirect to="/webchat" />
            }

            if (userRole === "sas-team"){
                return <Redirect to="/stores" />
            }

            if (userRole === "manager" && storeHasIntegrated){
                return <Redirect to="/dashboard" />
            }

            if (userRole === "manager" && !storeHasIntegrated){
                return <Redirect to="/store-location" />
            }

            if (userRole === "seller"){
                return <Redirect to="/seller" />
            }
        }

        return (
            <React.Fragment>
                {this.state.isModalOpen && (
                    currentModal === "forgot-password" ? (
                        <ForgotPasswordModal isModalOpen={this.handleModal}/>
                    ) : currentModal === "change-password" ? (
                        <ChangePasswordModal code={this.state.code}  isModalOpen={this.handleModal}/>
                    ) : null
                )}

                <LoginContainer>
                {this.state.loading ? (
                    <LoadingContainer>
                        <InlineLoading color={blueDefault} />
                    </LoadingContainer>
                ) : (
                    <React.Fragment>
                    <Row maxWidth="40em" justifyCenter alignCenter>
                        <ReactSVG src={txtarLogo}/>
                    </Row>
                    <Form onSubmit={this.handleSubmit}>
                        <Module className="login-module">
                            <Column>
                                <Label>E-mail</Label>
                                <Input
                                    type="email"
                                    placeholder="Enter your e-mail"
                                    value={this.state.email}
                                    onChange={e => this.handleChange(e, "email")}
                                    required/>
                            </Column>

                            <Column mt="2em">
                                <Label>Password</Label>
                                <Input
                                    type="password"
                                    placeholder="Enter your password"
                                    value={this.state.password}
                                    onChange={e => this.handleChange(e, "password")}
                                    required/>
                            </Column>
                            {this.state.loginHasFailed && (
                                <FeedbackText color={red}>Email or password is wrong. Please try again.</FeedbackText>
                            )}
                            <Row alignCenter className="login-action-container">
                                <Button
                                    text="Sign in"
                                    uppercase
                                    width="209px"
                                    type="submit"
                                    bgColor={blueDefault}
                                    bgColorOnHover={blueDefaultHover}
                                    color="white"
                                    borderRadius="20em"/>
                                <Anchor color="#999" ml1 onClick={() => this.handleModal(true, "forgot-password")}>
                                    Forgot password
                                </Anchor>
                            </Row>
                        </Module>
                    </Form>
                    </React.Fragment>
                    )}
                </LoginContainer>
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    setIssueStatus(status){
        dispatch(setIntegrationIssueStatus(status))
    },
    setIssueReport(report){
        dispatch(setIntegrationIssueReport(report))
    }
})

export default connect(null, mapDispatchToProps)(Login)
