import React from "react";
import { AvatarContainer } from "./styled";
import AvatarBgColor from "./AvatarBgColor";

export default class Avatar extends React.Component {
  state = {
    initials: "",
    avatarBgColor: "",
    firstInitial: ""
  };

  handleAvatarBgColor = (avatarBgColor) => {
      this.setState({ avatarBgColor })
  }

  render() {

    const name = this.props.name || "";
    let firstInitial;
    let initials = name.split(/\s/).reduce((res, word) => (res += word.slice(0, 1)), "");
    initials = initials.split("");

    initials = initials.map((initial, index) => {
      if (index < 2) {
        return initial;
      }
    });


    for (let i = 0; i < 2; i++) {
      initials = initials[0] + initials[1]
      firstInitial = initials[0];
    }


    return (
    <AvatarBgColor firstInitial={firstInitial} handleAvatarBgColor={this.handleAvatarBgColor}>
      <AvatarContainer avatarBgColor={this.state.avatarBgColor}>
        <label>{initials || ""}</label>
      </AvatarContainer>
    </AvatarBgColor>
    );
  }
}
