import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 100%;
    max-width: 40em;
    background-color: ${props => props.newLocationFeedback ? "#f8f8f8" : "white"};
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    position: fixed;
    left: 50%;
    border-radius: 5px;
    top: 50%;

    .close-container{
        display: flex;
        padding: 0 1em;
        height: ${props => (props.headerTitle === undefined ? "auto" : "3em")};
        justify-content: ${props => (props.headerTitle === undefined ? "flex-end" : "space-between")};
        padding: 1em;
        box-sizing: border-box;

        label{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.15em;
            color: #999999;
        }


        svg{
            cursor: pointer;
            
        }
    }

    @media only screen and (max-width:500px){
        max-width: ${props => props.newEmployees.length > 0 ? "calc(100% - 1em)" : null};
        height: ${props => props.newEmployees.length > 0 ? "auto !important" : null};;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
    top: 0;
    z-index: 1000;
`;