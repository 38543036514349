import styled from 'styled-components';
export const SearchContainer = styled.div`
    width: ${props => (props.searchIsActive ? "50%" : "40px")};
    transition: width 1s ease;
    display: flex;
    cursor: pointer;
    height: 40px;
    margin-left: 1em;
    background-color: #e7e7e7;
    border-radius: 20em;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (orientation: portrait) and (max-width: 800px){
        svg{
            right: 10px;
        }
    }

    @media only screen and (orientation: landscape) and (max-width: 1025px){
        svg{
            right: 20px;
        }
    }

    @media only screen and (max-width: 500px){
        width: ${props => props.searchIsActive ? "100%" : "40px"};
    }
`;

export const Input = styled.input`
    display: ${props => (!props.searchIsActive && "none")};
    width: ${props => (props.searchIsActive ? "100%" : "0%")};
    border: none;
    outline: none;
    padding: ${props => (props.searchIsActive ? "0 2em" : null)};
    background-color: transparent;
    border-radius: 20em;
`;

export const SearchIconContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;