import styled from 'styled-components';
import { blueDefault, blueDefaultHover } from '../../../Assets/colorPallet/colorPallet';

export const ConfirmationContainer = styled.div`
    padding: 1em;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.15);
    width: 100%;
    position: absolute;
    max-width: 25em;
    border-radius: 5px;
    z-index: 150;
    margin-top: ${props => (props.upContainer && "-10em")};
    right: calc(3em + 80px);

    p{
        color: #333;
        letter-spacing: .1em;
        line-height: 1.5em;
        span{
            color: ${blueDefault};
        }
    }

    @media only screen and (max-width: 500px){
        max-width: 18em;
    }
`; 

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 150;
    position: fixed;
    background-color: #00000090;
`;

export const ActionContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2em;

    button{
        border: none;
        letter-spacing: .2em;
        width: 11em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        outline: none;
        border-radius: 20em;
        color: white;
        font-size: 12px;
        cursor: pointer;
        transition: background-color .4s ease, color .4s ease;
    }

    .btn-delete{
        background-color: ${blueDefault};
        margin-right: 1em;

        &:hover{
            background-color: ${blueDefaultHover};
        }
    }

    .btn-cancel{
        background-color: transparent;
        color: #ccc;
        border: 1px solid #ccc;

        &:hover{
            background-color: #ccc;
            color: white;
        }
    }
`;