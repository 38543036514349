import styled from "styled-components";
import {
  blueDefault,
  blueDefaultHover
} from "../../Assets/colorPallet/colorPallet";

export const ButtonContainer = styled.button`
  border: ${props => (props.customBorder ? props.customBorder : "none")};
  padding: ${props => {
    if (props.padding) return props.padding
    else if (props.kind === "icon-button") return 0
    else return "15px 40px"
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius
    else if (props.kind === "icon-button") return "50%"
    else return null
  }};
  outline: none;
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  background-color: ${props => (props.bgColor ? props.bgColor : props.transparent ? "transparent" : blueDefault)};
  color: ${props => props.color};
  letter-spacing: 0.2em;
  cursor: pointer;
  transition: background-color 0.4s ease, opacity 0.4s ease;
  text-transform: ${props => props.uppercase && "uppercase"};
  display: flex;
  align-items: center;
  box-shadow: ${props => props.hasShadow && "0px 1px 0px rgba(0, 0, 0, 0.15)"};
  justify-content: center;
  width: ${props => {
    if (props.width) return props.width
    else if (props.kind === "icon-button") return "38px"
    else return null
  }};
  margin-top: ${props => props.mt};
  margin-right: ${props => props.mr};
  height: ${props => {
    if (props.height) return props.height
    else if (props.kind === "icon-button") return "38px"
  }};
  opacity: ${props => (props.integrated ? ".5" : props.disabled ? ".2" : "1")};
  pointer-events: ${props =>
  props.integrated ? "none" : props.disabled ? "none" : "all"};

  div:nth-child(1){
    &:first-child{
      display: flex;
    }
  }

  

  svg {
    margin-right: ${(props => props.onlyIcon || props.kind === "icon-button") ? "0" : "1em"};
    width: ${props => {
      if (props.iconWidth) return props.iconWidth
      else if (props.kind === "icon-button") return "20px"
      else return null
    }};
    fill: ${props => props.iconColor};
    height: ${props => {
      if (props.iconHeight) return props.iconHeight
      else if (props.kind === "icon-button") return "20px"
      else return null
    }};


    path {
      fill: ${props => props.iconColor};
    }

  }

  &:hover {
    background-color: ${props =>
      props.bgColorOnHover
        ? props.bgColorOnHover
        : props.bgColorOnHover === false
        ? "none"
        : props.transparent ? "transparent" : blueDefaultHover};

    color: ${props => props.colorOnHover};
  }

  @media only screen and (max-width: 500px) {
    width: ${props => props.kind === "header" ? "50px" : props.hideTextOnMobile ? "40px" : props.widthOnMobile ? props.widthOnMobile : null};
    height: ${props => props.kind === "header" ? "50px" : props.hideTextOnMobile ? "40px" : null};
    padding: ${props => props.kind === "header" ? "0" : props.hideTextOnMobile ? "0" : null};
    

    svg {
      margin-right: ${props => props.kind === "header" ? "0" : props.hideTextOnMobile ? "0" : null};
    }
  }
`;

export const Text = styled.label`
  cursor: pointer;
  margin-left: ${props => props.hasIcon && props.text ? "10px" : null};
  pointer-events: none;

  @media only screen and (max-width: 600px){
    display: ${props => props.hideTextOnMobile ? "none" : "block"};
  }
`;
