import React from "react";
import { CSVLink } from 'react-csv';
import axios from 'axios';
import {
  HeaderContainer,
  DownloadContainer,
} from "./styled";
import downloadIcon from "../../../Assets/icons/download.svg";
import Button from "../../../Components/Button/Button";
import ReportFilter from "./ReportFilter/ReportFilter";
import { Row } from "../../../Assets/Structure/Structure";

export default class Header extends React.Component {

  state = {
    reportData: ""
  }

  handleUpdateRequests = (requests, totalOfRequests) => {
    this.props.updateRequests(requests, totalOfRequests);
  }

  handleEndpoint = (endpoint) => {
    this.props.endpoint(endpoint);
  }

  handleResetFilter = () => {
    this.props.handleResetFilter();
  }

  handleDownloadSVG = () => {
    const endpoint = this.props.currentEndpoint;
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth" : authToken } };
    axios.get(`${endpoint}&&get_csv=1`, headers)
    .then(res => {
      const reportData = res.data;
      this.setState({ reportData })

      const downloadActionElement = document.querySelector(".download-action");
      downloadActionElement.click();
    })
  }

  render() {
    return (
      <React.Fragment>
      <CSVLink 
      className="download-action" 
      data={this.state.reportData} 
      filename="reviewalert-report.csv"
       />
      <HeaderContainer>
        <Row>
          <DownloadContainer>
              <Button
              text="Download Report"
              hasIcon
              hideTextOnMobile
              iconPath={downloadIcon}
              bgColor="white"
              borderRadius="20em"
              onClick={this.handleDownloadSVG}
              hasShadow
              height="40px"
              bgColorOnHover={false}
              padding="0 40px"
              fontSize="14px"
              />
          </DownloadContainer>
          <ReportFilter 
            handleResetFilter={this.handleResetFilter}
            endpoint={this.handleEndpoint}
            updateRequests={this.handleUpdateRequests} />
        </Row>
      </HeaderContainer>
      </React.Fragment>
    );
  }
}
