import styled from 'styled-components';

export const SkeletonPageAssociationContainer = styled.div`

`;

export const SkeletonHeader = styled.div`

`;

export const SkeletonBody = styled.div`
    padding: 2em;
    box-sizing: border-box;
`;

export const SkeletonActionContainer = styled.div`

`;