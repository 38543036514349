import React from 'react';
import ReactSVG from 'react-svg';
import { Input, SearchContainer, SearchIconContainer } from './styled';
import searchIcon from '../../Assets/icons/search.svg';

export default class Search extends React.Component{
    
    state = {
        searchValue: "",
    }

    componentWillReceiveProps(props){
        if (!props.searchIsActive){
            this.setState({ searchValue: "" })
        }
    }

    handleChange = (e) => {
        const searchValue = e.target.value;
        this.setState({ searchValue });
        this.props.searchValue(searchValue);
    }


    render(){
        return(
            <SearchContainer onClick={this.props.onClick} searchIsActive={this.props.searchIsActive} id="search-container">
                <Input type="text" autofocus searchIsActive={this.props.searchIsActive} onChange={this.handleChange} value={this.state.searchValue}/>
                <SearchIconContainer><ReactSVG src={searchIcon} /></SearchIconContainer>
            </SearchContainer>
        )
    }
}