import React from "react";
import { CopyCodeContainer } from "./styled";
import CopyCodeButton from "./CopyCodeButton/CopyCodeButton";

export default class CopyCode extends React.Component {
  handlePreview = beacon => {
    this.props.currentPreview(beacon);
  };

  render() {
    return (
      <CopyCodeContainer>
        <CopyCodeButton
          onMouseOver={() => this.handlePreview("home-beacon")}
          onMouseOut={() => this.handlePreview("")}
          widgetType="home-beacon"
          widget="Home: Beacon"
        />
        <CopyCodeButton
          onMouseOver={() => this.handlePreview("home-horizontal")}
          onMouseOut={() => this.handlePreview("")}
          widgetType="home-horizontal"
          style={{ marginTop: "1em" }}
          widget="Home: Horizontal"
        />
        <CopyCodeButton
          onMouseOver={() => this.handlePreview("product-catalog-horizontal")}
          onMouseOut={() => this.handlePreview("")}
          widgetType="product-catalog-horizontal"
          style={{ marginTop: "1em" }}
          widget="Product Catatalog: Horizontal"
        />
        <CopyCodeButton
          onMouseOver={() => this.handlePreview("product-catalog-banner")}
          onMouseOut={() => this.handlePreview("")}
          widgetType="product-catalog-banner"
          style={{ marginTop: "1em" }}
          widget="Product Catalog: Banner"
        />
      </CopyCodeContainer>
    );
  }
}