import React from "react";
import ReactSVG from "react-svg";
import BeaconGuide from '../../Components/BeaconGuide/BeaconGuide'
import Feedback from '../../Components/Feedback/Feedback'
import NewLocation from "../../Pages/StoreLocation/NewLocation/NewLocation";
import { ModalContainer, Overlay } from "./styled";
import NewIntegration from "../../Pages/StoreLocation/NewIntegration/NewIntegration";
import closeIcon from "../../Assets/icons/close.svg";
import errorIcon from "../../Assets/icons/error.svg";
import SkeletonPageAssociation from "../../Pages/StoreLocation/SkeletonPageAssociation/SkeletonPageAssociation";

export default class AltModal extends React.Component {

  state = {
    newLocationFeedback: false
  }

  componentDidMount() {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  }

  handleCloseModal = isModalOpen => this.props.isModalOpen(isModalOpen);

  handleUpdatedLocations = updatedLocations => this.props.updatedLocations(updatedLocations);

  handleUpdateCurrentStep = currentStep => this.props.updatedCurrentStep(currentStep);

  handleNewLocationFeedback = newLocationFeedback => this.setState({ newLocationFeedback })

  render() {
    const { currentModal, headerTitle, className } = this.props;

    return (
      <React.Fragment>
        <Overlay onClick={() => this.handleCloseModal(false)} />
        <ModalContainer newEmployees={this.props.newEmployees} headerTitle={headerTitle} className={className} newLocationFeedback={this.state.newLocationFeedback}>
          {currentModal !== "integration-modal" && currentModal !== "loading" && (
            <div className="close-container">
              {headerTitle !== undefined && <label>{headerTitle}</label>}
              <ReactSVG
                src={closeIcon}
                onClick={() => this.handleCloseModal(false, "integration-modal", "")}
              />
            </div>
          )}
          {currentModal === "new-location-modal" ? (
            <NewLocation
              isModalOpen={this.handleCloseModal}
              updatedLocations={this.handleUpdatedLocations}
              showFeedback={this.handleNewLocationFeedback}
            />
          ) : currentModal === "integration-modal" ? (
            <NewIntegration
              isModalOpen={this.handleCloseModal}
              currentStep={this.props.currentStep}
              pages={this.props.pages}
              integrationHasFailed={this.props.integrationHasFailed}
              service={this.props.service}
              updateCurrentStep={this.handleUpdateCurrentStep}
              currentLocation={this.props.currentLocation}
              updatedLocations={this.handleUpdatedLocations}
            />
          ) : currentModal === undefined ? (
            this.props.children
          ) : currentModal === "loading" ? (
            <SkeletonPageAssociation />
          ) : currentModal === "beacon-guide" ? (
            <BeaconGuide />
          ) : currentModal === "integration-fail" ? (
            <Feedback 
             type="negative"
             direction="column"
             iconPath={errorIcon}
             text="Seems like you don’t have any business page associated with this email."
              />
            
          ) : null}
        </ModalContainer>
      </React.Fragment>
    );
  }
}
