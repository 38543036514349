import styled from 'styled-components';

export const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;

    svg{
        cursor: pointer;
        path{
            fill: #888;
        }

        &:hover{
            path{
                fill: #333;
            }
        }
    }
`;

export const LocationsContainer = styled.ul`
    padding: 0 2em 2em;;
    margin: 0;
    max-height: 50vh;
    overflow: auto;
`;

export const Location = styled.li`
    list-style: none;
    line-height: 42px;
    display: flex;
    border-bottom: 1px solid #ccc;
    align-items: center;

`;

export const Services = styled.div`
    display: flex;
    align-items: center;
`;

export const Service = styled.label`
    margin-left: 2em;
`;  