import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { API_URL } from '../../../config';
import ReactSVG from 'react-svg';
import {
    setFilter,
    setCurrentChat,
    setShowChatsOnMobile,
    setSendReviewRequestOnChat
} from '../../../store/modules/webchat/actions'
import { Row, Label, Container } from '../../../Assets/Structure/Structure';
import { blueDefault, green } from '../../../Assets/colorPallet/colorPallet';
import { WebchatHeaderContainer, Status, ListContainer, ListItem } from './styled';
import menuIcon from '../../../Assets/icons/mob-menu.svg'
import Button from '../../../Components/Button/Button';
import phoneIcon from '../../../Assets/icons/phone.svg'
import Modal from '../../../Components/Modal/Modal';
import SubMenu from "./SubMenu/SubMenu";
import {Link} from "react-router-dom";
import videoIcon from "../../../Assets/icons/video.svg";
import CreateVideoRoomDialog from "../CreateVideoRoomDialog/CreateVideoRoomDialog";


class WebchatHeader extends React.Component{

    state = {
        active: false,
        currentFilter: "all",
        showChatsOnMobile: this.props.showChatsOnMobile,
        locations: [],
        locationsPlaceholder: "Choose location",
        currentChatClientNumber: "",
        showCreateVideoRoomDialog: false
    }

    componentDidMount(){
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {headers: { "x-sas-Auth" : authToken }};
        axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
        .then(res => {
            const locations = res.data;


           this.setState({ locations });

        })
    }

    componentWillReceiveProps(props, prev){
        if (props.showChatsOnMobile !== prev.showChatsOnMobile){
            this.setState({ showChatsOnMobile: props.showChatsOnMobile })
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.filter !== this.props.filter){
            if (Object.getOwnPropertyNames(this.props.filter).length === 1 && this.props.filter.locationId){
                this.setState({ currentFilter: "location" })
            }
        }
    }

    setFilter = filter => {
        let filters = this.props.filter
        let currentFilter = filter

        if (filter === "all"){
            filters = {}
        } else if (filters.locationId){
            filters = { locationId: filters.locationId, [filter]: true }
        } else {
            filters = { [filter]: true }
        }

        this.props.setFilter(filters)
        this.props.setCurrentChat({})
        this.setState({ currentFilter })

    }

    showChatsOnMobile = () => {
        this.setState(state => ({ showChatsOnMobile: !state.showChatsOnMobile }), () => {
            this.props.setShowChatsOnMobile(this.state.showChatsOnMobile)
        })
    }

    handleSelectedLocation = location => this.setState({ locationsPlaceholder: location.name })

    handleSendReviewRequestOnChat = () => {
        this.props.setSendReviewRequestOnChat(true)
    }

    handleShowClientDetailsOnMobile = (showClientDetailsOnMobile) => this.setState({ showClientDetailsOnMobile })

    handleComposeMessageDialog = () => this.props.onShowComposeMessageDialog(true)

    handleOnShowWebchatNumber = () => this.setState({ showClientDetailsOnMobile: true })

    handleShowCreateVideoRoomDialog = (showCreateVideoRoomDialog) => {
        this.setState({ showCreateVideoRoomDialog })
    }


    render(){
        let currentChatClientNumber = this.props.currentChat.clientNumber

        if (currentChatClientNumber){
            currentChatClientNumber = currentChatClientNumber.toString()
            currentChatClientNumber = currentChatClientNumber.slice(1)
        }

        const { currentFilter } = this.state;

        const clientNumber = currentChatClientNumber ? `(1) ${currentChatClientNumber}` : ""
        const clientName = this.props.currentChat.clientName ? this.props.currentChat.clientName : this.props.currentChat.author

        return(
            <>
            <WebchatHeaderContainer>
                <Row between>
                    <Container flex alignCenter className="webchat-header-container">
                        <ListContainer fluidOnMobile>
                            <Container alignCenter between showOnMobile fluid>
                                <Container flex alignCenter fluid between>
                                    <ListItem
                                    onClick={this.showChatsOnMobile}
                                    showOnMobile>
                                        <Row alignCenter>
                                            <ReactSVG src={menuIcon} className="chat-menu-icon" />
                                        </Row>
                                    </ListItem>
                                    <Container >
                                        <Button
                                            text="Compose Message"
                                            bgColor="white"
                                            borderRadius="20em"
                                            onClick={this.handleComposeMessageDialog}
                                            hasShadow
                                            width="14em"
                                            padding="0"
                                            height="40px"
                                            bgColorOnHover={false}
                                            fontSize="14px"
                                        />
                                    </Container>
                                </Container>
                                <SubMenu
                                onSendReviewRequest={this.handleSendReviewRequestOnChat}
                                onShowWebchatNumber={this.handleOnShowWebchatNumber} />
                            </Container>
                            <ListItem
                             hideOnMobile
                             style={{ marginLeft: "0", marginRight: "2em" }}
                             active={currentFilter === "all" ? true : false}
                             onClick={() => this.setFilter('all')}>
                                <Row alignCenter padding=".5em 0">
                                    <Label pointer ml=".5em">All messages</Label>
                                </Row>
                                <div class="animated-line" />
                            </ListItem>

                            <ListItem
                             style={{ marginLeft: 0 }}
                             clickDisabled
                             hideOnMobile
                             active={currentFilter === "newMessage" ? true : false}
                             onClick={() => this.setFilter('newMessage')}>
                                <Row alignCenter padding=".5em 0">
                                    <Status color={blueDefault} />
                                    <Label pointer ml=".5em">New messages</Label>
                                </Row>
                                <div class="animated-line" />
                            </ListItem>
                            <ListItem
                             clickDisabled
                             hideOnMobile
                             active={currentFilter === "needsResponse" ? true : false}
                             onClick={() => this.setFilter('needsResponse')}>
                                <Row alignCenter padding=".5em 0">
                                    <Status color={green} />
                                    <Label pointer ml=".5em">Need response</Label>
                                </Row>
                                <div class="animated-line" />
                            </ListItem>
                        </ListContainer>
                    </Container>
                    {/*<Container flex alignCenter>*/}
                    {/*    <Button*/}
                    {/*        bgColor="white"*/}
                    {/*        bgColorOnHover="#f8f8f8"*/}
                    {/*        borderRadius="50%"*/}
                    {/*        width="38px"*/}
                    {/*        height="38px"*/}
                    {/*        customBorder="1px solid #D0D0D0;"*/}
                    {/*        padding="0"*/}
                    {/*        hasIcon*/}
                    {/*        onlyIcon*/}
                    {/*        iconPath={videoIcon}*/}
                    {/*        onClick={() => this.handleShowCreateVideoRoomDialog(true)}/>*/}
                    {/*</Container>*/}
                    <Container hideOnMobile flex alignCenter>
                       <Container flex justifyEnd column>
                            <Label bold textRight>{clientName}</Label>
                            <Container flex alignCenter mt="5px">
                                <Container flex mr="5px">
                                    <ReactSVG src={phoneIcon} className="phone-icon" />
                                </Container>
                                <Container>
                                    <Label>{clientNumber !== "" ? clientNumber : this.props.currentChat.sentFrom}</Label>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Row>
            </WebchatHeaderContainer>
             {this.state.showClientDetailsOnMobile ? (
                <Modal maxWidth="80%" isModalOpen={this.handleShowClientDetailsOnMobile}>
                    <Container padding="20px">
                            <Label bold textRight>{this.props.currentChat.author}</Label>
                            <Container flex alignCenter mt="10px">
                                <Container flex mr="5px">
                                    <ReactSVG src={phoneIcon} className="phone-icon" />
                                </Container>
                                <Container>
                                    <Label>{clientNumber}</Label>
                                </Container>
                        </Container>
                    </Container>
                </Modal>
            ) : null}
                {this.state.showCreateVideoRoomDialog && (
                    <CreateVideoRoomDialog active={this.state.showCreateVideoRoomDialog} onClose={this.handleShowCreateVideoRoomDialog} />
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    needsResponseAmount: state.webchat.needsResponseAmount,
    newMessagesAmount: state.webchat.newMessagesAmount,
    filter: state.webchat.filter,
    showChatsOnMobile: state.webchat.showChatsOnMobile,
    currentChat: state.webchat.currentChat
})

const mapDispatchToProps = dispatch => ({
    setFilter(filters){
        dispatch(setFilter(filters))
    },

    setCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },

    setShowChatsOnMobile(showChatsOnMobile){
        dispatch(setShowChatsOnMobile(showChatsOnMobile))
    },

    setSendReviewRequestOnChat(sendReviewRequestOnChat){
        dispatch(setSendReviewRequestOnChat(sendReviewRequestOnChat))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(WebchatHeader)
