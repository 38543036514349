// MUTATIONS
import {
    SET_CURRENT_CHAT,
    SET_CHAT_CREDENTIALS,
    UPDATE_CHAT_GROUPS_MENU,
    SET_NEEDS_RESPONSE_AMOUNT,
    SET_NEW_MESSAGES_AMOUNT,
    SET_FILTER,
    SET_SHOW_CHATS_ON_MOBILE,
    SET_CONVERSATION_BEFORE_REFRESH,
    SET_CHAT_GROUPS_AMOUNT,
    SET_SEND_REVIEW_REQUEST_ON_CHAT,
    SET_CHAT_GROUPS
} from "./mutations"

const initialState = {
    currentChat: {},
    chatCredentials: {},
    updatedChatGroups: [],
    optmisticChatGroupUpdated: {},
    updateChatGroupsMenu: false,
    needsResponseAmount: 0,
    newMessagesAmount: 0,
    filter: {},
    showChatsOnMobile: false,
    chatIdBeforeRefresh: null,
    chatGroupsAmount: null,
    sendReviewRequestOnChat: false,
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_CHAT:
            return {...state, currentChat: action.payload }

        case SET_CHAT_CREDENTIALS:
            return {...state, chatCredentials: action.payload}

        case UPDATE_CHAT_GROUPS_MENU:
            return {...state, updateChatGroupsMenu: action.payload}

        case SET_NEEDS_RESPONSE_AMOUNT:
            return {...state, needsResponseAmount: action.payload}

        case SET_NEW_MESSAGES_AMOUNT:
            return {...state, newMessagesAmount: action.payload }

        case SET_FILTER:
            return {...state, filter: action.payload}

        case SET_SHOW_CHATS_ON_MOBILE:
            return {... state, showChatsOnMobile: action.payload}

        case SET_CONVERSATION_BEFORE_REFRESH:
            return {...state, chatIdBeforeRefresh: action.payload}

        case SET_CHAT_GROUPS_AMOUNT:
            return {...state, chatGroupsAmount: action.payload}

        case SET_SEND_REVIEW_REQUEST_ON_CHAT:
            return {...state, sendReviewRequestOnChat: action.payload}

        case SET_CHAT_GROUPS:
            return { ...state, updatedChatGroups: action.payload }

        default:
            return state
    }
}

export default reducer
