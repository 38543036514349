import styled from "styled-components";

export const SellerLoginContainer = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #F8F8F8;
  padding-bottom: 4em;
  .top-container {
    width: 100%;
    flex-flow: row wrap;
    padding-bottom: 2em;
    box-sizing: border-box;
    background: linear-gradient(
      346.34deg,
      rgb(2, 95, 136) -25.62%,
      rgb(195, 60, 42) 198.58%
    );

    .location-login-ra-logo {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      box-sizing: border-box;
    }
  }

  .bottom-container{
    margin-top: 2em;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1100px){
    .column{
      padding: 0;
    }
  }

  @media only screen and (max-width: 600px){
    .column{
      padding: 0 1em;
    }

    .location-overview{
      order: 2;
    }

    .send-review-invitation{
      order: 1;
    }
  }
`;
