import React from "react";
import axios from "axios";
import queryString from "query-string";
import ReviewsFilter from "../ReviewsFilter/ReviewsFilter";
import { 
  TotalOfReviews, 
  SkeletonContainer,
  UnansweredReviewsFeedbackContainer
} from "./styled";
import Review from "../../../Components/Review/Review";
import Modal from "../../../Components/Modal/Modal";
import AnswerReview from "../AnswerReview/AnswerReview";
import { API_URL } from "../../../config";
import EmptyFeedback from "../../../Components/EmptyFeedback/EmptyFeedback";
import emptyReviewsIcon from "../../../Assets/icons/empty-reviews.svg";
import ReviewSkeleton from "../../../Components/Review/ReviewSkeleton";
import Pagination from "../../../Components/Pagination/Pagination";
import { Label, Text } from "../../../Assets/Structure/Structure";

export default class RenderContent extends React.Component {
  state = {
    reviews: [],
    totalReviews: "",
    locations: [],
    isModalOpen: false,
    reviewToAnswer: "",
    showEmptyFeedback: false,
    loading: true,
    store: "",
    headers: {},
    authToken: "",
    resetFilter: false,
    filterType: "",
    filterByLocation: false,
    filterByPlatform: false,
    filterByReviewType: false,
    filterByNoReply: false,
    selectedPlatform: "",
    reviewType: "",
    selectedLocation: "",
    endpoint: ""
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};

    axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
    .then(res => {
      const locations = res.data;
      this.setState({ locations, loading: false });
    });

    this.checkQueryString(store, headers, authToken);
  }

  checkQueryString = (store, headers, authToken) => {

    let parsed = queryString.parse(window.location.search);
    let endpoint;

    if (!Object.keys(parsed).length){
      parsed = queryString.parse(`?${window.location.hash.substr(1)}`)
    }


    if (parsed.filter_negative) {
      endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&maximum_rating=2`;
      this.setState({ endpoint: `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&maximum_rating=2`  })
    }  else {
      endpoint = `${API_URL}/admin/stores/${store}/reviews?`;
      this.setState({ endpoint: `${API_URL}/admin/stores/${store}/reviews?` })
    }

    axios.get(endpoint, headers)
    .then(res => {
        this.setState({
          reviews: res.data.reviews,
          totalReviews: res.data.total,
          loading: false,
          store,
          authToken,
          headers
        });
    });
  }

  handleReviews = (selectedOption, type) => {
    const { 
      store, 
      headers, 
      filterByLocation, 
      filterByPlatform, 
      filterByNoReply,
      selectedLocation,
      selectedPlatform,
      reviewType
    } = this.state;

    this.setState({ isFilterOn: true });
    let endpoint;

    // NO REPLY
    if (type === "no-reply"){
      if (filterByPlatform && !filterByLocation && reviewType === ""){
        endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}`;
      } else if (filterByPlatform && filterByLocation && reviewType === ""){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}`;
      } else if (filterByLocation && !filterByPlatform && reviewType === ""){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1`;
      } else if (reviewType === "positive"){
        if (filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&minimum_rating=4`;
        } else if (!filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&minimum_rasting=4`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=4&&unreplied=1`;
        }
      } else if (reviewType === "neutral"){
        if (filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&minimum_rating=3&&maximum_rating=3`;
        } else if (!filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        } else if (filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&minimum_rasting=3&&maximum_rating=3`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=3&&maximum_rating=3&&unreplied=1`;
        }
      } else if (reviewType === "negative"){
        if (filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&maximum_rating=2`;
        } else if (!filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&maximum_rating=2`;
        } else if (filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}&&maximum_rating=2`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?maximum_rating=2&&unreplied=1`;
        }
      } else {
        endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1`;
      }

      axios.get(endpoint, headers)
      .then(res => {
        if (res.data.total === 0) {
          this.setState({ 
            showEmptyFeedback: true,  
            reviews: [],
            totalReviews: 0,
            filterByNoReply: true
          });
        } else {
            this.setState({ 
              reviews: res.data.reviews, 
              filterType: "no-reply", 
              endpoint,
              filterByNoReply: true,
              totalReviews: res.data.total, 
            });
          }
      }).catch(() => this.setState({ isFilterOn: false }))
    }

    //  FILTER BY LOCATION
    if (type === "location") {
      if (filterByPlatform && reviewType === ""){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?service=${selectedPlatform.toLowerCase()}`
        //  + POSITIVE
      } else if (reviewType === "positive") {
        if (filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else {
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?minimum_rating=4`;
        }
        // + NEUTRAL
      } else if (reviewType === "neutral"){
        if (filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        } else {
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?minimum_rating=3&&maximum_rating=3`;
        }
        // + NEGATIVE
      } else if (reviewType === "negative"){
        if (filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?service=${selectedPlatform.toLowerCase()}&&maximum_rating=2`;
        } else {
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?maximum_rating=2`;
        }
      } else if (filterByNoReply){
        if (filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?unreplied=1&&service=${selectedPlatform.toLowerCase()}`;
        } else {
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?unreplied=1`;
        }
      } else {
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedOption.id}/reviews?`
      }
      
      axios.get(endpoint, headers)
      .then(res => {
  
          if (res.data.total === 0) {
            this.setState({ 
              showEmptyFeedback: true, 
              reviews: [],
              totalReviews: 0,
              selectedLocation: selectedOption.id,
              filterByLocation: true
            });
          } else {
            this.setState({ 
              reviews: res.data.reviews, 
              filterType: "location", 
              endpoint,
              filterByLocation: true,
              selectedLocation: selectedOption.id,
              totalReviews: res.data.total, 
              selectedLocation: selectedOption.id 
            });
          }
      }).catch(() => this.setState({ isFilterOn: false }))
    }

    // FILTER BY PLATFORM
    if (type === "platform") {
      if (filterByLocation){
        endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}`;
        if (reviewType === "positive") {
          if (filterByLocation && !filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
          } else if (filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`
          } else if (!filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
          } else {
            endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
          }
          // + NEUTRAL
        } else if (reviewType === "neutral") {
          if (filterByLocation && !filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
          } else if (filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`
          } else if (!filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
          } else {
            endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
          }
          // + NEGATIVE
        } else if (reviewType === "negative") {
          if (filterByLocation && !filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
          } else if (filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`
          } else if (!filterByLocation && filterByNoReply){
            endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
          } else {
            endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
          }
        }
      }  else if (reviewType === "positive") {
        if (filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`
        } else if (!filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=4`;
        }
        // + NEUTRAL
      } else if (reviewType === "neutral") {
        if (filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        } else if (filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`
        } else if (!filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        }
        // + NEGATIVE
      } else if (reviewType === "negative") {
        if (filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
        } else if (filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews/?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`
        } else if (!filterByLocation && filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}&&maximum_rating=2`;
        }
      } else if (filterByNoReply){
        if (filterByLocation){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}`;
        } else {
          endpoint = `${API_URL}/private/stores/${store}/reviews?unreplied=1&&service=${selectedOption.value.toLowerCase()}`;
        }
      }  else {
        endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedOption.value.toLowerCase()}`;
      }

      axios.get(endpoint, headers)
      .then(res => {
        if (res.data.total === 0) {
          this.setState({ 
            showEmptyFeedback: true,  
            reviews: [],
            totalReviews: 0,
            selectedPlatform: selectedOption.value,
            filterByPlatform: true
          });
        } else {
          this.setState({ reviews: res.data.reviews, 
            service: selectedOption.value.toLowerCase(), 
            filterType: "platform", 
            selectedPlatform: selectedOption.value,
            filterByPlatform: true,
            endpoint,
            totalReviews: res.data.total 
          });
        }
      }).catch(() => this.setState({ isFilterOn: false }))
    }

    // FILTER BY REVIEW-TYPE
    if (type === "review-type") {

      //POSITIVE
      if (selectedOption === "positive"){
        if (filterByLocation && !filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?minimum_rating=4`;
        } else if (filterByLocation && filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByPlatform && !filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByNoReply && filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4&&unreplied=1`;
        } else if (filterByNoReply && filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?minimum_rating=4&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=4&&unreplied=1`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=4`;
        }
      }

      //NEUTRAL
      if (selectedOption === "neutral"){
        if (filterByLocation && !filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?minimum_rating=3&&maximum_rating=3`;
        } else if (filterByLocation && filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=3&&maximum_rating=3`;
        } else if (filterByPlatform && !filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByNoReply && filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=3&&maximum_rating=3&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=3&&maximum_rating=3&&unreplied=1`;
        } else if (filterByNoReply && filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?minimum_rating=3&&maximum_rating=3&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=3&&maximum_rating=3&&unreplied=1`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?minimum_rating=3&&maximum_rating=3`;
        }
      }

      //NEGATIVE
      if (selectedOption === "negative"){
        if (filterByLocation && !filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?maximum_rating=2`;
        } else if (filterByLocation && filterByPlatform && !filterByNoReply){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&maximum_rating=2`;
        } else if (filterByPlatform && !filterByLocation && !filterByNoReply){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&minimum_rating=4`;
        } else if (filterByNoReply && filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?service=${selectedPlatform.toLowerCase()}&&maximum_rating=2&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?service=${selectedPlatform.toLowerCase()}&&maximum_rating=2&&unreplied=1`;
        } else if (filterByNoReply && filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews?maximum_rating=2&&unreplied=1`;
        } else if (filterByNoReply && !filterByLocation && !filterByPlatform){
          endpoint = `${API_URL}/admin/stores/${store}/reviews?maximum_rating=2&&unreplied=1`;
        } else {
          endpoint = `${API_URL}/admin/stores/${store}/reviews?maximum_rating=2`;
        }
      }
      


        axios.get(endpoint, headers)
        .then(res => {
          if (res.data.total === 0) {
            this.setState({ 
              showEmptyFeedback: true,  
              reviews: [],
              filterByReviewType: true,
              filterType: selectedOption,
              reviewType: selectedOption,
              totalReviews: 0
            });
          } else {
              this.setState({
                reviews: res.data.reviews,
                showEmptyFeedback: false,
                endpoint,
                filterType: selectedOption,
                reviewType: selectedOption,
                filterByReviewType: true,
                totalReviews: res.data.total
              });
            }
        }).catch(() => this.setState({ isFilterOn: false }))
      }
  };

  // ANSWER REVIEW
  handleAnswerReview = (review, isModalOpen) => {
    this.setState({ reviewToAnswer: review });
    this.setState({ isModalOpen });
  };

  // RESET FILTER
  handleResetFilter = async () => {
    const { store, headers } = this.state;
    this.setState({ loading: true, isFilterOn: false });

    await axios.get(`${API_URL}/admin/stores/${store}/reviews`, headers)
    .then(res => {
       this.setState({
          reviews: res.data.reviews,
          resetFilter: true,
          filterType: "",
          reviewType: "",
          filterByLocation: false,
          filterByPlatform: false,
          filterByNoReply: false,
          filterByReviewType: false,
          showEmptyFeedback: false,
          totalReviews: res.data.total,
          endpoint: `${API_URL}/admin/stores/${store}/reviews?`
        });
      })
      .catch(() => {})
      .finally(() => {
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1000)
      })  

      this.resetFilterOff();
  };
  
  //SET RESETFILTER STATE TO FALSE 
  resetFilterOff = () => {
    this.setState({ resetFilter: false })
  }

  // HANDLE FILTER TYPE
  handleFilterType = filterType => this.setState({ filterType });

  // HANDLE CURRENT PAGE WHEN CLICKING ON PAGINATION
  handleCurrentPage = currentPage => {
    const { headers} = this.state;
    this.setState({ loading: true })
    window.scrollTo(0,0);
      axios.get(`${this.state.endpoint}&&page=${currentPage}`, headers)
      .then(res => {
        this.setState({
          reviews: res.data.reviews,
          loading: false,
        });
      });
    
  };


  // UPDATE REVIEWS
  handleUpdateReviews = (reviews, totalOfReviews) => {
    this.setState({
      reviews,
      totalOfReviews
    })
  }

  handleStopBackground = () => {
    this.props.stopBackground();
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isModalOpen && (
          <Modal 
           className="answer-review-modal" 
           isModalOpen={() => this.handleAnswerReview(false)}>
            <AnswerReview
              isModalOpen={this.handleAnswerReview}
              updateReviews={this.handleUpdateReviews}
              review={this.state.reviewToAnswer}
            />
          </Modal>
        )}
        <ReviewsFilter
          locations={this.state.locations}
          selectedLocation={this.handleReviews}
          selectedPlatform={this.handleReviews}
          selectedReviewType={this.handleReviews}
          noReply={this.handleReviews}
          privateReviews={this.handleReviews}
          handleResetFilter={this.handleResetFilter}
          resetFilter={this.state.resetFilter}
          device={this.props.device}
          filterType={this.handleFilterType}
          isFilterOn={this.state.isFilterOn}
        />
        {this.state.filterByNoReply && this.state.totalReviews > 0 ? (
        <UnansweredReviewsFeedbackContainer>
          <Label 
           color="#025F88" 
           fontSize="16px" 
           fontWeight="500" 
           className="no-reply-feedback">
           {this.state.totalReviews} unanswered reviews
           </Label>
          <Label mt=".25em">
            Build trust and show that you care by answering your customer reviews.
          </Label>
        </UnansweredReviewsFeedbackContainer>
        ) : (
          <TotalOfReviews>{this.state.totalReviews} reviews</TotalOfReviews>
        )}
        {this.state.showEmptyFeedback ? (
          <EmptyFeedback
            iconPath={emptyReviewsIcon}
            text="No reviews with the selected criteria has been founded."
            containerHeight="calc(100% - 91px - 4em)"
            flex="1"
          />
        ) : (
          <React.Fragment>
            {this.state.loading ? (
              <SkeletonContainer>
                <ReviewSkeleton />
                <ReviewSkeleton />
                <ReviewSkeleton />
              </SkeletonContainer>
            ) : (
              this.state.reviews.map(review => {
                return (
                  <Review
                    review={review}
                    locations={this.state.locations}
                    answerReview={() => this.handleAnswerReview(review, true)}
                    marginTop="1em"
                  />
                );
              })
            )}
          </React.Fragment>
        )}
        {this.state.reviews.length > 0 && (
          <Pagination
            totalOfItems={this.state.totalReviews}
            maxItemsPerPage={20}
            currentPage={this.handleCurrentPage}
            mt="2em"
          />
        )}
      </React.Fragment>
    );
  }
}
