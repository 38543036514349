import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import { GraphicContainer, LeftLabel, RightLabel } from "./styled";
import { RowJustifyContainer } from "./styled";
import { Row } from "../../../Assets/Structure/Structure";
import BarChartSimple from "../../BarChartSimple/BarChartSimple";

export default class Graphic extends React.Component {
  state = {
    reviews: this.props.reviews,
    width: ""
  };

  componentDidMount() {
    this.setState({ reviews: this.props.reviews, width: this.props.width });
    setTimeout(() => {
      this.getClientWidth();
    }, 1000);
    window.addEventListener("resize", this.handleFluidWidth);
  }

  componentWillReceiveProps(props) {
    this.setState({ width: props.width });
    const reviews = props.reviews.map(review => {
      return review.time;
    });
    let compressed = this.compressArray(reviews);

    compressed = compressed.map((review, index) => {
      if (index < 7) {
        return review.count;
      }
    });

    const reducer = (accumulate, current) => accumulate + current;
    const last7DaysReviews = compressed.reduce(reducer, 0);
    this.setState({ last7DaysReviews });
  }

  getClientWidth = () => {
    const barChartContainer = document.getElementById(
      "all-reviews-bar-chart-container"
    );

    let width;
    if (barChartContainer === null) {
      return;
    } else {
      width = barChartContainer.clientWidth;
    }
    this.setState({ width });
  };

  handleFluidWidth = e => {
    const barChartContainer = document.getElementById(
      "all-reviews-bar-chart-container"
    );
    
    let width;
    if (barChartContainer === null) {
      return;
    } else {
      width = barChartContainer.clientWidth;
    }
    this.setState({ width });
  };

  compressArray = original => {
    var compressed = [];
    var copy = original.slice(0);
    for (var i = 0; i < original.length; i++) {
      var myCount = 0;
      for (var w = 0; w < copy.length; w++) {
        if (original[i] == copy[w]) {
          myCount++;
          delete copy[w];
        }
      }

      if (myCount > 0) {
        var a = new Object();
        a.value = original[i];
        a.count = myCount;
        compressed.push(a);
      }
    }
    return compressed;
  };

  render() {
    return (
      <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
      <GraphicContainer
        className={this.props.className}
        id="all-reviews-bar-chart-container"
        width={this.state.width}
      >
        <RowJustifyContainer>
          {!this.props.noLabel ? <LeftLabel>Last 7 days</LeftLabel> : <div />}
          <RightLabel>{this.state.last7DaysReviews} reviews</RightLabel>
        </RowJustifyContainer>
        <Row id="bar-chart-container-row">  
          <BarChartSimple width={800} className="bar-chart"/>
        </Row>
      </GraphicContainer>
      </CSSTransitionGroup>
    );
  }
}
