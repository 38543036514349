import styled from 'styled-components';

export const SideFilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7;
    cursor: pointer;
    border-radius: 20em;
    width: 8em;
    height: 40px;

    svg{
        margin-right: .5em;
    }

    label{
        font-size: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #333333;
    }
`;

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #00000090;
    left: 0;
    opacity: ${props => (props.showSideFilter ? "1" : "0")};
    pointer-events: ${props => props.showSideFilter ? "all" : "none"};
    z-index: 150;
    top: 0;
    transition: opacity .4s ease;
`;