import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (max-width: 600px){
        padding: 0 1em;
    }
`;

export const DownloadContainer = styled.div`
    flex: 1 1 25em;

    a{
        text-decoration: none;
        color: #333;
    }
`;