import React, { useState } from 'react'
import ReactSVG from 'react-svg'
import { SelectFileContainer } from './styled'
import checkIcon from '../../Assets/icons/check.svg'
import { Container, Label } from '../../Assets/Structure/Structure'
import Dropzone from 'react-dropzone'


export default function SelectFile(props){

    const [fileUpdated, setFileUpdated] = useState(false)

    const handleChange = e => {
        props.onChange(e)
        setFileUpdated(true)
    }

    const handleFileOnDrop = file => {
        props.onDrop(file)
        setFileUpdated(true)
    }

    const label = props.multiple ? "Select File (s)" : "Select File"

    return (
        <Dropzone onDrop={handleFileOnDrop}>
            {({getRootProps, getInputProps}) => (
            <Container flex alignCenter>
                <SelectFileContainer {...getRootProps()}>
                    <input {...getInputProps()}  type="file" id="file" onChange={handleChange} multiple={props.multiple ? "multiple" : null}/>
                    <div htmlFor="file">{label}</div>
                </SelectFileContainer>
                {fileUpdated && 
                <Container ml="20px" flex alignCenter>
                    <ReactSVG src={checkIcon} />
                    <Container ml="10px">
                        <Label>selected</Label>
                    </Container>
                </Container>}
            </Container>
            )}
        </Dropzone>
    )
}