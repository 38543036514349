import React from 'react'
import { BeaconFormatContainer, RowContainer } from './styled'
import stepOneCode from '../../../Assets/images/step-one-code.png'
import stepTwoCode from '../../../Assets/images/step-two-code.png'

export default class BeaconFormat extends React.Component {
  render() {
    const { beaconName, beaconNameTxt } = this.props

    return (
      <React.Fragment>
        <h3 style={{ marginTop: '4em' }}>{beaconName}</h3>
        <BeaconFormatContainer>
          <RowContainer>
              <div className="step">1</div>
              <div className="container">
              <p>
                Copy the embed code for the {beaconNameTxt} format. You can
                easily do it by clicking on Copy Code, located on the right side
                menu of the dashboard.
              </p>
            </div>
          </RowContainer>
        </BeaconFormatContainer>

        <BeaconFormatContainer>
          <RowContainer>
            <div className="step">2</div>
            {beaconNameTxt === 'Home Horizontal' ? (
              <div className="container">
                <p>
                  Create a div html tag with the ID of "ra-beacon-container" in
                  the html document of your home page website where you wish the Home Horizontal to be displayed.
                </p>
                <div className="code-container">
                  <img src={stepOneCode} />
                </div>
              </div>
            ) : beaconNameTxt === 'Product Catalog Horizontal' ? (
              <div className="container">
                <p>
                  Create a div html tag with the ID of "ra-beacon-container" in
                  the html document of your product catalog page website where
                  you wish the {beaconNameTxt} to be displayed.
                </p>
                <div className="code-container">
                  <img src={stepOneCode} />
                </div>
              </div>
            ) : beaconNameTxt === 'Product Catalog Banner' ? (
              <div className="container">
                <p>
                  Create a div html tag with the ID of "ra-beacon-container" in
                  the html document of your product catalog page website, inside
                  your products list.
                </p>
                <div className="code-container">
                  <img src={stepOneCode} />
                </div>
              </div>
            ) : (
              <div className="container">
              <p>Create a script html tag at the end of the body of the HTML document on your website and insert the copied code inside it.</p>
              <div className="code-container">
                  <img src={stepTwoCode} />
                </div>
              </div>
              
            )}
          </RowContainer>
        </BeaconFormatContainer>

        <BeaconFormatContainer>
          <RowContainer>
            <div className="step">3</div>
            {beaconNameTxt !== 'Home Beacon' ? (
              <div className="container">

              <p>Create a script html tag at the end of the body of the HTML document on your website and insert the copied code inside it.</p>
              <div className="code-container">
                  <img src={stepTwoCode} />
                </div>
              </div>
            ) : (
              <p>
                Done. The {beaconNameTxt} format should now be running on your website.
              </p>
            )}
          </RowContainer>
        </BeaconFormatContainer>
        {beaconNameTxt !== "Home Beacon" && (
          <BeaconFormatContainer>
          <RowContainer>
            <div className="step">4</div>
              <p>
                Done. The {beaconNameTxt} format should now be running on your website.
              </p>
          </RowContainer>
        </BeaconFormatContainer>
        )}
      </React.Fragment>
    )
  }
}
