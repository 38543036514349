import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from 'react-svg';
import {
    TableWrapper,
    TableRow,
    TableCol,
    Col1Inner1,
    Col1Inner2,
    ColTitle,
    ColValue,
    NameAvatarWrapper,
    ColUserName,
    Col2Inner1,
    Col2Inner2,
    InnerCol
} from "./styled";
import AvatarBgColor from "./AvatarBgColor/AvatarBgColor";
import Axios from "axios";
import {API_URL} from "../../../../config";
import UpdateEmployeeLocation from "../../UpdateEmployeeLocation/UpdateEmployeeLocation";
import UpdateEmployeeRole from "../../UpdateEmployeeRole/UpdateEmployeeRole";
import DeleteEmployee from "../../DeleteEmployee/DeleteEmployee";
import locationIcon from '../../../../Assets/icons/location.svg';
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";
import { Label } from '../../../../Assets/Structure/Structure';

export default class    TeamTableRow extends React.Component {
    state = {
        avatarBgColor: "",
        locations: [],
        locationId: "",
        showDeleteAction: false
    };

    componentDidMount() {
        const authToken = localStorage.getItem("token");
        const store = localStorage.getItem("store");
        const headers = {headers: { "x-sas-Auth" : authToken }};
        Axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
        .then(res => {
            const locations = res.data;
            const employeeLocation = this.props.employee.location;
            this.setState({ locations })
            const locationId = locations.filter(location => location.name === employeeLocation)[0].id;
            this.setState({ locationId });
        });   
    }

    handleAvatarBgColor = avatarBgColor => this.setState({avatarBgColor});

    handleMouseOver = () => {
        this.setState({showDeleteAction: true});
    };

    handleMouseLeave = () => {
        this.setState({showDeleteAction: false});
    };

    deleteEmployee = () => {};

    handleUpdateEmployees = (employees, totalOfEmployees) => {
        this.props.updateEmployees(employees, totalOfEmployees);
    }

    render() {
        const {employee, rowId} = this.props;

        //GET EMPLOYEE INITIALS
        const employeeName = employee.name;
        const employeeInitials = employeeName
            .split(/\s/)
            .reduce((res, word) => (res += word.slice(0, 1)), "");

        const initials = employeeInitials.split("");
        const firstInitial = initials[0];


        //temporarily solution to hide None CTR response
        let ctr = employee.ctr;
        if (ctr === "None"){
            ctr = "";
        } else {
            ctr = `${ctr}%`;
        }

        return (
            <CSSTransitionGroup
            transitionName="mount-page"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
            <TableWrapper>
                <TableRow
                    onMouseOver={this.handleMouseOver}
                    onMouseLeave={this.handleMouseLeave}
                    device={this.props.device}>
                    <TableCol borderRight style={{ paddingLeft: "0" }}>
                        <Col1Inner1>
                            <AvatarBgColor
                                employeeFirstInitial={firstInitial}
                                avatarBgColor={this.handleAvatarBgColor}>
                                <NameAvatarWrapper
                                    style={{
                                    backgroundColor: this.state.avatarBgColor
                                }}>
                                    {employeeInitials}
                                </NameAvatarWrapper>
                            </AvatarBgColor>
                        </Col1Inner1>
                        <Col1Inner2 style={{ width: "100%" }}>
                            <ColUserName>{employee.name || ""}</ColUserName>
                            <ColValue>
                                <Label>{employee.profile}</Label>
                                {/* <UpdateEmployeeRole
                                    updateEmployees={this.handleUpdateEmployees}
                                    employee={employee || ""}
                                    role={employee.profile || employee.role}
                                    id={`update-employee-role-${this.props.rowId}`}/> */}
                            </ColValue>
                        </Col1Inner2>

                    </TableCol>

                    <TableCol 
                        maxWidth="20em" 
                        borderRight 
                        className="location-container"
                        style={{ padding: "0 1em" }}>
                        <Col2Inner1 className="location-svg-container">
                            <ReactSVG src={locationIcon}/>
                        </Col2Inner1>
                        <Col2Inner2>
                            <ColTitle className="location-label">Location</ColTitle>
                            <ColValue style={{ margin: "0" }}>
                                <Label>{employee.location}</Label>
                                {/* <UpdateEmployeeLocation
                                    updateEmployees={this.handleUpdateEmployees}
                                    employee={employee || ""}
                                    location={employee.location || ""}
                                    id={`update-employee-location-${rowId}`}/> */}
                            </ColValue>
                        </Col2Inner2>
                    </TableCol>

                    <TableCol borderRight between>
                        <InnerCol
                            style={{
                            width: "100%",
                            padding: "0 15px"
                        }}>
                            <ColTitle>Sent</ColTitle>
                            <ColValue>{employee.rr_sent || 0}</ColValue>
                        </InnerCol>
                        <InnerCol
                            style={{
                            width: "100%",
                            padding: "0 15px"
                        }}>
                            <ColTitle>Clicked</ColTitle>
                            <ColValue>{employee.rr_clicked || 0}</ColValue>
                        </InnerCol>
                        <InnerCol
                            style={{
                            width: "100%",
                            padding: "0 15px"
                        }}>
                            <ColTitle>CTR</ColTitle>
                            <ColValue>{ctr || 0}</ColValue>
                        </InnerCol>
                    </TableCol>
                    <TableCol width="auto" style={{ padding: "0 20px 0 30px" }}>
                        <DeleteEmployee
                            locationId={this.state.locationId}
                            updateEmployees={this.handleUpdateEmployees}
                            employee={employee}
                            showDeleteAction={this.state.showDeleteAction}
                            device={this.props.device}
                            className="delete-employee"
                            id={`delete-empoyee-${this.props.rowId}`}/>
                    </TableCol>
                </TableRow>
            </TableWrapper>
            </CSSTransitionGroup>
        );
    }
}
