import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from "react-svg";
import axios from "axios";
import {
    AllReviewsChartContainer,
    Container,
    Row,
    Label,
    TotalReviews,
    ReviewsList
} from "./styled";
import ReviewType from "./ReviewType/ReviewType";
import ratingIcon from "../../Assets/icons/rating.svg";
import AverageRatingChartSkeleton from "./AverageRatingChartSkeleton";

export default class AverageRatingChart extends React.Component {
    state = {
        rating: "",
        facebookRating: "",
        googleRating: "",
        redirectsToFacebook: this.props.redirectsToFacebook,
        redirectsToGoogle: this.props.redirectsToGoogle
    };

    componentWillReceiveProps(props){
        const redirectsToFacebook = props.redirectsToFacebook;
        const redirectsToGoogle = props.redirectsToGoogle;
        this.setState({ redirectsToFacebook, redirectsToGoogle })
      }

    render() {

        const {
            storeRating,
            googleRating,
            locationRating,
            facebookRating,
            loading,
            totalRequestsSent,
        } = this.props;

        const { redirectsToFacebook, redirectsToGoogle } = this.state;

        const percentageOfRedirectsToGoogle = (redirectsToGoogle * 100) / totalRequestsSent;
        const percentageOfRedirectsToFacebook = (redirectsToFacebook * 100) / totalRequestsSent;

        return (
            
            <AllReviewsChartContainer>
                {loading
                    ? (<AverageRatingChartSkeleton/>)
                    : (
                        <CSSTransitionGroup
                        transitionName="mount-page"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnter={false}
                        transitionLeave={false}>
                        <Container>
                            <Row between>
                                <Label>Total average rating all platforms</Label>
                                <TotalReviews>
                                    <span>{storeRating || locationRating}</span>
                                    <ReactSVG src={ratingIcon}/>
                                </TotalReviews>
                            </Row>
                            <ReviewsList>
                                <ReviewType
                                    type="Google"
                                    redirectsToGoogle={redirectsToGoogle || 0}
                                    bgColor="#44CA61"
                                    percentage={percentageOfRedirectsToGoogle || 0}
                                    rating={googleRating || 0.0}/>
                                <ReviewType
                                    type="Facebook"
                                    bgColor="#449BC1"
                                    redirectsToFacebook={redirectsToFacebook || 0}
                                    percentage={percentageOfRedirectsToFacebook || 0}
                                    rating={facebookRating || 0.0}/>
                            </ReviewsList>
                        </Container>
                        </CSSTransitionGroup>
                    )}
            </AllReviewsChartContainer>
        );
    }
}
