import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class BadReviewNotification extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    When a negative review is posted we will send you an email notification with a link to reply to the review.
                </Text>
                <Image src={img1} center />
            </DescriptionContainer>
        )
    }
}