import React from 'react';
import { FeedbackContainer, Text } from './styled';

export default class Feedback extends React.Component{
    render(){

        const { type, text } = this.props;

        return(
            <FeedbackContainer>
                <Text type={type}>{text}</Text>
            </FeedbackContainer>
        )
    }
}