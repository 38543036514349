import React from 'react';
import ReactSVG from 'react-svg';
import { RatingOverviewContainer, TotalReviews } from './styled';
import positive from '../../../../Assets/icons/positive.svg';
import neutral from '../../../../Assets/icons/neutral.svg';
import negative from '../../../../Assets/icons/negative.svg';

export default class RatingOverview extends React.Component{
    render(){

        const { type, amount } = this.props;

        let name, color; 
        if (type === "positive"){
            name = positive;
            color = "#44CA61";
        }

        if (type === "neutral"){
            name = neutral;
            color = "#449BC1";
        }

        if (type === "negative"){
            name = negative
            color = "#E97373";
        }

        return(
            <RatingOverviewContainer>
                <ReactSVG src={name} />
                <TotalReviews color={color}>{amount || 0}</TotalReviews>
            </RatingOverviewContainer>
        )
    }
}