import React, { useState, useEffect } from 'react'
import { WidgetPicture } from './styled'
import { Container, Label } from '../../../Assets/Structure/Structure'
import Button from '../../../Components/Button/Button'
import SelectFile from '../../../Components/SelectFile'
import axios from 'axios'
import { WEBCHAT_API_URL } from '../../../config'
import WidgetPictureSkeleton from './WidgetPictureSkeleton'
import RaSnackbar from '../../../Components/RaSnackbar/RaSnackbar'

const CLOUDINARY_API_URL = "https://api.cloudinary.com/v1_1/dutd7m7bj/upload"
const CLOUDINARY_UPLOAD_PRESET = "vyna3xsq"


export default function ChangeWidgetPicture(props) {
    const [pictureUrl, setPictureUrl] = useState("https://reviewalert-widget.s3.amazonaws.com/assets/home-webchat/images/user.jpg")
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [loadingImage, setLoadingImage] = useState(true)
    const [imageSizeError, setImageSizeError] = useState(false)
    const [imageReadyToBeUpdated, setImageReadyToBeUpdated] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("Widget picture updated with success.")


    useEffect(() => {
        methods.fetchWidgetPicture()
    }, [])

    const methods = {
        handleFileOnChange: (e) => {
            const file = e.target.files[0]
            const pictureUrl = URL.createObjectURL(file)
            const maxImageSize = 300000
            const fileSize = file.size
            if (fileSize > maxImageSize){
                setImageSizeError(true)
                setImageReadyToBeUpdated(false)
                return
            }

            setImageReadyToBeUpdated(true)
            setPictureUrl(pictureUrl)
            setFile(file)
        },

        handleS3BucketUpload: (file) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)

            return axios({
                url: CLOUDINARY_API_URL,
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((res) => {
                let { url } = res.data
                url = url.replace("http", "https")
                return url
            })
            .catch(() => {
                return false
            })
        },

        handleUploadFile: async () => {
            setLoading(true)
            
            const { accountSid, token } = props.credentials
            const headers = { headers: { "x-account-sid": accountSid , "x-auth-token": token } }
            const storeId = localStorage.getItem("store")
                
            const photoUrl = await methods.handleS3BucketUpload(file)
   
            if (!photoUrl) return

            const payload = { photoUrl }

            axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/widgetSettings`, payload, headers)
            .then(res => {
                setShowSnackbar(true)
            })
            .finally(() => {
                setLoading(false)
            })
        },

        fetchWidgetPicture: () => {
            setLoadingImage(true)
            const storeId = localStorage.getItem("store")
            const { accountSid, token } = props.credentials
            const headers = { headers: { "x-account-sid": accountSid , "x-auth-token": token } }

            axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/widgetSettings`, headers)
            .then(res => {
                if (res.data){
                    const pictureUrl = res.data.photoUrl
                    setPictureUrl(pictureUrl)
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setLoadingImage(false)
                }, 1000)
            })
        },

        handleCloseSnackbar: () => {
            setShowSnackbar(false)
        }
    }

    return (
        <>
        <Container>
            <Container flex>
                {loadingImage ? <WidgetPictureSkeleton /> : <WidgetPicture imageUrl={pictureUrl} />}
                <Container ml="20px" flex column between >
                    <Container>
                        <Label>Change picture</Label>
                        <Container mt="10px">
                            <SelectFile onChange={methods.handleFileOnChange} />
                        </Container>
                        {imageSizeError && <Container mt="10px">
                        <Label fontSize="12px" color="red">Image exceeds allowed size. Please upload a picture up to 300 kb</Label>
                    </Container>}
                    </Container>
                    <Container mt="20px">
                        <Button 
                        disabled={!imageReadyToBeUpdated}
                        customBorder="1px solid #eee"
                        height="40px"
                        onClick={methods.handleUploadFile}
                        borderRadius="8px"
                        loading={loading}
                        color="white"
                        uppercase
                        padding="0 20px"
                        borde
                        text="Update" />
                    </Container>
                </Container>
            </Container>
        </Container>
        <RaSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        onClose={methods.handleCloseSnackbar} />
        </>
    )
}