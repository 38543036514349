import React from 'react';

export default class AvatarBgColor extends React.Component{

    componentDidMount(){
        this.getInitial();
    }



    getInitial = () => {
        const firstInitial = this.props.firstInitial;

        let avatarBgColor;
        if (firstInitial === "A") avatarBgColor = "#A088FF";
        if (firstInitial === "B") avatarBgColor = "#FF9487";
        if (firstInitial === "C") avatarBgColor = "#6A8D73";
        if (firstInitial === "D") avatarBgColor = "#F0A868";
        if (firstInitial === "E") avatarBgColor = "#03B5AA";
        if (firstInitial === "F") avatarBgColor = "#006DAA";
        if (firstInitial === "G") avatarBgColor = "#7A306C";
        if (firstInitial === "G") avatarBgColor = "#0C1B33";
        if (firstInitial === "I") avatarBgColor = "#B2AA8E";
        if (firstInitial === "J") avatarBgColor = "#B9D6F2";
        if (firstInitial === "K") avatarBgColor = "#607B7D";
        if (firstInitial === "L") avatarBgColor = "#3A606E";
        if (firstInitial === "M") avatarBgColor = "#00B1FF";
        if (firstInitial === "N") avatarBgColor = "#DB8A74";
        if (firstInitial === "O") avatarBgColor = "rgb(158, 231, 73);";
        if (firstInitial === "P") avatarBgColor = "#f1a4e8";
        if (firstInitial === "Q") avatarBgColor = "#ede368";
        if (firstInitial === "R") avatarBgColor = "#ed688d";
        if (firstInitial === "S") avatarBgColor = "#a39599";
        if (firstInitial === "T") avatarBgColor = "#d598ba";
        if (firstInitial === "U") avatarBgColor = "#d560a1";
        if (firstInitial === "V") avatarBgColor = "#60d57f";
        if (firstInitial === "W") avatarBgColor = "#7eac8a";
        if (firstInitial === "X") avatarBgColor = "#00bcd4";
        if (firstInitial === "Y") avatarBgColor = "#009688";
        if (firstInitial === "Z") avatarBgColor = "#62d7b1";

        this.props.handleAvatarBgColor(avatarBgColor);
    }

    render(){
        return <React.Fragment>{this.props.children}</React.Fragment>
        
    }
}