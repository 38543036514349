import React, { useState, useEffect } from 'react'
import Modal from '../../../../Components/Modal/Modal'
import { Container, Text, Span } from '../../../../Assets/Structure/Structure'
import Button from '../../../../Components/Button/Button'
import axios from 'axios'
import { WEBCHAT_API_URL } from '../../../../config'

export default function DeleteChatAgentDialog(props){
    
    const [loading, setLoading] = useState(false)

    const handleDeleteChatAgent = (e) => {
        setLoading(true)
        e.preventDefault()
        const storeId = localStorage.getItem("store")
        axios.delete(`${WEBCHAT_API_URL}/stores/${storeId}/phoneNumber/${props.selectedChatAgent.id}`)
        .then(() => {
            props.onSuccess()
            props.onClose(false)
        })
        .catch(() => {
            props.onClose(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal showClose title={"Delete Chat Agent"} isModalOpen={() => props.onClose(false)} maxWidth="36em">
            <form onSubmit={handleDeleteChatAgent} style={{ padding: "0 1em 1em" }}>
                <Container>
                    <Text>Are you sure about deleting the chat member <Span bold>{props.selectedChatAgent.name}</Span> ?</Text>
                </Container>
                <Container flex justifyEnd alignCenter mt="40px">
                    <Container flex alignCenter>
                        <Container>
                            <Button 
                            padding="0"
                            width="120px"
                            height="46px"
                            onClick={() => props.onClose(false)}
                            borderRadius="5px"
                            color="#333"
                            bgColor="#ddd"
                            bgColorOnHover="#ccc"
                            text="Cancel" />
                        </Container>
                        <Container ml="10px">
                            <Button 
                            padding="0"
                            width="120px"
                            height="46px"
                            loading={loading}
                            borderRadius="5px"
                            color="white"
                            type="submit"
                            text="Delete" />
                        </Container>
                    </Container>
                </Container>
            </form>
        </Modal>
    )
}