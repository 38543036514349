import styled from 'styled-components';

export const RatingOverviewContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    box-sizing: border-box;

    svg{
        width: 20px;
    }
`;

export const TotalReviews = styled.label`
font-size: 16px;
text-align: center;
letter-spacing: 0.1em;
color: ${props => props.color};
`;