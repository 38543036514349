import React, {useEffect, useState} from "react"
import { EditCampaignWrapper } from "./styled";

import CreateMarketingCampaignDialog from "../../../Containers/MarketingCampaign/CreateMarketingCampaignDialog/CreateMarketingCampaignDialog";
import ReactSVG from 'react-svg';
import editIcon from '../../../Assets/icons/edit.svg';

const EditCampaign = (props) => {
  const [state, setState] = useState({
    isDialogOpen: false,
    loading: false
  })

  useEffect(() => {
  }, [])  

  /**
   * Handle success
   */
   const handleSuccess = () => {}

  /**
   * Toggle edit dialog
   */
  const toggleEditDialog = (status = null) => {
    setState({
      ...state,
      isDialogOpen: status !== null ? status : !state.isDialogOpen
    })
  }

  return (
    <>
      <EditCampaignWrapper className="edit-campaign" id={`edit-campaign-${props.campaign.id}`}>
        <button
          type="button"
          className="edit-icon"
          onClick={toggleEditDialog}
        >
          <ReactSVG src={editIcon} />
        </button>
        <CreateMarketingCampaignDialog
          active={state.isDialogOpen}
          campaign={props.campaign}
          onClose={() => toggleEditDialog(false)}
          onSuccess={handleSuccess}/>
      </EditCampaignWrapper>
    </>
  )
}

export default EditCampaign