import React, {useState} from "react"
import { AddClientContainer } from "./styled";
import {Input} from "@material-ui/core";
import {Container, Label} from "../../../Assets/Structure/Structure";
import addIcon from "../../../Assets/icons/add.svg";
import {blueDefault, blueDefaultHover} from "../../../Assets/colorPallet/colorPallet";
import Button from "../../../Components/Button/Button";


const CLIENT_NAME = "clientName"
const CLIENT_NUMBER = "clientNumber"

export default function AddClient(props){
    const [clientName, setClientName] = useState("")
    const [clientNumber, setClientNumber] = useState("")


    const handleChange = (key, value) => {
        switch(key) {
            case CLIENT_NAME:
                setClientName(value)
                break
            case CLIENT_NUMBER:
                setClientNumber(value)
                break
            default:
                break
        }
    }

    const handleAddClient = (e) => {
        e.preventDefault()
        if (clientNumber === "" || clientName === "") {
            return
        }

        props.onAdd({
            clientName,
            clientNumber: `1${clientNumber}`
        })

        setClientNumber("")
        setClientName("")
    }


    return (
        <AddClientContainer onSubmit={handleAddClient}>
            <Container className="add-client--name-field-container">
                <Input
                    placeholder="Client name"
                    value={clientName}
                    onChange={(e) => handleChange(CLIENT_NAME, e.target.value)} />
            </Container>
            <Container className="add-client--phone" flex alignCenter ml="20px" mr="30px">
                <Label>(+1)</Label>
                <Container className="add-client--phone-field-container" ml="10px">
                    <Input
                        type="number"
                        placeholder="Client phone number"
                        value={clientNumber}
                        onChange={(e) => handleChange(CLIENT_NUMBER, e.target.value)} />
                </Container>
            </Container>
            <Container className="add-client--icon-action-container" hideOnMobile>
                <Button
                    borderRadius="20em"
                    hasIcon
                    iconPath={addIcon}
                    height="50px"
                    iconColor={blueDefault}
                    flex
                    hideTextOnMobile
                    padding="0"
                    mr="1em"
                    width="50px"
                    bgColor="transparent"
                    bgColorOnHover="#f8f8f8"
                    color="white"
                    uppercase
                    kind="icon-button"/>
            </Container>
            <Container className="add-client--button-action-container" fluid mt="20px" showOnMobile>
                <Button
                    height="40px"
                    borderRadius="8px"
                    flex
                    padding="0 20px"
                    text="Add client"
                    width="100%"
                    bgColor={blueDefault}
                    bgColorOnHover={blueDefaultHover}
                    color="white"/>
            </Container>
        </AddClientContainer>
    )
}
