const blueDefault = "#00B1FF";
const blueDefaultHover = "#47c4fa";
const red = "#E97373";
const green = "#44CA61";

module.exports = {
    blueDefault,
    blueDefaultHover,
    red,
    green
}