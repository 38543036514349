import React, { useState } from 'react'
import { RaSwitchContainer } from './styled'
import { Switch } from '@material-ui/core'


export default function RaSwitch(props) {
    return (
        <RaSwitchContainer>
            <Switch {...props} />
        </RaSwitchContainer>
    )
}