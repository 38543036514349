import styled from "styled-components";

export const NewMemberFeedbackContainer = styled.div`
  background-color: #f8f8f8;
  h2 {
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 400;
    color: #44ca61;
    margin: 0;
    padding-top: 1em;
  }

  p {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #666666;
    width: 100%;
    line-height: 26px;
    max-width: 22em;
    margin: 1em auto;
  }
`;

export const NewMembersContainer = styled.div`
  padding: 2em;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 40vh;

  .new-member-module {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:first-child){
        margin-top: 1em;
    }
  }
`;

export const LeftContainer = styled.div`
  h3 {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #333333;
  }

  label {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #999999;
  }
`;

export const RightContainer = styled.div`
  label {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #999999;

    span {
      color: #333;
    }
  }
`;
