export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"
export const SET_CHAT_CREDENTIALS = "SET_CHAT_CREDENTIALS"
export const UPDATE_CHAT_GROUPS_MENU = "UPDATE_CHAT_GROUPS_MENU"
export const SET_NEEDS_RESPONSE_AMOUNT = "SET_NEEDS_RESPONSE_AMOUNT"
export const SET_NEW_MESSAGES_AMOUNT = "SET_NEW_MESSAGES_AMOUNT"
export const SET_FILTER = "SET_FILTER"
export const SET_SHOW_CHATS_ON_MOBILE = 'SET_SHOW_CHATS_ON_MOBILE'
export const SET_CONVERSATION_BEFORE_REFRESH = 'SET_CONVERSATION_BEFORE_REFRESH'
export const SET_CHAT_GROUPS_AMOUNT = 'SET_CHAT_GROUPS_AMOUNT'
export const SET_SEND_REVIEW_REQUEST_ON_CHAT = 'SET_SEND_REVIEW_REQUEST_ON_CHAT'
export const SET_CHAT_GROUPS = "SET_CHAT_GROUPS"
