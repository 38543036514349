import React from "react";
import axios from "axios";
import queryString from "query-string";
import { PageWrapper } from "../../Assets/Structure/Structure";
import Button from "../../Components/Button/Button";
import { API_URL } from "../../config";
import { Wrapper, RowContainer, BodyContainer } from "./styled";
import ListStores from "../../Containers/ListStores/ListStores";
import AltModal from "../../Components/AltModal/AltModal";
import WidgetMenu from "../../Components/WidgetMenu/WidgetMenu";
import EmptyFeedback from "../../Components/EmptyFeedback/EmptyFeedback";
import BeaconPreview from "../../Components/BeaconPreview/BeaconPreview";
import locationIcon from "../../Assets/icons/location.svg";
import emptyIcon from "../../Assets/icons/empty-member.svg";

export default class StoreLocation extends React.Component {
  state = {
    isModalOpen: false,
    currentModal: "",
    currentLocation: [],
    locationsOnService: [],
    currentPreview: "",
    device: "",
    locations: [],
    loading: true,
    currentStep: 1,
    pages: [],
    showEmptyFeedback: false,
    chatOnly: null,
    widgetOnly: null
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const screenWidth = window.innerWidth;
    let parsed = queryString.parse(window.location.search);

    if (!Object.keys(parsed).length){
      parsed = queryString.parse(`?${window.location.hash.substr(1)}`)
    }

    if (parsed.code) {
      this.associateAPI("google", parsed.code);
    } else if (parsed.access_token) {
      this.associateAPI("facebook", parsed.access_token);
    }

    if (screenWidth < 760) {
      this.setState({ device: "mobile" });
    }

    if (screenWidth > 760 && screenWidth < 1025) {
      this.setState({ device: "tablet" });
    }

    if (screenWidth > 1025) {
      this.setState({ device: "desktop" });
    }

    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };
    const store = localStorage.getItem("store");

    axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
    .then(res => {
      const locationsOnService = res.data.filter(location => location.review_services.length > 0);
      this.setState({ locationsOnService })
      const locations = res.data;

      if (locations.length > 0){
        this.sortLocations(res.data);
      } else {
        this.setState({ showEmptyFeedback: true });
      }
    });

    this.isAccountChatOnly()
  }

  isAccountChatOnly = () => {
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    axios.get(`${API_URL}/private/sessions`, headers)
    .then(res => {
        const chatOnly = res.data.chat_only;
        const widgetOnly = res.data.widget_only;

        this.setState({ chatOnly, widgetOnly })
    })
  }

  sortLocations = locations => {
    const sortedLocations = locations.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    });

    this.setState({ locations: sortedLocations, loading: false });
  };

  associateAPI(service, code) {
    let store = localStorage.getItem("store");
    let authToken = localStorage.getItem("token");
    let currentLocationId = localStorage.getItem("current_location_id");

    axios
      .post(
        `${API_URL}/admin/stores/${store}/locations/${currentLocationId}/services/oauth`,
        {
          service: service,
          auth_token: code
        },
        {
          headers: {
            "x-sas-Auth": authToken
          }
        }
      )
      .then(response => {
        this.setState({ isModalOpen: true, currentModal: "loading" });
        axios
          .get(
            `${API_URL}/admin/stores/${store}/locations/${currentLocationId}/services/${service}`,
            {
              headers: {
                "x-sas-Auth": localStorage.getItem("token")
              }
            }
          )
          .then(response => {
            this.setState({
              pages: response.data.pages,
              service,
              integrationHasFailed: false,
              isModalOpen: true,
              currentModal: "integration-modal",
              currentStep: 2
            });

            if (service === "google") {
              this.setState({
                isConnectingGoogle: true,
                isConnectingFacebook: false
              });
            } else {
              this.setState({
                isConnectingFacebook: true,
                isConnectingGoogle: false
              });
            }
          })
          .catch(error => {
            this.setState({
              integrationHasFailed: true,
              currentModal: "integration-fail"
            });
          });
      })
  }

  handleModal = (isModalOpen, currentModal, currentLocation) => {
    this.setState({
      isModalOpen,
      currentModal,
      currentLocation,
      currentStep: 1
    });
  };

  handleLocationsOnService = locationsOnService =>
    this.setState({ locationsOnService });

  handleCurrentPreview = beacon => this.setState({ currentPreview: beacon });

  handleUpdatedLocations = locations => {
    let locationsOnService = this.state.locationsOnService;

    locations.map(location => {
      if (location.review_services.length > 0){
        locationsOnService.push(location);
        this.setState({ locationsOnService })
      }
    })

    this.setState({ locations, showEmptyFeedback: false, loading: false });
  }

  handleUpdatedCurrentStep = currentStep => this.setState({ currentStep });

  handleBeaconGuide = (isModalOpen, currentModal) => {
    this.setState({ isModalOpen, currentModal })
  }

  render() {

    const {
      chatOnly,
      locations,
      loading,
      widgetOnly,
      locationsOnService,
      currentPreview,
      isModalOpen,
      showEmptyFeedback,
      device,
      currentModal,
      currentLocation,
      integrationHasFailed,
      currentStep,
      pages,
      service
    } = this.state

    let maxWidth = "100%"
    if (chatOnly == 1 && widgetOnly == 0) {
      maxWidth = "100%"
    } else {
      maxWidth = "calc(100% - 18em)"
    }

    return (
      <PageWrapper
        style={{ maxWidth }}>
        {isModalOpen && (
          <AltModal
            className="mob-modal"
            isModalOpen={this.handleModal}
            currentModal={currentModal}
            currentLocation={currentLocation}
            updatedLocations={this.handleUpdatedLocations}
            currentStep={currentStep}
            newEmployees={[]}
            integrationHasFailed={integrationHasFailed}
            pages={pages}
            service={service}
            updatedCurrentStep={this.handleUpdatedCurrentStep}
          />
        )}
        {!showEmptyFeedback ? (
          <Wrapper>
            <RowContainer>
              <BodyContainer
              chatOnly={chatOnly}
              locationsOnService={locationsOnService}>
                <BeaconPreview
                  currentPreview={currentPreview}
                  device={device}
                />
                <Button
                  bgColor="white"
                  color="#333"
                  borderRadius="20em"
                  hasShadow
                  bgColorOnHover={false}
                  height="50px"
                  flex
                  hasIcon
                  iconColor="#00B1FF"
                  iconPath={locationIcon}
                  className="btn-new-location"
                  onClick={() => this.handleModal(true, "new-location-modal")}
                  text="Add Location"
                />
                <ListStores
                  handleModal={this.handleModal}
                  locationsOnService={this.handleLocationsOnService}
                  locations={locations}
                  loading={loading}
                />
              </BodyContainer>

              {chatOnly == 0 || (chatOnly == 1 && widgetOnly == 1) ? (
                <WidgetMenu
                  className="store-location--widget-menu"
                  currentPreview={this.handleCurrentPreview}
                  device={device}
                  handleBeaconGuide={this.handleBeaconGuide}
                  locationsOnService={locationsOnService}
                />
              ) : null}
            </RowContainer>
          </Wrapper>
        ) : (
          <EmptyFeedback
            iconPath={emptyIcon}
            text="You do not have any locations yet"
            buttonText="New Location"
            handleNew={() => this.handleModal(true, "new-location-modal")}
          />
        )}
      </PageWrapper>
    );
  }
}
