import styled from 'styled-components';

export const RadioItemContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    label{
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        color: #333333;
        cursor: pointer;
    }

    &:not(:first-child){
        margin-left: 1em;
    }
`;

export const Circle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #999;
    margin-right: .5em;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InsideCircle = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${props => props.active ? "#333" : "transparent"};
    border-radius: 50%;
`;