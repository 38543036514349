import styled from "styled-components";

export const SelectOptionsContainer = styled.div`
  background-color: white;
  width: 100%;
  max-width: ${props => props.position === "relative" ? "100%" : props.maxWidth ? props.maxWidth : "14em"};
  max-height: 18em;
  overflow-y: auto;
  box-sizing: border-box;
  margin-left: ${props => props.position === "relative" ? "0" : "0"};
  margin-top: 1em;
  padding: 1em;
  z-index: 10;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: ${props => props.position === "relative" ? "relative" : "absolute"};

  &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }
`;
