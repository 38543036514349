import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from 'react-svg';
import EmptyFeedback from '../../../Components/EmptyFeedback/EmptyFeedback';
import {
    TableContainer,
    ReportTable,
    TableHeader,
    TableData,
    TableRow,
    TableSkeletonContainer,
    EmptyFeedbackContainer
} from './styled';
import Overview from './Overview/Overview';
import ReportDataSkeleton from './ReportDataSkeleton/ReportDataSkeleton';
import googleIcon from '../../../Assets/icons/google.svg';
import facebookIcon from '../../../Assets/icons/facebook.svg';
import checkIcon from '../../../Assets/icons/check.svg';
import emptyIcon from '../../../Assets/icons/empty-requests.svg';

export default class Table extends React.Component {
    render() {
        
        if (this.props.loading) {
            return (
                <TableSkeletonContainer>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                    <ReportDataSkeleton/>
                </TableSkeletonContainer>
            )
        }

        return (
            <CSSTransitionGroup
                    transitionName="mount-page"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnter={false}
                    transitionLeave={false}>
            {this.props.showEmptyFeedback ? (
             <EmptyFeedbackContainer>
                <EmptyFeedback
                containerHeight="calc(100vh - 91px - 70px - 8em);"
                iconPath={emptyIcon}
                text="No requests with the selected criteria has been founded." />
             </EmptyFeedbackContainer>
            ) : (
                <TableContainer>
                {/* <Overview/> */}
                <div>
                    <ReportTable>
                        <TableRow>
                            <TableHeader>Sent By</TableHeader>
                            <TableHeader>Location</TableHeader>
                            <TableHeader>Client Name</TableHeader>
                            <TableHeader>Email</TableHeader>
                            <TableHeader>Mobile</TableHeader>
                            <TableHeader>Send Date</TableHeader>
                            <TableHeader>Viewed</TableHeader>
                            <TableHeader>Clicked</TableHeader>
                            <TableHeader>Platform</TableHeader>
                        </TableRow>
                        
                        {this.props.reviewRequests.map(reviewRequest => {
                            return (
                                <TableRow>
                                        <TableData>{reviewRequest.seller}</TableData>
                                        <TableData>{reviewRequest.location}</TableData>
                                        <TableData>{reviewRequest.client}</TableData>
                                        <TableData>{reviewRequest.email}</TableData>
                                        <TableData>{reviewRequest.phone}</TableData>
                                        <TableData>{reviewRequest.date}</TableData>
                                        <TableData textCenter>
                                            {reviewRequest.viewed === 1
                                                ? <ReactSVG src={checkIcon} className="check-icon"/>
                                                : null}
                                        </TableData>
                                        <TableData textCenter>
                                            {reviewRequest.clicked === 1
                                                ? <ReactSVG src={checkIcon} className="check-icon"/>
                                                : null}
                                        </TableData>
                                        <TableData textCenter>
                                            {reviewRequest.sent_to === "google"
                                                ? <ReactSVG src={googleIcon}/>
                                                : reviewRequest.sent_to === "facebook"
                                                    ? <ReactSVG src={facebookIcon}/>
                                                    : null}
                                        </TableData>
                                    </TableRow>
                                )
                            })}
                    </ReportTable>
                </div>
            </TableContainer>
            )}
            </CSSTransitionGroup>
        )
    }
}