import React from 'react';
import ReactSVG from 'react-svg';
import { Button } from './styled';

export default class PlatformButton extends React.Component{
    render(){

        const { iconPath, className } = this.props;

        return (
            <Button className={className}>
                <ReactSVG src={iconPath} />
            </Button>
        )
    }
}