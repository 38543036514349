import styled from 'styled-components';

export const ChatMessagesContainer = styled.div`
    max-height: calc(100vh - 10em - 5em - 60px - 20px - 80px - 50px);
    min-height: calc(100vh - 10em - 5em - 60px - 20px - 80px - 50px);
    overflow: auto;
    box-sizing: border-box;
    padding: 2em;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    @media only screen and (max-width: 600px){
        max-height: calc(100vh - 10em - 5em);
        min-height: calc(100vh - 10em - 5em);
    }
`;