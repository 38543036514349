import React from 'react';
import { UserDetailContainer, UserName } from './styled';
import Avatar from '../../../Avatar/Avatar';
import { Column } from '../../../../Assets/Structure/Structure';
import EditProfile from '../EditProfile/EditProfile';

export default class UserDetail extends React.Component{


    handleLoggedIn = (loggedIn) => this.props.loggedIn(loggedIn);


    render(){

        const userRole = localStorage.getItem("role");

        return(
            <UserDetailContainer>
                <Avatar name={this.props.userName}/>
                <Column ml1>
                    <UserName>{this.props.userName}</UserName>
                    <EditProfile onClick={this.props.onClick} userRole={userRole} />
                </Column>
            </UserDetailContainer>
        )
    }
}