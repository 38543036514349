const convertCSVToArrayOfObjects = content => {
    let bufferString = content.toString();
    let arr = bufferString.split('\n');
    
    let arrayOfObjects = [];
    let headers = arr[0].split(',');

    for(let i = 1; i < arr.length; i++) {
        const data = arr[i].split(',');
        let obj = {};
        for(var j = 0; j < data.length; j++) {
           obj[headers[j].trim()] = data[j].trim();
        }   
        arrayOfObjects.push(obj);
    }

    JSON.stringify(arrayOfObjects);

   arrayOfObjects =  arrayOfObjects.map(obj => {
        let key, keys = Object.keys(obj);
        let n = keys.length;
        let newObj = {};
        while (n--){
            key = keys[n];
            newObj[key.toLowerCase()] = obj[key];
        }

        return newObj;
    })
    return arrayOfObjects
}

module.exports = {
    convertCSVToArrayOfObjects
}