import React from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {API_URL} from '../../config';
import {PageWrapper} from "../../Assets/Structure/Structure";
import {TopOverview} from "./styled";
import AllReviewsChart from "../../Components/AllReviewsChart/AllReviewsChart";
import AverageRatingChart from '../../Components/AverageRatingChart/AverageRatingChart';
import ConvertionRateChart from '../../Components/ConvertionRateChart/ConvertionRateChart';
import LastDaysOverview from '../../Components/LastDaysOverview/LastDaysOverview';
import OverviewByPlatform from '../../Components/OvervieByPlatform/OverviewByPlatform';
import LastReviews from '../../Components/LastReviews/LastReviews';
import BestRatedLocations from '../../Components/BestRatedLocations/BestRatedLocations';
import IntegrationIssueAlert from '../../Containers/IntegrationIssueAlert/IntegrationIssueAlert';
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';

export class Dashboard extends React.Component {
    state = {
        reviews: [],
        loading: true,
        totalReviews: "",
        bestLocations: [],
        storeOverview: [],
        routeNotAllowed: false,
        showIssueWarning: this.props.integrationIssueStatus,
        redirectUrl: "/store-location"
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {headers: { "x-sas-Auth": authToken }};

        this.getReviews(store, headers);
        this.getRatingStats(store, headers);
        this.getReviewsStats(store, headers);
        this.getRequestStats(store, headers);
        this.getBestLocations(store, headers);
        this.getStoreOverview(store, headers);
        this.getStoreRatings(store, headers);

        this.setState({
            showIssueWarning: this.props.integrationIssueStatus,
        })
    }

    componentWillReceiveProps(props){
        this.setState({ showIssueWarning: props.integrationIssueStatus })
    }

    // GET REVIEWS
    getReviews = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/reviews`, headers)
        .then(res => this.setState({reviews: res.data.reviews, loading: false}));
    }

    //GET RATING STATS
    getRatingStats = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/stats/ratings`, headers)
        .then(res => {
          const services = res.data.services;
          const storeRating = res.data.rating.toFixed(1);
          let googleRating, facebookRating;
          services.filter(service => {
              if (service.review_service === "Google"){
                  googleRating = service.rating.toFixed(1);
              }

              if (service.review_service === "Facebook"){
                  facebookRating = service.rating.toFixed(1);
              }
          })

          this.setState({ storeRating, googleRating, facebookRating });
        })
    }

    //GET REVIEWS STATS
    getReviewsStats = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/stats/reviews`, headers)
        .then(res => {
          const totalReviews = res.data.reviews.total;
          const totalReviewsSinceStart = res.data.reviews.since_start;
          const positiveReviews = res.data.reviews.positive;
          const neutralReviews = res.data.reviews.neutral;
          const negativeReviews = res.data.reviews.negative;

          this.setState({
              totalReviews,
              positiveReviews,
              neutralReviews,
              totalReviewsSinceStart,
              negativeReviews
            });
        })
    }

    //GET REQUEST STATS
    getRequestStats = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/stats/requests`, headers)
        .then(res => {
          const totalRequestsSent = res.data.sent;
          const requestsForReview = res.data.requests_for_review;
          const redirectsToGoogle = res.data.redirects_to_google;
          const redirectsToFacebook = res.data.redirects_to_facebook;
          const ctr = res.data.ctr;


          this.setState({
              totalRequestsSent,
              requestsForReview,
              ctr,
              redirectsToFacebook,
              redirectsToGoogle
            });
        })
    }

    getBestLocations = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/locations/best`, headers)
        .then(res => {
            this.setState({ bestLocations: res.data.best_locations })
        })
    }

    getStoreOverview = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/stats/reviews`, headers)
        .then(res => this.setState({ storeOverview: res.data }))
    }

    getStoreRatings = (store, headers) => {
        axios.get(`${API_URL}/admin/stores/${store}/stats/ratings`, headers)
        .then(res => this.setState({ storeRatings: res.data }));
    }

    handleRouteProtection = (redirectUrl) => {
        this.setState({ routeNotAllowed: true, redirectUrl })
    }

    handleIssueWarning = () => {
        this.setState({ showIssueWarning: false })
    }

    handleValidateWidgetOnlyLoading = loading => this.setState({ loading })

    render() {


        if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl}/>

        return (
            <ValidateWidgetOnly
            protectRoute={this.handleRouteProtection}
            currentRoute="dashboard"
            onLoading={this.handleValidateWidgetOnlyLoading}>
                {this.state.showIssueWarning && <IntegrationIssueAlert report={this.props.integrationIssueReport} isModalOpen={this.handleIssueWarning} />}
                <PageWrapper>
                    <TopOverview>
                        <AllReviewsChart
                            positiveReviews={this.state.positiveReviews}
                            neutralReviews={this.state.neutralReviews}
                            negativeReviews={this.state.negativeReviews}
                            totalReviews={this.state.totalReviews}
                            loading={this.state.loading}/>
                        <AverageRatingChart
                            totalReviews={this.state.totalReviews}
                            storeRating={this.state.storeRating || 0.0}
                            googleRating={this.state.googleRating}
                            totalRequestsSent={this.state.totalRequestsSent}
                            facebookRating={this.state.facebookRating}
                            redirectsToFacebook={this.state.redirectsToFacebook}
                            redirectsToGoogle={this.state.redirectsToGoogle}
                            loading={this.state.loading}/>
                        <ConvertionRateChart
                            totalReviews={this.state.totalReviewsSinceStart}
                            totalRequestsSent={this.state.totalRequestsSent}
                            ctr={this.state.ctr}
                            requestsForReview={this.state.requestsForReview}
                            loading={this.state.loading}/>
                    </TopOverview>
                    <LastDaysOverview
                        fluid
                        className="last-days-overview"
                        style={{
                        marginTop: '4em'
                    }}/>
                    <OverviewByPlatform
                        type="store"
                        overview={this.state.storeOverview}
                        ratings={this.state.storeRatings}
                        style={{ marginTop: '4em' }}
                        loading={this.state.loading}/>
                    <LastReviews
                        reviews={this.state.reviews}
                        style={{
                        marginTop: '4em'
                    }}
                        loading={this.state.loading}/>
                    <BestRatedLocations
                        bestLocations={this.state.bestLocations}
                        style={{
                        marginTop: '4em'
                    }}
                        loading={this.state.loading}/>
                </PageWrapper>
            </ValidateWidgetOnly>
        );
    }
}

const mapStateToProps = state => ({
    integrationIssueStatus: state.integration.integrationIssueStatus,
    integrationIssueReport: state.integration.integrationIssueReport
})


export default connect(mapStateToProps)(Dashboard);
