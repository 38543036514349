import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  box-sizing: border-box;

  h3 {
    font-size: 18px;
    letter-spacing: 0.1em;
    font-weight: normal;
    color: #666666;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  padding: 1em 2em;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: auto auto 0 auto;
  justify-content: center;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 60vh;

  &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    @media only screen and (max-width: 500px){
      max-height: none;
    }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;

  @media only screen (max-width: 500px){
    height: 100vh;
  }
`;
