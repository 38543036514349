import React from 'react';
import { ConvertionRateContainer } from './styled';
import { Label } from '../../../../Assets/Structure/Structure';

export default class ConvertionRate extends React.Component{
    render(){
        return (
            <ConvertionRateContainer>
                <Label textCenter uppercase fontSize="12px" color="#333">
                    For every {this.props.convertionRate} sent, 1 is converted into a review
                </Label>
            </ConvertionRateContainer>
        )
    }
}