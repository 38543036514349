import React from 'react';
import ReactSVG from 'react-svg';
import { FilterHeaderContainer, ResetFilterBtn } from './styled';
import filterIcon from '../../../../Assets/icons/filter.svg';

export default class FilterHeader extends React.Component{

    handleResetFilter = () => {
        this.props.resetFilter(true);
        const body = document.querySelector("body");
        body.style.overflow = "auto";
    }


    render(){
        return(
            <FilterHeaderContainer>
                <div className="row-top-txt">
                    <ReactSVG src={filterIcon} />
                    <label>FILTERS</label>
                </div>
                <ResetFilterBtn onClick={this.handleResetFilter} >reset filters</ResetFilterBtn>
            </FilterHeaderContainer>
        )
    }
}