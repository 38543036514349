import React from 'react';
import ReactSVG from 'react-svg';
import { TotalAverageRateContainer } from './styled';
import ratingIcon from '../../../../Assets/icons/rating.svg';

export default class TotalAverageRate extends React.Component{
    render(){
        return(
            <TotalAverageRateContainer>
                <label>{this.props.totalAverageRate}<span><ReactSVG src={ratingIcon} /></span></label>
                <p>Total average rating all platforms</p>
            </TotalAverageRateContainer>
        )
    }
}