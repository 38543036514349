import styled from 'styled-components';

export const NewMemberModalContainer = styled.div`

`;

export const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36vh;

    @media only screen and (max-width: 500px){
        height: 100vh;
    }
`;