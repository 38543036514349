import React from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import LastReviews from "../../../Components/LastReviews/LastReviews";
import EmptyFeedback from "../../../Components/EmptyFeedback/EmptyFeedback";
import { LatestReviewsContainer } from "./styled";
import { Label } from "../../../Assets/Structure/Structure";
import emptyIcon from "../../../Assets/icons/empty-member.svg";
import LastReviewsSkeleton from "../../../Components/LastReviews/LastReviewsSkeleton/LastReviewsSkeleton";

export default class LatestReviews extends React.Component {
  state = {
    reviews: [],
    showEmptyFeedback: false,
    loading: true
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const location = localStorage.getItem("location");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    axios
      .get(
        `${API_URL}/private/stores/${store}/locations/${location}/reviews?page_size=10`,
        headers
      )
      .then(res => {
        const reviews = res.data.reviews;
        if (reviews.length === 0) this.setState({ showEmptyFeedback: true });
        
        this.setState({ reviews, loading: false });
      });
  }

  render() {
    return (
      <LatestReviewsContainer>
        <Label uppercase fontSize="12px" color="#666">
          Latest reviews from your location
        </Label>
        {!this.state.showEmptyFeedback ? (
          this.state.loading ? (
            <LastReviewsSkeleton />
          ) : (
            <LastReviews reviews={this.state.reviews} noLabel />
          )
        ) : (
          <EmptyFeedback
            iconPath={emptyIcon}
            text=" You do not have reviews yet."
          />
        )}
      </LatestReviewsContainer>
    );
  }
}
