import styled from "styled-components";

export const HumorFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: #999999;
    }
    width: 25px;
    height: 25px;
    margin: 0 0.5em;
    cursor: pointer;
  }

  @media only screen and (max-width:500px){
    justify-content: space-between;
  }

`;


export const PositiveContainer = styled.div`
cursor: pointer;
  div:nth-child(1){
      &:first-child{
          display: flex;
          svg{
            cursor: pointer;
            path{
                fill: ${props => (props.reviewType === "positive" && "#44CA61")};
            }
        }
      }
  }
    
`;

export const NegativeContainer = styled.div`
cursor: pointer;
div:nth-child(1){
    &:first-child{
    display: flex;
    svg{
      cursor: pointer;
        path{
            fill: ${props => (props.reviewType === "negative" && "#E97373")};
        }
        }
    }
}
`;

export const NeutralContainer = styled.div`
cursor: pointer;
div:nth-child(1){
      &:first-child{
          display: flex;
    svg{
      cursor: pointer;
        path{
            fill: ${props => (props.reviewType === "neutral" && "#449bc1")};
        }
    }
      }
}
`;