import React from "react";
import ReactSVG from 'react-svg';
import { ReviewContainer, InfoContainer } from "./styled";
import positive from '../../../../Assets/icons/positive.svg';
import neutral from '../../../../Assets/icons/neutral.svg';
import negative from '../../../../Assets/icons/negative.svg';

export default class Review extends React.Component {
  render() {
    const { type } = this.props;

    return (
      <ReviewContainer>
        {type === "positive" ? (
          <ReactSVG
            src={positive}
          />
        ) : type === "neutral" ? (
          <ReactSVG
            src={neutral}
            fill="#449BC1"
          />
        ) : type === "negative" ? (
          <ReactSVG
            src={negative}
            fill="#E97373"
          />
        ) : null}
        <InfoContainer type={type}>
          <p>{type === "positive" ? "Positive" 
          : type === "neutral" ? "Neutral" 
          : type === "negative" ? "Negative" 
          : null}</p>

          <label>{this.props.reviews}</label>
        </InfoContainer>
      </ReviewContainer>
    );
  }
}
