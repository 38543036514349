import React from "react";
import {
  NewMemberFeedbackContainer,
  NewMembersContainer,
  LeftContainer,
  RightContainer
} from "./styled";
import Module from "../../../../Components/Module/Module";

export default class NewMemberFeedback extends React.Component {
  render() {
    return (
      <NewMemberFeedbackContainer>
        <h2>Members successfully added</h2>
        <p>
          The user will receive a confirmation email along with their user ID
        </p>
        <NewMembersContainer>
          {this.props.newEmployees.map(newEmployee => {
            return (
              <Module className="new-member-module">
                <LeftContainer>
                  <h3>{newEmployee.name}</h3>
                  <label>{newEmployee.email}</label>
                </LeftContainer>
                {/* <RightContainer>
                  <label>
                    User ID: <span></span>
                  </label>
                </RightContainer> */}
              </Module>
            );
          })}
        </NewMembersContainer>
      </NewMemberFeedbackContainer>
    );
  }
}
