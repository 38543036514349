import React from "react";
import ReactSVG from "react-svg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Text, RowContainer } from "./styled";
import checkIcon from "../../../../Assets/icons/check.svg";

export default class StepThree extends React.Component {
  handleCloseModal = isModalOpen => {
    this.props.isModalOpen(isModalOpen);
  };

  render() {
    const { currentLocationName, currentLocationAddress } = this.props;

    return (
      <React.Fragment>
        <Header
          currentLocationName={currentLocationName}
          currentLocationAddress={currentLocationAddress}
          isModalOpen={this.handleCloseModal}
        />
        <div className="modal-body">
          <RowContainer>
            <Text>
              <ReactSVG src={checkIcon} />
              <span />Integration made successfully
            </Text>
          </RowContainer>
        </div>
        <Footer currentStep={this.props.currentStep} />
      </React.Fragment>
    );
  }
}
