// MUTATIONS
import {
    SET_CURRENT_VIDEO_ROOM,
    SET_CURRENT_VIDEO_MESSAGES
} from "./mutations"

const initialState = {
    currentVideoRoom: null,
    currentVideoMessages: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_VIDEO_ROOM:
            return { ...state, currentVideoRoom: action.payload }

        case SET_CURRENT_VIDEO_MESSAGES:
            return { ...state, currentVideoMessages: action.payload }

        default:
            return state
    }
}

export default reducer
