import React from 'react'
import ReactSVG from 'react-svg'
import { CSSTransitionGroup } from 'react-transition-group';
import closeIcon from '../../Assets/icons/close.svg'
import { ModalContainer } from './styled';
import { Container, Label } from '../../Assets/Structure/Structure';


export default class ModalBody extends React.Component{
    render(){
        return (
            <CSSTransitionGroup
            transitionName="modal"
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnter={false}
            transitionLeave={false}>
                <ModalContainer
                backgroundColor={this.props.backgroundColor}
                style={this.props.style}
                sent={this.props.sent}
                maxWidth={this.props.maxWidth}
                className={this.props.className}>
                {this.props.showClose && (
                    <Container padding="40px" className="modal--modal-body--header-container" row flex alignCenter between>
                        <Label fontSize="16px" color="#666" ls="0.1em">{this.props.title}</Label>
                        <ReactSVG src={closeIcon} className="close-icon" onClick={() => this.props.onClose()} />
                    </Container>
                )}
                        {this.props.children}
                </ModalContainer>
            </CSSTransitionGroup>
        )
    }
}
