import styled from 'styled-components';

export const TopOverview = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;

  @media only screen and (max-width: 1200px){
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px){
    grid-template-columns: 1fr;
  }
`;