import styled from "styled-components";

export const CircleGraphicContainer = styled.div`
  width: 100%;
  max-width: 9em;

  .recharts-wrapper {
    width: 18em !important;
    height: 12em !important;
    z-index: 20;

    svg {
      width: 18em;
      height: 18em;
      transform: translate(-2em, -5.5em);
    }
    
    @media only screen and (max-width: 500px){
      width: 100% !important;
    }
  }
`;

export const BackgroundCircle = styled.div`
  width: 6.8em;
  height: 6.8em;
  background-color: #eee;
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  transform: translate(0.35em, 0.25em);
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    position: absolute;
    text-align: center;
    z-index: 10;
    color: ${props =>
      props.platform === "Google"
        ? "#FE5943"
        : props.platform === "Facebook"
        ? "#3B5998"
        : null};
  }
`;
