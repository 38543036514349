import React from "react";
import axios from "axios";
import InlineLoading from '../../../Components/InlineLoading/InlineLoading';
import { NewLocationForm, ScrollContainer, AlertText } from "./styled";
import { API_URL } from "../../../config";
import { blueDefault } from '../../../Assets/colorPallet/colorPallet';
import NewLocationFeedback from "./NewLocationFeedback/NewLocationFeedback";

// STRUCTURE COMPONENTS
import { Container } from "../../../Assets/Structure/Structure";

// COMPONENTS
import Button from "../../../Components/Button/Button";

export default class NewLocation extends React.Component {
  state = {
    name: "",
    address: "",
    city: "",
    zipcode: "",
    locationRegisteredWithSuccess: "",
    authToken: "",
    store: "",
    loading: false,
    noMoreLocationsAllowed: false,
    showFeedback: false
  };

  componentDidMount() {
    const authToken = localStorage.getItem("token");
    const store = localStorage.getItem("store");
    this.setState({ authToken, store });
  }

  handleChange = (e, type) => this.setState({ [type]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true })
    let zipcode = this.state.zipcode;
    zipcode = parseInt(zipcode);

    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: {"x-sas-Auth" : authToken}};
    const payload = {
      name : this.state.name,
      address: this.state.address,
      city: this.state.city,
      zipcode
    }

    axios.post(`${API_URL}/admin/stores/${store}/locations`, payload, headers )
    .then(res => {
        this.setState({ locationRegisteredWithSuccess: true });

        axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
        .then(res => {
          const locations = res.data;
          this.props.updatedLocations(locations);
          this.setState({ loading: false, showFeedback: true  })
          this.props.showFeedback(true);
        })
    })
    .catch(error => {
      if (error.response.status === 403){
          this.setState({
            noMoreLocationsAllowed: true,
            loading: false,
          })
      }
      this.setState({ locationRegisteredWithSuccess: false, loading: false });
    });
  };

  render() {

    const newLocation = {
      name: this.state.name,
      address: this.state.address
    }

    return (
      <NewLocationForm onSubmit={this.handleSubmit}>
        {this.state.loading ? (
          <InlineLoading color={blueDefault} />
        ) : this.state.showFeedback ? (
          <NewLocationFeedback newLocation={newLocation} />
        ) : (
          <React.Fragment>
          <ScrollContainer>
          <div className="form-item">
          <label>Name</label>
          <input
            type="text"
            placeholder="Location name"
            value={this.state.name}
            onChange={e => this.handleChange(e, "name")}
            required
            />
        </div>

        <div className="form-item">
          <label>Address</label>
          <input
            type="text"
            placeholder="Address location"
            value={this.state.address}
            onChange={e => this.handleChange(e, "address")}
            required
            />
        </div>

        <div className="form-row-item">
          <div className="form-item" style={{ flex: "1", marginRight: "2em" }}>
            <label>City</label>
            <input
              type="text"
              placeholder="City location"
              value={this.state.city}
              onChange={e => this.handleChange(e, "city")}
              required
              />
          </div>

          <div className="form-item">
            <label>Zipcode</label>
            <input
              type="text"
              placeholder=""
              value={this.state.zipcode}
              onChange={e => this.handleChange(e, "zipcode")}
              required
              />
          </div>
        </div>
        </ScrollContainer>
        {this.state.noMoreLocationsAllowed &&
        <AlertText>
          <span>You have got to the limit of allowed locations</span>.<br />
          If you wish to increase your limite, please contact us on support@reviewalert.com or (212) 719 9521.
        </AlertText>}
        <Container flex fluid justifyEnd alignCenter>
          <Button
              text="Save Location"
              type="submit"
              className="btn-save-location"
              color="white"
              flex
            borderRadius="20em"/>
        </Container>
      </React.Fragment>
      )}
      </NewLocationForm>
    );
  }
}
