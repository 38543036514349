import React from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import { TeamFilterContainer } from "./styled";
import OrderBy from "../../../Components/OrderBy/OrderBy";
import Search from "../../../Components/Search/Search";
import SelectAlt from "../../../Components/SelectAlt/SelectAlt";
import SideFilter from "../../../Components/SideFilter/SideFilter";

export default class TeamFilter extends React.Component {
  state = {
    searchIsActive: false,
    locations: this.props.locations,
    roles: this.props.roles,
    employees: this.props.employees,
    locationsPlaceholder: "Locations",
    filterByLocation: false,
    filterByProfile: false,
    selectedLocation: "",
    profilePlaceholder: "Profile",
    selectedProfile: "",
    endpoint: ""
  };

  componentWillReceiveProps(props) {
    this.setState({ employees: props.employees });
    if (!props.isFilterOn) {
      this.setState({ 
        searchIsActive: false, 
        locationsPlaceholder: "Locations", 
        profilePlaceholder: "Profile",
        endpoint: "",
        filterByLocation: false,
        filterByProfile: false,
        selectedLocation: "",
        selectedProfile: ""
      });
    }
  }

  componentDidMount() {
    this.detectClickOnSearch();
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};
    axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
    .then(res => this.setState({ locations: res.data }));
  }

  detectClickOnSearch = () => {
    window.addEventListener("click", e => {
      const searchContainer = document.getElementById("search-container");
      let target = e.target;

      do {
        if (target == searchContainer) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ searchIsActive: false });
    });
  };

  handleClick = () => {
    this.setState({ searchIsActive: true });
  };

  handleSearchValue = searchValue => {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth" : authToken } };

    let endpoint = this.state.endpoint;
    if (endpoint === ""){
      endpoint = `${API_URL}/admin/stores/${store}/employees?name=${searchValue}`;
    } else {
      endpoint = `${endpoint}&&name=${searchValue}`;
    }

    axios.get(endpoint, headers)
    .then(res => {
      const employees = res.data.employees;
      const totalOfEmployees = res.data.total;
      this.props.updateEmployees(employees, totalOfEmployees, "filter", endpoint);
    })
  };

  handleOrderBySelectedOption = option => {};

  handleSelectedOption = (option, type) => {
    const { 
      filterByProfile, 
      filterByLocation, 
      selectedLocation, 
      selectedProfile 
    } = this.state;

    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }}

    let endpoint;
    if (type === "location"){
      this.setState({ 
        filterByLocation: true, 
        selectedLocation: option.id,
        locationsPlaceholder: option.name
      })
      if (filterByProfile){
        endpoint = `${API_URL}/admin/stores/${store}/locations/${option.id}/employees?profile=${selectedProfile.toLowerCase()}`;
      } else {
        endpoint = `${API_URL}/admin/stores/${store}/locations/${option.id}/employees?`;
      }
    } else if (type === "profile"){
      this.setState({ 
        filterByProfile: true, 
        selectedProfile: option.name ,
        profilePlaceholder: option.name
      })
      if (filterByLocation){
        endpoint = `${API_URL}/admin/stores/${store}/locations/${selectedLocation}/employees?profile=${option.name.toLowerCase()}`
      } else {
        endpoint = `${API_URL}/admin/stores/${store}/employees?profile=${option.name.toLowerCase()}`;
      }
    } 

    axios.get(endpoint, headers)
    .then(res => {
      const employees = res.data.employees;
      const totalOfEmployees = res.data.total;
      this.props.updateEmployees(employees, totalOfEmployees, "filter", endpoint);
      this.setState({ endpoint })
    })
  };

  handleResetFilter = resetFilter => {
    this.props.handleResetFilter();
  };

  render() {
    return (
      <TeamFilterContainer className={this.props.className}>
        <React.Fragment>
          <div style={{ marginRight: "1em" }} className="locations">
            <SelectAlt
              placeholder={this.state.locationsPlaceholder}
              selectedOption={this.handleSelectedOption}
              options={this.state.locations}
              type="location"
              resetFilter={this.props.resetFilter}
              id="select-locations"
            />
          </div>

          <div className="profiles">
            <SelectAlt
              placeholder={this.state.profilePlaceholder}
              selectedOption={this.handleSelectedOption}
              options={this.props.roles}
              type="profile"
              maxWidth="10em"
              resetFilter={this.props.resetFilter}
              id="select-profile"
            />
          </div>
        </React.Fragment>

        {this.props.device !== "desktop" && !this.state.searchIsActive && (
          <SideFilter
            page="team"
            locations={this.props.locations}
            selectedProfile={this.handleSelectedOption}
            selectedLocation={this.handleSelectedOption}
            roles={this.props.roles}
            handleResetFilter={this.handleResetFilter}
            resetFilter={this.props.resetFilter}
          />
        )}

        <Search
          onClick={this.handleClick}
          searchIsActive={this.state.searchIsActive}
          searchValue={this.handleSearchValue}
          searchIsActive={this.state.searchIsActive}
        />
      </TeamFilterContainer>
    );
  }
}
