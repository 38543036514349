import styled from 'styled-components';

export const OrderByContainer = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e7e7e7;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Overlay = styled.div`
   position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #00000090;
    left: 0;
    height: 100vh;
`;