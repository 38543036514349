import styled from 'styled-components';

export const SideMenuContainer = styled.div`
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 269px;
    background-color: white;
    border-right: 1px solid #E7E7E7;

    @media only screen and (max-width: 1050px){
        display: none;
    }
`;