import styled from 'styled-components';

export const ChangePasswordModalContainer = styled.div`
    width: 100%;
    background-color: white;
    padding: 2em;
    box-sizing: border-box;
`;

export const CloseContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    svg{
        cursor: pointer;
    }
`;