import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Container, PageWrapper, Label, Title} from '../../Assets/Structure/Structure'
import { WEBCHAT_API_URL } from '../../config'
import UpdatePhoneNumbers from './UpdatePhoneNumbers'
import NotificationSettings from './NotificationSettings/NotificationSettings'
import ChangeWidgetPicture from './ChangeWidgetPicture/ChangeWidgetPicture'
import WebchatWidgets from "./WebchatWidgets/WebchatWidgets";


export default class WebchatSettings extends React.Component{

    state = {
        store: "",
        number: "",
        managerPhoneNumber: "",
        accountSid: "",
        token: "",
        storeKey: "",
        showError: false,
        redirectToWebChat: false,
        storeHasTwilioNumber: false,
        loading: true,
        newStorePhoneNumber: "",
        storePhoneNumbers: [],
        phoneNumbers: { manager: {}, others: [] },
        credentials: null,
    }

    componentDidMount(){
        const store = localStorage.getItem("store_name")
        this.setState({ store })

        this.getStoreTwilioNumber()
    }

    getStoreTwilioNumber = () => {
        const storeKey = localStorage.getItem('store_key')
        axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
        .then((response) => {
            this.setState({ storeHasTwilioNumber: true })

            const credentials = response.data[0]
            this.setState({
                storeHasTwilioNumber: true,
                credentials
            })

            this.getStoreManagerPhoneNumber()
            this.getStorePhoneNumbers()
        })
        .catch(() => {
            this.setState({ storeHasTwilioNumber: false })
        })
        .finally(() => {
            this.setState({ loading: false })
        })
    }

    getStorePhoneNumbers = () => {
        const storeId = localStorage.getItem('store')
        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/phoneNumber?notManager=true`)
        .then((response) => {
            const phoneNumbers = this.state.phoneNumbers
            phoneNumbers.others = response.data
            this.setState({ phoneNumbers })
        })
    }

    getStoreManagerPhoneNumber = () => {
        const storeId = localStorage.getItem('store')
        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/phoneNumber?manager=true`)
        .then((response) => {
            const phoneNumbers = this.state.phoneNumbers

            if (response.data.length === 0){
                phoneNumbers.manager = {}
            } else {
                phoneNumbers.manager = response.data[0]
            }

            this.setState({ phoneNumbers })
        })
    }

    handleChange = (e, type) => { this.setState({ [type]: e.target.value }) }


    handleNewStorePhoneNumber = (e) => {
        const newStorePhoneNumber = e.target.value
        this.setState({ newStorePhoneNumber })
    }

    handleAddNewStorePhoneNumber = () => {
        if (this.state.newStorePhoneNumber !== ""){
            const storePhoneNumbers = this.state.storePhoneNumbers
            storePhoneNumbers.push(`1${this.state.newStorePhoneNumber}`)
            this.setState({ storePhoneNumbers, newStorePhoneNumber: "" })
        }
    }

    handleDeleteNumber = index => {
        let storePhoneNumbers = this.state.storePhoneNumbers
        storePhoneNumbers = storePhoneNumbers.filter((phone, i) => i !== index)
        this.setState({ storePhoneNumbers })
    }

    handleSuccessUpdate = (data) => {
        this.getStorePhoneNumbers()
        this.getStoreManagerPhoneNumber()
        this.setState({ storeHasTwilioNumber: true })
    }


    render(){
        if (this.state.redirectToWebChat) return <Redirect to="/webchat" />

        const { loading, storeHasTwilioNumber } = this.state

        return (
            <Container flex>
                <PageWrapper style={{ width: "100%" }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                            <Container fluid>
                                <Container>
                                    <Title>Webchat settings</Title>
                                </Container>
                                {storeHasTwilioNumber ? (
                                <>
                                <Container mt="40px">
                                    <Label bold>Webchat Widgets</Label>
                                    <Container mt="20px">
                                        <WebchatWidgets />
                                    </Container>
                                </Container>
                                <Container mt="40px">
                                    <Label bold>Widget picture</Label>
                                    <Container mt="20px">
                                        <ChangeWidgetPicture credentials={this.state.credentials} />
                                    </Container>
                                </Container>
                                <Container
                                boxShadowDefault
                                padding="40px"
                                borderRadius="8px"
                                backgroundColor="white"
                                mt="40px">
                                    <Container fluid>
                                        <Container>
                                            <Label bold>Phone numbers allowed to receive a new message notification:</Label>
                                        </Container>
                                        <Container mt="20px">
                                            <UpdatePhoneNumbers
                                            twilioNumber={this.state.credentials && this.state.credentials.number}
                                            phoneNumbers={this.state.phoneNumbers}
                                            onSuccess={this.handleSuccessUpdate} />
                                        </Container>
                                    </Container>
                                </Container>
                                <Container
                                boxShadowDefault
                                padding="40px"
                                borderRadius="8px"
                                backgroundColor="white"
                                mt="40px">
                                    <Container>
                                        <Label bold>Notification Settings</Label>
                                    </Container>
                                    <Container mt="20px">
                                        <NotificationSettings />
                                    </Container>
                                </Container>
                                </>
                                ) : (
                                <Container mt="40px">
                                    <Label>Seems like your store does not have the webchat setup yet.</Label>
                                </Container>
                                )}
                            </Container>)}
                </PageWrapper>
            </Container>
        )
    }
}
