import React from "react";
import AltModal from "../../../Components/AltModal/AltModal";
import NewMemberForm from "./NewMemberForm/NewMemberForm";
import NewMemberFeedback from "./NewMemberFeedback/NewMemberFeedback";


export default class NewMemberModal extends React.Component {
  state = {
    showFeedback: false,
    newEmployees: []
  };

  handleCloseModal = isModalOpen => this.props.isModalOpen(isModalOpen);

  handleUpdatedEmployees = (employees, totalOfEmployees) => {
    this.props.updatedEmployees(employees, totalOfEmployees, "new-employees");
    this.setState({ loading: false })
  };

  handleFeedback = showFeedback => this.setState({ showFeedback });

  handleNewEmployees = (newEmployees) => this.setState({ newEmployees });

  render() {
    return (
      <AltModal
        isModalOpen={this.handleCloseModal}
        headerTitle="add members"
        className="new-member-modal"
        newEmployees={this.state.newEmployees}
      >
        {this.state.showFeedback ? (
          <NewMemberFeedback newEmployees={this.state.newEmployees} />
        ) : (
          <NewMemberForm
            showFeedback={this.handleFeedback}
            updatedEmployees={this.handleUpdatedEmployees}
            onLoading={this.handleLoading}
            newEmployees={this.handleNewEmployees}
            location={this.props.location}
            type={this.props.type}
            location={this.props.locationId}
          />
        )}
      </AltModal>
    );
  }
}
