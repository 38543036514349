import React from 'react';
import { BeaconGuideContainer } from './styled';
import BeaconFormat from './BeaconFormat/BeaconFormat';
import BeaconGuideNav from './BeaconGuideNav/BeaconGuideNav';

export default class BeaconGuide extends React.Component{

    state = {
        currentNavItem: "home-beacon"
    }

    handleCurrentNavItem = currentNavItem => {
        this.setState({ currentNavItem })
    }

    render(){

        const { currentNavItem } = this.state;

        return(
            <BeaconGuideContainer>
                <h2>How to setup the beacon on your website</h2>
                <BeaconGuideNav currentNavItem={this.handleCurrentNavItem} />
                {currentNavItem === "home-beacon" ? (
                    <BeaconFormat beaconName="Home: Beacon" beaconNameTxt="Home Beacon"/>
                ) : currentNavItem === "home-horizontal" ? (
                    <BeaconFormat beaconName="Home: Horizontal" beaconNameTxt="Home Horizontal"/>
                ) : currentNavItem === "product-catalog-horizontal" ? (
                    <BeaconFormat beaconName="Product Catalog: Horizontal" beaconNameTxt="Product Catalog Horizontal"/>
                ) : currentNavItem === "product-catalog-banner" ? (
                    <BeaconFormat beaconName="Product Catalog: Banner" beaconNameTxt="Product Catalog Banner"/>
                ) : null}
            </BeaconGuideContainer>
        )
    }
}