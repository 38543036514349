import React from "react";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { API_URL } from "../../config";
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import { PageWrapper } from "../../Assets/Structure/Structure";
import Header from "./Header/Header";
import Table from "./Table/Table";
import Pagination from "../../Components/Pagination/Pagination";

export default class Reports extends React.Component {
  state = {
    requests: [],
    totalOfRequests: "",
    loading: true,
    locationOnFiter: "",
    filterType: "",
    valueOnFilter: "",
    isFilterOn: false,
    store: "",
    authToken: "",
    headers: "",
    endpoint: "",
    currentPage: 1,
    showEmptyFeedback: false,
    redirectUrl: "/store-location"
  };

  componentDidMount() {
    const authToken = localStorage.getItem("token");
    const store = localStorage.getItem("store");
    const headers = { headers: { "x-sas-Auth": authToken } };

    const endpoint = `${API_URL}/private/stores/${store}/review_requests?sort_order=desc`;
    this.setState({ endpoint });

    axios
      .get(`${API_URL}/private/stores/${store}/review_requests?sort_order=desc`, headers)
      .then(res => {
        this.setState({
          requests: res.data.requests,
          totalOfRequests: res.data.total,
          loading: false,
          store,
          authToken,
          headers
        });
      });
  }

  handleCurrentPage = currentPage => {
    window.scrollTo(0, 0);
    this.setState({ currentPage }, () => {
      this.getNextData();
    });
  };

  getNextData = () => {
    const { endpoint, currentPage, headers } = this.state;
    const fullEndpoint = `${endpoint}&&page=${currentPage}`;

    this.setState({ loading: true });
      axios.get(fullEndpoint, headers)
      .then(res => {
        this.setState({
          requests: res.data.requests,
          totalOfRequests: res.data.total,
          loading: false
        });
      });
  }

  handleUpdateRequests = (requests, totalOfRequests) => {
    if (requests.length === 0){
      this.setState({ 
        showEmptyFeedback: true,
        requests,
        totalOfRequests
      })
    } else {
      this.setState({ 
        requests, 
        totalOfRequests,
        showEmptyFeedback: false
      })
    }
  }

  handleResetFilter = () => {
    this.setState({ loading: true });
    axios.get(`${API_URL}/private/stores/${this.state.store}/review_requests?sort_order=desc`, this.state.headers)
    .then(res => {
      this.setState({
        requests: res.data.requests,
        totalOfRequests: res.data.total,
        isFilterOn: false,
        currentPage: 1,
        showEmptyFeedback: false,
        endpoint: `${API_URL}/private/stores/${this.state.store}/review_requests?sort_order=desc`
      });
    })
    .catch(() => {})
    .finally(() => {
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    })
  };

  handleEndpoint = endpoint => {
    this.setState({ endpoint })
  }

  handleRouteProtection = (redirectUrl) => {
    this.setState({ routeNotAllowed: true, redirectUrl })
  }

  handleValidateWidgetOnlyLoading = loading => this.setState({ loading })




  render() {

    if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl} />

    const {
      isFilterOn,
      requests,
      loading,
      totalOfRequests,
      showEmptyFeedback
    } = this.state;

    return (
      <ValidateWidgetOnly 
      protectRoute={this.handleRouteProtection} 
      currentRoute="reports"
      onLoading={this.handleValidateWidgetOnlyLoading}>
      <PageWrapper>
        <Header
          isFilterOn={isFilterOn}
          updateRequests={this.handleUpdateRequests}
          endpoint={this.handleEndpoint}
          currentEndpoint={this.state.endpoint}
          handleResetFilter={this.handleResetFilter}
        />
        <Table  
          reviewRequests={requests}
          loading={loading}
          showEmptyFeedback={showEmptyFeedback}
        />
        <Pagination
          totalOfItems={totalOfRequests}
          maxItemsPerPage={20}
          currentPage={this.handleCurrentPage}
          mt="2em"
        />
      </PageWrapper>
      </ValidateWidgetOnly>
    );
  }
}
