import styled from "styled-components";

export const LogoutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    path {
      fill: #ccc;
      transition: fill 0.4s ease;
    }
  }

  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }
`;
