// Libraries
import styled from "styled-components"


export const StyledTabsContainer = styled.div`
    border-radius: 8px !important;
    box-shadow: 0px 1px 0px rgba(0,0,0,0.15) !important;
  
    header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.MuiPaper-elevation4 {
        box-shadow: none !important;
        color: #333;
        border-bottom: 1px solid #eee;
    }
    .MuiTabs-flexContainer {
      background-color: white !important;
    }
    
   span.MuiTabs-indicator {
      background-color: transparent !important;
    }
`

