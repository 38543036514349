import React from "react";
import moment from 'moment';
import {
  FilterContainer,
  FilterBody,
  ApplyFilterButtom,
  ActionContainer,
  NoReply
} from "./styled";
import FilterHeader from "./FilterHeader/FilterHeader";
import SelectAlt from "../../SelectAlt/SelectAlt";
import HumorFilter from '../../HumorFilter/HumorFilter';
import SelectDate from '../../SelectDate/SelectDate';

export default class Filter extends React.Component {

  state = {
    locationsPlaceholder: "Locations",
    platformsPlaceholder: "Platforms",
    sentByPlaceholder: "Sent by",
    datePlaceholder: "Date",
    profilePlaceholder: "Profile",
    resetFilter: false,
    noReplyActive: false
  }

  //RESET FILTER
  handleResetFilter = resetFilter => {
    this.props.handleResetFilter(resetFilter);
    this.props.handleSideFilter(false)
    this.setState({ 
      locationsPlaceholder: "Locations",
      platformsPlaceholder: "Platforms",
      datePlaceholder: "Date",
      sentByPlaceholder: "Sent by",
      profilePlaceholder: "Profile",
      resetFilter: true,
      noReplyActive: false
    })
  };

  //APLY FILTER AND CLOSE MODAL
  handleApplyFilter = () => {
    this.props.handleSideFilter(false);
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  }

  //REVIEW TYPE FILTER
  handleSelectedReviewType = reviewType => {
    this.props.selectedReviewType(reviewType, "review-type");
  };

  //LOCATION FILTER
  handleSelectedLocation = location => {
    this.props.selectedLocation(location, "location");
    this.setState({ locationsPlaceholder: location.name })
  };

  //PLATFORM FILTER
  handleSelectedPlatform = platform => {
    this.props.selectedPlatform(platform, "platform");
    this.setState({ platformsPlaceholder: platform.name })
  };

  //UNANSWERED REVIEWS
  handleNoReply = () => {
    this.props.noReply("no-reply", "no-reply");
    this.setState({ noReplyActive: true })
  };

  //SENT BY FILTER
  handleSelectedSentBy = sentBy => {
    this.props.selectedSentBy(sentBy, "sent-by");
    this.setState({ 
      sentByPlaceholder: sentBy.name,
    })
  }

  //DATE FILTER
  handleSelectedDateInterval = dateInterval => {
    const interval = {
      startDate: moment(dateInterval.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateInterval.endDate).format("YYYY-MM-DD")
    }
    this.props.selectedInterval(interval, "date");
    this.setState({ 
      datePlaceholder: `${interval.startDate} - ${interval.endDate}`
    })
  }

  //PROFILE FILTER
  handleSelectedProfile = selectedProfile => {
    this.props.selectedProfile(selectedProfile, "profile")
    this.setState({ profilePlaceholder: selectedProfile.name })
  }

  handleFilterType = () => {}

  render() {

    const { page } = this.props;

    return (
      <FilterContainer showSideFilter={this.props.showSideFilter}>
        <FilterHeader resetFilter={this.handleResetFilter} />
        <FilterBody>
          {page === "reviews" && (
            <div style={{ marginRight: "1em" }} className="humor-filter">
            <HumorFilter
              selectedReviewType={this.handleSelectedReviewType}
              resetFilter={this.props.resetFilter}
              filterType={this.handleFilterType}
              />
            </div>
          )}

          {page === "reviews" && (
          <div style={{ width: "100%" }}>
            <NoReply 
             onClick={this.handleNoReply} 
             noReplyActive={this.state.noReplyActive}>
              Unanswered reviews
            </NoReply>
          </div>
          )}

          {page === "reviews" && (
            <div style={{ marginTop: "1em" }}  className="platform-filter">
              <SelectAlt
                placeholder={this.state.platformsPlaceholder}
                selectedOption={this.handleSelectedPlatform}
                options={this.props.platforms}
                type="platform"
                position="relative"
                id="platform-mob-filter"
                resetFilter={this.state.resetFilter}
              />
            </div>
          )}      

          {page === "reviews"  && (
            <div style={{ marginTop: "1em" }} className="location-filter">
            <SelectAlt
              placeholder={this.state.locationsPlaceholder}
              selectedOption={this.handleSelectedLocation}
              options={this.props.locations}
              type="location"
              id="location-mob-filter"
              position="relative"
              resetFilter={this.state.resetFilter}
              />
            </div>
          )}

            {page === "reports"  && (
            <div style={{ marginTop: "1em" }} className="location-filter">
            <SelectAlt
              placeholder={this.state.locationsPlaceholder}
              selectedOption={this.handleSelectedLocation}
              options={this.props.locations}
              type="location"
              id="location-mob-filter"
              position="relative"
              resetFilter={this.state.resetFilter}
              />
            </div>
          )}

          

          {page === "reports" && (
            <div style={{ marginTop: "1em", width: "100%" }}>
              <SelectAlt
                placeholder={this.state.sentByPlaceholder}
                selectedOption={this.handleSelectedSentBy}
                options={this.props.employees}
                type="sent-by"
                position="relative"
                id="sent-by-mob-filter"
                resetFilter={this.state.resetFilter}
              />
            </div>
          )}

          {page === "reports" && (
            <div style={{ marginTop: "1em", width: "100%" }}>
              <SelectDate 
              placeholder={this.state.datePlaceholder} 
              selectedInterval={this.handleSelectedDateInterval} />
            </div>
          )}

          {page === "team"  && (
            <div style={{ marginTop: "1em" }} className="location-filter">
            <SelectAlt
              placeholder={this.state.locationsPlaceholder}
              selectedOption={this.handleSelectedLocation}
              options={this.props.locations}
              type="location"
              id="location-mob-filter"
              position="relative"
              resetFilter={this.state.resetFilter}
              />
            </div>
          )}

          {page === "team"  && (
            <div style={{ marginTop: "1em" }} className="location-filter">
            <SelectAlt
              placeholder={this.state.profilePlaceholder}
              selectedOption={this.handleSelectedProfile}
              options={this.props.roles}
              type="profile"
              id="profile-mob-filter"
              position="relative"
              resetFilter={this.state.resetFilter}
              />
            </div>
          )}

        <ApplyFilterButtom 
          onTouchStart={() => this.handleApplyFilter()}
          onTouchTap={() => this.handleApplyFilter()}
          onClick={() => this.handleApplyFilter()}
          onMouseUp={() => this.handleApplyFilter()}>  
          Apply Filter
        </ApplyFilterButtom>
        </FilterBody>
        
       
      </FilterContainer>
    );
  }
}
