import styled from "styled-components";

export const ReviewContainer = styled.div`
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);

  .end {
    margin: 0 0 auto auto;
    align-items: flex-end;
  }

  .flex-end{
      justify-content: flex-end;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientName = styled.label`
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #333333;
`;

export const ClientLocation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.1em;

  svg {
    width: 13px;
  }

  label {
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #999999;
    margin-left: 0.5em;
  }

  @media only screen and (max-width: 500px){
    max-width: 8em;
  }
`;

export const Date = styled.label`
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #666666;
  margin-top: 0.3em;

  @media only screen and (max-width: 500px){
    font-size: 12px;
  }
`;

export const ReviewText = styled.p`
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #333333;
  margin-top: 1.5em;
`;


export const SmileContainer = styled.div`
  svg{
    width: 40px;
    height: 40px;
  }
`;