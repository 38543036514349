import React from "react";
import ReactSVG from 'react-svg';
import { InnerCol } from "../TeamTable/TeamTableRow/styled";
import Confirmation from "./Confirmation/Confirmation";
import trashIcon from '../../../Assets/icons/trash.svg';

export default class DeleteEmployee extends React.Component {
  state = {
    showConfirmation: false,
    employee: ""
  };

  componentDidMount(){
    this.detectClick();
  }

  showConfirmation = (showConfirmation, employee) => {
    this.setState({ showConfirmation, employee });
  };

  
  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById(this.props.id);
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ showConfirmation: false })

    });
  };

  handleUpdateEmployees = (employees, totalOfEmployees) => {
    this.props.updateEmployees(employees, totalOfEmployees)
  }


  render() {

    return (
      <div id={this.props.id}>
        {this.state.showConfirmation && (
          <Confirmation 
            locationId={this.props.locationId} 
            employee={this.state.employee} 
            updateEmployees={this.handleUpdateEmployees}
            handleClose={this.showConfirmation} />
        )}
        <InnerCol
          showDeleteAction={this.props.showDeleteAction}
          device={this.props.device}
          onClick={() => this.showConfirmation(true, this.props.employee)}
          className={this.props.className}
          style={{ right: "calc(3em + 80px)" }}
        >
          <ReactSVG src={trashIcon} />
        </InnerCol>
      </div>
    );
  }
}
