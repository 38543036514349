import React from "react";
import ReactSVG from 'react-svg';
import { SelectOptionsContainer, NoResultContainer, Overlay } from "./styled";
import Search from "../Search/Search";
import InlineLoading from '../../InlineLoading/InlineLoading';
import memberIcon from '../../../Assets/icons/team.svg';
import { blueDefault } from '../../../Assets/colorPallet/colorPallet';

export default class SelectOptions extends React.Component {
  state = {
    searchValue: "",
    options: this.props.options,
    showEmptyFeedback: false,
    loading: this.props.loading
  };

  componentDidMount(){
    this.setState({ loading: this.props.loading })
  }

  componentWillReceiveProps(props){
    this.setState({ loading: props.loading, options: props.options })
  }

  handleCloseSelectOptions = () => {
    this.props.handleClose(false);
  };

  handleSelect = option => {
    this.props.optionSelected(option);
    this.props.handleClose(false);
    this.setState({ searchValue: "" });
  };

  handleSearchValue = searchValue => {
    let options = this.props.options;
    let value = searchValue.trim().toLowerCase();

    if (value.length > 0) {
      options = options.filter(function(i) {
        const label = i.label || i.name;
        return label.toLowerCase().match(value);
      });

      if (this.props.options.length > 0){

        if (options.length === 0) {
        this.setState({ showEmptyFeedback: true }) 
      } else {
        this.setState({ showEmptyFeedback: false })
      }
      
      this.setState({ options });
    }
    }

    if (value.length === 0) {
      options = this.props.options;
      this.setState({ options });
    }
  };

  render() {  

    return (
      <React.Fragment>
        <Overlay onClick={() => this.props.handleClose(false)} />
        <SelectOptionsContainer
          shadow={this.props.shadow}
          absolute={this.props.absolute}
          id={this.props.id}
          width={this.props.width}
          maxWidth={this.props.maxWidth}
        >
        {this.state.loading ? (
          <InlineLoading color={blueDefault} />
        ) : (
            this.state.showEmptyFeedback ? (
              <React.Fragment>
              <Search searchValue={this.handleSearchValue} />
              <NoResultContainer>
                <ReactSVG src={memberIcon} />
                <p>No search result</p>
              </NoResultContainer>
            </React.Fragment>
            ) : (
            <React.Fragment>
              <Search searchValue={this.handleSearchValue} />
                <ul>
                {this.state.options.map(option => {
                  return (
                    <li onClick={() => this.handleSelect(option)}>
                      {option.label || option.name}
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
            )
        )}
        </SelectOptionsContainer>
        </React.Fragment>
    );
  }
}
