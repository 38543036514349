import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import ReactSVG from 'react-svg';
import { Container, Status, Label, Row, Text } from '../../Assets/Structure/Structure';
import { blueDefault, green } from '../../Assets/colorPallet/colorPallet';
import locationIcon from '../../Assets/icons/location.svg';
import { formatTimeLabel }  from '../../utils/helpers'
import ChatInboxSkeleton from './ChatInboxSkeleton/ChatInboxSkeleton'

export default class ChatInbox extends React.Component{

    state = {
        message: this.props.message,
        location: "",
    }

    componentDidMount(){
        const { location, message } = this.props
        this.setState({ message, location})
    }

    componentWillReceiveProps(props){
        this.setState({
            message: props.message,
            location: props.location,
        })
    }

    render(){

        const { message, location } = this.state;
        const lastMessageCreatedAt = formatTimeLabel(message.createdAt)

        return(
            <Container
            onClick={this.props.onClick}
            padding="1em"
            borderBottom="1px solid #E6E6E6">
                {this.props.loading ? (
                    <ChatInboxSkeleton />
                ) : (
                    <CSSTransitionGroup
                    transitionName="mount-page"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnter={true}
                    transitionLeave={true}>
                    <React.Fragment>
                        <Container flex alignCenter fluid between>
                            <Container flex alignCenter>
                            {message.newMessage ? (
                                <Status color={blueDefault} />
                            ) : message.needsResponse && message.newMessage === false ? (
                                <Status color={green} />
                            ) : null}
                                <Label ml=".5em" uppercase>{message.clientName ? message.clientName : message.author}</Label>
                            </Container>
                            {message.fromCampaign && (
                                <Container
                                flex
                                alignCenter
                                padding="0 10px"
                                backgroundColor={blueDefault}
                                height="18px"
                                borderRadius="20em">
                                <Label color="white" fontSize="12px">
                                    Campaign
                                </Label>
                            </Container>
                            )}
                        </Container>
                        <Row>
                            <Text
                            ml="calc(8px + .5em)"
                            mt=".25em"
                            mb="0"
                            ellipsis
                            maxWidth="90%"
                            color="#999">
                            {message.lastMessage}
                            </Text>
                        </Row>
                        <Row mt=".5em" between>
                            <Row auto>
                                <ReactSVG src={locationIcon} />
                            <Label ml=".3em" color="#666">{ location ? location.name : "" }</Label>
                            </Row>
                            <Label fontSize="12px" color="#B5B5B5">{lastMessageCreatedAt}</Label>
                        </Row>
                    </React.Fragment>
                    </CSSTransitionGroup>
                )}
            </Container>
        )
    }
}
