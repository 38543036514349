import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png';

export default class HowToAnswerReview extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0" fontSize="12px"><i>*You can only respond to Google reviews through Review Alert.</i></Text>
                <Text mt="0">
                   In the <strong style={{ color: blueDefault }}>Reviews</strong> section you will be able to see all your reviews.
                  To reply to a review click on <strong style={{ color: blueDefault }}>Answer Review</strong>.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}