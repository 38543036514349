import styled from "styled-components";
import BaseText from "../../../../Assets/Structure/Structure";
import { blueDefault } from "../../../../Assets/colorPallet/colorPallet";

export const TableWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;
export const TableRow = styled.div`
  background: #fff;
  display: flex;
  width: 98%;
  padding: 15px 10px;
  margin: 0 auto;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 5px;

  .delete-employee-mob{
    display: none;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    padding: 1em;
    box-sizing: border-box;

    .delete-employee{
      display: none;
    }

    .delete-employee-mob{
      display: block;
      margin-top: -2em;
    }

    .location-container{
      margin: 1em 0;
    }
  }
`;

export const TableCol = styled.div`
  display: flex;
  width: ${props => props.width ? props.width : "100%"};
  padding: 0 1em;
  border-right: ${props => props.borderRight ?  "1px solid #e7e7e7": null };
  justify-content: ${props => props.between ? "space-between" : null};
`;

export const TableCol5 = styled.div`
  display: inline-flex;
  width: 5%;
  padding-left: 20px;
  justify-content: center;
  border-left: 1px solid #e7e7e7;

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 1em 0;
    box-sizing: border-box;
  }
`;

export const NameAvatarWrapper = styled(BaseText)`
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: #a088ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-right: 15px;
`;

export const ColUserName = styled(BaseText)`
  font-size: 18px;
  color: #333333;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 10em;

  @media only screen and (max-width: 1025px) {
    font-size: 16px;
  }

`;
export const ColTitle = styled(BaseText)`
  display: block;
  width: 100%;
  color: #999999;
  font-size: 14px;
  letter-spacing: 0.1em;
`;
export const ColValue = styled(BaseText)`
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.05em;
  margin-top: 10px;
  display: block;
  width: 100%;

  .select-location {
  }

  @media only screen and (max-width: 500px){
    margin-top: 0;
  }
`;
export const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  svg {
    transform: translate(0, 100%);
    cursor: pointer;
    transition: opacity 0.4s ease;
    opacity: ${props =>
      props.device === "desktop" && props.showDeleteAction
        ? "1"
        : props.device === "desktop" && !props.showDeleteAction
        ? "0"
        : "1"};

    path {
      fill: #333;
      transition: fill 0.4s ease;
    }

    &:hover {
      path {
        fill: ${blueDefault};
      }
    }
  }
`;

export const Col1Inner1 = styled.div`
  width: 50px;
`;
export const Col1Inner2 = styled.div`
  width: calc(100% - 50px);
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 500px){
    justify-content: center;
  }
`;

export const Col2Inner1 = styled.div`
  display: flex;
  width: 20px;
  height: 100%;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
`;

export const Col2Inner2 = styled.div`
  box-sizing: border-box;
  width: calc(100% - 20px);
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 500px){
    padding-left: 10px;
  }
`;
