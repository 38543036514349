import React, {useEffect, useState} from "react"
import axios from "axios"
import {WEBCHAT_API_URL} from "../../../../config";
import {Container, Label} from "../../../../Assets/Structure/Structure";
import { StoreResponseItemContainer } from "./styled";
import StoresResponseRankingSkeleton from "./StoresResponseRankingSkeleton";

export default function StoresResponseRanking() {

    const [storesResponseRanking, setStoresResponseRanking] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchStoresResponseRanking()
    }, [])

    const fetchStoresResponseRanking = async () => {
        try {
            setLoading(true)
            const authToken = localStorage.getItem("token")
            const headers = { headers: { "ra-user-token": authToken } }
            const response = await axios.get(`${WEBCHAT_API_URL}/analytics/stores/responseRanking`, headers)
                .then(res => res.data)

            if (response){
                setStoresResponseRanking(response)
            }
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            {loading ? (
                <StoresResponseRankingSkeleton />
            ) : (
                storesResponseRanking.map(storeResponse => (
                    <StoreResponseItemContainer>
                    <Container fluid maxWidth="20em">
                    <Label>{storeResponse.storeName}</Label>
                    </Container>
                    <Container>
                    <label>{storeResponse.responses}</label>
                    </Container>
                    </StoreResponseItemContainer>
                ))
            )}
        </Container>
    )
}
