import React from "react";
import Skeleton from 'react-skeleton-loader';
import axios from "axios";
import InlineLoading from '../../../../../Components/InlineLoading/InlineLoading';
import { StepTwoActionContainer, ContinueButton } from "./styled";
import { API_URL } from "../../../../../config";

export default class StepTwoAction extends React.Component {

  state = {
    active: false,
    loading: false
  }

  handlePageAssociation = () => {
    const selectedPage = this.props.selectedPage;
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const currentLocationId = localStorage.getItem("current_location_id");
    const service = this.props.service;
    this.setState({ loading: true })

    const headers = { headers: { "x-sas-Auth": authToken } };
    let payload = {};
    payload.page_id = selectedPage.id;

    if (selectedPage.access_token) {
      payload.page_token = selectedPage.access_token;
    }

    axios.post(`${API_URL}/admin/stores/${store}/locations/${currentLocationId}/services/${service}`,
        payload,
        headers
      )
      .then(res => {
        this.props.updatedCurrentStep(3);

        axios.get(`${API_URL}/admin/stores/${store}/locations`, headers).then(res => {
          this.props.updatedLocations(res.data);
          this.setState({ loading: false })
        });
      });
  };

  render() {
    return (
      <StepTwoActionContainer selectedPage={this.props.selectedPage}>
        {this.props.loading ? (
          <Skeleton width="18em" height="2em" />
        ) : (
          <ContinueButton 
            active={this.props.selectedPage ? true : false} 
            onClick={this.handlePageAssociation}>
            {this.state.loading ? <InlineLoading /> : "CONTINUE"}
          </ContinueButton>
        )}
      </StepTwoActionContainer>
    );
  }
}
