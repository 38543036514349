import React from 'react';
import ReactSVG from 'react-svg';
import { ItemContainer, Question, DescriptionContainer } from './styled';
import { Row } from '../../../Assets/Structure/Structure';
import arrowIcon from '../../../Assets/icons/arrow.svg';


export default class Item extends React.Component{
   

    render(){
        return(
            <ItemContainer onClick={this.props.onClick} active={this.props.active}>
                <Row between alignCenter padding=".25em 1em .25em 0">
                    <Question>{this.props.question}</Question>
                    <ReactSVG src={arrowIcon} />
                </Row>
                <DescriptionContainer active={this.props.active}>
                    {this.props.children}
                </DescriptionContainer>
            </ItemContainer>
        )
    }
}