import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';

export default class DoIGetAReport extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                Yes. Every week we will send a weekly report summarizing all your Review Alert activities. You will see total review invitations that were sent, the total average rating, rating and reviews per reviews site platform, your best locations among other things.
                </Text>
            </DescriptionContainer>
        )
    }
}