import React from 'react';
import { ProfileContainer } from './styled';
import Logout from './Logout/Logout';
import UserDetail from './UserDetail/UserDetail';

export default class Profile extends React.Component{

    handleLoggedIn = (loggedIn) => this.props.loggedIn(loggedIn);

    render(){   
        return(
            <ProfileContainer backgroundOff={this.props.backgroundOff}>
                <Logout loggedIn={this.handleLoggedIn} />
                <UserDetail 
                    onClick={this.props.handleEditProfile}
                    userName={this.props.userName} 
                    userEmail={this.props.userEmail}  />
            </ProfileContainer>
        )
    }
}