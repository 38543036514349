import styled from 'styled-components';

export const OptionsContainer = styled.div`
     background-color: white;
    width: 100%;
    max-width: 14em;
    max-height: 18em;
    margin-left: -3em;
    margin-top: 1em;
    padding: 1em;
    z-index: 10;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.15);
    border-radius: 4px;
    position: absolute;
  margin-top: ${props => (props.upContainer && "-10em")};
`;

export const OptionsList = styled.ul`
    margin: 0;
    list-style: none;
    padding: 0;
`;

export const Option = styled.li`
    padding: 1em;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;

  &:hover{
    background-color: #E6E6E6;
  }
`;