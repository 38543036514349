import React from "react";
import ReactSVG from "react-svg";
import { HumorFilterContainer, PositiveContainer, NegativeContainer, NeutralContainer } from "./styled";
import positive from "../../Assets/icons/positive.svg";
import neutral from "../../Assets/icons/neutral.svg";
import negative from "../../Assets/icons/negative.svg";

export default class HumorFilter extends React.Component {
  state = {
    reviewType: ""
  };

  componentWillReceiveProps(props){
    if (props.resetFilter === true){
      this.setState({ reviewType: "" })
    }
  }

  handleClick = reviewType => {
    this.props.selectedReviewType(reviewType);
    this.props.filterType("humor-filter");
    this.setState({ reviewType });
  };

  render() {
    return (
      <HumorFilterContainer
        id="humor-filter"
      >
        <PositiveContainer reviewType={this.state.reviewType}>
          <ReactSVG
            src={positive}
            onTouchStart={() => this.handleClick("positive")}
            onClick={() => this.handleClick("positive")}
          />
        </PositiveContainer>
        <NeutralContainer reviewType={this.state.reviewType}>
          <ReactSVG
            src={neutral}
            svgClassName="neutral"
            onTouchStart={() => this.handleClick("neutral")}
            onClick={() => this.handleClick("neutral")}
          />
        </NeutralContainer>
        <NegativeContainer reviewType={this.state.reviewType}>
          <ReactSVG
            src={negative}
            svgClassName="negative"
            onTouchStart={() => this.handleClick("negative")}
            onClick={() => this.handleClick("negative")}
          />
        </NegativeContainer>
      </HumorFilterContainer>
    );
  }
}
