import styled from "styled-components";

export const SendReviewInvitationContainer = styled.form`
  width: 100%;
  max-width: 575px;
  background-color: #f8f8f8;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  margin: 0 1em;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 770px){
    max-width: 100%;
    margin-top: 2em;
  }

  @media only screen and  (max-width: 600px){
        margin: 0 0 2em;
    }
`;

export const FormContainer = styled.div`
    padding: 1em;
    box-sizing: border-box;



    .send-review-module{
        margin-top: 1em;
        overflow-y: auto;
        padding: 2em;

        @media only screen and (max-width: 600px){
          max-height: none;
          overflow-y: unset;

          .form-input{
            width: 100%;
            max-width: 8em;
          }
        }
    }
`;

export const ActionContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #333;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
`;

export const ForgotUserIdBtn = styled.a`
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 235px;
  cursor: pointer;
`;


export const ScrollContainer = styled.div`
  height: ${props => props.invitations.length > 0 ? "50vh" : "auto"};
  overflow-y: auto;
  padding: 1em;
  box-sizing: border-box;

  @media only screen and (max-width: 600px){
    padding: 0;
  }
`;