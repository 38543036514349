import React from "react";
import {NewMemberFormContainer, FormItem, FooterContainer, BodyContainer} from "./styled";
import SelectFluid from "../../../../Components/SelectFluid/SelectFluid";
import RadioButton from "../../../../Components/RadioButton/RadioButton";
import AddMoreMembers from "../AddMoreMembers/AddMoreMembers";
import {API_URL} from "../../../../config";
import axios from "axios";
import NewMembersList from "./NewMembersList/NewMembersList";
import { Container } from "../../../../Assets/Structure/Structure";
import { Label } from "../../../../Components/SelectFluid/styled";
import { red, blueDefault } from '../../../../Assets/colorPallet/colorPallet'
import { LoadingContainer } from "../styled";
import InlineLoading from "../../../../Components/InlineLoading/InlineLoading";

export default class NewMemberForm extends React.Component {
    state = {
        locations: [],
        loading: true,
        location: "",
        name: "",
        phone: "",
        email: "",
        role: "manager",
        store: "",
        authToken: "",
        AddMoreMembers: false,
        newEmployees: [],
        multipleRequests: false,
        enableMultipleRequests: false,
        employeesEmails: [],
        currentNewEmployee: null,
        currentNewEmployeeNotAllowed: false,
        selectedLocationPlaceholder: "Select location"
    };

    componentDidMount() {
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {headers: { "x-sas-Auth" : authToken }};
        this.setState({store, authToken, headers});

        if (!this.props.location) {
            axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
            .then(res => {
                let locations = res.data;
                locations = locations.map(location => {
                    return {label: location.name, id: location.id};
                });
                this.setState({locations, loading: false});
            });
        }

        this.getEmployees(headers)
    }

    getEmployees = (headers) => {
        const store = localStorage.getItem("store");
        const employees = []

        axios.get(`${API_URL}/admin/stores/${store}/employees?page=1`, headers)
        .then((res) => {
            const totalPages = Math.ceil(res.data.total / 10)

            let page;
            for (page = 0; page < totalPages; page++){
                axios.get(`${API_URL}/admin/stores/${store}/employees?page=${page}`, headers)
                .then(result => {

                    result.data.employees.forEach(employee => employees.push(employee))

                })
                .finally(() => {
                    if (page === totalPages){
                        const employeesEmails = employees.map(employee => employee.email)
                        this.setState({ employeesEmails })
                    }
                })
            }
        })
    }

    isAlreadyAnEmployee = (email) => {
        if (this.state.employeesEmails.includes(email)){
            return true
        }

        return false
    }

    handleChange = (e, type) => {
        this.setState({[type]: e.target.value});
        this.enableMultipleRequests();
    };

    handleSelectedOption = location => {
        this.setState({
            location,
            selectedLocationPlaceholder: location.label
        }, () => this.enableMultipleRequests());
    };

    handleSelectedRole = role => {
        this.setState({
            role: role.value
        }, () => this.enableMultipleRequests());
    };

    enableMultipleRequests = () => {
        let location,
            role;
        if (this.props.type === "location") {
            location = this.props.location;
            role = "seller";
        } else {
            location = this.state.location;
            role = this.state.role;
        }
        const name = this.state.name;
        const email = this.state.email;

        const condition = name && role && email && location !== "";

        if (condition) {
            this.setState({enableMultipleRequests: true});
        } else {
            this.setState({enableMultipleRequests: false});
        }
    };

    handleMultipleEmployees = () => {
        let location,
            role;
        if (this.props.type === "location") {
            location = this.props.location;
            role = "seller";
        } else {
            location = this.state.location;
            role = this.state.role;
        }

        const {name, email} = this.state;

        let enableRequest;
        const condition = name && location && email && role !== "";

        if (condition) {
            enableRequest = true;
        } else {
            enableRequest = false;
        }

        if (enableRequest) {
            let newEmployees = this.state.newEmployees;

            const newEmployee = {
                email: email,
                name: name,
                role: role,
                location: location
            };

            if (this.isAlreadyAnEmployee(newEmployee.email)){
                this.setState({
                    currentNewEmployee: newEmployee,
                    currentNewEmployeeNotAllowed: true,
                })

                this.setState({ loading: false })

                return
            } else {
                this.setState({
                    currentNewEmployeeNotAllowed: false
                 })
            }

            newEmployees.push(newEmployee);

            this.setState({multipleRequests: true, newEmployees, name: "", email: ""});
        }
    };

    handleSubmit = multipleRequests => {
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {
            headers: {
                "x-sas-Auth": authToken
            }
        };

        this.setState({ loading: true })

        if (multipleRequests) {
            let newEmployees = this.state.newEmployees;
            const employeeOnForm = {
                email: this.state.email,
                name: this.state.name,
                role: this.state.role,
                location: this.state.location
            }

            if (employeeOnForm.email && employeeOnForm.name
            && employeeOnForm.role && employeeOnForm.location !== ""){
                newEmployees.push(employeeOnForm);
            }

            newEmployees.map(newEmployee => {
                const payload = {
                    email: newEmployee.email,
                    name: newEmployee.name,
                    role: newEmployee.role,
                    password: newEmployee.password
                };
                let location;
                if (this.props.type === "location") {
                    location = this.props.location;
                } else {
                    location = newEmployee.location.id;
                }

                axios.post(`${API_URL}/admin/stores/${this.state.store}/locations/${location}/employees`, payload, headers)
                .then(() => {
                    axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
                    .then(res => {
                        const employees = res.data.employees;
                        const totalOfEmployees = res.data.total;
                        this.props.updatedEmployees(employees, totalOfEmployees);
                        this.props.showFeedback(true);
                        this.props.newEmployees(newEmployees);
                        this.setState({
                            AddMoreMembers: true,
                            currentNewEmployeeNotAllowed: false,
                            loading: false
                        });
                    });
                })
                .catch((error) => {
                    if (error.response.status === 403){
                        this.setState({
                            currentNewEmployeeNotAllowed: true,
                            loading: false
                        })
                    }
                })
            });
        }

        let location,
            role;
        if (this.props.type === "location") {
            location = this.props.location;
            role = "seller"
        } else {
            location = this.state.location.id;
            role = this.state.role;
        }

        const payload = {
            email: this.state.email,
            name: this.state.name,
            role: role
        };

        if (this.isAlreadyAnEmployee(payload.email)){
            this.setState({
                currentNewEmployee: payload,
                currentNewEmployeeNotAllowed: true ,
                loading: false
            })

            return
        }

        axios
            .post(`${API_URL}/admin/stores/${this.state.store}/locations/${location}/employees`, payload, headers)
            .then(() => {
                axios
                .get(`${API_URL}/admin/stores/${store}/employees`, headers)
                .then(res => {
                    const employees = res.data.employees;
                    const totalOfEmployees = res.data.total;
                    this.props.updatedEmployees(employees, totalOfEmployees);
                    this.props.showFeedback(true);
                    this.props.newEmployees([payload]);
                    this.setState({ AddMoreMembers: true });
                })
            })
            .catch(() => {
                this.setState({ currentNewEmployeeNotAllowed: true })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    };

    handleDeleteNewEmployee = index => {
        let newEmployees = this.state.newEmployees;
        newEmployees.splice(index, 1);
        this.setState({newEmployees});
    };

    render() {
        const chatOnly = localStorage.getItem("chat_only")

        return (
            <React.Fragment>
                <>
                {this.state.loading ? (
                    <LoadingContainer>
                    <InlineLoading color={blueDefault} />
                  </LoadingContainer>
                ) : (
                    <>
                    {this.state.currentNewEmployeeNotAllowed && <Container pl="1em" mb="20px">
                    <Label color={red} fs="12px">This email is already being used by another employee.</Label>
                    </Container>}
                <BodyContainer className="new-member-body-modal">
                    <NewMembersList
                        newEmployees={this.state.newEmployees}
                        indexToBeDeleted={this.handleDeleteNewEmployee}/>
                    <NewMemberFormContainer>
                        <FormItem>
                            <label>Name</label>
                            <input
                                type="text"
                                placeholder="Add name"
                                className="form-input"
                                onChange={e => this.handleChange(e, "name")}
                                value={this.state.name}/>
                        </FormItem>

                        <FormItem>
                            <label>E-mail</label>
                            <input
                                type="email"
                                placeholder="Add e-mail"
                                className="form-input"
                                onChange={e => this.handleChange(e, "email")}
                                value={this.state.email}/>
                        </FormItem>
                        {!this.props.location && (
                            <React.Fragment>
                                <FormItem>
                                    <SelectFluid
                                        options={this.state.locations}
                                        placeholder={this.state.selectedLocationPlaceholder}
                                        justify="space-between"
                                        showFullName
                                        selectedOption={this.handleSelectedOption}/>
                                </FormItem>
                                {chatOnly == 0 && <FormItem>
                                    <RadioButton
                                        className="radio-button-new-member"
                                        options={[
                                            {
                                                label: "Manager",
                                                value: "manager"
                                            }, {
                                                label: "Salesperson",
                                                value: "seller"
                                            }
                                        ]}
                                        selectedOption={this.handleSelectedRole}/>
                                </FormItem>}
                            </React.Fragment>
                        )}
                        <FormItem>
                            <AddMoreMembers
                                onClick={this.handleMultipleEmployees}
                                enableMultipleRequests={this.state.enableMultipleRequests}/>
                        </FormItem>
                    </NewMemberFormContainer>
                </BodyContainer>
                <FooterContainer className="new-member-footer-modal">
                    <button
                        type="submit"
                        onClick={() => this.handleSubmit(this.state.multipleRequests)}>
                        add members
                    </button>
                </FooterContainer>
                </>
                )}
                </>
            </React.Fragment>
        );
    }
}
