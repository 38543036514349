import styled from "styled-components";
import { blueDefault } from "../../../Assets/colorPallet/colorPallet";

export const FilterContainer = styled.div`
  width: 100%;
  margin-right: ${props => (props.showSideFilter ? "0" : "-100%")};
  transition: margin-right 0.4s ease;
  position: fixed;
  right: 0;
  z-index: 150;
  height: 100vh;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const FilterBody = styled.div`
  width: 100%;
  max-width: 15em;
  margin: 2em auto;

  .humor-filter {
    margin-top: 2em;
    width: 100%;

    @media only screen and (max-width: 500px){
      margin-right: 1em;
      max-width: 12em;
      margin: 2em auto;
    }
  }

  .location-filter {
    margin-top: 2em;
    width: 100%;
  }

  .profile-filter {
    margin-top: 1em;
    width: 100%;
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  margin: auto auto 0 auto;
`;

export const ApplyFilterButtom = styled.button`
  border: none;
  background-color: ${blueDefault};
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 20px;
  margin-top: 4em;
  width: 100%;
  border-radius: 20em;
  cursor: pointer;
`;

export const NoReply = styled.button`
  outline: none;
  border: none;
  border-radius: 20em;
  height: 40px;
  background-color: ${props => props.noReplyActive ? "transparent" : "#e7e7e7"};
  border: ${props => props.noReplyActive ? "1px solid #025F88" : "1px solid transparent"};
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
`;
