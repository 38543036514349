import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #E6E6E6;
  border-radius: 20em;
  padding: 0 .5em;
  box-sizing: border-box;

  .search-input {
    font-weight: normal;
    outline: none;
    font-size: 14px;    
    width: 100%;
    padding: .5em;
    box-sizing: border-box;
    border: none;
    color: #333;
    &::placeholder {
      color: #999999;
    }
  }
`;
