import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


export default function MessagesRepliedChart(props) {
    const data = [
        {
          name: props.storeName, conversations: props.storeWebchatAnalytics.conversations, replied: props.storeWebchatAnalytics.repliedMessages,
        },
        {
          name: 'All stores', conversations: props.allStoresWebchatAnalytics.conversations, replied: props.allStoresWebchatAnalytics.repliedMessages,
        }
      ];


    return (
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="replied" fill="#8884d8" />
        <Bar dataKey="conversations" fill="#82ca9d" />
      </BarChart>
    );
}