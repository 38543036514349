import React from 'react';
import axios from 'axios';
import { API_URL } from '../config';

export default class ValidateWidgetOnly extends React.Component{

    componentDidMount(){
        const authToken = localStorage.getItem("token");
        const headers = {headers: {"x-sas-Auth" : authToken}};

        axios.get(`${API_URL}/private/sessions`, headers)
        .then(res => {
            this.props.onLoading(true)

            const widgetOnly = res.data.widget_only;
            const chatOnly = res.data.chat_only
            const { currentRoute } = this.props

            if (widgetOnly == 1 && chatOnly == 0) this.props.protectRoute("/store-location")
            if (chatOnly == 1 && currentRoute === "dashboard") this.props.protectRoute("/webchat")
            if (chatOnly == 1 && currentRoute === "locations-analytics") this.props.protectRoute("/webchat")
            if (chatOnly == 1 && currentRoute === "reports") this.props.protectRoute("/webchat")
   
            
        }).catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user_store");
            localStorage.removeItem("user_email");
            localStorage.removeItem("widget_only");
            localStorage.removeItem("chat_only");
            localStorage.removeItem("store");
            localStorage.removeItem("role");
            localStorage.removeItem("is_admin");
            localStorage.removeItem("logged_in");

            // window.location.pathname = '/login'
        })
        .finally(() => {
            setTimeout(() => {
                this.props.onLoading(false)
            }, 1000)
        })
    }


    

    render(){
        return this.props.children
    }
}