import React from "react";
import { RateResultContainer, TotalReviews, Rate } from "./styled";
import RatingBar from "./RatingBar/RatingBar";

export default class RateResult extends React.Component {
  render() {
    const starSvg = (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.83784 0L7.78378 4.51737H12L9.08108 7.64479L9.72973 12.5097L5.83784 9.67065L1.94595 12.5097L2.91892 7.64479L0 4.51737H3.89189L5.83784 0Z"
          fill="#B5B5B5"
        />
      </svg>
    );

    return (
      <RateResultContainer>
        <Rate>{this.props.rating}</Rate>
        {starSvg}
        <RatingBar ratePercentage={this.props.ratePercentage} />
        <TotalReviews>{this.props.totalReviewsByRate}</TotalReviews>
      </RateResultContainer>
    );
  }
}
