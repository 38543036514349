import React from "react";
import ReactSVG from "react-svg";
import { Row, Column, Grid } from "../../../../../Assets/Structure/Structure";
import { InvitationContainer, CustomerName, CustomerEmail } from "./styled";
import editIcon from "../../../../../Assets/icons/edit.svg";
import trashIcon from "../../../../../Assets/icons/trash.svg";

export default class Invitation extends React.Component {
  render() {

    const { invitation, deleteInvitation, editInvitation } = this.props;

    return (
      <InvitationContainer>
        <Row between alignCenter>
          <Column>
            <CustomerName>{invitation.customerName}</CustomerName>
            <CustomerEmail>{invitation.email || invitation.phone}</CustomerEmail>
          </Column>
          <Column>
            <Row>
              <Grid>
                <ReactSVG src={editIcon} onClick={editInvitation} />
                <ReactSVG src={trashIcon} onClick={deleteInvitation} />
              </Grid>
            </Row>
          </Column>
        </Row>
      </InvitationContainer>
    );
  }
}
