import React from 'react';
import axios from 'axios'
import { connect } from 'react-redux'
import {
    setCurrentChat,
    setChatCredentials,
    setNeedsResponseAmount,
    setNewMessagesAmount,
    setShowChatsOnMobile,
    setChatGroupsAmount
} from '../../../store/modules/webchat/actions'
import { setLocations } from "../../../store/modules/locations/actions"
import { WebchatMenuContainer, ListContainer, ListItem } from './styled';
import ChatInbox from '../../../Components/ChatInbox/ChatInbox';
import { WEBCHAT_API_URL, API_URL } from '../../../config';

class WebchatMenu extends React.Component{

    state = {
        currentChatId: "",
        chatGroupsMenu: [],
        chatCredentials: "",
        locations: [],
        loading: true
    }

    handleClick = (chatGroup, locationName) => {
        this.props.updateCurrentChat({
            chatId: chatGroup.chatId,
            locationId: chatGroup.locationId,
            clientNumber: chatGroup.clientNumber ? chatGroup.clientNumber : chatGroup.sentFrom,
            clientName: chatGroup.clientName,
            author: chatGroup.author,
            lastMessage: chatGroup.text,
            locationName
        })

        this.setState({ currentChatId: chatGroup.chatId })
        this.props.setShowChatsOnMobile(false)
        localStorage.setItem("chatId", chatGroup.chatId)
        localStorage.setItem("chatLocationId", chatGroup.locationId)
        localStorage.setItem("chatClientNumber", chatGroup.clientNumber)
        localStorage.setItem("chatAuthor", chatGroup.author)
        localStorage.setItem("chatLastMessage", chatGroup.text)
        localStorage.setItem("chatLocationName", locationName)
        localStorage.setItem("chatClientName", chatGroup.clientName)
    }

    componentDidMount(){
        const storeId = localStorage.getItem('store')
        const storeKey = localStorage.getItem('store_key')

        this.setState({ storeKey, storeId }, () => {
            this.getWebChatCredentials(storeKey)
        })
    }

    getWebChatCredentials = (storeKey) => {
        axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
        .then(res => {
            const accountSid = res.data[0].accountSid
            const twilioAuthToken = res.data[0].token
            const twilioNumber = res.data[0].number
            this.setState({ chatCredentials })
            const chatCredentials = { accountSid, twilioAuthToken, twilioNumber }
            this.fetchChatGroupsMenu(chatCredentials)
            this.props.setChatCredentials(chatCredentials)
        })
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.updatedChatGroups && nextProps.updatedChatGroups.length > 0) {
            this.setState({ chatGroupsMenu: nextProps.updatedChatGroups })
            return
        }
        if (nextProps.currentChat){
            this.setState({ currentChatId: nextProps.currentChat.chatId })
        }
        if (nextProps.updateChatGroupsMenu === true) {
            this.setOptmisticChatGroupUpdate(nextProps.currentChat)
        }

        if (this.props.filter !== nextProps.filter) {
            this.fetchChatGroupsMenu(this.props.chatCredentials, nextProps.filter)
        }
    }


    fetchChatGroupsMenu = (chatCredentials, filter) => {
        const storeId = localStorage.getItem('store');

        const headers = { headers: {"x-auth-token": chatCredentials.twilioAuthToken , "x-account-sid": chatCredentials.accountSid} }

        let filters = null;
        if (filter) filters = this.generateFilterString(filter)

        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/messages/menu?${filters ? filters : ''}`, headers)
        .then(res => {
            const { chatGroups } = res.data
            if (chatGroups && chatGroups.length === 0) localStorage.removeItem("chatId")

            this.setState({ chatGroupsMenu: chatGroups, currentChatId: "" })
            this.props.setChatGroupsAmount(chatGroups ? chatGroups.length : 0)
            this.props.setNeedsResponseAmount(res.data.meta.needsResponse)
            this.props.setNewMessagesAmount(res.data.meta.newMessages)
            this.fetchLocations()
        })
    }

    generateFilterString = (filters) => {
        let filterString = "";
        const amountOfFilters = Object.getOwnPropertyNames(filters).length
        let count = 0

        for (let filter in filters){
            if (filters.hasOwnProperty(filter)){
                filterString = filterString + filter + "=" + filters[filter] + "&&"
                count += 1
            }

            if (count === amountOfFilters) return filterString
        }
    }

    fetchLocations = () => {
        const storeId = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = { headers: { "x-sas-Auth": authToken } };

        axios.get(`${API_URL}/admin/stores/${storeId}/locations`, headers)
        .then((res) => {
            this.props.setLocations(res.data)
            this.setState({ locations: res.data })
        })
        .finally(() => {
            setTimeout(() => {
                this.setState({ loading: false })
            }, 300)
        })
    }

    setOptmisticChatGroupUpdate = (currentChat) => {
        let chatGroupsMenu = this.state.chatGroupsMenu

        chatGroupsMenu.find((chatGroup, index) => {
            if (chatGroup.chatId === currentChat.chatId){
                chatGroup.lastMessage = currentChat.lastMessage
                chatGroup.createdAt = currentChat.createdAt

                chatGroupsMenu.splice(index, 1)
                chatGroupsMenu.unshift(chatGroup)

                return chatGroup
            }
        })

        this.setState({ chatGroupsMenu })

    }


    render(){
        const { chatGroupsMenu, locations, loading } = this.state;

        return (
            <WebchatMenuContainer>
                <ListContainer>
                    {chatGroupsMenu ? chatGroupsMenu.map((chatGroup, index) => {
                        const location = locations.find(location => location.id === chatGroup.locationId)

                        return (
                            <ListItem key={index} active={this.props.currentChat.chatId === chatGroup.chatId ? true : false}>
                                <ChatInbox
                                loading={loading}
                                location={location}
                                message={chatGroup}
                                onClick={() => this.handleClick(chatGroup, location ? location.name : "")} />
                            </ListItem>
                        )
                    }) : null}
                </ListContainer>
            </WebchatMenuContainer>
        )
    }
}

const mapStateToProps = state => ({
    currentChat: state.webchat.currentChat,
    updateChatGroupsMenu: state.webchat.updateChatGroupsMenu,
    filter: state.webchat.filter,
    chatCredentials: state.webchat.chatCredentials,
    updatedChatGroups: state.webchat.updatedChatGroups
})


const mapDispatchToProps = dispatch => ({
    updateCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },
    setChatCredentials(chatCredentials){
        dispatch(setChatCredentials(chatCredentials))
    },
    setNeedsResponseAmount(amount){
        dispatch(setNeedsResponseAmount(amount))
    },
    setChatGroupsAmount(amount){
        dispatch(setChatGroupsAmount(amount))
    },
    setNewMessagesAmount(amount){
        dispatch(setNewMessagesAmount(amount))
    },

    setLocations(locations){
        dispatch(setLocations(locations))
    },

    setShowChatsOnMobile(showChatsOnMobile){
        dispatch(setShowChatsOnMobile(showChatsOnMobile))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(WebchatMenu)
