import React from 'react';
import ReactSVG from 'react-svg';
import { SendMoreInvitationsButton } from './styled';
import addIcon from '../../../../Assets/icons/add.svg';
import { Label } from '../../../../Assets/Structure/Structure';

export default class SendMoreInvitations extends React.Component{
    render(){

        const { onClick, formIsFilled } = this.props;

        return(
            <SendMoreInvitationsButton onClick={onClick} formIsFilled={formIsFilled}>
                <ReactSVG src={addIcon} />
                <Label color="rgba(153, 153, 153, 0.5)" fontSize="16px">Send invitation to more people</Label>
            </SendMoreInvitationsButton>
        )
    }
}