import React from 'react';
import { BarContainer, BarPool, BarFilling } from './styled';

export default class Bar extends React.Component{
    render(){
        return(
            <BarContainer>
                <BarPool>
                    <BarFilling bgColor={this.props.bgColor} percentage={this.props.percentage} />
                </BarPool>
            </BarContainer>
        )
    }
}