import styled from "styled-components";
import {
  blueDefault,
  blueDefaultHover
} from "../../Assets/colorPallet/colorPallet";

export const ListModuleContainer = styled.div``;

export const LocationContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(100% - 21em);

  @media only screen and (max-width: 700px) {
    padding: 0 0 1em;
    border-bottom: 1px solid #ccc;
    max-width: 100%;
  }

  .mobile-container {
    .location-name-container {
      display: flex;
      align-items: center;

      svg {
        path {
          transform: translate(0, 18%);
        }

        @media only screen and (max-width: 500px){
          margin-right: .5em;
          height: 30px;
        }
      }

      h2 {
        font-weight: normal;
        line-height: 34px;
        font-size: 18px;
        letter-spacing: 0.1em;
        margin: 0;
        color: #333333;
        font-family: "Roboto", sans-serif;
      }
    }

    p {
      margin: 0;
      font-weight: normal;
      line-height: 26px;
      font-size: 14px;
      letter-spacing: 0.05em;
      font-family: "Roboto", sans-serif;
      color: #999999;
    }
  }
`;

export const IntegratedPlatformsContainer = styled.div`
  padding: 0 2em;
  border-left: 1px solid #ccc;
  width: 100%;
  max-width: 16em;

  h3 {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1em;
    font-family: "Roboto", sans-serif;
    color: #999999;
    margin: 0;

    span {
      color: ${blueDefault};
      margin-left: 1em;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 10px;
      cursor: pointer;

      @media only screen and (max-width: 500px){
        padding: .3em 1.2em;
      }
    }
  }

  .btn-integrate-website {
    font-size: 14px;
    font-weight: normal;
    transition: background-color 0.4s ease;

    &:hover {
      background-color: ${blueDefaultHover};
    }

    @media only screen and (max-width: 500px){
      width: 100%;
    }
  }

  @media only screen and (max-width: 700px) {
    margin: 1em 0 0 0;
    padding: 0;
    border-left: none;
    width: 100%;
    padding-bottom: 1em;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    max-width: 100%;

    a {
      width: 100%;

      span {
        margin: 0 auto;
      }
    }
  }
`;

export const MembersContainer = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  padding: 0 0 0 2em;
  border-left: 1px solid #ccc;

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #999999;
    display: flex;
    align-items: center;

    span {
      margin-left: 0.5em;
    }
  }

  svg {
    margin-left: 0.5em;
    align-items: center;
    display: flex;
    height: 1em;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 2em;
    padding: 0;
    border-left: none;
  }
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const LocationInfoContainer = styled.div`
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2,
  p {
    margin: 0;
  }
  h2 {
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #333333;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
  }

  p {
    font-size: 14px;
    margin-top: 5px;
    font-weight: normal;
    letter-spacing: 0.05em;
    font-family: "Roboto", sans-serif;
    color: #999999;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IntegratedPlatforms = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const Platform = styled.div`
  border: ${props =>
    props.platform === "google"
      ? "1px solid #FE5943"
      : props.platform === "facebook"
      ? "1px solid #3B5998"
      : null};
  border-radius: 50px;
  font-size: 13px;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  padding: 0.2em 1em;
  letter-spacing: 0.05em;
  background-color: ${props =>
    props.platform === "google"
      ? "#FE5943"
      : props.platform === "facebook"
      ? "#3B5998"
      : props.platform === "yahoo"
      ? "#5631D6"
      : props.platform === "yelp"
      ? "#BF1712"
      : null};

  &:not(:first-child) {
    margin-left: 1em;
  }
`;

export const StoreKeyContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 9em;
`;
