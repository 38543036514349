// LIBRARIES
import React from 'react'
import axios from 'axios'
import ReactSVG from 'react-svg'

// CONFIG
import { API_URL } from '../../../config'

// STRUCTURE COMPONENTS
import { ListContainer, ListItem, Container, Label } from '../../../Assets/Structure/Structure'

// COMPONENTS
import Button from '../../../Components/Button/Button'
import SelectFluid from '../../SelectFluid/SelectFluid'

// STYLE
import { ImportedContactsListContainer } from './styled'

// UTILS
import { validateEmail } from '../../../utils/validateEmail'

// ASSETS
import addIcon from "../../../Assets/icons/add.svg"
import sendIcon from '../../../Assets/icons/send.svg'
import closeIcon from '../../../Assets/icons/close.svg'
import { blueDefault, blueDefaultHover } from '../../../Assets/colorPallet/colorPallet'


export default class ImportedContactsList extends React.Component{

    state = {
        locationsPlaceholder: "Select location",
        locations: []
    }

    componentDidMount() {
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");

        if (!this.props.onlyList) {
            axios.get(`${API_URL}/admin/stores/${store}/locations`, {
                    headers: {
                        "x-sas-Auth": authToken
                    }
            })
            .then(res => {
                const locations = res.data.map(location => {
                    return {
                        id: location.id,
                        label: location.name,
                        value: location.name,
                        services: location.review_services
                    };
                });

                this.setState({
                    locations,
                    loading: false
                });
            });
        }
    }

    handleDelete = (contact) => {
        let importedContacts = this.props.importedContacts;
        importedContacts = importedContacts.filter(obj => obj !== contact);
        this.props.updateContacts(importedContacts);
    }

    handleSelectedLocation = location => {
        this.setState({
            locationPlaceholder: location.label,
            selectedLocation: location.id
        })
    }

    render(){
        return(
            <ImportedContactsListContainer>
                <Container>
                    {!this.props.onlyList && <Container padding="2em">
                    <SelectFluid
                    justify="space-between"
                    options={this.state.locations}
                    selectedOption={this.handleSelectedLocation}
                    placeholder={this.state.locationsPlaceholder} />
                </Container>}
                    <ListContainer maxHeight="30vh" padding="0 2em">
                        {this.props.importedContacts.map(contact => {
                            return (
                                <ListItem>
                                    <Container flex between alignCenter>
                                        <Container maxWidth="14em">
                                            <Label color="#333">{contact.name}</Label>
                                        </Container>
                                        <Container maxWidth="20em">
                                            <Label color="#333">
                                                {validateEmail(contact.email) ? contact.email : contact.phone}
                                            </Label>
                                        </Container>
                                        <Container>
                                            <ReactSVG
                                            onClick={() => this.handleDelete(contact)}
                                            src={closeIcon}
                                            className="close-icon" />
                                        </Container>
                                    </Container>
                                </ListItem>
                            )
                        })}
                    </ListContainer>
                </Container>
                <Container m="auto auto 0 auto">
                    <Container
                    flex
                    forceBottom
                    fixedOnMobile
                    mt="2em"
                    alignCenter
                    justifyEnd >
                    <Button
                    color="#333"
                    bgColorOnHover="#eee"
                    borderRadius="20em"
                    height="40px"
                    padding="0"
                    width="150px"
                    text="Cancel"
                    onClick={() => this.props.cancel(false)}
                    transparent />
                    <Container ml="20px">
                        <Button
                        text={this.props.onlyList ? "Add Contacts" : "Send"}
                        bgColor={blueDefault}
                        className="tag-send-review-request"
                        color="white"
                        borderRadius="20em"
                        hasIcon
                        padding="0"
                        flex
                        alignCenter
                        height="40px"
                        iconColor="white"
                        width="220px"
                        widthOnMobile="150px"
                        iconPath={this.props.onlyList ? addIcon : sendIcon}
                        bgColorOnHover={blueDefaultHover}
                        onClick={() => this.props.sendInvitationToImportedContacts(this.props.importedContacts, this.state.selectedLocation)}/>
                    </Container>
                    </Container>
                </Container>
            </ImportedContactsListContainer>
        )
    }
}
