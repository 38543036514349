import styled from 'styled-components';

export const TableContainer = styled.div `
    margin-top: 2em;
    overflow-x: auto;
    background-color: white;
`;


export const ReportTable = styled.table `
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
`;

export const TableRow = styled.tr `
    
`;

export const TableHeader = styled.th `
    text-align: left;
    padding: .2em .2em 2em .2em;
    box-sizing: border-box;
    color: #999999;
    letter-spacing: 0.1em;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;

`;

export const TableData = styled.td `
    padding: .2em;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 14px;
    white-space: nowrap;
    letter-spacing: 0.05em;
    color: #333333;
    text-align: ${props => props.textCenter ? "center" : null};

    .check-icon{
        svg{
            path{

            fill: #888;
            }
        }
    }

    svg{
        width: 15px;
        height: 15px;
    }
`;

export const TableSkeletonContainer = styled.div`
    padding: 1em;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
    margin-top: 2em;
    background-color: white;
    grid-row-gap: 1em;
`;

export const EmptyFeedbackContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;