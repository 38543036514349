import React from 'react';
import { RadioItemContainer, Circle, InsideCircle } from './styled';

export default class RadioItem extends React.Component{


    render(){

        const { option, onClick, isActive } = this.props;

        return(
            <RadioItemContainer onClick={onClick}>
                <Circle>
                    <InsideCircle active={isActive}/>
                </Circle>
                <label>{option.label}</label>
            </RadioItemContainer>
        )
    }
}