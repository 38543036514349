import styled from "styled-components";

export const OverviewContainer = styled.div`
    width: 100%;
    max-width: 18em;
    background-color: white;
    border-radius: 5px;
    margin-top: -2em;
    box-shadow: 0px 6px 50px rgba(0,0,0,0.15);

  .header{
    padding-bottom: 1em;
    border-bottom: 1px solid #e6e6e6;
    padding: 1em;
    box-sizing: border-box;
  }

  h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: #666666;
  }

  label {
    font-size: 16px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #333333;
  }

  @media only screen and (max-width: 500px){
    max-width: 100%;
  }

`;

export const ReviewsContainer = styled.div`
    width: 100%;
    max-width: 10em;
    margin: 2em auto;

    @media only screen and (max-width: 500px){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0;
      padding: 1em;
      box-sizing: border-box;
      max-width: 100%;

      p{
        display: none;
      }
    }
`;

export const TotalAverageRateContainer = styled.div`
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  background-color: #eee;
`;

export const RowJustifyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;