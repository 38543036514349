import React from "react";
import {LastReviewsContainer, Label, Container, SkeletonContainer} from "./styled";
import Review from "./Review/Review";
import ReviewSkeleton from "../Review/ReviewSkeleton";

export default class LastReviews extends React.Component {
    state = {
        reviews: this.props.reviews
    };

    componentDidMount() {
        this.setState({reviews: this.props.reviews});
    }

    componentWillReceiveProps(props) {
        this.setState({reviews: props.reviews});
    }

    render() {
        return (
            <Container className={this.props.className} style={this.props.style}>
                {!this.props.noLabel && <Label>Latest Reviews</Label>}
                <LastReviewsContainer noLabel={this.props.noLabel}>
                    {this.props.loading
                        ? (
                            <SkeletonContainer>
                                <ReviewSkeleton/>
                                <ReviewSkeleton/>
                                <ReviewSkeleton/>
                            </SkeletonContainer>
                        )
                        : (this.state.reviews.map(review => {
                            return (<Review
                                clientName={review.author}
                                location={review.location_name}
                                date={review.time}
                                text={review.text}
                                rating={review.rating}
                                platform={review.review_service}/>);
                        }))}
                </LastReviewsContainer>
            </Container>
        );
    }
}
