import styled from "styled-components"
import { blueDefault } from "../../../../Assets/colorPallet/colorPallet"

export const MarketingCampaignListItem = styled.li`
  list-style: none;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:not(:first-child) {
    margin-top: 5px;
  }
  
  @media only screen and (max-width: 1372px) {
    flex-wrap: wrap;
    
    .marketing-campaign-list-item--right-column{
      justify-content: flex-start;
      margin-top: 20px;
      
      &__item{
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }

  .delete-campaign {
    margin-right: 10px;
  }

  .edit-campaign {
    margin-right: 10px;
  }
`

