import React from "react";
import ReactSVG from 'react-svg';
import { OrderByContainer, Overlay} from "./styled";
import OrderByOptions from "./OrderByOptions/OrderByOptions";
import orderByIcon from '../../Assets/icons/orderby.svg';

export default class OrderBy extends React.Component {
  state = {
    active: false
  };

  showOrderByOptions = (active) => {
    this.setState({ active });
    this.detectClick();
  };

  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById(this.props.id);
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ active: false });
    });
  };

  handleSelectedOrderByOption = (option) => {
    this.props.selectedOrderByOption(option);
    this.setState({ active: false })
  }

  render() {
    return (
      <div id={this.props.id}>
        <OrderByContainer onClick={() => this.showOrderByOptions(true)}>
          <ReactSVG src={orderByIcon} />
        </OrderByContainer>
        {this.state.active && <OrderByOptions selectedOrderByOption={this.handleSelectedOrderByOption} />}
      </div>
    );
  }
}
