import styled from "styled-components";
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';

export const AddMoreMembersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  cursor: pointer;

  svg{
    margin-right: .5em;
    path{
      fill: ${props => props.enableMultipleRequests ? blueDefault : "#ccc"};
    }
  }

  label{
    color: ${props => props.enableMultipleRequests ? blueDefault : "#ccc"};
    cursor: pointer;
  }

`;
