import React from 'react';
import { ModuleContainer } from './styled';

export default class Module extends React.Component{
    render(){

        const { style, className, noShadow, noPadding, maxWidth } = this.props;

        return(
            <ModuleContainer maxWidth={maxWidth} style={style} className={className} noShadow={noShadow} noPadding={noPadding}>
                {this.props.children}
            </ModuleContainer>
        )
    }
}