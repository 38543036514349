import React from 'react';
import axios from "axios";
import ReactSVG from "react-svg";
import { createBrowserHistory } from 'history';
import Modal from "../../Modal/Modal";
import Button from "../../Button/Button";
import { HeaderContainer, ActionContainer, ScrollContainer, LoadingContainer } from "./styled";
import closeIcon from "../../../Assets/icons/close.svg";
import Loading from "../../../Components/Loading/Loading";
import InvitationForm from "./InvitationForm/InvitationForm";
import {
  blueDefault,
  blueDefaultHover
} from "../../../Assets/colorPallet/colorPallet";
import sendIcon from "../../../Assets/icons/send.svg";
import MultipleInvitation from "./MultipleInvitations/MultipleInvitations";
import { API_URL } from "../../../config";
import InlineLoading from '../../../Components/InlineLoading/InlineLoading';
import InvitationFeedback from './InvitationFeedback/InvitationFeedback';
import ImportContacts from '../../ImportContacts/ImportContacts';
import { Container } from '../../../Assets/Structure/Structure';
import uploadIcon from '../../../Assets/icons/upload.svg';
import { sendInvitationsToImportedContacts } from '../../../utils/sendInvitationsToImportedContacts';

export default class ReviewInvitation extends React.Component {
  state = {
    invitations: [],
    phone: "",
    email: "",
    customerName: "",
    locationId: "",
    invitationToBeUpdated: "",
    show: true,
    sentWithSuccess: "",
    invitationOnForm: {},
    importContacts: false,
    loading: false
  };


  handleCloseModal = isModalOpen => this.props.isModalOpen(isModalOpen);

  handleMultipleInvitations = invitations => this.setState({ invitations });

  handleDeleteInvitation = index => {
    let invitations = this.state.invitations;

    invitations.splice(index, 1);
    this.setState({ invitations, invitationToBeUpdated: "" });
  };

  handleUpdateInvitation = index => {
    let invitationToBeUpdated = this.state.invitations[index];
    invitationToBeUpdated = {
      customerName: invitationToBeUpdated.customerName,
      email: invitationToBeUpdated.email,
      locationId: invitationToBeUpdated.locationId,
      index: index
    };
    this.setState({ invitationToBeUpdated }, () =>
      this.handleDeleteInvitation(index)
    );
  };

  handleFormData = (type, data) => {
    if (type === "phone") this.setState({ phone: data });
    if (type === "contactType") this.setState({ contactType: data });
    if (type === "email") this.setState({ email: data });
    if (type === "customerName") this.setState({ customerName: data });
    if (type === "locationId") this.setState({ locationId: data });
  };

  handleSubmit = () => {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    let payload;
    if (this.state.contactType === "email") {
      payload = {
        email: this.state.email,
        name: this.state.customerName
      };
    }

    if (this.state.contactType === "phone") {
      const phone = "1" + this.state.phone;
      payload = {
        phone: parseInt(phone),
        name: this.state.customerName
      };
    }

    if (this.state.invitations.length > 0) {
      this.handleMultipleRequests();
    } else {

      this.setState({ loading: true, invitations: [payload] });
      axios
        .post(
          `${API_URL}/private/stores/${store}/locations/${
            this.state.locationId
          }/review_requests`,
          payload,
          headers
        )
        .then(() => {
          this.setState({ loading: false, sentWithSuccess: true });
          this.getAndUpdateReviewRequests(store, headers);

          const history = createBrowserHistory();
          history.push({ search: '?request_sent' })
        });
    }
  };

  handleMultipleRequests = () => {
    this.setState({ loading: true });
    let invitations = this.state.invitations;
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth": authToken } };

    const { invitationOnForm } = this.state;

    invitations.push(invitationOnForm);
    invitations.map((invitation, index) => {
      let payload;
      if (invitation.phone) {
        const phone = "1" + invitation.phone;
        payload = {
          name: invitation.customerName || invitation.client,
          phone: parseInt(phone)
        };
      }

      if (invitation.email) {
        payload = {
          name: invitation.customerName || invitation.client,
          email: invitation.email
        };
      }


      axios.post(`${API_URL}/private/stores/${store}/locations/${invitation.locationId}/review_requests`,payload, headers)
      .then(() => {
        if (index === invitations.length - 1) this.setState({ loading: false, sentWithSuccess: true })
        this.getAndUpdateReviewRequests(store, headers);

        const history = createBrowserHistory();
        history.push({ search: '?request_sent' })
      });
    });
  };

  getAndUpdateReviewRequests = (store, headers) => {
    axios.get(`${API_URL}/private/stores/${store}/review_requests`, headers)
    .then(res => {
      const requests = res.data.requests;
      const totalReviewRequests = res.data.total;
      this.props.updateReviewRequests(requests, totalReviewRequests);
    })
  }

  handleInvitationOnForm = (invitationOnForm) => {
    this.setState({ invitationOnForm })
  }

  handleImportContactsMode = (isModeOn) => {
    this.setState({ importContacts: isModeOn })
  }

  sendInvitationToImportedContacts = (importedContacts, location) => {
    this.setState({ loading: true })
    const sentWithSuccess = sendInvitationsToImportedContacts(importedContacts, location);
    if (sentWithSuccess) this.handleSentWithSuccess();
  }

  handleSentWithSuccess = () => {
    this.setState({
      loading: false,
      sentWithSuccess: true
    })
  }

  render() {
    return (
        <Modal
          isModalOpen={this.handleCloseModal}
          style={this.state.style}
          sent={this.state.sentWithSuccess}
          onTransitionEnd={this.transitionEnd}
          backgroundColor="white"
          className="review-invitation-modal"
          showClose={this.state.loading || this.state.sentWithSuccess ? false : true}
          title="Send Review Invitation"
        >
          <Container padding="40px" className="review-invitation--modal-boby-container">
          {this.state.loading ? (
            <LoadingContainer>
              <InlineLoading color={blueDefault} />
            </LoadingContainer>
          ) : this.state.sentWithSuccess ? (
              <InvitationFeedback isModalOpen={this.handleCloseModal} invitations={this.state.invitations}/>
            ) : this.state.importContacts ? (
              <ImportContacts
              sendInvitationToImportedContacts={this.sendInvitationToImportedContacts}
              cancel={this.handleImportContactsMode} />
            ) : (
            <React.Fragment>
              <Container padding="0 1em">
                <Button
                uppercase
                hasIcon
                iconWidth="20px"
                iconColor={blueDefault}
                iconPath={uploadIcon}
                onClick={this.handleImportContactsMode}
                bgColor="transparent"
                bgColorOnHover="transparent"
                color={blueDefault}
                fontSize="12px"
                padding="0"
                text="Import Contacts" />
              </Container>
              <ScrollContainer>
                {this.state.invitations.length > 0 && (
                  <MultipleInvitation
                    invitations={this.state.invitations}
                    invitationToBeDeleted={this.handleDeleteInvitation}
                    invitationToBeUpdated={this.handleUpdateInvitation}
                  />
                )}
                <InvitationForm
                  invitations={this.handleMultipleInvitations}
                  phone={this.handleFormData}
                  isModalOpen={this.handleCloseModal}
                  email={this.handleFormData}
                  customerName={this.handleFormData}
                  locationId={this.handleFormData}
                  contactType={this.handleFormData}
                  invitationToBeUpdated={this.state.invitationToBeUpdated}
                  invitationOnForm={this.handleInvitationOnForm}
                />
              </ScrollContainer>
              <ActionContainer>
                <Button
                  text="Send"
                  uppercase
                  bgColor={blueDefault}
                  className="tag-send-review-request"
                  color="white"
                  borderRadius="20em"
                  hasIcon
                  width="300px"
                  iconPath={sendIcon}
                  bgColorOnHover={blueDefaultHover}
                  onClick={this.handleSubmit}
                />
              </ActionContainer>
            </React.Fragment>
          )}
          </Container>
        </Modal>
    );
  }
}
