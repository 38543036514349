import styled from 'styled-components'

export const BeaconFormatContainer = styled.div`
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
  background-color: #f4f4f4;
  margin-top: 1em;
`

export const RowContainer = styled.div`
  display: flex;

  .step {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #2196f3 !important;
    display: flex;
    color: #2196f3;
    justify-content: center;
    align-items: center;
  }

  p {
    width: 100%;
    margin-left: 1em;
    font-size: 14px;
    line-height: 24px;
    max-width: 40em;
    color: #333;

    @media only screen and (max-width: 700px) {
      max-width: 13em;
    }
  }

  .container{
      max-width: 34em;

      @media only screen and (max-width: 700px) {
      max-width: 13em;
    }
  }

  .code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    img {
      width: 70%;
    }

    @media only screen and (max-width: 700px){
        img{
          width: 100%;
        }
      }
  }
`
