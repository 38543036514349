import styled from 'styled-components';

export const RatingBarContainer = styled.div`
    width: 100%;
    background-color: #E6E6E6;
    height: .25em;
    max-width: 6em;
    margin-left: .5em;

    @media only screen and (max-width: 800px){
        max-width: 100%;
    }
`;

export const FillingBar = styled.div`
    width: ${props => props.ratePercentage}%;
    background-color: #B5B5B5;
    height: .25em;
`;