// Libraries
import React, { useState } from "react"
import { connect } from "react-redux"
import {Dialog} from "@material-ui/core";
import {Container, Input, Label, Span} from "../../../../Assets/Structure/Structure";
import Button from "../../../../Components/Button/Button";

// Store actions
import { validateAccessCode } from "../../../../store/modules/videoChat/actions"


function RequestVideoRoomPasswordDialog({ active, videoRoomId, validateAccessCode, onSuccess }) {
    const [accessCode, setAccessCode] = useState("")
    const [invalidAccessCode, setInvalidAccessCode] = useState(false)

    const handleAccessCodeOnChange = (e) => {
        setAccessCode(e.target.value)
    }

    const handleJoinVideoCall = async (e) => {
        e.preventDefault()

        const accessCodeIsValid = await validateAccessCode(videoRoomId, accessCode)

        if (accessCodeIsValid) {
            setInvalidAccessCode(false)
            onSuccess()
        } else {
            setInvalidAccessCode(true)
        }
    }


    return (
        <Dialog
            style={{ borderRadius: "20em" }}
            open={active}
            maxWidth="sm"
            fullWidth>
            <Container padding="40px">
                <Container mt="10">
                    <form onSubmit={handleJoinVideoCall}>
                        <Label>Please insert the <Span bold>access code</Span> to join the video call</Label>
                        <Container mt="10px">
                            <Input
                            value={accessCode}
                            placeholder="Add access code"
                            required
                            onChange={handleAccessCodeOnChange}/>
                        </Container>
                        {invalidAccessCode && (
                            <Container mt="10px">
                                <Label fontSize="12px" color="red">Invalid access code</Label>
                            </Container>
                        )}
                        <Container mt="20px">
                            <Button
                                type="submit"
                                color="white"
                                borderRadius="20em"
                                height="40px"
                                text="Join Video Call"
                                padding="0 20px" />
                        </Container>
                    </form>
                </Container>
            </Container>
        </Dialog>
    )
}

const mapDispatchToProps = dispatch => ({
    async validateAccessCode(videoRoomId, accessCode) {
        const accessCodeIsValid = await dispatch(validateAccessCode(videoRoomId, accessCode))
        return accessCodeIsValid
    }
})

export default connect(null, mapDispatchToProps)(RequestVideoRoomPasswordDialog)
