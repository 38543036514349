import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from '../../../../Components/Modal/Modal'
import { Container, Label, Input, Text } from '../../../../Assets/Structure/Structure'
import Button from '../../../../Components/Button/Button'
import { API_URL, WEBCHAT_API_URL } from '../../../../config'
import SelectFluid from '../../../../Components/SelectFluid/SelectFluid'

export default function AddPhoneNumberDialog(props){
    
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState(localStorage.getItem("user_name"))
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [teamMembers, setTeamMembers] = useState([])
    const [selectedTeamMemberPlaceholder, setSelectedTeamMemberPlaceholder] = useState("Select a team member")

    useEffect(() => {
        fetchTeamMembers()
    }, [])

    const fetchTeamMembers = () => {
        const store = localStorage.getItem("store");
        const authToken = localStorage.getItem("token");
        const headers = {headers: { "x-sas-Auth" : authToken }};

        const endpoint = `${API_URL}/admin/stores/${store}/employees`

        axios.get(endpoint, headers)
        .then(res => {
            const teamMembersAmount = res.data.total
            const pagesAmount = Math.ceil(teamMembersAmount / 20)
            
            for(let page = 1; page <= pagesAmount; page += 1){
                axios.get(`${endpoint}?page=${page}`, headers)
                .then(response => {
                    setTeamMembers(teamMembers.concat(response.data.employees))
                })
            }
        })
    }

    const handlePhoneNumber = e => setPhoneNumber(e.target.value)

    const handleName = e => setName(e.target.value)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        const payload = { number: phoneNumber, name, isManager: props.currentPhoneIsManager, userId }
        const storeId = localStorage.getItem("store")

        axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/phoneNumber/`, payload)
        .then(() => {
            props.onSuccess(payload)
        })
        .catch(() => {})
        .finally(() => {
            props.onClose(false)
            setLoading(false)
        })

    }

    const handleCancel = () => {
        if (props.storeDoesNotHasManagerPhoneNumber) return
        props.onClose(false)
    }

    const handleSelectedTeamMember = (teamMember) => {
        const teamMemberName = teamMember.name
        setSelectedTeamMemberPlaceholder(teamMemberName)
        setName(teamMemberName)
        setUserId(teamMember.id)
    }


    return (
        <Modal  
        doNotClose={props.storeDoesNotHasManagerPhoneNumber} 
        showClose title={props.title} 
        isModalOpen={() => props.onClose(false)}>
            {props.storeDoesNotHasManagerPhoneNumber && <Container ml="1em">
                    <Label fontSize="12px" color="red">
                        Please add a manager phone number in order to activate your webchat
                    </Label>
                </Container>}
            <form onSubmit={handleSubmit} style={{ padding: "1em" }}>
                <Container mt="20px">
                {props.currentPhoneIsManager ? (
                    <>
                    <Label>Name:</Label>
                    <Container mt="10px">
                        <Input type="text" onChange={handleName} value={name} required/>
                    </Container>
                    </> 
                ) : (
                    <Container mt="10px">
                        <SelectFluid 
                        showFullName
                        selectedOption={handleSelectedTeamMember}
                        avatar={true}
                        placeholder={selectedTeamMemberPlaceholder} 
                        options={teamMembers}/>
                    </Container> 
                )}
                </Container>
                <Container mt="20px">
                    <Label>Phone number:</Label>
                    <Container mt="10px">
                        <Input type="number" onChange={handlePhoneNumber} value={phoneNumber} required/>
                    </Container>
                </Container>
                <Container flex justifyEnd alignCenter mt="40px">
                    <Container flex alignCenter>
                        <Container>
                            <Button 
                            padding="0"
                            width="120px"
                            height="46px"
                            onClick={handleCancel}
                            borderRadius="5px"
                            color="#333"
                            bgColor="#ddd"
                            bgColorOnHover="#ccc"
                            text="Cancel" />
                        </Container>
                        <Container ml="10px">
                            <Button 
                            padding="0"
                            width="120px"
                            height="46px"
                            loading={loading}
                            borderRadius="5px"
                            color="white"
                            type="submit"
                            text="Save" />
                        </Container>
                    </Container>
                </Container>
            </form>
        </Modal>
    )
}