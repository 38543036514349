import styled from 'styled-components';

export const LoadingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: ${props => props.height ? `${props.height}` : null};

    img{
        width: ${props => props.imageWidth};
    }
`;