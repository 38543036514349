import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

export default class BarChartSimple extends React.Component{

  state = {
    width: this.props.width,
  }

  componentDidMount(){
    this.setState({ width: this.props.width })
  }

  componentWillReceiveProps(props){
    this.setState({ width: props.width })
  }

	render () {

    const data = this.props.last7Days.map(review => {
      return {
        date: review.day,
        positive: parseInt(review.positive),
        neutral: parseInt(review.neutral),
        negative: parseInt(review.negative)
      }
    });

  	return (
      <BarChart 
       width={this.state.width} 
       height={250} data={data} 
       className={this.props.className}
       margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <CartesianGrid strokeDasharray="3 3"/>
       <XAxis dataKey="date"/>
       <YAxis type="number" allowDecimals={false} />
       <Tooltip/>
       <Bar dataKey="positive" fill="#44CA61" barSize={6} />
       <Bar dataKey="neutral" fill="#449BC1" barSize={6} />
       <Bar dataKey="negative" fill="#E97373" barSize={6} />
      </BarChart>
    );
  }
}