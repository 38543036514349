import styled from "styled-components";

export const AllReviewsContainer = styled.div`
  width: 100%;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 1em;
  margin-top: 2em;
  box-sizing: border-box;
  display: flex;


  .bar-chart-container {
    margin-left: 4em;

    svg {
      width: 1000px;
    }
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    padding: 0;
    
    .recharts-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .bar-chart-container {
      margin-left: 0;

      svg {
        width: auto !important;
      }
    }
  }
`;
