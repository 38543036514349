import styled from 'styled-components';

export const WebchatMenuContainer = styled.div`
    width: 100%;
    background-color: white;
    border-right: 1px solid #E6E6E6;
    z-index: 1500;

    @media only screen and (max-width: 600px){
        margin-top: 4em;
    }
`;

export const ListContainer = styled.ul`
    margin: 0;
    padding: 0;
    max-height: calc(100vh - 10em - 5em - 80px);
    min-height: calc(100vh - 10em - 5em - 80px);
    overflow: auto;

    &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }

    @media only screen and (max-width: 600px){
        max-height: calc(100vh - 91px - 4em);
        min-height: calc(100vh - 91px - 4em);
    }
`;

export const ListItem = styled.li`
    list-style: none;
    cursor: pointer;
    background-color: ${props => props.active ? "#eee" : "white"};

    &:hover{
        background-color: #eee;
    }
`;