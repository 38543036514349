import styled from "styled-components";

export const LocationRateOverviewContainer = styled.div`
  background-color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 100%;
`;

export const HeroContainer = styled.div`
  padding: 1em;
  box-sizing: border-box;
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: cover;
  height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${props => (props.between ? "space-between" : null)};
`;

export const Column = styled.div``;

export const TotalReviews = styled.p`
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #888888;
  margin: 0.5em 0 0 0;

  span {
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #333333;
  }
`;

export const Wrapper = styled.div`
  padding: 1em;
  box-sizing: border-box;
`;

export const TeamMembers = styled.label`
  font-size: 14px;
  color: #999999;
  margin-left: 0.5em;
`;

export const RatingsContainer = styled.div`
  display: flex;
  border-top: 1px solid #e6e6e6;
  align-items: center;
  justify-content: space-between;

  div:nth-child(2) {
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
  }
`;

export const AverageRating = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #ffffff;
  }

  svg {
    width: 12px;
    margin-left: 0.5em;
  }
`;

export const LocationName = styled.label`
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin-top: 0.5em;
`;

export const LocationAddress = styled.p`
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #d0d0d0;
  margin: 0;
`;
