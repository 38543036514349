import React from "react";
import Skeleton from "react-skeleton-loader";
import Module from "../Module/Module";

export default class BestRatedLocationSkeleton extends React.Component {
  render() {
    return (
      <Module
        style={{
          padding: "2em",
          display: "grid",
          gridTemplateColumns: "1fr 1f 1fr",
          gridRowGap: "2em",
        }}
      >
        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>

        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>

        <div>
          <Skeleton height="1em" />
          <div style={{ marginTop: ".5em" }}>
            <Skeleton width="100%" />
          </div>
        </div>
      </Module>
    );
  }
}
