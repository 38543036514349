import styled from "styled-components";
import { blueDefault } from '../../../Assets/colorPallet/colorPallet';

export const CalendarContainer = styled.div`
  width: auto;
  background-color: white;
  top: 50%;
  left: 0;
  z-index: 160;
  position: fixed;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);

  .close-icon{
    svg{
      cursor: pointer;
      path{
        fill: #888;
        transition: fill .4s ease;
      }
    }

    &:hover{
      svg{
        path{
          fill: #333;
        }
      }
    }
  }
`;

export const DatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;

  .ra-calendar{
    border: none;
    width: 100%;

    button:hover {
      border-radius: 50px;
    }
    .react-calendar__tile--active{
      background-color: ${blueDefault} !important;
      border-radius: 50px;
    }
  }
`;

export const StartContainer = styled.div`
  border-right: 1px solid #e6e6e6;
  padding: 2em;
  box-sizing: border-box;
`;

export const EndContainer = styled.div`
  padding: 2em;
  box-sizing: border-box;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  box-sizing: border-box;
  background-color: #f8f8f8;
`;

export const Label = styled.label`
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #449bc1;
  width: 100%;
`;
