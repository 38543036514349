import styled from 'styled-components';

export const BeaconGuideContainer = styled.div`
    padding: 2em;
    box-sizing: border-box;
    overflow: auto;
    max-height: 74vh;

    h2{
        text-align: center;
        letter-spacing: .1em;
        color: #2196F3;
        font-size: 18px;
        margin: 0 0 2em;    
    }

    @media only screen and (max-width: 800px){
        padding: 2em;
        max-height: 85vh;
    }

    &::-webkit-scrollbar {
    width: 8px;  
    background-color: white;
    }
 
    &::-webkit-scrollbar-track {
        width: 1px;
        background-color: white;
    }
 
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background-color: #ccc;
        -webkit-box-shadow: inset 1px 1px 0px #eee ;
    }


`;  