import React from 'react';
import { DescriptionContainer } from '../../styled';
import { Text } from '../../../../Assets/Structure/Structure';

export default class Devices extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                    Yes, Review Alert is a mobile first platform. Our platform uses responsive design and can be accessed 
                    on all types of websites(HTML, PHP, Drupal, Joomla, Magento, WordPress, and more).
                </Text>
            </DescriptionContainer>
        )
    }
}