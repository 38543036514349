import React from 'react';
import { NewMembersListContainer } from './styled';
import NewMember from './NewMember/NewMember';

export default class NewMembersList extends React.Component{

    state = {
        newEmployees: []
    }

    componentWillReceiveProps(props){
        this.setState({ newEmployees: props.newEmployees })
    }

    handleDeleteNewEmployee = (index) => {
        this.props.indexToBeDeleted(index);
    }

    render(){
        return(
            <NewMembersListContainer>
                {this.state.newEmployees.map((newEmployee, index) => {
                    return <NewMember  name={newEmployee.name} email={newEmployee.email} index={index} indexToBeDeleted={this.handleDeleteNewEmployee} />
                })}
            </NewMembersListContainer>
        )
    }
}