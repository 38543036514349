// LIBRARIES
import React from "react"
import moment from "moment";

// STYLE
import { MarketingCampaignListItem } from "./styled"
import {Container, Label} from "../../../../Assets/Structure/Structure";
import DeleteCampaign from "../../../../Components/MarketingCampaign/DeleteCampaign/DeleteCampaign";
import SendCampaign from "../../../../Components/MarketingCampaign/SendCampaign/SendCampaign";
import ReactSVG from 'react-svg';
import checkIcon from '../../../../Assets/icons/check.svg';
import EditCampaign from "../../../../Components/MarketingCampaign/EditCampaign/EditCampaign";

export default function MarketingCampaign(props) {

    const createdAt = moment(props.marketingCampaign.createdAt).format("MM/DD/YYYY")

    return (
        <MarketingCampaignListItem>
            <Container flex alignCenter fluid maxWidth="12em">
                <Container flex column fluid maxWidth="12em">
                    <Label color="#999">Campaign name</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.name}</Label>
                    </Container>
                </Container>
            </Container>
            <Container className="marketing-campaign-list-item--right-column" flex alignCenter fluid justifyEnd wrap>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="10em">
                    <Label color="#999">Location</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.locationName}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="8em">
                    <Label color="#999">Created at</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{createdAt}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="10em">
                    <Label color="#999">Created by</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.createdBy}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="5em">
                    <Label color="#999">Sent</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.targetsAmount}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="5em">
                    <Label color="#999">Replies</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.replies || 0}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid textCenter maxWidth="5em">
                    <Container mt="10px" flex>
                        <EditCampaign
                            className="edit-campaign"
                            campaign={props.marketingCampaign}
                        />
                        <DeleteCampaign
                            locationId={props.marketingCampaign.locationId}
                            campaign={props.marketingCampaign}
                            device={props.device}
                            className="delete-campaign"
                            id={`delete-campaign-${props.marketingCampaign.id}`}
                        />
                        { props.marketingCampaign.isSent &&
                            <div class="sent-wrapper">
                                <ReactSVG className="check-icon" src={checkIcon} />
                            </div>
                        }
                        {!props.marketingCampaign.isSent &&
                            <SendCampaign
                                locationId={props.marketingCampaign.locationId}
                                campaign={props.marketingCampaign}
                                device={props.device}
                                className="send-campaign"
                                id={`send-campaign-${props.marketingCampaign.id}`}
                            />
                        }
                    </Container>
                </Container>
            </Container>
        </MarketingCampaignListItem>
    )
}
