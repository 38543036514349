import React, { useState } from 'react'
import { SelectFileContainer } from './styled'
import { Container, Label } from '../../Assets/Structure/Structure'
import Dropzone from 'react-dropzone'
import InlineLoading from "../InlineLoading/InlineLoading";
import { blueDefault } from "../../Assets/colorPallet/colorPallet"


export default function DropFilesContainer(props){

    const [fileUpdated, setFileUpdated] = useState(false)

    const handleChange = e => {
        props.onChange(e)
        setFileUpdated(true)
    }

    const handleFileOnDrop = file => {
        props.onDrop(file)
        setFileUpdated(true)
    }

    const label = "Drag the file or click here"

    return (
        <Dropzone onDrop={handleFileOnDrop}>
            {({getRootProps, getInputProps}) => (
            <Container {...getRootProps()} fluid justifyCenter  className="dropzone" flex alignCenter >
                <SelectFileContainer>
                    {props.loadingFiles ? (
                        <>
                        <InlineLoading color={blueDefault} />
                        <Container mt="10px">
                            <Label>Redimensionando imagens...</Label>

                        </Container>
                        </>
                    ) : (
                        <>
                            <input {...getInputProps()}  type="file" id="file" onChange={handleChange} multiple={props.multiple ? "multiple" : null}/>
                            <div style={{ textAlign: "center" }} htmlFor="file">{label}</div>
                        </>
                    )}
                </SelectFileContainer>
            </Container>
            )}
        </Dropzone>
    )
}
