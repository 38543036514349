import styled from 'styled-components';

export const ChoosePlatformContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #E5E5E5;
    height: 100vh;
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 2em;
    max-width: 16em;
`;