import React from "react";
import axios from 'axios';
import queryString from 'query-string'
import {API_URL} from '../../../../config'
import {IntegrateActionContainer} from "./styled";
import Button from "../../../../Components/Button/Button";
import googleIcon from '../../../../Assets/icons/google.svg';
import facebookIcon from '../../../../Assets/icons/facebook.svg';

export default class IntegrateAction extends React.Component {

    state = {
        isConnectingFacebook: false,
        isConnectingGoogle: false,
        integrationHasFailed: "",
        socialMedia: "",
        loading: false,
        isGoogleIntegrated: "",
        isFacebookIntegrated: "",
        pages: [],
        service: ""
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)

        const currentLocationServices = this.props.currentLocation.review_services;
        
        if (currentLocationServices.includes("google")) {
            this.setState({isGoogleIntegrated: true})
        }

        if (currentLocationServices.includes("facebook")) {
            this.setState({isFacebookIntegrated: true})
        }
    }

    getSocialAPIUrl(service, cb) {
        let store = localStorage.getItem('store');
        let currentLocation = localStorage.getItem('current_location_id');

        let url = `${API_URL}/admin/stores/${store}/locations/${currentLocation}/services/oauth?service=${service}`

        if (service === 'google') {
            this.setState({isConnectingGoogle: true})
        }

        if (service === 'facebook') {
            this.setState({isConnectingFacebook: true})
        }

        axios
            .get(url, {
            headers: {
                'x-sas-Auth': localStorage.getItem('token')
            }
        })
            .then(response => {
                window.location = response.data.auth_url
                this.setState({integrationHasFailed: false})
            })
            .catch(e => {
                this.setState({integrationHasFailed: true})

                if (service === 'google') {
                    this.setState({isConnectingGoogle: false, loading: false})
                }

                if (service === 'facebook') {
                    this.setState({isConnectingFacebook: false, loading: false})
                }
            })
    }

    handleIntegration = (service) => {
        this.getSocialAPIUrl(service)

        if (service === "facebook") {
            if (!this.state.isFacebookIntegrated) {
                this.setState({socialMedia: 'facebook', loading: true})
            }
        }

        if (service === "google") {
            if (!this.state.isGoogleIntegrated) {
                this.setState({socialMedia: 'google', loading: true})
            }
        }
    }

    render() {

        return (
            <IntegrateActionContainer>
                <Button
                    bgColor="white"
                    color="#333"
                    className="btn-facebook"
                    text="Connect with facebook"
                    customBorder={"1px solid #D0D0D0"}
                    hasIcon
                    iconPath={facebookIcon}
                    bgColorOnHover={false}
                    borderRadius="20em"
                    onClick={() => this.handleIntegration('facebook')}
                    integrated={this.state.isFacebookIntegrated}/>

                <Button
                    bgColor="white"
                    color="#333"
                    hasIcon
                    iconPath={googleIcon}
                    borderRadius="20em"
                    customBorder={"1px solid #D0D0D0"}
                    className="btn-google"
                    bgColorOnHover={false}
                    text="Connect with Google"
                    onClick={() => this.handleIntegration('google')}
                    integrated={this.state.isGoogleIntegrated}/>
            </IntegrateActionContainer>
        );
    }
}
