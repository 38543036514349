import React from 'react';
import { CSVLink } from 'react-csv';
import ReactSVG from 'react-svg'
import queryString from "query-string";
import { connect } from 'react-redux'
import axios from 'axios'
import { setChatCredentials, setFilter, setCurrentChat } from '../../store/modules/webchat/actions'
import WebchatHeader from '../../Containers/Webchat/WebchatHeader/WebchatHeader';
import WebchatMenu from '../../Containers/Webchat/WebchatMenu/WebchatMenu';
import { PageWrapper, Container, Row, Label } from '../../Assets/Structure/Structure';
import WebchatBody from '../../Containers/Webchat/WebchatBody/WebchatBody';
import MobileFilterModal from '../../Containers/Webchat/MobileFilterModal/MobileFilterModal';
import {InactiveWebchatContainer, WebchatContainer} from './styled';
import SelectFluid from '../../Components/SelectFluid/SelectFluid'
import locationIcon from '../../Assets/icons/location.svg'
import { WEBCHAT_API_URL } from '../../config';
import { setShowChatsOnMobile, setConversationBeforeRefresh } from '../../store/modules/webchat/actions'
import Button from '../../Components/Button/Button';
import { DownloadContainer } from '../Reports/Header/styled';
import downloadIcon from "../../Assets/icons/download.svg";
import ComposeMessageDialog from "../../Containers/Webchat/ComposeMessageDialog/ComposeMessageDialog";
import chatIcon from "../../Assets/icons/chat.svg"
import InlineLoading from "../../Components/InlineLoading/InlineLoading";
import { blueDefault} from "../../Assets/colorPallet/colorPallet"


class Webchat extends React.Component{

    state = {
        currentChat: "",
        messagesNeedResponse: [],
        newMessages: [],
        showChatsOnMobile: false,
        showFilterOnMobile: false,
        storeId: "",
        authToken: "",
        chatCredentials: "",
        selectedLocation: null,
        selectedLocationPlaceholder: "Select Location",
        twilioNumber: "",
        webchatReportData: "",
        showComposeMessageDialog: false,
        twilioNumberIsActive: true,
        loading: true,
    }

    componentDidMount(){
        const storeKey = localStorage.getItem('store_key')

        axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
        .then(res => {
            let twilioNumber = res.data[0].number
            twilioNumber = twilioNumber.split('')
            twilioNumber.shift()
            twilioNumber = twilioNumber.join('')

            this.setState({ twilioNumber, twilioNumberIsActive: true })
        })
        .catch((error) => {
            if (error && error.response && error.response.status === 403) {
                this.setState({ twilioNumberIsActive: false })
            }
            console.log(error.response.status)
            this.props.setCurrentChat({})
        })
            .finally(() => {
                this.setState({ loading: false })
            })

        const search = queryString.parse(window.location.search)
        const openDrawer = search.open_drawer

        if (openDrawer){
            this.props.setShowChatsOnMobile(true)
        }

        const chatId = localStorage.getItem("chatId")
        if (chatId) this.props.setConversationBeforeRefresh(chatId)

        const chatGroup = {
            chatId:localStorage.getItem("chatId"),
            locationId:localStorage.getItem("chatLocationId"),
            clientNumber: localStorage.getItem("chatClientNumber"),
            clientName: localStorage.getItem("chatClientName"),
            author: localStorage.getItem("chatAuthor"),
            lastMessage: localStorage.getItem("chatLastMessage"),
        }

        const locationName = localStorage.getItem("chatLocationName")
        if (chatGroup.chatId) {
            this.props.setCurrentChat({
                chatId: chatGroup.chatId,
                locationId: chatGroup.locationId,
                clientNumber: chatGroup.clientNumber,
                clientName: chatGroup.clientName,
                author: chatGroup.author,
                lastMessage: chatGroup.text,
                locationName
            })
        } else {
            this.props.setCurrentChat({})
        }

        this.fetchChatGroupsMenu()
    }


    fetchChatGroupsMenu = () => {
        const storeKey = localStorage.getItem('store_key')

        axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
        .then(res => {
            const accountSid = res.data[0].accountSid
            const twilioAuthToken = res.data[0].token
            const twilioNumber = res.data[0].number
            const chatCredentials = { accountSid, twilioAuthToken, twilioNumber }

            const storeId = localStorage.getItem('store');

            const headers = { headers: {"x-auth-token": chatCredentials.twilioAuthToken , "x-account-sid": chatCredentials.accountSid} }

            axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/messages/menu`, headers)
            .then(res => {
                const { chatGroups } = res.data
                const browserWidth = window.innerWidth
                const isMobile = browserWidth <= 600

                if (chatGroups.length > 0 && isMobile){
                    this.props.setShowChatsOnMobile(true)
                }
            })

        })
    }


    componentWillReceiveProps(props, prev){
        if (props.updatedCurrentChat !== prev.updatedCurrentChat){
            this.setState({ currentChat: props.currentChat })
            this.setState({ showChatsOnMobile: false })
        }

        if (props.showChatsOnMobile !== prev.showChatsOnMobile){
            this.setState({ showChatsOnMobile: props.showChatsOnMobile })
        }

        if (Object.getOwnPropertyNames(props.filter).length === 0){
            this.setState({  selectedLocationPlaceholder: "Select location" })
        }
    }


    showFilterOnMobile = showFilterOnMobile => {
        this.setState({ showFilterOnMobile })
    }

    handleSelectedLocation = selectedLocation => {
        this.setState({
            selectedLocationPlaceholder: selectedLocation.name,
            currentFilter: "location"
        })
        this.props.setFilter({ locationId: selectedLocation.id })
        this.props.setCurrentChat({})
    }

    handleDownloadClientList = () => {
        const storeId = localStorage.getItem('store')
        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/clients`)
        .then(res => {
            this.setState({ webchatReportData: res.data })

            const downloadActionElement = document.querySelector(".reviewalert-webchat-download-action");
            downloadActionElement.click();
        })
    }

    handleComposeMessageDialog = (showComposeMessageDialog) => {
        this.setState({ showComposeMessageDialog })
    }


    render(){

        const { locations } = this.props

        const {
            messagesNeedResponse,
            newMessages,
            chatCredentials,
            showChatsOnMobile,
            showFilterOnMobile,
            selectedLocationPlaceholder,
            twilioNumberIsActive,
            twilioNumber,
            loading
        } = this.state;

        if (loading) {
            return (
                <Container flex height="calc(100vh - 91px)" alignCenter justifyCenter>
                    <InlineLoading color={blueDefault}/>
                </Container>
            )
        }

        if (!twilioNumber && !twilioNumberIsActive) {
            return (
                <InactiveWebchatContainer flex alignCenter justifyCenter height="calc(100vh - 91px)">
                    <Container flex alignCenter justifyCenter column>
                        <ReactSVG src={chatIcon} className="chat-icon"  />
                        <Container flex column alignCenter mt="20px">
                            <Label fontSize="18px">Your webchat is not active</Label>
                            <Container mt="5px">
                                <Label>Contact support@reviewalert.com to reactivate this module</Label>
                            </Container>
                        </Container>
                    </Container>
                </InactiveWebchatContainer>
            )
        }

        return(
            <>
                <CSVLink
                className="reviewalert-webchat-download-action"
                data={this.state.webchatReportData}
                filename="reviewalert-webchat-report.csv"/>
                {showFilterOnMobile &&
                <MobileFilterModal
                handleFilter={this.handleFilter}
                isModalOpen={this.showFilterOnMobile}/>}
                <WebchatContainer showChatsOnMobile={showChatsOnMobile}>
                    <PageWrapper mobPadding="0">
                        <Container hideOnMobile flex between>
                            <Container>
                            <Container flex alignCenter>
                                <ReactSVG src={locationIcon} className="location-icon" />
                                <Container ml=".5em">
                                    <Label uppercase color="#999">Locations</Label>
                                </Container>
                            </Container>
                            <Container mt="10px">
                                <SelectFluid
                                options={locations}
                                placeholder={selectedLocationPlaceholder}
                                absolute
                                loading={false}
                                maxWidth="15em"
                                noBorder
                                selectedOption={this.handleSelectedLocation}
                                />
                            </Container>
                            </Container>
                            <Container flex alignCenter>
                                <Container>
                                    <DownloadContainer>
                                        <Button
                                            text="Compose Message"
                                            hideTextOnMobile
                                            bgColor="white"
                                            borderRadius="20em"
                                            onClick={() => this.handleComposeMessageDialog(true)}
                                            hasShadow
                                            height="40px"
                                            bgColorOnHover={false}
                                            padding="0 40px"
                                            fontSize="14px"
                                        />
                                    </DownloadContainer>
                                </Container>
                                <Container ml="20px">
                                    <DownloadContainer>
                                        <Button
                                        text="Download Report"
                                        hasIcon
                                        hideTextOnMobile
                                        iconPath={downloadIcon}
                                        bgColor="white"
                                        borderRadius="20em"
                                        onClick={this.handleDownloadClientList}
                                        hasShadow
                                        height="40px"
                                        bgColorOnHover={false}
                                        padding="0 40px"
                                        fontSize="14px"
                                        />
                                    </DownloadContainer>
                                </Container>
                            </Container>
                        </Container>
                        <Container mt="30px" radius="5px" shadowOn className="webchat-container">
                            <WebchatHeader
                            showFilterOnMobile={this.showFilterOnMobile}
                            onShowComposeMessageDialog={this.handleComposeMessageDialog}
                            filter={this.handleFilter}
                            messagesNeedResponse={messagesNeedResponse.length}
                            newMessages={newMessages.length}  />
                            <Row>
                                <Container fluid maxWidth="268px" id="webchat-menu">
                                    <WebchatMenu chatCredentials={chatCredentials} />
                                </Container>
                                <Container fluid maxWidth="calc(100% - 268px)" id="webchat-body">
                                    <WebchatBody
                                    currentChat={this.props.currentChat}
                                    handleHideChats={this.showChatsOnMobile}/>
                                </Container>
                            </Row>
                        </Container>
                    </PageWrapper>
                </WebchatContainer>
                <ComposeMessageDialog
                    active={this.state.showComposeMessageDialog}
                    onClose={this.handleComposeMessageDialog} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    locations: state.locations.locations,
    updatedCurrentChat: state.webchat.currentChat,
    showChatsOnMobile: state.webchat.showChatsOnMobile,
    currentChat: state.webchat.currentChat,
    filter: state.webchat.filter,
    chatGroupsAmount: state.webchat.chatGroupsAmount
})

const mapDispatchToProps = dispatch => ({
    setChatCredentials(chatCredentials){
        dispatch(setChatCredentials(chatCredentials))
    },

    setFilter(filter){
        dispatch(setFilter(filter))
    },

    setCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },

    setShowChatsOnMobile(showChats){
        dispatch(setShowChatsOnMobile(showChats))
    },

    setConversationBeforeRefresh(chatId){
        dispatch(setConversationBeforeRefresh(chatId))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Webchat)
