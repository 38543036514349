import React from 'react';
import ReactSVG from 'react-svg';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import {API_URL} from '../../config';
import Module from '../../Components/Module/Module';
import SelectFluid from '../../Components/SelectFluid/SelectFluid';
import {StoresContainer, Form} from './styled';
import { Label } from '../../Assets/Structure/Structure';
import txtarLogo from '../../Assets/icons/txtar.svg';
import { Row } from "../../Assets/Structure/Structure"

export default class Stores extends React.Component {

    state = {
        stores: [],
        placeholder: "Select store",
        redirect: false,
        notAllowed: false,
        loading: true
    }

    async componentDidMount() {
        const authToken = localStorage.getItem("token");
        const userRole = localStorage.getItem("role");
        if (userRole !== "sas-team"){
            this.setState({ notAllowed: true })
        }
        const headers = {
            headers: {
                "x-sas-Auth": authToken
            }
        };

        axios.get(`${API_URL}/private/sessions`, headers)
        .then(res => {
            const userRole = res.data.role;
            if (userRole !== "sas-team"){
                this.setState({ notAllowed: true });
            }
        })
        .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user_store");
            localStorage.removeItem("user_email");
            localStorage.removeItem("widget_only");
            localStorage.removeItem("store");
            localStorage.removeItem("role");
            localStorage.removeItem("is_admin");
            localStorage.removeItem("logged_in");
        })

        await axios.get(`${API_URL}/system/stores`, headers)
        .then(res => {
            const stores = res.data.stores;
            this.setState({
                stores,
                loading: false
            });
        })
    }

    handleSelectedStore = store => {
        const storeId = store.id;
        const storeName = store.name;
        const authToken = localStorage.getItem("token");
        const headers = {headers: {"x-sas-Auth" : authToken}};

        localStorage.setItem("store", storeId);
        localStorage.setItem("store_name", storeName);

        axios.get(`${API_URL}/admin/stores/${storeId}`, headers )
        .then(res => {
            localStorage.setItem("store_key", res.data.store_key )
            this.setState({ redirect: true, placeholder: storeName })
        })

    }

    render() {

        const {
            stores,
            placeholder,
            redirect,
            notAllowed,
            loading
        } = this.state;

        if (redirect){
            return <Redirect to="/dashboard" />
        }

        if (notAllowed){
            return <Redirect to="/dashboard" />
        }

        return (
            <StoresContainer>
                <Form>
                    <Row alignCenter justifyCenter>
                        <ReactSVG src={txtarLogo} />
                    </Row>
                    <Module className="stores-module">
                        <Label fontSize="24px">Stores</Label>
                        <SelectFluid
                         justify="space-between"
                         options={stores}
                         loading={loading}
                         selectedOption={this.handleSelectedStore}
                         placeholder={placeholder}
                         id="stores"/>
                    </Module>
                </Form>
            </StoresContainer>
        )
    }
}
