import styled from 'styled-components'
import {blueDefault} from "../../Assets/colorPallet/colorPallet";

export const SelectFileContainer = styled.div`
[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
    outline: none !important;
  }
  
  [type="file"] + div {
    background: ${props => props.fileUpdated ? blueDefault : "transparent"};
    border: ${`1px solid ${blueDefault}`};
    border-radius: 5px;
    color: ${props => props.fileUpdated ? "white" : blueDefault};
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: inherit;
    font-weight: 600;
    outline: none;
    padding: 40px;
    position: relative;
    outline: none !important;
    transition: all 0.3s;
    vertical-align: middle;
    
    &:hover {
      background-color: darken(#f15d22, 10%);
    }
    
    &.btn-1 {
      background-color: #f79159;
      box-shadow: 0 6px darken(#f79159, 10%);
      transition: none;
  
      &:hover {
        box-shadow: 0 4px darken(#f79159, 10%);
        top: 2px;
      }
    }
    
    &.btn-2 {
      background-color: #99c793;
      border-radius: 50px;
      overflow: hidden;
      
      &::before {
        color: #fff;
        content: "\f382";
        font-family: "Font Awesome 5 Pro";
        font-size: 100%;
        height: 100%;
        right: 130%;
        line-height: 3.3;
        position: absolute;
        top: 0px;
        transition: all 0.3s;
      }
  
      &:hover {
        background-color: darken(#99c793, 30%);
          
        &::before {
          right: 75%;
        }
      }
    }
    
    &.btn-3 {
      background-color: #ee6d9e;
      border-radius: 0;
      overflow: hidden;
      
      span {
        display: inline-block;
        height: 100%;
        transition: all 0.3s;
        width: 100%;
      }
      
      &::before {
        color: #fff;
        content: "\f382";
        font-family: "Font Awesome 5 Pro";
        font-size: 130%;
        height: 100%;
        left: 0;
        line-height: 2.6;
        position: absolute;
        top: -180%;
        transition: all 0.3s;
        width: 100%;
      }
  
      &:hover {
        background-color: darken(#ee6d9e, 30%);
        
        span {
          transform: translateY(300%);
        }
          
        &::before {
          top: 0;
        }
      }
    }
  }
`
