import React from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import SelectLocation from './SelectLocation/SelectLocation';
import Members from '../../Components/Members/Members';
import AllReviews from '../../Components/AllReviews/AllReviews';
import OverviewByPlatform from '../../Components/OvervieByPlatform/OverviewByPlatform';
import LastReviews from '../../Components/LastReviews/LastReviews';
import { LocationContainer, RowJustifyContainer } from "./styled";
import { PageWrapper } from "../../Assets/Structure/Structure";
import { API_URL } from "../../config";

export default class LocationsAnalytics extends React.Component {
  state = {
    selectedLocation: "",
    store: "",
    authToken: "",
    location: "",
    reviews: [],
    totalAverageRating: 0,
    loading: true,
    locationOverview: [],
    locationRatings: [],
    locations: [],
    routeNotAllowed: false,
    redirectUrl: "/store-location"
  };

  componentDidMount() {
    window.scrollTo(0,0);
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};
    this.setState({ store, authToken, headers });

    axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
    .then(res => {
        const selectedLocation = res.data[0].id;
        this.setState({ location: res.data[0], locations: res.data, selectedLocation });

        axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation}/reviews`, headers)
        .then(res => this.setState({ reviews: res.data.reviews, loading: false }));

        // GET LOCATION OVERVIEW
        axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation}/stats/reviews`, headers)
        .then(res => this.setState({ locationOverview: res.data }));

        axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation}/stats/ratings`, headers)
        .then(res => this.setState({ locationRatings: res.data }));
      });
  }

  handleSelectedLocation = selectedLocation => {
    const { store, headers } = this.state;

    let locations = this.state.locations;
    const location = locations.filter(loc => loc.id === selectedLocation.id)[0];
    this.setState({ location, selectedLocation: location.id })

    axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation.id}/reviews`, headers)
    .then(res => this.setState({ reviews: res.data.reviews }));

    // GET LOCATION OVERVIEW
    axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation.id}/stats/reviews`, headers)
   .then(res => this.setState({ locationOverview: res.data }))
   .catch(() => this.setState({ locationOverview: [] }));

    axios.get(`${API_URL}/private/stores/${store}/locations/${selectedLocation.id}/stats/ratings`, headers)
    .then(res => this.setState({ locationRatings: res.data }));
  };

  handleTotalAverageRate = totalAverageRating => this.setState({ totalAverageRating });

  handleRouteProtection = (redirectUrl) => {
    this.setState({ routeNotAllowed: true, redirectUrl })
  }

  handleValidateWidgetOnlyLoading = loading => this.setState({ loading })

  render() {

    if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl} />

    const  {
      location,
      locationOverview,
      locationRatings,
      reviews,
      loading,
      selectedLocation
     } = this.state;



    return (
      <ValidateWidgetOnly
      protectRoute={this.handleRouteProtection}
      currentRoute="locations-analytics"
      onLoading={this.handleValidateWidgetOnlyLoading}>
      <PageWrapper>
        <LocationContainer>
          <RowJustifyContainer>
            <SelectLocation
              selectedLocation={this.handleSelectedLocation}
              initialLocation={location}
            />
            <Members numberOfEmployees={location.employees} />
          </RowJustifyContainer>
          <AllReviews
            locationOverview={locationOverview}
            locationRatings={locationRatings}
            reviews={reviews}
            totalAverageRating={this.handleTotalAverageRate}
            loading={loading}
            selectedLocation={selectedLocation}
          />
          <OverviewByPlatform
            type="location"
            overview={locationOverview}
            ratings={locationRatings}
            loading={loading} />
          <LastReviews reviews={reviews} loading={loading} />
        </LocationContainer>
      </PageWrapper>
      </ValidateWidgetOnly>
    );
  }
}
