import React from "react";
import ReactSVG from "react-svg";
import EllipsisText from "react-ellipsis-text";
import axios from "axios";
import { API_URL } from "../../../config";
import { UpdateEmployeeLocationContainer } from "./styled";
import Axios from "axios";
import Locations from "./Locations/Locations";
import arrowIcon from '../../../Assets/icons/arrow.svg';

export default class UpdateEmployeeLocation extends React.Component {
  state = {
    locations: [],
    showLocations: false,
    location: this.props.location,
    loading: true,
    location: this.props.location
  };

  componentDidMount() {
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};

    Axios.get(`${API_URL}/admin/stores/${store}/locations`, headers)
    .then(res => {
      this.setState({ locations: res.data, loading: false });
    });

    this.detectClick();
  }

  detectClick = () => {
    window.addEventListener("click", e => {
      const element = document.getElementById(this.props.id);
      let target = e.target;

      do {
        if (target == element) {
          return;
        }

        target = target.parentNode;
      } while (target);

      this.setState({ showLocations: false });
    });
  };

  componentWillReceiveProps(props){
    this.setState({ location: props.location })
  }

  handleLocationsList = showLocations => {
    this.setState({ showLocations });
  };

  handleLocationUpdate = location => {
    const authToken = localStorage.getItem("token");
    const store = localStorage.getItem("store");
    this.setState({ loading: true });
    const headers = {headers: { "x-sas-Auth" : authToken }};

    const { employee } = this.props;

    const payload = {
      email: employee.email,
      name: employee.name,
      role: employee.profile.toLowerCase(),
    }
    axios.post(`${API_URL}/admin/stores/${store}/locations/${location.id}/employees`, payload, headers)
      .then(res => {
        
        axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
        .then(res => {
          this.props.updateEmployees(res.data.employees, res.data.total);
        });

        this.setState({
          location: location.name,
          showLocations: false,
          loading: false
        });
      }).catch(() => {
        this.setState({ loading: false, showLocations: false });
      });
  };

  render() {
    return (
      <div id={this.props.id}>
        <UpdateEmployeeLocationContainer
          onClick={() => this.handleLocationsList(true)}
        >
          <ReactSVG src={arrowIcon} />
          <label>{this.state.location}</label>
        </UpdateEmployeeLocationContainer>
        {this.state.showLocations && (
          <Locations
            locations={this.state.locations}
            selectedLocation={this.handleLocationUpdate}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}
