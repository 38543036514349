import React from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import ReactSVG from "react-svg";
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import { SellerLoginContainer } from "./styled";
import { FluidPageWrapper, Column, Row } from "../../Assets/Structure/Structure";
import LocationOverview from "./LocationOverview/LocationOverview";
import RALogo from ".././../Assets/icons/ra-logo.svg";
import SendReviewInvitation from "./SendReviewInvitation/SentReviewInvitation";
import LatestStats from "./LatestStats/LatestStats";
import LatestReviews from "./LatestReviews/LatestReviews";
import Profile from "../../Components/SideMenu/Profile/Profile";
import { API_URL } from "../../config";
import EditSellerProfile from "./EditSellerProfile/EditSellerProfile";
import NewMemberFeedback from "../Team/NewMemberModal/NewMemberFeedback/NewMemberFeedback";
import InvitationFeedback from "../../Components/Header/ReviewInvitation/InvitationFeedback/InvitationFeedback";
import Modal from "../../Components/Modal/Modal";

export default class SellerLogin extends React.Component {

  state = {
    userEmail: "",
    userName: "",
    locationId: "",
    requests: {},
    storeName: "",
    loggedIn: "",
    userRole: "",
    locationRating: "",
    convertionRate: "",
    locationRatings: {},
    newInvitations: [],
    currentModal: "",
    routeNotAllowed: false,
    redirectUrl: "/store-location",
    loading: true
  }

  componentDidMount(){
    const userName = localStorage.getItem("user_name");
    const userEmail = localStorage.getItem("user_email");
    const locationId = localStorage.getItem("location");
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    
    this.setState({ userEmail, userName, locationId, store });

    const headers = {headers: { "x-sas-Auth" : authToken }}

    // //CHECK USER ROLE
    axios.get(`${API_URL}/private/sessions/`, headers)
    .then(res => {
      const userRole = "seller";
      if (userRole !== "seller"){
        this.setState({ isSeller: false, loggedIn: true, userRole });
      } else {
        this.setState({ isSeller: true, loggedIn: true, userRole});
      }
    }).catch(()=> this.setState({ loggedIn: false }));

    //GET USER REQUESTS STATS
    this.getUserRequestStats(authToken, headers);

    //GET REQUESTS STATS
    this.getRequestStats(store, locationId, headers);

    //GET LOCATION RATING
    axios.get(`${API_URL}/private/stores/${store}/locations/${locationId}/stats/ratings`, headers)
    .then(res => {
      const locationRating = res.data.rating;
      const googleRating = res.data.services.filter(service => service.review_service === "Google")[0].rating;
      const facebookRating = res.data.services.filter(service => service.review_service === "Facebook")[0].rating;
      this.setState({ locationRating, googleRating, facebookRating })
    })

    //GET TOTAL REVIEWS
    axios.get(`${API_URL}/private/stores/${store}/locations/${locationId}/reviews`, headers)
    .then(res => this.setState({ totalReviews: res.data.total }))
  }

  //GET USER REQUEST STATS
  getUserRequestStats = (authToken, headers) => {
    axios.get(`${API_URL}/private/users/${authToken}`, headers)
    .then(res => {
      const locationName = "Testing location";
      const requests = {
        sent: res.data.stats.sent,
        sentToday: res.data.stats.sent_today,
        sentYesterday: res.data.stats.sent_yesterday,
        sent7Days: res.data.stats.sent_7_days,
        sent30Days: res.data.stats.sent_30_days
      }

      this.setState({ requests, locationName });
    })
  }

  //GET REQUESTS STATS
  getRequestStats = (store, location, headers) => {
    axios.get(`${API_URL}/private/stores/${store}/locations/${location}/stats/requests`, headers)
    .then(res => {
      const convertionRate = res.data.requests_for_review;
      const redirectsToGoogle = res.data.redirects_to_google;
      const redirectsToFacebook = res.data.redirects_to_facebook;
      const totalRequestsSent = res.data.sent;
      const ctr = res.data.ctr;
      this.setState({ convertionRate, redirectsToFacebook, redirectsToGoogle, ctr, totalRequestsSent });
    })
  }

  handleLoggedIn = loggedIn => this.setState({ loggedIn });

  handleEditProfile = (isModalOpen, currentModal) => {
    this.setState({ 
      isModalOpen,
      currentModal
    });
  }

  handleFeedback = (newInvitations, currentModal) => this.setState({ 
    newInvitations,
    isModalOpen: true,
    currentModal
  });

  handleCloseModal = (isModalOpen) => {
    this.setState({ isModalOpen, currentModal: "" })
  }

  handleUpdateOverview = () => {
    const authToken = localStorage.getItem("token");
    const headers = {headers: { "x-sas-Auth" : authToken }};
    this.getUserRequestStats(authToken, headers);
  }

  handleRouteProtection = (redirectUrl) => {
    this.setState({ routeNotAllowed: true, redirectUrl })
  } 

  updateUserName = userName => {
    this.setState({ userName })
  };

  handleValidateWidgetOnlyLoading = loading => this.setState({ loading })


  
  render() {

    const { 
      locationRating, 
      userRole, 
      userName, 
      userEmail, 
      convertionRate,
      locationId,
      requests,
      totalReviews,
      isModalOpen,
      googleRating,
      ctr,
      redirectsToGoogle,
      locationName,
      newInvitations,
      facebookRating,
      currentModal,
      totalRequestsSent,
      redirectsToFacebook
    } = this.state;

    const loggedIn = this.state.loggedIn;
    const isSeller = this.state.isSeller;
    const storeName = localStorage.getItem("store_name");

    if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl} />

    if (loggedIn === false) return <Redirect to="/login" />
    if (!isSeller && loggedIn) return <Redirect to="/" />
    

    return (
      <ValidateWidgetOnly protectRoute={this.handleRouteProtection} onLoading={this.handleValidateWidgetOnlyLoading}>
      <React.Fragment>
        {isModalOpen && currentModal === "edit-profile" ? (
          <EditSellerProfile userName={this.updateUserName} isModalOpen={this.handleEditProfile} />
        ) : isModalOpen && currentModal === "new-invitations-feedback" ? (
          <Modal>
            <InvitationFeedback invitations={newInvitations} isModalOpen={this.handleCloseModal} />
          </Modal>
        ) : null}
      <SellerLoginContainer>
        <div className="top-container">
          <Row between>
            <div style={{ width: "100%", maxWidth: "17em" }}>
              <Profile  
                handleEditProfile={() => this.handleEditProfile(true, "edit-profile")}
                userRole={userRole} 
                loggedIn={this.handleLoggedIn} 
                backgroundOff 
                userName={userName} 
                userEmail={userEmail} 
              />
            </div>
            <ReactSVG src={RALogo} className="location-login-ra-logo" />
          </Row>
          <Column mt="2em" padding="0 2em" className="column">
            <FluidPageWrapper between >
              <LocationOverview 
                className="location-overview" 
                requests={requests}
                locationName={locationName}
                locationRating={locationRating}
                convertionRate={convertionRate}
                storeName={storeName} />
              <SendReviewInvitation 
                className="send-review-invitation" 
                updateOverview={this.handleUpdateOverview}
                handleFeedback={this.handleFeedback}
                locationId={locationId} />
            </FluidPageWrapper>
          </Column>
        </div>
        <div className="bottom-container">
          <Column padding="0 2em" className="column">
            <FluidPageWrapper between>
              <LatestStats 
                totalRequestsSent={totalRequestsSent}
                locationRating={locationRating}
                ctr={ctr}
                redirectsToFacebook={redirectsToFacebook}
                redirectsToGoogle={redirectsToGoogle}
                googleRating={googleRating}
                facebookRating={facebookRating}
                totalReviews={totalReviews} />
              <LatestReviews />
            </FluidPageWrapper>
          </Column>
        </div>
      </SellerLoginContainer>
      </ React.Fragment>
      </ValidateWidgetOnly>
    );
  }
}
