import React from "react";
import ReactSVG from 'react-svg';
import { WidgetMenuContainer, WidgetContainer, Overlay, BeaconGuideButton } from "./styled";
import CopyCode from "./CopyCode/CopyCode";
import WidgetMobileButton from "./WidgetMobileButton/WidgetMobileButton";
import closeIcon from '../../Assets/icons/close.svg';
import questionIcon from '../../Assets/icons/question.svg';


export default class WidgetMenu extends React.Component {
  state = {
    sideMenuOpen: false,
  };

  handleSideMenuOpen = sideMenuOpen => {
    this.setState({ sideMenuOpen });
  };

  handleCurrentPreview = (beacon) => {
    this.props.currentPreview(beacon);
  }

  handleBeaconGuide = (isModalOpen, currentModal) => {
    this.props.handleBeaconGuide(isModalOpen, currentModal);
  }

  render() {
    return (
      <WidgetContainer {...this.props}>
        {this.state.sideMenuOpen && <Overlay onClick={() => this.handleSideMenuOpen(false)} />}

        <WidgetMobileButton onClick={() => this.handleSideMenuOpen(true)} sideMenuOpen={this.state.sideMenuOpen}/>
        <WidgetMenuContainer sideMenuOpen={this.state.sideMenuOpen} device={this.props.device}>
          {this.props.device !== "desktop" && (
            <div className="close-container">
            <ReactSVG src={closeIcon} onClick={() => this.handleSideMenuOpen(false)} />
          </div>
          )}
          <label>Embed Codes</label>
          <p>
            You have four different formats available. Copy the code for each
            format to your website to generate the embedded reviews.
          </p>
          <CopyCode currentPreview={this.handleCurrentPreview} />
          <BeaconGuideButton className="guide-btn" onClick={() => this.handleBeaconGuide(true, 'beacon-guide')}>
            <ReactSVG src={questionIcon} />
            <span>Beacon Guide</span>
          </BeaconGuideButton>
        </WidgetMenuContainer>
      </WidgetContainer>
    );
  }
}
