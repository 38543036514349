import React from "react";
import Skeleton from "react-skeleton-loader";
import ReactSVG from "react-svg";
import { Row, LeftContainer, RightContainer } from "../styled";
import Bar from "../Bar/Bar";
import { ReviewTypeContainer } from "./styled";
import positive from "../../../Assets/icons/positive.svg";
import neutral from "../../../Assets/icons/neutral.svg";
import negative from "../../../Assets/icons/negative.svg";

export default class ReviewType extends React.Component {
  render() {
    const { bgColor, percentage, amount, type } = this.props;
  
    return (
      <ReviewTypeContainer>
        <Row between alignCenter>
          <LeftContainer>
            {type === "Positive" ? (
              <ReactSVG src={positive} />
            ) : type === "Neutral" ? (
              <ReactSVG src={neutral} />
            ) : type === "Negative" ? (
              <ReactSVG src={negative} />
            ) : null}

            <label>{type}</label>
          </LeftContainer>
          <RightContainer bgColor={bgColor}>
            <label>{amount}</label>
          </RightContainer>
        </Row>
        <Row className="bar">
          <Bar bgColor={bgColor} percentage={percentage || 0} />
        </Row>
      </ReviewTypeContainer>
    );
  }
}
