import styled from 'styled-components';

export const ListContainer = styled.ul`
    margin: 0;
    padding: 0;
`;

export const ListItem = styled.li`
    list-style: none;
    padding: 1em;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .1em;
    color: #333;

    &:hover{
        background-color: #eee;
        border-radius: 5px;
    }
`;