import React, {useEffect} from "react"
import { ClientsListContainer, ClientContainer } from "./styled"
import {Container, Label} from "../../../Assets/Structure/Structure";
import clientIcon from "../../../Assets/icons/client.svg";
import ReactSVG from "react-svg";
import trashIcon from "../../../Assets/icons/trash.svg";
import {blueDefault} from "../../../Assets/colorPallet/colorPallet";
import Button from "../../../Components/Button/Button";

export default function ClientsList(props) {

    const handleDeleteClient = client => props.onDelete(client)


    return (
        <ClientsListContainer clients={props.clients}>
            <Container maxHeight="30vh" overflowHidden>
            {props.clients.map(client => (
                <ClientContainer>
                    <Container fluid flex alignCenter>
                        <ReactSVG src={clientIcon} />
                        <Container ml="20px" fluid maxWidth="8em">
                            <Label ellipsis maxWidth="8em">{client.clientName}</Label>
                        </Container>
                        <Container ml="20px">
                            <Label>{client.clientNumber}</Label>
                        </Container>
                    </Container>
                    <Container ml="20px">
                        <Button
                            borderRadius="20em"
                            hasIcon
                            iconPath={trashIcon}
                            height="50px"
                            iconColor={blueDefault}
                            flex
                            hideTextOnMobile
                            padding="0"
                            mr="1em"
                            width="50px"
                            bgColor="transparent"
                            bgColorOnHover="#f8f8f8"
                            color="white"
                            uppercase
                            kind="icon-button"
                            onClick={() => handleDeleteClient(client)}/>
                    </Container>
                </ClientContainer>
            ))}
            </Container>
        </ClientsListContainer>
    )
}
