import React from 'react';
import { DescriptionContainer, Image, Container } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';
import img1 from './images/img-1.png'

export default class HowToIntegrate extends React.Component{

    render(){
        return(
            <DescriptionContainer> 
                <Container pl="2em" bl="2px solid #333">
                    <Text><strong>First make sure that the following requirements are fulfilled:</strong></Text>
                    <Text mb="0"><strong>On Facebook</strong></Text>
                    <Text mt="0" mb="0">
                        Make sure tha the Facebook profile you are trying to connect 
                        with is associated with your store's page.
                    </Text>
                    <Text mt="0">Make sure that reviews are <strong>enabled</strong> for your store page on Facebook.</Text>
                    <Text mb="0"><strong>On Google</strong></Text>
                    <Text mt="0">
                       Make sure that you have a <strong>Google Business Account</strong> and that it's activated.
                    </Text>
                </Container>
                <Text>If these requirements are done you are ready to connect.</Text>
                <Text style={{textDecoration: "underline"}}>Connecting with Google and Facebook on Review Alert:</Text>
                <Text>  
                    Go to <strong style={{ color: blueDefault }}>Store Location</strong> and select the location you want
                    to associate from to load your reviews.
                </Text>
                <Text>
                    *You may choose to integrate with several of your locations in order to load all reviews from all platforms.
                </Text>
                <Text>
                    Once you have connected with a social platform, a list will be presented with
                    all the pages that are connected to that account. Choose the page you want to integrate with.
                    <br />
                    Once integration is successfully done, it may take up to 8 hours for your reviews to load.
                </Text>
                <Image fluid src={img1} />
            </DescriptionContainer>
        )
    }
}