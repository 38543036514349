import axios from 'axios';
import { API_URL } from '../config';
import { validateEmail } from './validateEmail';

export const sendInvitationsToImportedContacts = (contacts, location) => {

    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const headers = { headers: { "x-sas-Auth" : authToken } };
    const endpoint = `${API_URL}/private/stores/${store}/locations/${location}/review_requests`;
    let sentWithSuccess = false;

    contacts.map((contact, index) => {
        let payload;
        const name = contact.name;
        const phone = "1" + contact.phone;
        const email = contact.email;
        

        const isEmailValid = validateEmail(email);
        if (isEmailValid){
            payload = {
                name: name,
                email: email,
            }
        } else {
            payload = {
                name: name,
                phone: parseInt(phone)
            }
        }

        sentWithSuccess = axios.post(endpoint, payload, headers)
        .then(res => {
            if (index === contacts.length - 1){
                return true;
            }
        })
    })

    if (Promise.resolve(true)){
        return true;
    } else {
        return false;
    }
}

