import styled from 'styled-components';

export const NewIntegrationContainer = styled.div`
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;

    .modal-body{
      padding: 2em;
      box-sizing: border-box;
      background-color: #f8f8f8;

      p {
        text-align: center;
        width: 100%;
        max-width: 26em;
        margin: 0 auto;
        font-weight: normal;
        line-height: 30px;
        font-size: 18px;
        letter-spacing: 0.1em;
        color: #333333;
      }
    }

    @media only screen and (max-width: 500px){
        height: 100vh;
        .modal-body{
          height: 100vh;
        }
      }
`;