import React from 'react';
import { DescriptionContainer, Image } from '../../styled';
import { blueDefault } from '../../../../Assets/colorPallet/colorPallet';
import { Text } from '../../../../Assets/Structure/Structure';

export default class HowToKnowWhoAnswered extends React.Component{

    render(){
        return(
            <DescriptionContainer>
                <Text mt="0">
                   In the <strong style={{ color: blueDefault }}>Reviews</strong> section you will be able to see all your reviews.
                    To see the answer and who answered a review click on <strong style={{ color: blueDefault }}>Review answered</strong>.
                </Text>
            </DescriptionContainer>
        )
    }
}