import styled from 'styled-components';

export const SelectDateContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 20em;
    background-color: #e7e7e7;
    padding: 0 1em;
    width: 100%;
    max-width: 31em;
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;

    label{
        margin-left: 1em;
        cursor: pointer;
    }
    .arrow{
        svg{
            width: 7px;
            transform: ${props => props.isModalOn ? "rotate(180deg)" : null }
        }
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 150;
    position: fixed;
    background-color: #00000090;
`;

export const CalendarContainer = styled.div`
    z-index: 150;
    position: fixed;
    left: 0;
    margin-left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;