import styled from "styled-components";

export const ResetFilterAction = styled.button`
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #025f88;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin-top: 1em;
  transition: opacity 0.4s ease;
`;
