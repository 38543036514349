import styled from 'styled-components';

export const FeedbackContainer = styled.div`
    h2{
        font-size: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #44CA61;
        font-weight: 400;
    }
`;

export const StoreName = styled.h3`
    margin: 0;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #333333;
    font-weight: 400;
`;


export const StoreAddress = styled.p`
    margin: .5em 0 0;
    color: #999;
    font-size: 14px;
    letter-spacing: .05em;
`;