import React from "react";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import ValidateWidgetOnly from '../../utils/ValidateWidgetOnly';
import { API_URL } from "../../config";
import TeamManagement from "./TeamManagement/TeamManagement";
import { PageWrapper } from "../../Assets/Structure/Structure";

export default class Team extends React.Component {
  state = {
    searchIsActive: "",
    employees: [],
    loading: true,
    isModalOpen: false,
    resetPagination: false,
    currentPage: 1,
    totalOfEmployees: "",
    device: "",
    allEmployees: [],
    totalOfAllEmployees: "",
    routeNotAllowed: false,
    redirectUrl: "/store-location"
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const store = localStorage.getItem("store");
    const authToken = localStorage.getItem("token");
    const screenWidth = window.innerWidth;
    const headers = {headers: { "x-sas-Auth" : authToken }};

    if (screenWidth <= 500) {
      this.setState({ device: "mobile" });
    }

    if (screenWidth > 500 && screenWidth < 1030) {
      this.setState({ device: "tablet" });
    }

    if (screenWidth > 1030) {
      this.setState({ device: "desktop" });
    }

    axios.get(`${API_URL}/admin/stores/${store}/employees`, headers)
    .then(res => {
      this.setState({ 
        totalOfEmployees: res.data.total, 
        employees: res.data.employees,
        allEmployees: res.data.employees,
        totalOfAllEmployees: res.data.total,
      });
    })
    .catch(() => {})
    .finally(() => {
      this.setState({ loading: false })
    }, 1000)
  }

  handleSearchActivation = searchIsActive => {
    this.setState({ searchIsActive });
  };

  handleModal = isModalOpen => {
    this.setState({ isModalOpen });
  };

  handleUpdatedEmployees = (employees, totalOfEmployees) => {
    this.setState({ employees, totalOfEmployees });
  };

  handleRouteProtection = (redirectUrl) => {
    this.setState({ routeNotAllowed: true, redirectUrl })
  }

  handleValidateWidgetOnlyLoading = loading => this.setState({ loading })



  render() {
    if (this.state.routeNotAllowed) return <Redirect to={this.state.redirectUrl} />

    return (
      <ValidateWidgetOnly 
      protectRoute={this.handleRouteProtection} 
      currentRoute="team"
      onLoading={this.handleValidateWidgetOnlyLoading}>
        <PageWrapper>
            <TeamManagement
              isModalOpen={this.state.isModalOpen}
              searchIsActive={this.state.searchIsActive}
              addMemberClick={() => this.handleModal(true)}
              isSearchActive={this.handleSearchActivation}
              employees={this.state.employees}
              loading={this.state.loading}
              handleCloseModal={this.handleModal}
              updatedEmployees={this.handleUpdatedEmployees}
              totalOfEmployees={this.state.totalOfEmployees}
              device={this.state.device}
              allEmployees={this.state.allEmployees}
              totalOfAllEmployees={this.state.totalOfAllEmployees} />
        </PageWrapper>
      </ValidateWidgetOnly>
    );
  }
}
