// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import {
    VideoRoomMessageChatContainer,
    VideoRoomMessageHeaderContainer,
    VideoRoomMessageBodyContainer,
    VideoRoomMessageFormContainer
} from "./styled";
import {Label} from "../../../../Assets/Structure/Structure";
import {Button} from "@material-ui/core";

// Store
import { sendVideoMessage } from "../../../../store/modules/videoChat/actions"


function VideoRoomMessageChat({
    sendVideoMessageAction,
    videoRoomId,
    currentVideoRoom,
    currentVideoMessages
}) {
    // states
    const [message, setMessage] = useState("")

    const handleMessageOnChange = (e) => {
        setMessage(e.target.value)
    }

    useEffect(() => {
        console.log(currentVideoRoom)
    }, [currentVideoRoom])

    const handleSendMessage = (e) => {
        e.preventDefault()

        try {
            sendVideoMessageAction({
                message,
                author: "store",
                videoRoomId
            })
        } catch (error) {

        } finally {
            setMessage("")
        }
    }


    return (
        <VideoRoomMessageChatContainer>
            <VideoRoomMessageHeaderContainer>
                <Label fontSize="16px" uppercase bold>Felipe Fernandes</Label>
            </VideoRoomMessageHeaderContainer>
            <VideoRoomMessageBodyContainer>
                {currentVideoMessages.map((videoMessage) => (
                    <Label>{videoMessage.message}</Label>
                ))}
            </VideoRoomMessageBodyContainer>
            <VideoRoomMessageFormContainer onSubmit={handleSendMessage}>
                <input value={message} onChange={handleMessageOnChange}/>
                <Button type="submit">Send</Button>
            </VideoRoomMessageFormContainer>
        </VideoRoomMessageChatContainer>
    )
}

const mapStateToProps = state => ({
    currentVideoRoom: state.videoChat.currentVideoRoom,
    currentVideoMessages: state.videoChat.currentVideoMessages
})

const mapDispatchToProps = dispatch => ({
    sendVideoMessageAction({ message, author, videoRoomId }) {
        dispatch(sendVideoMessage({ message, author, videoRoomId }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoRoomMessageChat)
