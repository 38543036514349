import React from 'react';
import ReactSVG from 'react-svg';
import mobMenuIcon from '../../Assets/icons/mob-menu.svg';
import MobMenu from './MobMenu/MobMenu';
import { MobileMenuContainer } from './styled';

export default class MobileMenu extends React.Component{

    state = {
        isMenuActive: false
    }

    componentDidUpdate(props){
        if (this.props !== props) this.setState({ isMenuActive: false })
    }

    handleClick = () => {
        this.setState({ isMenuActive: true })
    }

    handleCloseMenu = (isMenuActive) => {
        this.setState({ isMenuActive })
    }

    handleLoggedIn = (loggedIn) => {
        this.props.loggedIn(loggedIn)
    }

    render(){
        return(
            <React.Fragment>
                <MobMenu loggedIn={this.handleLoggedIn} isMenuActive={this.state.isMenuActive} handleCloseMenu={this.handleCloseMenu} />
                <MobileMenuContainer onClick={this.handleClick}>
                    <ReactSVG src={mobMenuIcon} />
                </MobileMenuContainer>
            </React.Fragment>
        )
    }
}

