export default class VideoMessage {
    constructor({
        id,
        videoRoomId,
        message,
        author,
        createdAt
    }) {
        this.id = id
        this.videoRoomId = videoRoomId
        this.message = message
        this.author = author
        this.createdAt = createdAt
    }
}
