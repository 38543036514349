import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Module from '../../../Components/Module/Module';
import { ListContainer, ListItem } from './styled';

export default class MobileFilterModal extends React.Component{

    handleFilter = filterType => {
        this.props.handleFilter(filterType)
    }

    render(){
        return(
            <Modal 
             isModalOpen={this.props.isModalOpen}
             style={{ 
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
             }}>
                <Module maxWidth="18em">
                    <ListContainer>
                        <ListItem onClick={() => this.handleFilter("new-message")}>New messages (2)</ListItem>
                        <ListItem onClick={() => this.handleFilter("need-response")}>Need response (3)</ListItem>
                    </ListContainer>
                </Module>
            </Modal>
        )
    }
}