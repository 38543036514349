import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300}];
const COLORS = ['#025F88', '#00B1FF'];                 

export default class 

PieChartSimple extends React.Component{

	render () {
  	return (  
    	<PieChart width={300} height={200} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data} 
          cx={100} 
          cy={100} 
          labelLine={false}
          label={false}
          outerRadius={80} 
          fill="#8884d8"
        >
        	{
          	data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
          }
        </Pie>
      </PieChart>
    );
  }
}