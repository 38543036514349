import React from "react";
import axios from "axios";
import ReactSVG from "react-svg";
import { ForgotPasswordModalContainer } from "./styled";
import Modal from "../../Components/Modal/Modal";
import Module from "../../Components/Module/Module";
import {
  Text,
  Column,
  Label,
  Input,
  Row,
  Form
} from "../../Assets/Structure/Structure";
import Button from "../../Components/Button/Button";
import closeIcon from "../../Assets/icons/close.svg";
import { API_URL } from "../../config";

export default class ForgotPasswordModal extends React.Component {
  state = {
    email: "",
    showSuccessFeedback: false,
    showFailFeedback: false
  };

  handleCloseModal = () => this.props.isModalOpen(false);

  handleChange = e => this.setState({ email: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    axios
      .get(`${API_URL}/public/user/credential/forgot?email=${encodeURIComponent(this.state.email)}`)
      .then(res => {
        this.setState({ showSuccessFeedback: true, email: ""});
      })
      .catch(() => {
        this.setState({ showFailFeedback: true, email: "" });
      });
  };

  render() {
    const { isModalOpen } = this.props;

    return (
      <ForgotPasswordModalContainer>
        <Modal isModalOpen={isModalOpen} className="forgot-password-modal">
          <Module>
            <Row flexEnd>
              <ReactSVG src={closeIcon} onClick={this.handleCloseModal} />
            </Row>
            <Form onSubmit={this.handleSubmit} padding="2em">
              <Text fontSize="16px" maxWidth="28em">
                Forgot your password? No sweat. Just enter your email address
                and we will get you back on track.
              </Text>
              <Column mt="2em">
                <Label>E-mail</Label>
                <Input
                  type="email"
                  placeholder="Enter your e-mail"
                  onChange={this.handleChange}
                  value={this.state.email}
                  required
                />
              </Column>
              <Column mt="2em">
                {this.state.showSuccessFeedback ? <Label color="green" fontSize="12px">We've just sent you an email.</Label>
                : this.state.showFailFeedback ? <Label color="red" fontSize="12px">Sorry, your email is wrong.</Label>
                : null}
              </Column> 
              <Row mt="1em">
                <Button
                  text="Send"
                  type="submit"
                  uppercase
                  borderRadius="20em"
                  color="white"
                  height="50px"
                  flex
                  width="209px"
                />
              </Row>
            </Form>
          </Module>
        </Modal>
      </ForgotPasswordModalContainer>
    );
  }
}
