import React from "react";
import Overlay from './Overlay'
import ModalBody from "./ModalBody";

export default class Modal extends React.Component {

  componentDidMount(){
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  }
    
  componentWillUnmount(){
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  }
  
  handleClick = () => {
    if (this.props.doNotClose) return
    this.props.isModalOpen(false);
  };

  render() {

    const { 
      style, 
      sent, 
      maxWidth, 
      children, 
      className, 
      backgroundColor, 
      showClose, 
      title,
    } = this.props;

    return (
      <React.Fragment>
        <Overlay onClick={this.handleClick} />
        <ModalBody 
        backgroundColor={backgroundColor}
        style={style}
        maxWidth={maxWidth}
        title={title}
        sent={sent}
        showClose={showClose}
        onClose={this.handleClick}
        className={className}
        children={children} />
      </React.Fragment>
    );
  }
}
