import styled from "styled-components";

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;  
  height: 50px;
  background-color: ${props => props.avatarBgColor || "rgb(213, 152, 186)"};

  label {
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
`;
