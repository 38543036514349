import React from 'react';
import { AllReviewsContainer } from './styled';
import Overview from './Overview/Overview';
import Graphic from './Graphic/Graphic';
import LastDaysOverview from '../LastDaysOverview/LastDaysOverview';

export default class AllReviews extends React.Component{

    render(){

        const { locationOverview, loading, locationRatings, selectedLocation } = this.props;
        const totalReviews = locationOverview.reviews ? locationOverview.reviews.total : 0;
        const positiveReviews = locationOverview.reviews ? locationOverview.reviews.positive : 0;
        const neutralReviews = locationOverview.reviews ? locationOverview.reviews.neutral : 0;
        const negativeReviews = locationOverview.reviews ? locationOverview.reviews.negative : 0;
        const locationRating = locationRatings.rating;

        return(
            <AllReviewsContainer>
                <Overview 
                    totalReviews={totalReviews}
                    positiveReviews={positiveReviews}
                    neutralReviews={neutralReviews}
                    negativeReviews={negativeReviews}
                    locationRating={locationRating}
                    loading={loading} />
                <LastDaysOverview type="location" location={selectedLocation} />
            </AllReviewsContainer>
        )
    }
}